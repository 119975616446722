import { Box } from '@chakra-ui/react';
import { Divider, Modal, Typography } from '@mui/material';
import { IconBox, IconUserCircle, IconReceipt2 } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useShop } from '../../../hooks/Shop/useShop';

const ModalDetails = ({ modalOpen, handleModalClose, order }) => {
  let [data, setData] = useState(null);
  let [items, setItems] = useState(null);
  let [buyer, setBuyer] = useState(null);

  const { onGetShopOrderAdmin } = useShop();

  const getOrder = () => {
    onGetShopOrderAdmin({ order_uuid: order?.uuid }).then((res) => {
      setData(res);
      setBuyer(res?.buyer);
      setItems(res?.items);
    });
  };

  useEffect(() => {
    if (!order) return;
    getOrder();
  }, [order]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box w={'100%'} h={'100%'} bg={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'} pointerEvents={'none'}>
        <Box
          className={'container'}
          w={'85%'}
          h={'70%'}
          bg={'white'}
          borderRadius={'20px'}
          pointerEvents={'all'}
          position={'relative'}
          overflow={'hidden'}
        >
          <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
            <Box
              position={'absolute'}
              top={'2%'}
              right={'2%'}
              w={'20px'}
              h={'20px'}
              bg={'lightgrey'}
              borderRadius={'20px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              fontWeight={'bold'}
              cursor={'pointer'}
              onClick={handleModalClose}
            >
              <Typography>X</Typography>
            </Box>

            <Typography id="modal-modal-title" variant="h4" mt={1} ml={1.5}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Prezzo:
            </Typography>
            <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
              <IconReceipt2 stroke={1.5} size="1.3rem" />
              <Box ml={10}>
                <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                  Totale: {data?.total}€
                </Typography>
                <Typography id="modal-modal-description" variant="body1">
                  Prezzo ordine: {data?.total}€
                </Typography>
                <Typography id="modal-modal-description" variant="body1">
                  Prezzo spedizione: {data?.shipping?.price}€
                </Typography>
                {data?.coupon?.code && (
                  <Typography id="modal-modal-description" variant="body1">
                    Coupon utilizzato: {data?.coupon?.code}
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Prodotti:
            </Typography>
            {items?.map((item, index) => (
              <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} key={index}>
                <CalendarMonthIcon stroke={1.5} size="1.3rem" />
                <Box ml={10}>
                  <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                    {item?.variant?.product?.name}
                  </Typography>
                  <Typography id="modal-modal-description" variant="body1">
                    {item?.variant?.product?.brand?.[0]?.name}
                  </Typography>
                  <Typography id="modal-modal-description" variant="body1">
                    {item?.variant?.name} - {item?.variant?.size}
                  </Typography>
                  <Typography id="modal-modal-description" variant="body1">
                    Quantità: {item?.quantity}
                  </Typography>
                  <Typography id="modal-modal-description" variant="body2">
                    Prezzo: {item?.price}€
                  </Typography>
                </Box>
              </Box>
            ))}

            <Typography id="modal-modal-title" variant="h4" mt={1} ml={1.5}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Acquirente:
            </Typography>
            <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
              <IconUserCircle stroke={1.5} size="1.3rem" />
              <Box ml={10}>
                <Typography id="modal-modal-description" variant="body1">
                  {buyer?.name} {buyer?.surname}
                </Typography>
                <a href={`tel:${buyer?.phone_country?.dial_code}${buyer?.phone}`}>
                  <Typography id="modal-modal-description">
                    {buyer?.phone_country?.dial_code} {buyer?.phone}
                  </Typography>
                </a>
                <Typography id="modal-modal-description">{buyer?.email}</Typography>
              </Box>
            </Box>
            <Typography id="modal-modal-title" variant="h4" mt={1} ml={1.5}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Dati Spedizione:
            </Typography>
            <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
              <IconBox stroke={1.5} size="1.3rem" />
              <Box ml={10}>
                <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                  {data?.shipping_name} {data?.shipping_surname}
                </Typography>
                <Typography id="modal-modal-description">{data?.shipping_fiscal_code}</Typography>
                <Typography id="modal-modal-description">
                  {data?.shipping_address}, {data?.shipping_city}, {data?.shipping_cap}, {data?.country_shipping?.name}
                </Typography>
                <Typography id="modal-modal-description">{data?.shipping_note}</Typography>
              </Box>
            </Box>
            <Typography id="modal-modal-title" variant="h4" mt={1} ml={1.5}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Dati Fatturazione:
            </Typography>
            <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
              <IconBox stroke={1.5} size="1.3rem" />
              <Box ml={10}>
                <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                  {data?.invoice_name} {data?.invoice_surname}
                </Typography>
                <Typography id="modal-modal-description">{data?.invoice_fiscal_code}</Typography>
                <Typography id="modal-modal-description">
                  {data?.invoice_address}, {data?.invoice_city}, {data?.invoice_cap}, {data?.country_invoice?.name}
                </Typography>
                <Typography id="modal-modal-description">{data?.invoice_note}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            className={'bottom_buttons'}
            h={'50px'}
            position={'absolute'}
            bottom={0}
            w={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={20}
          ></Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalDetails;
