import gql from 'graphql-tag';

export const LIST_OFFICES_CITIES_ADMIN = gql`
  query listOfficesCitiesAdmin {
    adminRoute {
      listOfficesCitiesAdmin {
        id
        name
        icon
        active
      }
    }
  }
`;

export const CREATE_OFFICE_CITY_ADMIN = gql`
  query createOfficeCityAdmin($office_city_data: OfficeCityInput!) {
    adminRoute {
      createOfficeCityAdmin(office_city_data: $office_city_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_OFFICE_CITY_ADMIN = gql`
  query updateOfficeCityAdmin($office_city_data: OfficeCityInput!, $office_city_id: ID!) {
    adminRoute {
      updateOfficeCityAdmin(office_city_id: $office_city_id, office_city_data: $office_city_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_OFFICE_CITY_ADMIN = gql`
  query deleteOfficeCityAdmin($office_city_id: ID!) {
    adminRoute {
      deleteOfficeCityAdmin(office_city_id: $office_city_id) {
        response
        responseStatus
      }
    }
  }
`;
