import gql from 'graphql-tag';

export const COUNT_UNREAD_MESSAGES_DOCTOR = gql`
  query countUnreadMessagesDoctor {
    adminRoute {
      countUnreadMessagesDoctor
    }
  }
`;

export const LIST_CHATS_DOCTOR = gql`
  query listChatsDoctor($query: String, $limit: Int, $page: Int, $deleted_too: Boolean) {
    adminRoute {
      listChatsDoctor(query: $query, limit: $limit, page: $page, deleted_too: $deleted_too) {
        count
        rows {
          id
          uuid
          blocked
          patient {
            name
            surname
            profile_pic
          }
          last_message_time
          messages {
            id
            message
            createdAt
          }
          doctor_unread_messages
        }
      }
    }
  }
`;

export const GET_CHAT_DOCTOR = gql`
  query getChatDoctor($chat_uuid: String!, $limit: Int, $page: Int) {
    adminRoute {
      getChatDoctor(page: $page, limit: $limit, chat_uuid: $chat_uuid) {
        id
        uuid
        blocked
        doctor_deleted
        to_admin
        patient {
          uuid
          name
          surname
          profile_pic
          email
        }
        messages {
          id
          message
          sender_id
          createdAt
        }
      }
    }
  }
`;

export const GET_CHAT_WITH_ADMIN_DOCTOR = gql`
  query getChatDoctor($limit: Int, $page: Int) {
    adminRoute {
      getChatWithAdminDoctor(page: $page, limit: $limit) {
        id
        uuid
        blocked
        doctor_deleted
        patient_unread_messages
        last_message_time
        messages {
          id
          message
          sender_id
          createdAt
        }
      }
    }
  }
`;

export const START_CHAT_DOCTOR = gql`
  query startChatDoctor($patient_uuid: String!) {
    adminRoute {
      startChatDoctor(patient_uuid: $patient_uuid) {
        responseStatus
        response
        new_uuid
      }
    }
  }
`;

export const SEND_CHAT_MESSAGE_DOCTOR = gql`
  query sendChatMessageDoctor($chat_uuid: String!, $message: String!) {
    adminRoute {
      sendChatMessageDoctor(chat_uuid: $chat_uuid, message: $message) {
        responseStatus
        response
      }
    }
  }
`;

export const BLOCK_CHAT_DOCTOR = gql`
  query blockChatDoctor($chat_uuid: String!) {
    adminRoute {
      blockChatDoctor(chat_uuid: $chat_uuid) {
        responseStatus
        response
      }
    }
  }
`;

export const DELETE_CHAT_DOCTOR = gql`
  query deleteChatDoctor($chat_uuid: String!) {
    adminRoute {
      deleteChatDoctor(chat_uuid: $chat_uuid) {
        responseStatus
        response
      }
    }
  }
`;
