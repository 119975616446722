import gql from 'graphql-tag';

//**** SHOP PRODUCTS ****//

export const LIST_SHOP_PRODUCTS_BY_QUERY = gql`
  query listShopProductsByQuery($query: String!, $onlyActive: Boolean, $forceAll: Boolean) {
    adminRoute {
      listShopProductsByQuery(query: $query, onlyActive: $onlyActive, forceAll: $forceAll) {
        id
        uuid
        name
        brand {
          name
        }
        variants {
          uuid
          name
          size
          price
          discount
          discount_price
        }
      }
    }
  }
`;

export const LIST_SHOP_WAREHOUSE_PAGINATED = gql`
  query listShopWarehousePaginated($filters: mixedFilters, $query: String, $status: WarehouseStatus) {
    adminRoute {
      listShopWarehousePaginated(filters: $filters, query: $query, status: $status) {
        rows {
          id
          uuid
          name
          quantity
          size
          price
          product {
            name
          }
        }
      }
    }
  }
`;

export const LIST_SHOP_PRODUCTS_BY_UUIDS = gql`
  query listShopProductsByUuids($products_uuids: [String!]!) {
    adminRoute {
      listShopProductsByUuids(products_uuids: $products_uuids) {
        id
        uuid
        name
        media {
          id
          uuid
          media_link
        }
      }
    }
  }
`;

export const GET_SHOP_PRODUCT = gql`
  query getShopProductAdmin($product_uuid: String!) {
    adminRoute {
      getShopProductAdmin(product_uuid: $product_uuid) {
        uuid
        name
        description
        ingredients
        active
        categories {
          uuid
          name
          type
        }
        variants {
          uuid
          name
          size
          price
          discount
          discount_price
          quantity
          active
        }
        media {
          uuid
          media_link
        }
      }
    }
  }
`;

export const CREATE_SHOP_PRODUCT = gql`
  query createShopProduct($product_data: ShopProductInput!) {
    adminRoute {
      createShopProduct(product_data: $product_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_SHOP_PRODUCT = gql`
  query updateShopCategory($product_uuid: String!, $product_data: ShopProductInput!) {
    adminRoute {
      updateShopProduct(product_uuid: $product_uuid, product_data: $product_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_SHOP_PRODUCT_VARIANT_QUANTITY = gql`
  query updateShopProductVariantQuantity($variant_uuid: String!, $quantity: Int!) {
    adminRoute {
      updateShopProductVariantQuantity(variant_uuid: $variant_uuid, quantity: $quantity) {
        response
        responseStatus
      }
    }
  }
`;

export const UPLOAD_SHOP_PRODUCT_MEDIA = gql`
  query uploadShopProductMedia($product_uuid: String, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadShopProductMedia(product_uuid: $product_uuid, filename: $filename, contentType: $contentType) {
        key
        presignedUrl
        uuid
      }
    }
  }
`;

export const DELETE_SHOP_PRODUCT = gql`
  query deleteShopCategory($product_uuid: String!) {
    adminRoute {
      deleteShopProduct(product_uuid: $product_uuid) {
        response
        responseStatus
      }
    }
  }
`;

//**** SHOP CATEGORIES ****//

export const LIST_SHOP_CATEGORIES = gql`
  query listShopCategories($type: String) {
    adminRoute {
      listShopCategories(type: $type) {
        uuid
        name
        description
        type
        discount
        discount_type
        discount_value
        active
        discounts {
          uuid
        }
      }
    }
  }
`;

export const GET_SHOP_CATEGORY = gql`
  query getShopCategory($category_uuid: String!) {
    adminRoute {
      getShopCategory(category_uuid: $category_uuid) {
        uuid
        name
        description
        type
        discount
        discount_type
        discount_value
        active
      }
    }
  }
`;

export const CREATE_SHOP_CATEGORY = gql`
  query createShopCategory($category_data: ShopCategoryInput!) {
    adminRoute {
      createShopCategory(category_data: $category_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_SHOP_CATEGORY = gql`
  query updateShopCategory($category_uuid: String!, $category_data: ShopCategoryInput!) {
    adminRoute {
      updateShopCategory(category_uuid: $category_uuid, category_data: $category_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_SHOP_CATEGORY = gql`
  query deleteShopCategory($category_uuid: String!) {
    adminRoute {
      deleteShopCategory(category_uuid: $category_uuid) {
        response
        responseStatus
      }
    }
  }
`;

//**** SHOP DISCOUNTS ****//

export const LIST_SHOP_DISCOUNTS = gql`
  query listShopDiscounts {
    adminRoute {
      listShopDiscounts {
        uuid
        title
        categories {
          id
        }
        products {
          id
        }
      }
    }
  }
`;

export const GET_SHOP_DISCOUNT = gql`
  query getShopDiscount($discount_uuid: String!) {
    adminRoute {
      getShopDiscount(discount_uuid: $discount_uuid) {
        uuid
        title
        active
        categories {
          uuid
          name
          type
        }
        products {
          uuid
          name
          variants {
            uuid
            name
            size
            discount
            discount_price
          }
        }
      }
    }
  }
`;

export const CREATE_SHOP_DISCOUNT = gql`
  query createShopDiscount($discount_data: ShopDiscountInput!) {
    adminRoute {
      createShopDiscount(discount_data: $discount_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_SHOP_DISCOUNT = gql`
  query updateShopCategory($discount_uuid: String!, $discount_data: ShopDiscountInput!) {
    adminRoute {
      updateShopDiscount(discount_uuid: $discount_uuid, discount_data: $discount_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_SHOP_DISCOUNT = gql`
  query deleteShopDiscount($discount_uuid: String!) {
    adminRoute {
      deleteShopDiscount(discount_uuid: $discount_uuid) {
        response
        responseStatus
      }
    }
  }
`;

//! SHOP BANNERS

export const LIST_SHOP_BANNERS = gql`
  query listShopBanners {
    adminRoute {
      listShopBanners {
        uuid
        title
        subtitle
        image
        active
      }
    }
  }
`;

export const LIST_SHOP_BANNERS_BACKGROUNDS = gql`
  query listShopBannersBackgrounds {
    adminRoute {
      listShopBannersBackgrounds {
        uuid
        color
        circle
      }
    }
  }
`;

export const GET_SHOP_BANNER = gql`
  query getShopBanner($banner_uuid: String!) {
    adminRoute {
      getShopBanner(banner_uuid: $banner_uuid) {
        uuid
        title
        active
        subtitle
        image
        image_web
        image_web_position
        background {
          uuid
          color
        }
        discounts {
          uuid
        }
      }
    }
  }
`;

export const CREATE_SHOP_BANNER = gql`
  query createShopBanner($banner_data: ShopBannerInput!) {
    adminRoute {
      createShopBanner(banner_data: $banner_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_SHOP_BANNER = gql`
  query updateShopBanner($banner_uuid: String!, $banner_data: ShopBannerInput!) {
    adminRoute {
      updateShopBanner(banner_uuid: $banner_uuid, banner_data: $banner_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPLOAD_SHOP_BANNER_IMAGE = gql`
  query uploadShopBannerImage($banner_uuid: String!, $contentType: String!, $filename: String!, $image_type: ShopBannersImageType!) {
    adminRoute {
      uploadShopBannerImage(banner_uuid: $banner_uuid, contentType: $contentType, filename: $filename, image_type: $image_type) {
        presignedUrl
        key
        uuid
      }
    }
  }
`;

export const DELETE_SHOP_BANNER = gql`
  query deleteShopBanner($banner_uuid: String!) {
    adminRoute {
      deleteShopBanner(banner_uuid: $banner_uuid) {
        response
        responseStatus
      }
    }
  }
`;

//! SHOP REVIEWS

export const LIST_PRODUCTS_REVIEWS = gql`
  query listShopProductsReviews($product_id: ID, $account_id: ID, $rating: Int, $limit: Int, $page: Int) {
    adminRoute {
      listShopProductsReviews(product_id: $product_id, account_id: $account_id, rating: $rating, limit: $limit, page: $page) {
        count
        rows {
          id
          uuid
          review
          product {
            name
            brand {
              name
            }
          }
          reviewer {
            id
            name
            surname
          }
          reported_by {
            id
          }
        }
      }
    }
  }
`;

export const GET_SHOP_PRODUCT_REVIEW = gql`
  query getShopProductReview($review_uuid: String!) {
    adminRoute {
      getShopProductReview(review_uuid: $review_uuid) {
        id
        uuid
        review
        rating
        product {
          name
          brand {
            name
          }
        }
        reviewer {
          id
          name
          surname
          email
          phone_country {
            dial_code
          }
          phone
        }
        reported_by {
          id
          name
          surname
          email
          phone_country {
            dial_code
          }
          phone
        }
      }
    }
  }
`;

export const SET_SHOP_REVIEW_REPORTS_IGNORED = gql`
  query setShopIgnoredReviewReports($review_uuid: String!) {
    adminRoute {
      setShopIgnoredReviewReports(review_uuid: $review_uuid) {
        responseStatus
        response
      }
    }
  }
`;

export const SET_SHOP_HIDDEN_REVIEW = gql`
  query setShopHiddenReview($review_uuid: String!) {
    adminRoute {
      setShopHiddenReview(review_uuid: $review_uuid) {
        responseStatus
        response
      }
    }
  }
`;

export const UPDATE_SHOP_REVIEW = gql`
  query updateShopReview($review_uuid: String!, $review_data: ShopReviewInput!) {
    adminRoute {
      updateShopReview(review_uuid: $review_uuid, review_data: $review_data) {
        responseStatus
        response
      }
    }
  }
`;

//! SHOP ORDERS

export const LIST_SHOP_ORDERS_ADMIN = gql`
  query listShopOrdersAdmin($account_uuid: String, $status: String, $limit: Int, $page: Int) {
    adminRoute {
      listShopOrdersAdmin(account_uuid: $account_uuid, status: $status, limit: $limit, page: $page) {
        count
        rows {
          id
          uuid
          total
          subtotal
          createdAt
          buyer {
            id
            name
            surname
            email
            phone
            phone_country {
              dial_code
            }
          }
          status
        }
      }
    }
  }
`;

export const COUNT_SHOP_ORDERS_ADMIN = gql`
  query countShopOrdersAdmin($status: String) {
    adminRoute {
      countShopOrdersAdmin(status: $status)
    }
  }
`;

export const GET_SHOP_ORDER_ADMIN = gql`
  query getShopOrderAdmin($order_uuid: String!) {
    adminRoute {
      getShopOrderAdmin(order_uuid: $order_uuid) {
        id
        uuid
        total
        subtotal
        status
        shipping_name
        shipping_surname
        shipping_fiscal_code
        shipping_note
        shipping_address
        shipping_state_id
        shipping_city
        shipping_cap
        invoice_name
        invoice_surname
        invoice_fiscal_code
        invoice_note
        invoice_address
        invoice_state_id
        invoice_city
        invoice_cap
        tracking_link
        invoice_link
        country_invoice {
          name
        }
        country_shipping {
          name
        }
        buyer {
          id
          name
          surname
          email
          phone
          phone_country {
            dial_code
          }
        }
        shipping {
          price
        }
        coupon {
          code
        }
        items {
          id
          uuid
          quantity
          price
          variant {
            id
            uuid
            name
            size
            product {
              id
              uuid
              name
              brand {
                id
                name
              }
              media {
                id
                uuid
                media_link
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SHOP_ORDER_STATUS_ADMIN = gql`
  query updateShopOrderStatusAdmin($order_uuid: String!, $status: String!) {
    adminRoute {
      updateShopOrderStatusAdmin(order_uuid: $order_uuid, status: $status) {
        responseStatus
        response
      }
    }
  }
`;

export const UPDATE_SHOP_ORDER_ADMIN = gql`
  query updateShopOrderAdmin($order_uuid: String!, $order_data: ShopOrderInput!) {
    adminRoute {
      updateShopOrderAdmin(order_uuid: $order_uuid, order_data: $order_data) {
        responseStatus
        response
      }
    }
  }
`;

export const UPLOAD_SHOP_ORDER_INVOICE = gql`
  query uploadShopOrderInvoice($order_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadShopOrderInvoice(order_uuid: $order_uuid, filename: $filename, contentType: $contentType) {
        key
        presignedUrl
        uuid
      }
    }
  }
`;

export const DELETE_SHOP_ORDER_ADMIN = gql`
  query deleteShopOrderAdmin($order_uuid: String!) {
    adminRoute {
      deleteShopOrderAdmin(order_uuid: $order_uuid) {
        response
        responseStatus
      }
    }
  }
`;

//! SHOP COUPONS

export const LIST_SHOP_COUPONS_ADMIN = gql`
  query listShopCouponsAdmin($limit: Int, $page: Int, $query: String) {
    adminRoute {
      listShopCouponsAdmin(limit: $limit, page: $page, query: $query) {
        count
        rows {
          id
          uuid
          code
          active
          used
          discount_value
          discount_type
        }
      }
    }
  }
`;

export const GET_SHOP_COUPON_ADMIN = gql`
  query getShopCouponAdmin($coupon_uuid: String!) {
    adminRoute {
      getShopCouponAdmin(coupon_uuid: $coupon_uuid) {
        id
        uuid
        code
        account_id
        discount_type
        discount_value
        redeem_limit
        expiration_date
        active
        account {
          id
          name
          surname
        }
      }
    }
  }
`;

export const CREATE_SHOP_COUPON_ADMIN = gql`
  query createShopCouponAdmin($coupon_data: ShopCouponInput!) {
    adminRoute {
      createShopCouponAdmin(coupon_data: $coupon_data) {
        responseStatus
        response
      }
    }
  }
`;

export const UPDATE_SHOP_COUPON_ADMIN = gql`
  query updateShopCouponAdmin($coupon_uuid: String!, $coupon_data: ShopCouponInput!) {
    adminRoute {
      updateShopCouponAdmin(coupon_uuid: $coupon_uuid, coupon_data: $coupon_data) {
        responseStatus
        response
      }
    }
  }
`;

export const DELETE_SHOP_COUPON_ADMIN = gql`
  query deleteShopCouponAdmin($coupon_uuid: String!) {
    adminRoute {
      deleteShopCouponAdmin(coupon_uuid: $coupon_uuid) {
        responseStatus
        response
      }
    }
  }
`;

//! SHOP COMMISSIONS

export const GET_COMMISSIONS_DATA = gql`
  query getCommissionsData {
    adminRoute {
      getCommissionsData {
        total_commissions {
          total
          total_formatted
          next_goal
          actual_start
        }
        monthly_commissions {
          total
          total_formatted
          next_goal
          actual_start
        }
        total_orders {
          total
          total_formatted
          next_goal
          actual_start
        }
        total_users {
          total
          total_formatted
          next_goal
          actual_start
        }
        total_doctors {
          total
          total_formatted
          next_goal
          actual_start
        }
        total_appointments {
          total
          total_formatted
          next_goal
          actual_start
        }
      }
    }
  }
`;

export const LIST_SHOP_MONTHLY_COMMISSIONS = gql`
  query listShopMonthlyCommissions($filters: mixedFilters) {
    adminRoute {
      listShopMonthlyCommissions(filters: $filters) {
        rows {
          id
          uuid
          doctor_id
          amount
          month
          payed
        }
      }
    }
  }
`;

export const LIST_SHOP_MONTHLY_COMMISSIONS_ADMIN = gql`
  query listShopMonthlyCommissionsAdmin($filters: mixedFilters) {
    adminRoute {
      listShopMonthlyCommissionsAdmin(filters: $filters) {
        rows {
          id
          uuid
          doctor_id
          amount
          month
          payed
          doctor {
            id
            name
            surname
            email
          }
        }
      }
    }
  }
`;

export const GET_SUGGESTED_USERS = gql`
  query getSuggestedUsers($filters: mixedFilters) {
    adminRoute {
      getSuggestedUsers(filters: $filters) {
        rows {
          id
          uuid
          name
          surname
          orders {
            id
            items {
              order_id
            }
          }
        }
      }
    }
  }
`;

export const GET_COMMISSION = gql`
  query getCommission($month: String!) {
    adminRoute {
      getCommission(month: $month) {
        amount
        month
      }
    }
  }
`;

export const GET_COMMISSION_ITEMS = gql`
  query getCommissionItems($month: String!) {
    adminRoute {
      getCommissionItems(month: $month) {
        rows {
          amount
          commissionAt
          item {
            variant {
              product {
                name
                brand {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SET_COMMISSION_PAYED = gql`
  query setCommissionPayed($commission_uuid: ID!) {
    adminRoute {
      setCommissionPayed(commission_uuid: $commission_uuid) {
        response
        responseStatus
      }
    }
  }
`;
