import gql from 'graphql-tag';

export const LIST_PRODUCTS_TARGETS_ADMIN = gql`
  query listProductsTargetsAdmin {
    adminRoute {
      listProductsTargetsAdmin {
        id
        name
        icon
      }
    }
  }
`;

export const CREATE_PRODUCTS_TARGET_ADMIN = gql`
  query createProductTargetAdmin($product_target_data: ProductTargetInput!) {
    adminRoute {
      createProductTargetAdmin(product_target_data: $product_target_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_PRODUCTS_TARGET_ADMIN = gql`
  query updateProductTargetAdmin($product_target_data: ProductTargetInput!, $product_target_id: ID!) {
    adminRoute {
      updateProductTargetAdmin(product_target_id: $product_target_id, product_target_data: $product_target_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_PRODUCTS_TARGET_ADMIN = gql`
  query deleteProductTargetAdmin($product_target_id: ID!) {
    adminRoute {
      deleteProductTargetAdmin(product_target_id: $product_target_id) {
        response
        responseStatus
      }
    }
  }
`;
