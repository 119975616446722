import React from 'react';

import { grey } from '@mui/material/colors';
import { Box, Link, Text } from '@chakra-ui/react';

const primary = grey[300]; // #f44336

export default function Error() {
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'100vh'} backgroundColor={primary}>
      <Box id="notfound" pos={'relative'} width={'100%'} height={'100%'}>
        <Box
          maxWidth={'767px'}
          width={'100%'}
          textAlign={'center'}
          lineHeight={1.4}
          position={'absolute'}
          left={'50%'}
          top={'50%'}
          transform={'translate(-50%,-50%)'}
        >
          <Box className="notfound-404" height={'200px'}>
            <Text variant={'h1'} fontFamily={'Montserrat, sans-serif'} fontSize={'146px'} fontWeight={700} margin={0} color={'#232323'}>
              4
              <Box
                backgroundImage={'url(https://i.imgur.com/hVt1qxm.png)'}
                backgroundSize={'cover'}
                display={'inline-block'}
                width={'120px'}
                height={'120px'}
                transform={'scale(1.4)'}
                zIndex={-1}
              ></Box>
              4
            </Text>
          </Box>
          <Text
            variant={'h2'}
            fontFamily={'Montserrat, sans-serif'}
            fontSize={'22px'}
            fontWeight={700}
            margin={0}
            textTransform={'uppercase'}
            color={'#232323'}
          >
            Oops! Page Not Be Found
          </Text>
          <Text fontFamily={'Montserrat, sans-serif'} color={'#232323'} fontWeight={300} marginTop={'20px'}>
            Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable
          </Text>
          <Link
            href="/"
            fontFamily={'Montserrat, sans-serif'}
            display={'inline-block'}
            padding={'12px 30px'}
            fontWeight={700}
            backgroundColor={'#f99827'}
            color={'#fff'}
            borderRadius={'40px'}
            textDecoration={'none'}
            transition={'0.2s all'}
            marginTop={'20px'}
          >
            Back to homepage
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
