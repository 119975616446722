import { Box, Image } from '@chakra-ui/react';
import { Button, Divider, Modal, Typography } from '@mui/material';
import { IconPhoto, IconTrash } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import 'moment/locale/it';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import DropzoneFormikInput from '../../utilities/Dropzone';

const ModalUserPicsDoctor = ({ modalOpen, handleModalClose, user }) => {
  const { onDeleteUserMedia, onGetDoctorAdmin } = useAccounts();
  let [media, setMedia] = React.useState([]);

  const getDoctor = async () => {
    if (!user?.uuid) return;
    onGetDoctorAdmin({ doctor_uuid: user?.uuid }).then((res) => {
      setMedia(res?.media);
    });
  };

  useEffect(() => {
    getDoctor();
  }, [user?.uuid]);

  const handleDeleteMedia = async (media_id) => {
    await onDeleteUserMedia({ media_id });
    await getDoctor();
  };

  const handleReloadPics = async () => {
    await getDoctor();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Box
            w={'100vw'}
            h={'100vh'}
            bg={'transparent'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            pointerEvents={'none'}
          >
            <Box
              className={'container'}
              w={'85%'}
              h={'70%'}
              bg={'white'}
              borderRadius={'20px'}
              pointerEvents={'all'}
              position={'relative'}
              overflow={'hidden'}
            >
              <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                <Box
                  position={'absolute'}
                  top={'2%'}
                  right={'2%'}
                  w={'20px'}
                  h={'20px'}
                  bg={'lightgrey'}
                  borderRadius={'20px'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  fontWeight={'bold'}
                  cursor={'pointer'}
                  onClick={handleModalClose}
                >
                  <Typography>X</Typography>
                </Box>

                <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                  Foto Utente
                </Typography>
                {media?.map((pic, index) => (
                  <Box
                    display={'flex'}
                    mt={5}
                    bg={'rgba(200,200,200,0.2)'}
                    p={'10px'}
                    alignItems={'center'}
                    position={'relative'}
                    key={index}
                  >
                    <Box position={'absolute'} top={10} right={10}>
                      <Button variant={'contained'} color={'error'} type={'button'} onClick={() => handleDeleteMedia(pic?.id)}>
                        <IconTrash stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                    <Box>
                      <Typography variant={'h6'}>Foto {index + 1}</Typography>
                    </Box>
                    <IconPhoto stroke={1.5} size="1.3rem" />
                    <Box ml={20} w={'85%'}>
                      <Box key={index} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} my={2}>
                        <Image src={pic?.media_link} alt="Pic" w={'100px'} h={'100px'} />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Divider />
              <Box
                className={'bottom_buttons'}
                h={'50px'}
                position={'absolute'}
                bottom={0}
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                px={20}
              >
                <DropzoneFormikInput
                  variant={'account_media'}
                  setFieldValue={() => {}}
                  field={'icon'}
                  setLoading={() => {}}
                  folder={'account'}
                  label={'Carica immagine'}
                  callback={handleReloadPics}
                  extra_data={{ account_uuid: user?.uuid }}
                />
              </Box>
            </Box>
          </Box>
        </>
      </Box>
    </Modal>
  );
};
export default ModalUserPicsDoctor;
