import { Box } from '@chakra-ui/react';
import { IconCake, IconCheck, IconGenderBigender } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { toast } from 'react-toastify';
import { UIModal, UIModalBody, UIModalButtons, UIModalDivider, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UITypography } from '../../utilities/Typography';
import { UIFormControlBox } from '../../utilities/Form';
import { UICheckbox, UITextarea } from '../../utilities/Input';
import { UIButton } from '../../utilities/Button';

const ModalUserDoctor = ({ modalOpen, handleModalClose, user }) => {
  let [userData, setUserData] = useState(null);

  const { onUpdateUserDoctor, onGetUserAdmin } = useAccounts();

  const getUserData = async () => {
    onGetUserAdmin({
      user_uuid: user.uuid
    }).then((res) => {
      setUserData(res);
    });
  };

  useEffect(() => {
    if (!user) return;
    getUserData();
  }, [user]);
  const handleSubmit = async (values) => {
    const { pregnant, permanent_makeup, anamnesi, allergies, other_allergies, private_note } = values;

    if (user?.id) {
      onUpdateUserDoctor({
        user_id: userData.id,
        user_data: {
          pregnant,
          permanent_makeup,
          anamnesi,
          allergies,
          other_allergies,
          private_note
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  const handleModalCloseInternal = () => {
    setUserData(null);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <Formik
        initialValues={{
          pregnant: userData?.pregnant ?? false,
          permanent_makeup: userData?.permanent_makeup ?? false,
          anamnesi: userData?.anamnesi ?? '',
          allergies: userData?.allergies ?? '',
          other_allergies: userData?.other_allergies ?? '',
          private_note: userData?.private_note ?? ''
        }}
        validationSchema={Yup.object().shape({
          pregnant: Yup.boolean().nullable(),
          permanent_makeup: Yup.boolean().nullable(),
          anamnesi: Yup.string().nullable(),
          allergies: Yup.string().nullable(),
          other_allergies: Yup.string().nullable(),
          private_note: Yup.string().nullable()
        })}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ errors, submitForm, handleSubmit, touched, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} id={'appointment-form'}>
            <UiModalInternal onClose={handleModalCloseInternal} isLoading={userData === null}>
              <UIModalHeader sx={{ border: 'none', p: '50px 0 0 0' }}>
                <UITypography
                  variant={'Oxygen700'}
                  title={`${userData?.name} ${userData?.surname}`}
                  size={'18px'}
                  sxCustom={{
                    textAlign: 'center',
                    color: '#101828'
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '40px',
                    mt: '10px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '20px',
                      gap: '5px'
                    }}
                  >
                    <IconCake size={'20px'} stroke={'1.64'} />
                    <UITypography
                      variant={'Oxygen700'}
                      title={`${userData?.age}`}
                      size={'13px'}
                      sxCustom={{
                        textAlign: 'center',
                        mt: '2px'
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '20px',
                      gap: '5px'
                    }}
                  >
                    <IconGenderBigender size={'20px'} stroke={'1.64'} />
                    <UITypography
                      variant={'Oxygen700'}
                      title={`${userData?.sex === 'M' ? 'Uomo' : userData?.sex === 'F' ? 'Donna' : 'Altro'}`}
                      size={'13px'}
                      sxCustom={{
                        textAlign: 'center',
                        mt: '2px'
                      }}
                    />
                  </Box>
                </Box>
              </UIModalHeader>
              <UIModalBody sx={{ bgcolor: 'transparent' }}>
                <UIFormControlBox label={'Anamnesi'} error={Boolean(touched.anamnesi && errors.anamnesi)}>
                  <UITextarea
                    name={'anamnesi'}
                    value={values?.anamnesi}
                    onChange={(event) => setFieldValue('anamnesi', event.target.value)}
                    expandable={false}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Allergie mediche'} error={Boolean(touched.allergies && errors.allergies)}>
                  <UITextarea
                    name={'allergies'}
                    value={values?.allergies}
                    onChange={(event) => setFieldValue('allergies', event.target.value)}
                    expandable={false}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Allergie generali'} error={Boolean(touched.other_allergies && errors.other_allergies)}>
                  <UITextarea
                    name={'other_allergies'}
                    value={values?.other_allergies}
                    onChange={(event) => setFieldValue('other_allergies', event.target.value)}
                    expandable={false}
                  />
                </UIFormControlBox>

                <Box sx={{ display: 'flex', py: '2px', pl: '20px', justifyContent: 'center', maxWidth: '500px', m: '0 auto' }}>
                  <UIFormControlBox sx={{ width: '40%' }} error={Boolean(touched.pregnant && errors.pregnant)}>
                    <UICheckbox
                      label={<UITypography title={'Gravidanza'} />}
                      name={'pregnant'}
                      onChange={(event) => setFieldValue('pregnant', event.target.checked)}
                      readOnly={true}
                      checked={values?.pregnant}
                    />
                  </UIFormControlBox>
                  <UIFormControlBox>
                    <UICheckbox
                      label={<UITypography title={'Make-up Permanente'} />}
                      name={'permanent_makeup'}
                      readOnly={true}
                      checked={values?.permanent_makeup}
                      onChange={(event) => setFieldValue('permanent_makeup', event.target.checked)}
                    />
                  </UIFormControlBox>
                </Box>

                <UIModalDivider />

                <UIFormControlBox
                  label={'Note'}
                  subtitle={'Saranno visibili solo agli altri medici'}
                  error={Boolean(touched.private_note && errors.private_note)}
                >
                  <UITextarea
                    name={'private_note'}
                    value={values?.private_note}
                    onChange={(event) => setFieldValue('private_note', event.target.value)}
                    expandable={false}
                  />
                </UIFormControlBox>
              </UIModalBody>

              <UIModalButtons>
                <UIButton
                  type={'submit'}
                  sxCustom={{
                    lineHeight: '14.5px',
                    textTransform: 'none',
                    letterSpacing: '0.5px'
                  }}
                  icon={<IconCheck size={'26px'} stroke={'1.67'} />}
                  onClick={() => submitForm()}
                />
              </UIModalButtons>
            </UiModalInternal>
          </form>
        )}
      </Formik>
    </UIModal>
  );
};
export default ModalUserDoctor;
