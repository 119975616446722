import { toast } from 'react-toastify';

export const manageError = (error, apollo) => {
  let message;

  switch (error?.message) {
    case 'Failed to fetch':
    case 'Load failed':
      message = `Errore di caricamento, aggiornare la pagina. [CODE: ${apollo?.definitions?.[0]?.name?.value}]`;
      break;
    case 'EMAIL_ALREADY_EXISTS':
      message = "L'email inserita è già presente";
      break;
    case 'APPOINTMENT_DATE_ERROR_INVALID':
      message = 'La data non è valida';
      break;
    case 'APPOINTMENT_DATE_ERROR_IMPOSSIBLE':
      message = 'La data di fine deve essere successiva alla data di inizio';
      break;
    case 'APPOINTMENT_DATE_ERROR_EQUAL':
      message = 'La data di fine non può essere uguale alla data di inizio';
      break;
    case 'APPOINTMENT_DATE_ERROR_PAST':
      message = 'La data non può essere nel passato';
      break;
    case 'APPOINTMENT_DATA_ERROR_EMPTY':
      message = "I dati dell'appuntamento sono vuoti";
      break;
    case 'PERMISSION_DENIED':
      message = 'Non hai i permessi per eseguire questa azione';
      break;
    case 'WAITING_NOT_FOUND':
      message = 'Appuntamento già rimosso.';
      break;
    case 'APPOINTMENT_NOT_FOUND':
      message = 'Appuntamento non trovato.';
      break;
    case 'TREATMENT_NOT_FOUND':
      message = 'Trattamento non trovato.';
      break;
    case 'INTERVALS_REQUIRED':
      message = "E' necessario inserire almeno un intervallo";
      break;
    case 'TIME_NOT_FOUND':
      message = 'Uno degli intervalli richiesti non è impostato';
      break;
    case 'TIME_INVALID':
      message = 'Uno degli intervalli inseriti non è valido';
      break;
    case 'APPOINTMENT_UPDATE_TREATMENT_NOT_FOUND':
      message = 'Il trattamento specificato non è stato associato al calendario del dottore specificato';
      break;
    case 'APPOINTMENT_CREATE_TREATMENT_NOT_FOUND':
      message = 'Il trattamento specificato non è stato associato al calendario del dottore specificato';
      break;
    case 'EMPTY_STEPS':
      message = "Serve collegare almeno uno step per inviare il protocollo all'utente";
      break;
    case 'ALREADY_REFUNDED':
      message = 'Ordine già rimborsato';
      break;
    case 'VARIANT_NOT_FOUND':
      message = 'Prodotto non trovato';
      break;
    case 'USER_NOT_FOUND':
      message = 'Utente non trovato';
      break;
    case 'COUPON_CODE_ALREADY_EXIST':
      message = 'Codice sconto già utilizzato in un altro coupon';
      break;
    case 'ACCOUNT_NOT_ACTIVE':
      message = 'Account disattivato';
      break;
    case 'ACCOUNT_NOT_AUTHORIZED':
      message = 'Account non autorizzato';
      break;
    case 'WRONG_PASSWORD':
      message = 'Password errata.';
      break;
    default:
      message = `${error?.message}. [CODE: ${apollo?.definitions?.[0]?.name?.value}]`;
      break;
  }

  toast(message, {
    style: {
      fontSize: '14px',
      backgroundColor: 'red',
      color: '#ffffff'
    }
  });
};
