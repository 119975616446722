import { LIST_COUNTRIES, UPLOAD_ICON } from './graphql/queries';
import { useMutation } from '../useMutation';

/** @format */
export const useGeneral = () => {
  const { getDataGQL: uploadIcon } = useMutation(UPLOAD_ICON);
  const { getDataGQL: listCountries } = useMutation(LIST_COUNTRIES);

  const onUploadIcon = async ({ filename, contentType, folder }) => {
    const response = await uploadIcon({
      variables: {
        filename,
        contentType,
        folder
      }
    });
    if (response?.adminRoute?.uploadIcon) {
      return response?.adminRoute?.uploadIcon;
    }
    return null;
  };

  const onListCountries = async () => {
    const response = await listCountries();
    if (response?.adminRoute?.listCountries) {
      return response?.adminRoute?.listCountries;
    }
    return null;
  };

  return {
    onUploadIcon,
    onListCountries
  };
};
