import { AspectRatio, Box, Image } from '@chakra-ui/react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material';
import { IconCheck, IconSignature, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useProtocols } from '../../../hooks/Protocols/useProtocols';
import { useShop } from '../../../hooks/Shop/useShop';

const ModalEditStep = ({ modalOpen, handleModalClose, handleRefreshData, protocol_uuid, step_data, step, time }) => {
  let [query, setQuery] = useState('');
  let [products, setProducts] = useState([]);
  let [stepData, setStepData] = useState(false);
  let [deleteMode, setDeleteMode] = useState(false);
  let [productsToAdd, setProductsToAdd] = useState([]);
  let [selectedProducts, setSelectedProducts] = useState([]);

  const { onDeleteProtocolProduct, onAddProtocolProduct, onCreateProtocolStep, onUpdateProtocolStep, onDeleteProtocolStep } =
    useProtocols();
  const { onListShopProductsByQuery, onListShopProductsByUuids } = useShop();

  const getProducts = async () => {
    onListShopProductsByQuery({ query, onlyActive: true }).then((res) => {
      setProducts(res);
    });
  };

  useEffect(() => {
    if (query.length > 2 || query.length === 0) {
      getProducts();
    }
  }, [query]);

  useEffect(() => {
    if (!stepData?.uuid) {
      onListShopProductsByUuids({ products_uuids: productsToAdd }).then((res) => {
        setSelectedProducts(res);
      });
    }
  }, [productsToAdd]);

  useEffect(() => {
    setStepData(step_data);
  }, [step_data]);

  const handleAddProduct = async (product_uuid) => {
    if (product_uuid) {
      if (!stepData?.uuid) {
        setProductsToAdd([...productsToAdd, product_uuid]);
      } else {
        await onAddProtocolProduct({
          step_uuid: stepData?.uuid,
          product_uuid
        });
        handleRefreshData();
      }
    }
  };

  const handleDeleteProduct = async (product_uuid) => {
    if (!stepData?.uuid) {
      setProductsToAdd(productsToAdd.filter((item) => item !== product_uuid));
    } else {
      await onDeleteProtocolProduct({
        step_uuid: stepData?.uuid,
        product_uuid
      });
      handleRefreshData();
    }
  };

  const handleDeleteStep = async () => {
    await onDeleteProtocolStep({
      step_uuid: stepData?.uuid
    });
    setDeleteMode(false);
    internalHandleModalClose();
  };

  const internalHandleModalClose = () => {
    setDeleteMode(false);
    setSelectedProducts([]);
    setProductsToAdd([]);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { title } = values;

    if (stepData?.uuid) {
      onUpdateProtocolStep({
        step_uuid: stepData?.uuid,
        step_data: {
          title,
          step,
          time
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          internalHandleModalClose();
        }
      });
    } else {
      onCreateProtocolStep({
        protocol_uuid,
        step_data: {
          title,
          step,
          time
        }
      }).then(async (res) => {
        if (res?.response) {
          for (let product of selectedProducts) {
            await onAddProtocolProduct({
              step_uuid: res?.new_uuid,
              product_uuid: product.uuid
            });
          }

          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          internalHandleModalClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={internalHandleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
            <DialogTitle>Elimina Step</DialogTitle>
            <DialogContent>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <DialogContentText>Vuoi eliminare lo step corrente?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
                Annulla
              </Button>
              <Button variant={'contained'} color={'success'} onClick={handleDeleteStep}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>

          <Formik
            initialValues={{
              title: stepData?.title ?? ''
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Il titolo è obbligatorio')
            })}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={internalHandleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Box>
                        <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                          {stepData?.uuid ? 'Modifica' : 'Aggiungi'} Step {(stepData?.time ?? time) === 'day' ? 'Giorno' : 'Notte'}
                        </Typography>

                        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                          <IconSignature stroke={1.5} size="1.3rem" />
                          <Box ml={10}>
                            <FormControl fullWidth error={Boolean(touched.title && errors.title)}>
                              <InputLabel htmlFor="input-title">Titolo</InputLabel>
                              <OutlinedInput
                                id="input-title"
                                type="text"
                                value={values.title}
                                name="title"
                                onBlur={handleBlur}
                                onChange={() => setFieldValue('title', event.target.value)}
                                label="Titolo"
                                inputProps={{
                                  maxLength: 20
                                }}
                              />
                              {touched.title && errors.title && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                  {errors.title}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>

                      <>
                        <Box>
                          <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                            Prodotti dello step
                          </Typography>

                          {stepData?.products?.map((product, index) => (
                            <Box
                              key={index}
                              display={'flex'}
                              mt={5}
                              bg={'rgba(200,200,200,0.2)'}
                              p={'10px'}
                              alignItems={'center'}
                              justifyContent={'flex-start'}
                              position={'relative'}
                            >
                              <Box position={'absolute'} top={2} right={10} onClick={() => handleDeleteProduct(product?.uuid)}>
                                <IconX stroke={1.5} size="1.3rem" />
                              </Box>
                              <AspectRatio width="50px" ratio={1}>
                                <Image width={'50px'} src={product?.media?.[0]?.media_link} />
                              </AspectRatio>
                              <Box w={'100%'} ml={10}>
                                <Typography variant="subtitle1">{product.name}</Typography>
                                <Box>
                                  <Typography key={index} variant="body1">
                                    {product.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))}

                          {selectedProducts?.map((product, index) => (
                            <Box
                              key={index}
                              display={'flex'}
                              mt={5}
                              bg={'rgba(200,200,200,0.2)'}
                              p={'10px'}
                              alignItems={'center'}
                              justifyContent={'flex-start'}
                              position={'relative'}
                            >
                              <Box position={'absolute'} top={2} right={10} onClick={() => handleDeleteProduct(product?.uuid)}>
                                <IconX stroke={1.5} size="1.3rem" />
                              </Box>
                              <AspectRatio width="50px" ratio={1}>
                                <Image width={'50px'} src={product?.media?.[0]?.media_link} />
                              </AspectRatio>
                              <Box w={'100%'} ml={10}>
                                <Typography variant="subtitle1">{product.name}</Typography>
                                <Box>
                                  <Typography key={index} variant="body1">
                                    {product.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                        <Box>
                          <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                            Aggiungi un prodotto
                          </Typography>

                          <Box w={'100%'} mt={20}>
                            <FormControl fullWidth error={Boolean(touched.product && errors.product)}>
                              <Autocomplete
                                id="demo-simple-select"
                                fullWidth={true}
                                name={'calendar_id'}
                                defaultValue={() => {
                                  let product = products.find((product) => product.uuid === values.product);

                                  if (!product) {
                                    return '';
                                  }

                                  return {
                                    uuid: product?.uuid,
                                    label: `${product?.name}`
                                  };
                                }}
                                options={products?.map((product) => {
                                  return {
                                    uuid: product?.uuid,
                                    label: `${product?.name}`
                                  };
                                })}
                                onKeyUp={(e) => {
                                  setQuery(e.target.value);
                                }}
                                onChange={(e, data) => {
                                  setFieldValue('product', data?.uuid ?? '');
                                }}
                                onBlur={handleBlur}
                                renderInput={(params) => <TextField {...params} label="Prodotto" />}
                              />
                              {touched.product && errors.product && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                  {errors.product}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>

                          <Box mt={5} display={'flex'} justifyContent={'center'}>
                            <Button
                              variant={'contained'}
                              color={'primary'}
                              onClick={() => {
                                handleAddProduct(values.product);
                              }}
                            >
                              Aggiungi prodotto
                            </Button>
                          </Box>
                        </Box>
                        {stepData?.uuid && (
                          <Box d={'flex'} justifyContent={'center'}>
                            <Box
                              fontSize={15}
                              textAlign={'center'}
                              color={'#f44336'}
                              fontFamily={'Montserrat, sans-serif'}
                              mt={10}
                              fontWeight={700}
                              textTransform={'uppercase'}
                              bg={'rgba(200,200,200,0.2)'}
                              p={'5px '}
                              cursor={'pointer'}
                              onClick={() => setDeleteMode(true)}
                            >
                              Elimina Step
                            </Box>
                          </Box>
                        )}
                      </>
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'} onClick={internalHandleModalClose}>
                        <IconX stroke={1.5} size="1rem" />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};

export default ModalEditStep;
