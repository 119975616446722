import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';
import { LIST_OFFICES_CITIES_ADMIN, CREATE_OFFICE_CITY_ADMIN, UPDATE_OFFICE_CITY_ADMIN, DELETE_OFFICE_CITY_ADMIN } from './graphql/queries';

/** @format */
export const useOfficesCities = () => {
  const { getDataGQL: listOfficesCitiesAdmin } = useQuery(LIST_OFFICES_CITIES_ADMIN);
  const { getDataGQL: createOfficeCityAdmin } = useMutation(CREATE_OFFICE_CITY_ADMIN);
  const { getDataGQL: updateOfficeCityAdmin } = useMutation(UPDATE_OFFICE_CITY_ADMIN);
  const { getDataGQL: deleteOfficeCityAdmin } = useMutation(DELETE_OFFICE_CITY_ADMIN);

  const onListOfficesCitiesAdmin = async () => {
    const response = await listOfficesCitiesAdmin();
    if (response?.adminRoute?.listOfficesCitiesAdmin) {
      return response?.adminRoute?.listOfficesCitiesAdmin;
    }
    return null;
  };

  const onCreateOfficeCityAdmin = async ({ office_city_data }) => {
    const response = await createOfficeCityAdmin({
      variables: {
        office_city_data
      }
    });
    if (response?.adminRoute?.createOfficeCityAdmin) {
      return response?.adminRoute?.createOfficeCityAdmin;
    }
    return null;
  };

  const onUpdateOfficeCityAdmin = async ({ office_city_id, office_city_data }) => {
    const response = await updateOfficeCityAdmin({
      variables: {
        office_city_id,
        office_city_data
      }
    });
    if (response?.adminRoute?.updateOfficeCityAdmin) {
      return response?.adminRoute?.updateOfficeCityAdmin;
    }
    return null;
  };

  const onDeleteOfficeCityAdmin = async ({ office_city_id }) => {
    const response = await deleteOfficeCityAdmin({
      variables: {
        office_city_id
      }
    });
    if (response?.adminRoute?.deleteOfficeCityAdmin) {
      return response?.adminRoute?.deleteOfficeCityAdmin;
    }
    return null;
  };

  return {
    onListOfficesCitiesAdmin,
    onCreateOfficeCityAdmin,
    onUpdateOfficeCityAdmin,
    onDeleteOfficeCityAdmin
  };
};
