import { ApolloClient, ApolloLink, defaultDataIdFromObject, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getAuthInfo } from './storage';

const authLink = setContext(async (_, { headers }) => {
  const payload = await getAuthInfo();

  const token = `Bearer ${payload?.token}`;
  return {
    headers: {
      ...headers,
      Authorization: token
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      return console.warn(`GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'Location':
        return `Location:${object.placeId}`;
      default:
        return defaultDataIdFromObject(object);
    }
  }
});

const link = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
});

const apolloLink = ApolloLink.from([errorLink, authLink, link]);
export const makeApolloClient = new ApolloClient({
  link: apolloLink,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    },
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
});
