import { useQuery } from '../useQuery';
import { Login, Me } from './graphql/queries';
import { useAuthentication } from '../../utils/useAuthentication';

/** @format */
export const useAuth = () => {
  let data = useAuthentication();
  const { getDataGQL: login } = useQuery(Login);
  const { getDataGQL: me } = useQuery(Me);

  const onLogout = async () => {
    await data.logout();
  };

  const onLogin = async ({ email, password, device_id }) => {
    const response = await login({
      variables: {
        email,
        password,
        device_id
      }
    });
    if (response?.login) {
      return response.login;
    }
    return null;
  };

  const getMe = async () => {
    const response = await me();
    if (response?.getMe) {
      return response.getMe;
    }
    return null;
  };

  return {
    onLogout,
    onLogin,
    getMe
  };
};
