import { Divider, InputLabel, Link, MenuItem, Modal, Select, Typography } from '@mui/material';
import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { useAuthentication } from '../../../utils/useAuthentication';
import { MaterialReactTable } from 'material-react-table';
import { useReactToPrint } from 'react-to-print';
import { UIButton } from '../../utilities/Button';

export const ModalCalendarPrint = ({ modalOpen, handleModalClose }) => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [appointments, setAppointments] = useState([]);
  const componentToPrint = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
    documentTitle: startDate.startOf('day').isSame(endDate.startOf('day'))
      ? `Skinboost_Calendario_${startDate.format('DD-MM-YYYY')}`
      : `Skinboost_Calendario_${startDate.format('DD-MM-YYYY')}_${endDate.format('DD-MM-YYYY')}`,
    bodyClass: 'print'
  });

  const { onListOffices } = useOffices();
  const { onGetDoctorsAppointmentsToPrint } = useCalendar();

  useEffect(() => {
    onListOffices().then((res) => {
      setOffices(res);
    });
  }, []);

  useEffect(() => {
    if (selectedOffice && startDate && endDate) {
      onGetDoctorsAppointmentsToPrint({
        doctors: [user?.id],
        offices: [selectedOffice],
        start: startDate.utc(true),
        end: endDate.utc(true)
      }).then((res) => {
        setAppointments(res);
      });
    }
  }, [selectedOffice, startDate, endDate]);

  const handleClose = () => {
    setAppointments([]);
    setSelectedOffice('');
    setStartDate(moment());
    setEndDate(moment());
    handleModalClose();
  };

  return (
    <Modal open={modalOpen} onClose={handleClose} className={'modal'}>
      <Box w={'100%'} h={'100%'} bg={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'} pointerEvents={'none'}>
        <Box
          className={'container'}
          w={'85%'}
          h={'70%'}
          bg={'white'}
          borderRadius={'20px'}
          pointerEvents={'all'}
          position={'relative'}
          overflow={'hidden'}
        >
          <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
            <Box
              position={'absolute'}
              top={'2%'}
              right={'2%'}
              w={'20px'}
              h={'20px'}
              bg={'lightgrey'}
              borderRadius={'20px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              fontWeight={'bold'}
              cursor={'pointer'}
              onClick={handleClose}
            >
              <Typography>X</Typography>
            </Box>

            <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5} textAlign={'center'}>
              Stampa calendario
            </Typography>

            <Box m={'20px auto'} w={'90%'}>
              <Box>
                <InputLabel id="type-label">Ufficio</InputLabel>
                <Select
                  name={'type'}
                  fullWidth={true}
                  labelId="type-label"
                  value={selectedOffice}
                  id="type-select"
                  label="Ufficio"
                  onChange={(e) => {
                    setSelectedOffice(e.target.value);
                  }}
                >
                  {offices?.map((office) => (
                    <MenuItem key={office?.id} value={office?.id}>
                      {office?.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box mt={'20px'} display={'flex'} gap={'15px'}>
                <DatePicker
                  value={startDate}
                  dateFormat="dd/MM/yyyy"
                  label="Data di inizio"
                  onChange={(res) => {
                    setStartDate(res);
                  }}
                  locale={'it'}
                  style={{ width: '100%', border: '1px solid red' }}
                  disablePast={true}
                />
                <DatePicker
                  value={endDate}
                  dateFormat="dd/MM/yyyy"
                  label="Data di fine"
                  onChange={(res) => {
                    setEndDate(res);
                  }}
                  locale={'it'}
                  style={{ width: '100%', border: '1px solid red' }}
                  disablePast={true}
                />
              </Box>

              <UIButton variant={'confirm'} onClick={handlePrint} label={'Stampa'} sx={{ mt: '20px' }} />

              <Box mt={'20px'} ref={componentToPrint}>
                <MaterialReactTable
                  columns={[
                    {
                      header: 'Paziente',
                      accessorKey: 'patientEl'
                    },
                    {
                      header: 'Appuntamento',
                      accessorKey: 'appointmentEl'
                    },
                    {
                      header: 'Trattamento',
                      accessorKey: 'treatmentEl'
                    }
                  ]}
                  data={appointments?.map((appointment) => {
                    return {
                      appointment: appointment,
                      patientEl: (
                        <Box>
                          {appointment?.status !== 'blocked' ? (
                            <>
                              <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                                {appointment?.patient?.name
                                  ? `${appointment?.patient?.name} ${appointment?.patient?.surname}`
                                  : `${appointment?.patient_alias}`}
                              </Typography>
                              <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'13px'} color={'#667085'}>
                                <Link
                                  href={`tel:${appointment?.patient?.email ? appointment?.patient?.email : appointment?.patient_email}`}
                                  target={'_blank'}
                                  rel={'noreferrer'}
                                  component={'a'}
                                  underline={'none'}
                                  color={'#000'}
                                >
                                  {appointment?.patient?.phone
                                    ? `${appointment?.patient?.phone_country?.dial_code} ${appointment?.patient?.phone}`
                                    : `${appointment?.patient_phone}`}
                                </Link>
                              </Typography>
                              <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'13px'} color={'#667085'}>
                                <Link
                                  href={`mailto:${appointment?.patient?.email ? appointment?.patient?.email : appointment?.patient_email}`}
                                  target={'_blank'}
                                  rel={'noreferrer'}
                                  component={'a'}
                                  underline={'always'}
                                  color={'#000'}
                                >
                                  {appointment?.patient?.email ? appointment?.patient?.email : appointment?.patient_email}
                                </Link>
                              </Typography>
                            </>
                          ) : (
                            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                              Blocco
                            </Typography>
                          )}
                        </Box>
                      ),
                      appointmentEl: (
                        <Box>
                          <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                            {moment(appointment?.date + ' ' + appointment?.start_hour).format('HH:mm ')}
                          </Typography>
                          <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'11px'}>
                            {moment(appointment?.date + ' ' + appointment?.start_hour).format('DD/MM/YYYY')}
                          </Typography>
                          <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'11px'}>
                            {appointment?.calendar?.office?.name}
                          </Typography>
                          <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'11px'}>
                            {appointment?.calendar?.doctor?.name} {appointment?.calendar?.doctor?.surname}
                          </Typography>
                        </Box>
                      ),
                      treatmentEl: (
                        <Box>
                          <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                            {appointment?.treatment?.name}
                          </Typography>
                          <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'11px'}>
                            {appointment?.treatment?.doctor_treatment?.price} €
                          </Typography>
                          <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'11px'}>
                            {moment(appointment?.date + ' ' + appointment?.end_hour).diff(
                              moment(appointment?.date + ' ' + appointment?.start_hour),
                              'minutes'
                            )}{' '}
                            minuti
                          </Typography>
                          {appointment?.note && (
                            <>
                              <Divider
                                sx={{
                                  border: '1px solid #000',
                                  m: '5px 0px'
                                }}
                              />
                              <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'11px'}>
                                {appointment?.note}
                              </Typography>
                            </>
                          )}
                        </Box>
                      )
                    };
                  })}
                  enablePagination={false}
                  enableBottomToolbar={false}
                  enableRowVirtualization={false}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableFilters={false}
                  enableTopToolbar={false}
                  enableSorting={false}
                  enableRowActions={false}
                  enableHiding={false}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  muiTablePaperProps={{
                    sx: { width: '100%', height: 'auto', overflow: 'hidden' }
                  }}
                  muiTopToolbarProps={{
                    sx: { height: '5px', minHeight: '5px' }
                  }}
                  muiTableContainerProps={{
                    sx: { maxHeight: '100%', width: '97%', tableLayout: 'fixed' }
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
