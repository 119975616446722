// import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
// import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import UITypography from '../../../utilities/Typography';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AuthWrapper1>
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)', position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '25px',
                zIndex: 0,
                bgcolor: '#FECE2E',
                borderRadius: '100%',
                width: '670px',
                height: '670px'
              }}
            />

            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0, position: 'relative', zIndex: 10 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <UITypography
                    variant={'Oxygen700'}
                    size={'40px'}
                    title={'Skinboost Pro'}
                    sxCustom={{ color: '#000', position: 'absolute', top: '-50px', fontFamily: 'ButlerBold' }}
                  />

                  <Grid item xs={12}>
                    <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <UITypography
                            variant={'Oxygen700'}
                            size={'32px'}
                            title={'Ciao!'}
                            sxCustom={{
                              color: '#000'
                            }}
                          />

                          <UITypography
                            variant={'Oxygen400'}
                            size={'16px'}
                            title={'Inserisci le tue credenziali per accedere alla dashboard'}
                            sxCustom={{
                              color: '#8A92A6',
                              p: '0 30px',
                              textAlign: 'center'
                            }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthLogin />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
          <AuthFooter />
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default Login;
