import { Box } from '@chakra-ui/react';
import { Chip } from '@mui/material';
import { IconCheck } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { UIModal, UIModalBody, UIModalButtons, UIModalDivider, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UIPageHeader } from '../../utilities/Headers';
import { UIFormControlBox } from '../../utilities/Form';
import UITypography from '../../utilities/Typography';
import { UICheckbox, UIInput } from '../../utilities/Input';
import { toast } from 'react-toastify';
import { useOffices } from '../../../hooks/Offices/useOffices';
import UIInputAutocomplete from '../../utilities/InputSelect';
import { UIButton } from '../../utilities/Button';

const ModalTreatmentEditDoctor = ({ modalOpen, handleModalClose, treatment }) => {
  const [treatmentData, setTreatmentData] = useState(null);
  const [offices, setOffices] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);

  const { onUpdateTreatmentDoctor, onGetTreatmentDoctor } = useTreatments();
  const { onListOffices } = useOffices();

  useEffect(() => {
    if (!treatment) return;
    onGetTreatmentDoctor({ treatment_id: treatment?.id }).then((res) => {
      setTreatmentData(res);
    });

    onListOffices().then((res) => {
      setOffices(res);
    });
  }, [treatment]);

  useEffect(() => {
    if (!treatmentData) return;

    handleInitSelectedOffices();
  }, [treatmentData]);

  const handleSubmit = async (values) => {
    const { price, time, active } = values;

    onUpdateTreatmentDoctor({
      treatment_id: treatment.id,
      treatment_data: {
        price: parseFloat(price),
        time: Number(time),
        offices: selectedOffices?.map((office) => office?.id),
        active
      }
    }).then((res) => {
      if (res?.response) {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        handleModalClose();
      }
    });
  };

  const handleSelectOffice = (data) => {
    if (selectedOffices.find((office) => office?.id === data?.id)) return;

    let office = offices.find((office) => office?.id === data?.id);

    setSelectedOffices([...selectedOffices, office]);
  };

  const handleInitSelectedOffices = () => {
    let offices = [];
    treatmentData?.doctor_treatment?.calendars_treatments?.forEach((calendar) => {
      if (!calendar?.calendar?.office) return;
      offices?.push(calendar?.calendar?.office);
    });

    setSelectedOffices(offices);
  };

  const handleInternalClose = () => {
    setSelectedOffices([]);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleInternalClose} allowFullScreen={true}>
      <UiModalInternal
        onClose={handleInternalClose}
        isLoading={treatment === null}
        containerSx={{
          bgcolor: '#FFF'
        }}
      >
        <Formik
          initialValues={{
            price: treatmentData?.doctor_treatment?.price ?? 0,
            time: treatmentData?.doctor_treatment?.time ?? 0,
            active: treatmentData?.doctor_treatment?.active ?? false
          }}
          validationSchema={Yup.object().shape({
            price: Yup.number().min(1, 'Il prezzo non può essere minore di 1').required('Il prezzo è obbligatorio'),
            time: Yup.number().integer('Solo numeri interi').min(1, 'Il tempo non può essere negativo').required('Il tempo è obbligatorio'),
            active: Yup.boolean()
          })}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({ errors, handleSubmit, touched, values, setFieldValue, submitForm }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalHeader>
                <UIPageHeader
                  title={treatment?.name}
                  sx={{
                    textAlign: 'left'
                  }}
                />

                <UITypography
                  variant={'Oxygen400'}
                  title={treatment?.description}
                  sxCustom={{
                    m: '0px 10px 5px 10px'
                  }}
                />
              </UIModalHeader>

              <UIModalBody
                sx={{
                  bgcolor: '#FFF'
                }}
              >
                <UITypography
                  title={'Dati Trattamento'}
                  size={'20px'}
                  sxCustom={{
                    textAlign: 'left',
                    color: '#101828',
                    mt: '30px',

                    py: '2px',
                    px: '20px'
                  }}
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '10px',
                    w: '88%',
                    m: '0 auto'
                  }}
                >
                  <UIFormControlBox
                    label={'Prezzo'}
                    sx={{
                      px: 0
                    }}
                    error={Boolean(touched?.price && errors?.price)}
                    error_message={errors?.price}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <UIInput
                        type={'number'}
                        value={values?.price}
                        onChange={() => setFieldValue('price', event.target.value)}
                        inputProps={{
                          step: 1,
                          min: 0,
                          lang: 'en-150'
                        }}
                      />
                      <UITypography variant={'Oxygen700'} size={'12px'} title={'€'} />
                    </Box>
                  </UIFormControlBox>

                  <UIFormControlBox
                    label={'Durata'}
                    sx={{
                      px: 0
                    }}
                    error={Boolean(touched?.time && errors?.time)}
                    error_message={errors?.time}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <UIInput
                        type={'number'}
                        value={values?.time}
                        onChange={() => setFieldValue('time', event.target.value)}
                        inputProps={{
                          step: 1,
                          min: 0,
                          lang: 'en-150'
                        }}
                      />
                      <UITypography variant={'Oxygen700'} size={'12px'} title={'Minuti'} />
                    </Box>
                  </UIFormControlBox>
                </Box>

                <UIFormControlBox label={'Dove viene praticato'}>
                  <UIInputAutocomplete
                    options={offices?.map((office) => {
                      return {
                        id: office?.id,
                        label: office?.name
                      };
                    })}
                    setValue={(data) => {
                      if (data?.id) {
                        handleSelectOffice(data);
                      }
                    }}
                    label={''}
                    sx={{
                      mr: '10px'
                    }}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '5px ',
                      flexWrap: 'wrap',
                      p: '10px 0 5px 0',
                      lineHeight: 'normal'
                    }}
                  >
                    {selectedOffices?.map((office, index) => (
                      <Chip
                        key={index}
                        label={office?.name}
                        variant="outlined"
                        deleteIcon={true}
                        onDelete={() => {
                          setSelectedOffices(selectedOffices.filter((selectedOffice) => selectedOffice?.id !== office?.id));
                        }}
                        sx={{
                          width: 'calc(50% - 5px)',
                          '.MuiChip-label': {
                            py: '10px',
                            whiteSpace: 'initial',
                            textOverflow: 'initial'
                          },
                          '.MuiSvgIcon-root': {
                            color: '#FECE2E !important'
                          }
                        }}
                      />
                    ))}
                  </Box>
                </UIFormControlBox>

                <UIModalDivider
                  sx={{
                    m: '10px auto'
                  }}
                />

                <UIFormControlBox>
                  <UICheckbox
                    label={<UITypography variant={'Oxygen700'} title={'Prenotabile'} size={'12px'} />}
                    checked={values?.active}
                    onChange={(event) => setFieldValue('active', event.target.checked)}
                  />
                </UIFormControlBox>
              </UIModalBody>
              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()} icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};
export default ModalTreatmentEditDoctor;
