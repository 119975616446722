import { useAuthentication } from '../../../utils/useAuthentication';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import ModalEdit from './modalEdit';
import { useShop } from '../../../hooks/Shop/useShop';

const ShopBannersView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const { onListShopBanners } = useShop();

  const getBanners = useCallback(async () => {
    onListShopBanners().then((response) => {
      setBanners(response);
    });
  }, []);

  useEffect(() => {
    getBanners();
  }, []);

  const handleModalClose = useCallback(() => {
    getBanners();
    setEditMode(false);
  }, []);

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedBanner(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>

      <ModalEdit user={user} modalOpen={editMode} handleModalClose={handleModalClose} banner={selectedBanner} setEditMode={setEditMode} />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} mt={2}>
        Banners
      </Typography>
      <Typography
        color={'#5e5e5e'}
        variant={'h6'}
        align={'center'}
        fontSize={13}
        mt={1}
        mb={2}
        fontWeight={'normal'}
        width={'90%'}
        lineHeight={'1.1'}
        mx={'auto'}
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Una volta creati gli sconti, puoi raggrupparli sotto un banner. <br /> Quando selezionato dall'app, appariranno i prodotti correlati
        anche se appartenenti a sconti diversi
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Attivo</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banners?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'} width={'33%'}>
                  {row.title}
                </TableCell>
                <TableCell align="center">
                  <Checkbox checked={row.active} color="primary" disabled={true} />
                </TableCell>
                <TableCell align="center">
                  <IconEdit
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedBanner(row);
                      setEditMode(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ShopBannersView;
