import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import ModalUserAdmin from '../users/modalUserAdmin';
import ModalUserDoctor from '../users/modalUserDoctor';
import { useAuthentication } from '../../../utils/useAuthentication';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { UIModal, UIModalBody, UIModalButtons, UIModalDivider, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { Box } from '@mui/material';
import { Image } from '@chakra-ui/react';
import { IconCake, IconCameraPlus, IconEdit, IconGenderBigender, IconLock } from '@tabler/icons-react';
import { UIFormControlBox } from '../../utilities/Form';
import { UICheckbox, UITextarea } from '../../utilities/Input';
import { UITypography } from '../../utilities/Typography';
import { UIButton } from '../../utilities/Button';
import ModalUserAppointments from '../users/modalUserAppointments';
import ModalUserPicsDoctor from '../users/ModalUserPicsDoctor';
import { UiDialog } from '../../utilities/Dialog';

const ModalUserDetails = ({ modalOpen, handleModalClose, userRow }) => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  let [userData, setUserData] = useState(null);
  let [editMode, setEditMode] = useState(false);
  let [picsMode, setPicsMode] = useState(false);
  let [banMode, setBanMode] = useState(false);
  let [appointmentsMode, setAppointmentsMode] = useState(false);
  let [appointmentsType, setAppointmentsType] = useState('new');

  let { onGetUserAdmin, onBanUserDoctor } = useAccounts();

  const getUserData = async () => {
    onGetUserAdmin({
      user_uuid: userRow.uuid
    }).then((res) => {
      setUserData(res);
    });
  };

  useEffect(() => {
    if (!userRow) return;
    getUserData();
  }, [userRow]);

  const handleModalCloseEdit = () => {
    setEditMode(false);
    setPicsMode(false);
    getUserData();
  };

  const handleModalCloseEditAppointment = () => {
    setAppointmentsMode(false);
  };

  const handleModalCloseInternal = () => {
    setUserData(null);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      {user?.admin ? (
        <ModalUserAdmin modalOpen={editMode} handleModalClose={handleModalCloseEdit} user={userData} />
      ) : (
        <ModalUserDoctor modalOpen={editMode} handleModalClose={handleModalCloseEdit} user={userData} />
      )}
      <ModalUserPicsDoctor modalOpen={picsMode} handleModalClose={handleModalCloseEdit} user={userData} />

      <>
        <UiDialog
          type={'default'}
          variant={'alert'}
          title={'Vuoi bloccare questo utente?'}
          subtitle={"L'utente non potrà più prenotare appuntamenti"}
          open={banMode}
          onClose={() => setBanMode(false)}
          onConfirm={() => {
            onBanUserDoctor({ user_uuid: userData?.uuid }).then(() => {
              setBanMode(false);
            });
          }}
        />
      </>

      <ModalUserAppointments
        modalOpen={appointmentsMode}
        handleModalClose={handleModalCloseEditAppointment}
        user={userData}
        type={appointmentsType}
      />

      <UiModalInternal
        onClose={handleModalCloseInternal}
        isLoading={userData === null}
        containerSx={{
          bgcolor: '#FFF'
        }}
      >
        <UIModalHeader>
          <Box
            sx={{
              width: '75px',
              height: '75px',
              overflow: 'hidden',
              borderRadius: '100%',
              mx: 'auto'
            }}
          >
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              src={userData?.profile_pic ?? 'https://skinboost-prod.fra1.digitaloceanspaces.com/placeholder/paziente_empty.png'}
              alt={userData?.name}
            />
          </Box>

          <Box>
            <UITypography
              variant={'Oxygen700'}
              title={`${userData?.name} ${userData?.surname}`}
              size={'18px'}
              sxCustom={{
                textAlign: 'center',
                color: '#101828'
              }}
            />

            <UITypography
              variant={'Oxygen400'}
              title={`${userData?.phone_country?.dial_code} ${userData?.phone}`}
              size={'14px'}
              sxCustom={{
                textAlign: 'center',
                color: '#101828',
                mt: '2px'
              }}
            />

            <UITypography
              variant={'Oxygen700'}
              title={`${userData?.email}`}
              size={'14px'}
              sxCustom={{
                textAlign: 'center',
                textDecoration: 'underline',
                mt: '2px'
              }}
            />
          </Box>
        </UIModalHeader>
        <UIModalBody>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '40px',
              mt: '10px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '20px',
                gap: '5px'
              }}
            >
              <IconCake size={'20px'} stroke={'1.64'} />
              <UITypography
                variant={'Oxygen700'}
                title={`${userData?.age}`}
                size={'13px'}
                sxCustom={{
                  textAlign: 'center',
                  mt: '2px'
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '20px',
                gap: '5px'
              }}
            >
              <IconGenderBigender size={'20px'} stroke={'1.64'} />
              <UITypography
                variant={'Oxygen700'}
                title={`${userData?.sex === 'M' ? 'Uomo' : userData?.sex === 'F' ? 'Donna' : 'Altro'}`}
                size={'13px'}
                sxCustom={{
                  textAlign: 'center',
                  mt: '2px'
                }}
              />
            </Box>
          </Box>

          <UIFormControlBox label={'Anamnesi'}>
            <UITextarea name={'anamnesi'} readOnly={true} value={userData?.anamnesi} />
          </UIFormControlBox>

          <UIFormControlBox label={'Allergie mediche'}>
            <UITextarea name={'test'} readOnly={true} value={userData?.allergies} resize={'none'} />
          </UIFormControlBox>

          <UIFormControlBox label={'Allergie generali'}>
            <UITextarea name={'test'} readOnly={true} value={userData?.other_allergies} resize={'none'} />
          </UIFormControlBox>

          <Box sx={{ display: 'flex', py: '2px', pl: '20px', justifyContent: 'center', maxWidth: '500px', m: '0 auto' }}>
            <UIFormControlBox>
              <UICheckbox label={<UITypography title={'Gravidanza'} />} name={'test'} readOnly={true} checked={userData?.pregnant} />
            </UIFormControlBox>
            <UIFormControlBox>
              <UICheckbox
                label={<UITypography title={'Make-up Permanente'} />}
                name={'test'}
                readOnly={true}
                checked={userData?.permanent_makeup}
              />
            </UIFormControlBox>
          </Box>

          <UIModalDivider />

          <UIFormControlBox>
            <UITypography variant={'Oxygen700'} title={'Note :'} size={'13px'} />
            <UITypography variant={'Oxygen400'} title={userData?.private_note} size={'13px'} />
          </UIFormControlBox>

          <UIModalDivider />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              p: '20px'
            }}
          >
            <UIButton
              type={'button'}
              variant={'copy'}
              label={'Appuntamenti passati'}
              sxCustom={{
                lineHeight: '14.5px',
                textTransform: 'none',
                letterSpacing: '0.5px'
              }}
              onClick={() => {
                setAppointmentsType('old');
                setAppointmentsMode(true);
              }}
            />
            <UIButton
              type={'button'}
              sxCustom={{
                lineHeight: '14.5px',
                textTransform: 'none',
                letterSpacing: '0.5px'
              }}
              variant={'copy'}
              label={'Appuntamenti futuri'}
              onClick={() => {
                setAppointmentsType('new');
                setAppointmentsMode(true);
              }}
            />
          </Box>
        </UIModalBody>
        <UIModalButtons>
          <UIButton
            type={'modal-option'}
            sxCustom={{
              lineHeight: '14.5px',
              textTransform: 'none',
              letterSpacing: '0.5px'
            }}
            icon={<IconEdit size={'26px'} stroke={'1.67'} />}
            onClick={() => setEditMode(true)}
          />
          <UIButton
            type={'modal-option'}
            sxCustom={{
              lineHeight: '14.5px',
              textTransform: 'none',
              letterSpacing: '0.5px'
            }}
            icon={<IconCameraPlus size={'26px'} stroke={'1.67'} />}
            onClick={() => setPicsMode(true)}
          />
          <UIButton
            type={'modal-option'}
            sxCustom={{
              lineHeight: '14.5px',
              textTransform: 'none',
              letterSpacing: '0.5px'
            }}
            icon={<IconLock size={'26px'} stroke={'1.67'} />}
            onClick={() => setBanMode(true)}
          />
        </UIModalButtons>
      </UiModalInternal>
    </UIModal>
  );
};

export default ModalUserDetails;
