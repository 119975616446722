import { Box } from '@chakra-ui/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import { IconCheck, IconColorFilter, IconSignature } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { useShop } from '../../../hooks/Shop/useShop';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import DropzoneFormikInput from '../../utilities/Dropzone';

const ModalTracking = ({ modalOpen, handleModalClose, order }) => {
  let [data, setData] = useState(null);
  const [deleteMode, setDeleteMode] = useState(false);

  const { onGetShopOrderAdmin, onUpdateShopOrderAdmin, onDeleteShopOrderAdmin } = useShop();

  const getOrder = () => {
    onGetShopOrderAdmin({ order_uuid: order?.uuid }).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    if (!order) return;
    getOrder();
  }, [order]);

  const handleSubmit = async (values) => {
    const { tracking_link, invoice_link, status } = values;

    onUpdateShopOrderAdmin({
      order_uuid: order?.uuid,
      order_data: {
        tracking_link,
        invoice_link,
        status
      }
    }).then((res) => {
      if (res?.response) {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        handleModalClose();
      }
    });
  };

  const handleRefund = () => {
    onDeleteShopOrderAdmin({ order_uuid: order?.uuid }).then((res) => {
      if (res?.response) {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        handleModalClose();
      }
    });
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
          <DialogTitle>Rimborso ordine</DialogTitle>
          <DialogContent>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <DialogContentText>Vuoi rimborsare l'ordine?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
              Annulla
            </Button>
            <Button variant={'contained'} color={'success'} onClick={handleRefund}>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>{' '}
        <>
          <Formik
            initialValues={{
              tracking_link: data?.tracking_link ?? '',
              invoice_link: data?.invoice_link ?? '',
              status: data?.status ?? ''
            }}
            validationSchema={Yup.object().shape({
              tracking_link: Yup.string().nullable(),
              invoice_link: Yup.string().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati ordine
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.tracking_link && errors.tracking_link)} w={'100%'}>
                            <InputLabel htmlFor="outlined-tracking_link">Tracking Link</InputLabel>
                            <OutlinedInput
                              id="outlined-tracking_link"
                              type="text"
                              value={values.tracking_link}
                              name="tracking_link"
                              onBlur={handleBlur}
                              onChange={(e) => setFieldValue('tracking_link', e.target.value)}
                              label="Link Tracking"
                            />
                            {touched.tracking_link && errors.tracking_link && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.tracking_link}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.invoice_link && errors.invoice_link)} w={'100%'}>
                            <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} p={'0 10px'}>
                              <Box
                                sx={{
                                  mr: 5
                                }}
                              >
                                {values.invoice_link ? (
                                  <Link href={values.invoice_link} underline="none" target={'_blank'}>
                                    Visualizza fattura
                                  </Link>
                                ) : (
                                  <Typography variant={'body1'}>Nessuna fattura caricata</Typography>
                                )}
                              </Box>

                              <DropzoneFormikInput
                                variant={'order_invoice'}
                                setFieldValue={setFieldValue}
                                field={'invoice_link'}
                                setLoading={false}
                                folder={'profile_pic'}
                                label={'Carica'}
                                type={'all_allowed'}
                                extra_data={{
                                  order_uuid: order?.uuid
                                }}
                              />
                            </Box>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} w={'100%'}>
                        <IconColorFilter stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.status && errors.status)}>
                            <InputLabel id="status-label">Stato Ordine</InputLabel>
                            <Select
                              name={'status'}
                              fullWidth={true}
                              value={values?.status}
                              label="Stato Ordine"
                              labelId="status-label"
                              onChange={(e) => {
                                setFieldValue('status', e.target.value);
                              }}
                              onBlur={handleBlur}
                              autoWidth
                            >
                              <MenuItem value={'payed'}>In Attesa</MenuItem>
                              <MenuItem value={'shipped'}>Spedito</MenuItem>
                              <MenuItem value={'completed'}>Completato</MenuItem>
                            </Select>
                            {touched.status && errors.status && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.status}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box d={'flex'} justifyContent={'center'}>
                        <Box
                          fontSize={15}
                          textAlign={'center'}
                          color={'#f44336'}
                          fontFamily={'Montserrat, sans-serif'}
                          mt={10}
                          fontWeight={700}
                          textTransform={'uppercase'}
                          bg={'rgba(200,200,200,0.2)'}
                          p={'5px '}
                          cursor={'pointer'}
                          onClick={() => setDeleteMode(true)}
                        >
                          Rimborsa ordine
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};

export default ModalTracking;
