import {
  LIST_CHATS_DOCTOR,
  GET_CHAT_DOCTOR,
  SEND_CHAT_MESSAGE_DOCTOR,
  BLOCK_CHAT_DOCTOR,
  DELETE_CHAT_DOCTOR,
  GET_CHAT_WITH_ADMIN_DOCTOR,
  START_CHAT_DOCTOR,
  COUNT_UNREAD_MESSAGES_DOCTOR
} from './graphql/queries';
import { useQuery } from '../useQuery';

/** @format */
export const useChats = () => {
  const { getDataGQL: countUnreadMessagesDoctor } = useQuery(COUNT_UNREAD_MESSAGES_DOCTOR);
  const { getDataGQL: listChatsDoctor } = useQuery(LIST_CHATS_DOCTOR);
  const { getDataGQL: getChatDoctor } = useQuery(GET_CHAT_DOCTOR);
  const { getDataGQL: getChatWithAdminDoctor } = useQuery(GET_CHAT_WITH_ADMIN_DOCTOR);
  const { getDataGQL: startChatDoctor } = useQuery(START_CHAT_DOCTOR);
  const { getDataGQL: sendChatMessageDoctor } = useQuery(SEND_CHAT_MESSAGE_DOCTOR);
  const { getDataGQL: blockChatDoctor } = useQuery(BLOCK_CHAT_DOCTOR);
  const { getDataGQL: deleteChatDoctor } = useQuery(DELETE_CHAT_DOCTOR);

  const onCountUnreadMessagesDoctor = async () => {
    const response = await countUnreadMessagesDoctor().catch((error) => {
      throw new Error(error.message);
    });
    if (response?.adminRoute?.countUnreadMessagesDoctor) {
      return response?.adminRoute?.countUnreadMessagesDoctor;
    }
    return null;
  };

  const onListChatsDoctor = async ({ query, limit, page, deleted_too }) => {
    const response = await listChatsDoctor({
      variables: {
        query,
        limit,
        page,
        deleted_too
      }
    });
    if (response?.adminRoute?.listChatsDoctor) {
      return response?.adminRoute?.listChatsDoctor;
    }
    return null;
  };

  const onGetChatDoctor = async ({ chat_uuid, limit, page }) => {
    const response = await getChatDoctor({
      variables: {
        chat_uuid,
        limit,
        page
      }
    });
    if (response?.adminRoute?.getChatDoctor) {
      return response?.adminRoute?.getChatDoctor;
    }
    return null;
  };

  const onGetChatWithAdminDoctor = async ({ limit, page }) => {
    const response = await getChatWithAdminDoctor({
      variables: {
        limit,
        page
      }
    });
    if (response?.adminRoute?.getChatWithAdminDoctor) {
      return response?.adminRoute?.getChatWithAdminDoctor;
    }
    return null;
  };

  const onStartChatDoctor = async ({ patient_uuid }) => {
    const response = await startChatDoctor({
      variables: {
        patient_uuid
      }
    });
    if (response?.adminRoute?.startChatDoctor) {
      return response?.adminRoute?.startChatDoctor;
    }
    return null;
  };

  const onSendChatMessage = async ({ chat_uuid, message }) => {
    const response = await sendChatMessageDoctor({
      variables: {
        chat_uuid,
        message
      }
    });
    if (response?.adminRoute?.sendChatMessageDoctor) {
      return response?.adminRoute?.sendChatMessageDoctor;
    }
    return null;
  };

  const onBlockChatDoctor = async ({ chat_uuid }) => {
    const response = await blockChatDoctor({
      variables: {
        chat_uuid
      }
    });
    if (response?.adminRoute?.blockChatDoctor) {
      return response?.adminRoute?.blockChatDoctor;
    }
    return null;
  };

  const onDeleteChatDoctor = async ({ chat_uuid }) => {
    const response = await deleteChatDoctor({
      variables: {
        chat_uuid
      }
    });
    if (response?.adminRoute?.deleteChatDoctor) {
      return response?.adminRoute?.deleteChatDoctor;
    }
    return null;
  };

  return {
    onCountUnreadMessagesDoctor,
    onListChatsDoctor,
    onGetChatDoctor,
    onGetChatWithAdminDoctor,
    onStartChatDoctor,
    onSendChatMessage,
    onBlockChatDoctor,
    onDeleteChatDoctor
  };
};
