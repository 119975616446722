import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input } from '@mui/material';
import React from 'react';
import { UIButton } from './Button';

export const UiDialogForm = ({
  open = false,
  onClose,
  onConfirm = () => {},
  title = '',
  subtitle = '',
  variant = 'number',
  inputLabel = 'Inserisci un numero',
  value = null,
  setValue = () => {}
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth={true}>
      <DialogTitle
        sx={{
          fontFamily: 'Oxygen',
          fontWeight: '700',
          fontSize: '13px',
          letterSpacing: '0.5px',
          textAlign: 'center'
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>

        {variant === 'number' && (
          <Input
            label={inputLabel}
            sx={{
              width: '100%',
              my: '10px'
            }}
            placeholder={inputLabel}
            defaultValue={value}
            onChange={(event) => setValue(event.target.value)}
            type={'number'}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <UIButton variant={'confirm'} label={'Conferma'} onClick={onConfirm} />
      </DialogActions>
    </Dialog>
  );
};
