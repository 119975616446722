import { Box, Textarea } from '@chakra-ui/react';
import { Button, Divider, FormControl, FormHelperText, Modal, Typography } from '@mui/material';
import { IconCheck, IconSignature, IconX } from '@tabler/icons-react';
import React from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { toast } from 'react-toastify';

const ModalEdit = ({ modalOpen, handleModalClose, reviewData }) => {
  const { onUpdateReview } = useAccounts();

  const handleSubmit = async (values) => {
    const { review } = values;

    onUpdateReview({
      review_id: reviewData.id,
      review_data: {
        review
      }
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
      handleModalClose();
    });
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Formik
            initialValues={{
              review: reviewData?.review ?? ''
            }}
            validationSchema={Yup.object().shape({
              review: Yup.string().required('Recensione obbligatoria')
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Modifica testo recensione
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.review && errors.review)} w={'100%'}>
                            <Typography id="modal-review" variant="body1" fontWeight={'bold'}>
                              Testo recensione
                            </Typography>
                            <Textarea
                              id="input-review"
                              value={values.review}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('review', event.target.value)}
                              label="Testo recensione"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Formazione'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.review ? '1px solid red' : null}
                            />
                            {touched.description && errors.description && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.description}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'}>
                        <IconX stroke={1.5} size="1rem" onClick={() => handleModalClose} />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};
export default ModalEdit;
