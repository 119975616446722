import {
  CREATE_PRODUCTS_TECHNIQUE_ADMIN,
  DELETE_PRODUCTS_TECHNIQUE_ADMIN,
  LIST_PRODUCTS_TECHNIQUES_ADMIN,
  UPDATE_PRODUCTS_TECHNIQUE_ADMIN
} from './graphql/queries';
import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';

/** @format */
export const useProductsTechniques = () => {
  const { getDataGQL: listProductsTechniquesAdmin } = useQuery(LIST_PRODUCTS_TECHNIQUES_ADMIN);
  const { getDataGQL: createProductTechniqueAdmin } = useMutation(CREATE_PRODUCTS_TECHNIQUE_ADMIN);
  const { getDataGQL: updateProductTechniqueAdmin } = useMutation(UPDATE_PRODUCTS_TECHNIQUE_ADMIN);
  const { getDataGQL: deleteProductTechniqueAdmin } = useMutation(DELETE_PRODUCTS_TECHNIQUE_ADMIN);

  const onListProductsTechniquesAdmin = async () => {
    const response = await listProductsTechniquesAdmin();
    if (response?.adminRoute?.listProductsTechniquesAdmin) {
      return response?.adminRoute?.listProductsTechniquesAdmin;
    }
    return null;
  };

  const onCreateProductTechniqueAdmin = async ({ product_technique_data }) => {
    const response = await createProductTechniqueAdmin({
      variables: {
        product_technique_data
      }
    });
    if (response?.adminRoute?.createProductTechniqueAdmin) {
      return response?.adminRoute?.createProductTechniqueAdmin;
    }
    return null;
  };

  const onUpdateProductTechniqueAdmin = async ({ product_technique_id, product_technique_data }) => {
    const response = await updateProductTechniqueAdmin({
      variables: {
        product_technique_id,
        product_technique_data
      }
    });
    if (response?.adminRoute?.updateProductTechniqueAdmin) {
      return response?.adminRoute?.updateProductTechniqueAdmin;
    }
    return null;
  };

  const onDeleteProductTechniqueAdmin = async ({ product_technique_id }) => {
    const response = await deleteProductTechniqueAdmin({
      variables: {
        product_technique_id
      }
    });
    if (response?.adminRoute?.deleteProductTechniqueAdmin) {
      return response?.adminRoute?.deleteProductTechniqueAdmin;
    }
    return null;
  };

  return {
    onListProductsTechniquesAdmin,
    onCreateProductTechniqueAdmin,
    onUpdateProductTechniqueAdmin,
    onDeleteProductTechniqueAdmin
  };
};
