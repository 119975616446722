import {
  CREATE_PRODUCTS_ADMIN,
  DELETE_PRODUCTS_ADMIN,
  LIST_PRODUCTS_ADMIN,
  LIST_PRODUCTS_COLORS_ADMIN,
  UPDATE_PRODUCTS_ADMIN
} from './graphql/queries';
import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';

/** @format */
export const useProducts = () => {
  const { getDataGQL: listProductsAdmin } = useQuery(LIST_PRODUCTS_ADMIN);
  const { getDataGQL: listProductsColorsAdmin } = useQuery(LIST_PRODUCTS_COLORS_ADMIN);
  const { getDataGQL: createProductAdmin } = useMutation(CREATE_PRODUCTS_ADMIN);
  const { getDataGQL: updateProductAdmin } = useMutation(UPDATE_PRODUCTS_ADMIN);
  const { getDataGQL: deleteProductAdmin } = useMutation(DELETE_PRODUCTS_ADMIN);

  const onListProductsAdmin = async () => {
    const response = await listProductsAdmin();
    if (response?.adminRoute?.listProductsAdmin) {
      return response?.adminRoute?.listProductsAdmin;
    }
    return null;
  };

  const onListProductsColorsAdmin = async () => {
    const response = await listProductsColorsAdmin();
    if (response?.adminRoute?.listProductsColorsAdmin) {
      return response?.adminRoute?.listProductsColorsAdmin;
    }
    return null;
  };

  const onCreateProductAdmin = async ({ product_data }) => {
    const response = await createProductAdmin({
      variables: {
        product_data
      }
    });
    if (response?.adminRoute?.createProductAdmin) {
      return response?.adminRoute?.createProductAdmin;
    }
    return null;
  };

  const onUpdateProductAdmin = async ({ product_id, product_data }) => {
    const response = await updateProductAdmin({
      variables: {
        product_id,
        product_data
      }
    });
    if (response?.adminRoute?.updateProductAdmin) {
      return response?.adminRoute?.updateProductAdmin;
    }
    return null;
  };

  const onDeleteProductAdmin = async ({ product_id }) => {
    const response = await deleteProductAdmin({
      variables: {
        product_id
      }
    });
    if (response?.adminRoute?.deleteProductAdmin) {
      return response?.adminRoute?.deleteProductAdmin;
    }
    return null;
  };

  return {
    onListProductsAdmin,
    onListProductsColorsAdmin,
    onCreateProductAdmin,
    onUpdateProductAdmin,
    onDeleteProductAdmin
  };
};
