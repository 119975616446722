import React, { useCallback, useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import './custom-offices.css';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import ModalOfficeAdmin from './modalOfficeAdmin';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { useOfficesCities } from '../../../hooks/OfficesCities/useOfficesCities';

const OfficesView = () => {
  const [offices, setOffices] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const { onListOffices } = useOffices();
  const { onListOfficesCitiesAdmin } = useOfficesCities();

  useEffect(() => {
    onListOffices().then((res) => {
      setOffices(res);
    });
    onListOfficesCitiesAdmin().then((res) => {
      setCities(res);
    });
  }, []);

  const handleModalClose = useCallback(() => {
    onListOffices().then((res) => {
      setOffices(res);
      setEditMode(false);
    });
  }, []);

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedOffice(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>

      <ModalOfficeAdmin
        modalOpen={editMode}
        handleModalClose={handleModalClose}
        office={selectedOffice}
        cities={cities}
        setEditMode={setEditMode}
      />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Uffici
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offices?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'}>
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  <IconEdit
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedOffice(row);
                      setEditMode(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default OfficesView;
