import { Image } from '@chakra-ui/react';
import { Box, Card, CardContent, CardMedia, Link, Typography } from '@mui/material';
import {
  IconCake,
  IconCheck,
  IconDeviceFloppy,
  IconEdit,
  IconGenderBigender,
  IconMoon,
  IconPlus,
  IconSun,
  IconTrash
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useProtocols } from '../../../hooks/Protocols/useProtocols';
import ModalSurveyStepEdit from './modalSurveyStepEdit';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import MDEditor, { commands } from '@uiw/react-md-editor';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { UIModal, UIModalBody, UIModalButtons, UIModalDivider, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import UITypography from '../../utilities/Typography';
import { UIFormControlBox } from '../../utilities/Form';
import { UICheckbox, UIInput, UISlider, UITextarea } from '../../utilities/Input';
import { UIPageTitle } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import { UiDialog } from '../../utilities/Dialog';

const ModalSurveyCompile = ({ modalOpen, handleModalClose, survey }) => {
  let [surveyData, setSurveyData] = useState(false);
  let [selectedStepUuid, setSelectedStepUuid] = useState(false);
  let [selectedStepData, setSelectedStepData] = useState(false);
  let [selectedStepNumber, setSelectedStepNumber] = useState(false);
  let [selectedStepTime, setSelectedStepTime] = useState(false);
  let [addStepMode, setAddStepMode] = useState(false);
  let [deleteMode, setDeleteMode] = useState(false);
  let [confirmMode, setConfirmMode] = useState(false);
  let [saveMode, setSaveMode] = useState(false);
  let [stepDeleteMode, setStepDeleteMode] = useState(false);
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(-1);
  const [imagesUpdate, setImagesUpdate] = useState([]);
  const [imageUpdateIndex, setImageUpdateIndex] = useState(-1);

  const { onGetSurvey, onUpdateProtocol, onDeleteProtocol, onConfirmProtocol, onDeleteProtocolStep } = useProtocols();

  useEffect(() => {
    if (!survey) return;
    getSurvey();
  }, [survey]);

  const getSurvey = () => {
    onGetSurvey({
      survey_uuid: survey?.uuid
    }).then((res) => {
      console.log(res);
      setImages(
        res?.media
          ?.filter((media) => media?.for_update === false)
          ?.map((media) => {
            return {
              src: media.media_link
            };
          })
      );
      setImagesUpdate(
        res?.media
          ?.filter((media) => media?.for_update === true)
          ?.map((media) => {
            return {
              src: media.media_link
            };
          })
      );

      setSurveyData(res);
    });
  };

  const handleRefreshData = () => {
    getSurvey();
  };
  const handleModalEditOpen = ({ step_data, step, time }) => {
    setSelectedStepNumber(step);
    setSelectedStepTime(time);
    setSelectedStepData(step_data ?? null);
    setAddStepMode(true);
  };
  const handleModalEditClose = () => {
    setAddStepMode(false);
    setSelectedStepData(null);
    setSelectedStepNumber(null);
    setSelectedStepTime(null);
    getSurvey();
  };

  const handleRejectSurvey = async () => {
    await onDeleteProtocol({
      protocol_uuid: surveyData?.protocol?.uuid
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleDeleteStep = async (step_uuid) => {
    setSelectedStepUuid(step_uuid);
    setStepDeleteMode(true);
  };

  const deleteStep = async () => {
    await onDeleteProtocolStep({
      step_uuid: selectedStepUuid
    }).then(() => {
      setSelectedStepUuid(null);
      setStepDeleteMode(false);
      getSurvey();
    });
  };

  const handleConfirmSurvey = async () => {
    onConfirmProtocol({
      protocol_uuid: surveyData?.protocol?.uuid
    }).then((res) => {
      if (res?.response) {
        handleModalClose();
      }
      setConfirmMode(false);
    });
  };

  const handleSubmitForm = async (values, submit = true) => {
    const { title, subtitle, description, ingredients, custom_duration, invoice_link } = values;

    if (surveyData?.protocol?.uuid) {
      onUpdateProtocol({
        protocol_uuid: surveyData?.protocol?.uuid,
        protocol_data: {
          title,
          subtitle,
          description,
          ingredients,
          custom_duration: Number(custom_duration),
          invoice_link
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          if (submit) {
            handleModalCloseInternal();
          }
        }
      });
    } else {
      toast('Errore sconosciuto', {
        style: {
          fontSize: '14px',
          backgroundColor: 'red',
          color: '#ffffff'
        }
      });
    }
  };

  const handleModalCloseInternal = () => {
    setSelectedStepNumber(null);
    setSelectedStepTime(null);
    setSelectedStepData(null);
    setAddStepMode(false);
    setSurveyData(null);
    setSaveMode(false);
    setDeleteMode(false);
    setConfirmMode(false);
    handleModalClose();
  };

  const getTargetText = (target) => {
    switch (target) {
      case 'brightness':
        return 'Maggiore luminosità del viso ed omogeneità incarnato';
      case 'flexibility':
        return 'Maggior tono ed elasticità cutanea';
      case 'wrinkles_upgrade':
        return 'Miglioramento visibile delle rughe';
      case 'eye_area':
        return 'Miglioramento zona contorno occhi';
      case 'comfort':
        return 'Maggior comfort generale per la pelle (idratazione/rossori)';
      case 'sebo':
        return 'Minor produzione di sebo e miglioramento macchie cutanee';
    }
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <ModalSurveyStepEdit
        modalOpen={addStepMode}
        handleModalClose={handleModalEditClose}
        handleRefreshData={handleRefreshData}
        protocol_uuid={surveyData?.protocol?.uuid}
        step_data={selectedStepData}
        time={selectedStepTime}
        step={selectedStepNumber}
      />

      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi eliminare il protocollo? Il paziente riceverà la notifica che il protocollo è stato rifiutato'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleRejectSurvey}
        labelConfirm={'Rifiuta'}
      />

      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi eliminare lo step selezionato?'}
        open={stepDeleteMode}
        onClose={() => setStepDeleteMode(false)}
        onConfirm={deleteStep}
        labelConfirm={'Elimina'}
      />

      <UiDialog
        type={'default'}
        variant={'confirm'}
        title={"Vuoi confermare il questionario? Questo farà apparire il questionario nella scheda dell'utente."}
        open={confirmMode}
        onClose={() => setConfirmMode(false)}
        onConfirm={handleConfirmSurvey}
      />

      <UiModalInternal onClose={handleModalCloseInternal} isLoading={surveyData === null}>
        <Formik
          initialValues={{
            title: surveyData?.protocol?.title ?? '',
            subtitle: surveyData?.protocol?.subtitle ?? '',
            description: surveyData?.protocol?.description ?? '',
            ingredients: surveyData?.protocol?.ingredients ?? '',
            custom_duration: surveyData?.protocol?.custom_duration ?? '',
            invoice_link: surveyData?.order?.invoice_link ?? ''
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Il titolo è obbligatorio'),
            subtitle: Yup.string().required('Il sottotitolo è obbligatorio'),
            description: Yup.string().required('La descrizione è obbligatoria'),
            ingredients: Yup.string().required('La lista ingredienti è obbligatoria'),
            custom_duration: Yup.string().required('La durata è obbligatoria'),
            invoice_link: Yup.string().nullable()
          })}
          onSubmit={(values) => handleSubmitForm(values)}
          enableReinitialize={true}
        >
          {({ errors, handleSubmit, touched, values, setFieldValue, validateForm, submitForm }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UiDialog
                type={'default'}
                variant={'copy'}
                title={`Vuoi salvare le modifiche al questionario? ${
                  surveyData?.status !== 'completed'
                    ? `Salverai le modifiche e non apparirà nella scheda dell'utente finché non verrà confermato.`
                    : ''
                }`}
                open={saveMode}
                onClose={() => setSaveMode(false)}
                onConfirm={submitForm}
                onSecondary={() => setSaveMode(false)}
                labelConfirm={'Conferma'}
                labelSecondary={'Rifiuta'}
              />
              <UIModalHeader>
                <Box
                  sx={{
                    width: '75px',
                    height: '75px',
                    overflow: 'hidden',
                    borderRadius: '100%',
                    mx: 'auto'
                  }}
                >
                  <Image
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    src={
                      surveyData?.patient?.profile_pic ??
                      'https://skinboost-prod.fra1.digitaloceanspaces.com/placeholder/paziente_empty.png'
                    }
                    alt={surveyData?.patient?.name}
                  />
                </Box>

                <UITypography
                  variant={'Oxygen700'}
                  title={`${surveyData?.patient?.name} ${surveyData?.patient?.surname}`}
                  size={'18px'}
                  sxCustom={{
                    textAlign: 'center',
                    color: '#101828',
                    mt: '10px'
                  }}
                />
              </UIModalHeader>

              <UIModalBody
                sx={{
                  m: 0,
                  pb: '20px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '40px',
                    pt: '10px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '20px',
                      gap: '5px'
                    }}
                  >
                    <IconCake size={'20px'} stroke={'1.64'} />
                    <UITypography
                      variant={'Oxygen700'}
                      title={`${surveyData?.patient?.age}`}
                      size={'13px'}
                      sxCustom={{
                        textAlign: 'center',
                        mt: '2px'
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '20px',
                      gap: '5px'
                    }}
                  >
                    <IconGenderBigender size={'20px'} stroke={'1.64'} />
                    <UITypography
                      variant={'Oxygen700'}
                      title={`${surveyData?.patient?.sex === 'M' ? 'Uomo' : surveyData?.patient?.sex === 'F' ? 'Donna' : 'Altro'}`}
                      size={'13px'}
                      sxCustom={{
                        textAlign: 'center',
                        mt: '2px'
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    py: '2px',
                    pl: '20px',
                    justifyContent: 'center',
                    maxWidth: '500px',
                    m: '0 auto'
                  }}
                >
                  <UIFormControlBox>
                    <UICheckbox label={<UITypography title={'Gravidanza'} />} readOnly={true} checked={surveyData?.patient?.pregnant} />
                  </UIFormControlBox>
                  <UIFormControlBox>
                    <UICheckbox
                      label={<UITypography title={'Make-up Permanente'} />}
                      readOnly={true}
                      checked={surveyData?.patient?.permanent_makeup}
                    />
                  </UIFormControlBox>
                </Box>

                <Box
                  sx={{
                    bgcolor: '#E9ECEF',
                    width: '150px',
                    m: '10px auto',
                    borderRadius: '8px',
                    p: '10px 0',
                    border: '1px solid #D0D5DD'
                  }}
                >
                  <UITypography
                    variant={'Oxygen700'}
                    title={`Step richiesti: ${surveyData?.steps}`}
                    size={'15px'}
                    sxCustom={{ textAlign: 'center', color: '#344054' }}
                  />
                </Box>

                <UIFormControlBox label={'Colore della pelle'}>
                  <Box
                    sx={{
                      height: '50px',
                      width: '100%',
                      borderRadius: '10px',
                      border: '1px solid rgba(0,0,0,0.3)',
                      bgcolor: surveyData?.skin_tone?.color
                    }}
                  />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Allergie'}>
                  <UITextarea
                    value={surveyData?.allergies?.map((allergy, index) => (index !== 0 ? ' ' : '') + allergy.name)}
                    readOnly={true}
                  />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Problemi della pelle'}>
                  <UITextarea
                    value={surveyData?.skin_problems?.map((problem, index) => (index !== 0 ? ' ' : '') + problem.name)}
                    readOnly={true}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Prodotti utilizzati'}>
                  <UITextarea
                    value={surveyData?.skin_products?.map((problem, index) => (index !== 0 ? ' ' : '') + problem.name)}
                    readOnly={true}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Note sui prodotti utilizzati'}>
                  <UITextarea value={surveyData?.skin_products_note} readOnly={true} />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Solitamente la fronte e  le guance appaiono:'}>
                  <UISlider
                    value={surveyData?.cheeks}
                    onChange={() => {}}
                    subtitles={[
                      {
                        value: 1,
                        label: 'Molto secche'
                      },
                      {
                        value: 10,
                        label: 'Molto oleose'
                      }
                    ]}
                  />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Molte ore dopo aver messo la crema idratante la pelle appare:'}>
                  <UISlider
                    value={surveyData?.skin_dry_hours}
                    onChange={() => {}}
                    subtitles={[
                      {
                        value: 1,
                        label: 'Molto secca'
                      },
                      {
                        value: 10,
                        label: 'Molto oleosa'
                      }
                    ]}
                  />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Alcuni ingredienti o alcuni prodotti determinato irritazioni, macchie o danno reazioni?'}>
                  <UITypography
                    variant={'Oxygen300'}
                    title={surveyData?.products_reaction ? 'Si' : 'No'}
                    size={'12px'}
                    sxCustom={{ color: '#344054', ml: '5px' }}
                  />
                  {surveyData?.products_reaction && <UITextarea value={surveyData?.products_reaction_description} readOnly={true} />}
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'La tua pelle è colpita da acne o cisti visibili?'}>
                  <UITypography
                    variant={'Oxygen300'}
                    title={surveyData?.acne ? 'Si' : 'No'}
                    size={'12px'}
                    sxCustom={{ color: '#344054', ml: '5px' }}
                  />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Sviluppi macchie o rossore dopo che l’acne localizzato termina la fase di infiammazione?'}>
                  <UITypography
                    variant={'Oxygen300'}
                    title={surveyData?.inflammation ? 'Si' : 'No'}
                    size={'12px'}
                    sxCustom={{ color: '#344054', ml: '5px' }}
                  />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Dopo la stagione estiva presenti macchie solari o macchie da iperpigmentazione?'}>
                  <UITypography
                    variant={'Oxygen300'}
                    title={surveyData?.sun_stains ? 'Si' : 'No'}
                    size={'12px'}
                    sxCustom={{ color: '#344054', ml: '5px' }}
                  />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Cosa vuole ottenere la paziente?'}>
                  {surveyData?.targets?.length > 0 &&
                    surveyData?.targets?.map((target, index) => (
                      <UITypography
                        key={index}
                        variant={'Oxygen300'}
                        title={`${target?.value} - ${getTargetText(target?.key)}`}
                        size={'12px'}
                        sxCustom={{ color: '#344054', ml: '5px' }}
                      />
                    ))}

                  {surveyData?.targets?.length === 0 && (
                    <UITypography
                      variant={'Oxygen300'}
                      title={`Questionario compilato precedentemente all'aggiunta di questa sezione`}
                      size={'12px'}
                      sxCustom={{ color: '#344054', ml: '5px' }}
                    />
                  )}
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Note del paziente'}>
                  <UITextarea value={surveyData?.note} readOnly={true} />
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox label={'Foto allegate:'}>
                  <PhotoAlbum
                    layout="columns"
                    photos={images}
                    targetRowHeight={200}
                    onClick={({ index: current }) => setImageIndex(current)}
                    renderPhoto={(e) => {
                      return <Image src={e?.photo?.src} w={'100px'} h={'200px'} onClick={e?.imageProps?.onClick} />;
                    }}
                  />
                  <Lightbox index={imageIndex} slides={images} open={imageIndex >= 0} close={() => setImageIndex(-1)} />
                </UIFormControlBox>

                {imagesUpdate.length > 0 && (
                  <UIFormControlBox label={'Foto aggiornamento:'}>
                    <PhotoAlbum
                      layout="columns"
                      photos={imagesUpdate}
                      targetRowHeight={200}
                      onClick={({ index: current }) => setImageUpdateIndex(current)}
                      renderPhoto={(e) => {
                        return <Image src={e?.photo?.src} w={'100px'} h={'200px'} onClick={e?.imageProps?.onClick} />;
                      }}
                    />
                    <Lightbox
                      index={imageUpdateIndex}
                      slides={imagesUpdate}
                      open={imageUpdateIndex >= 0}
                      close={() => setImageUpdateIndex(-1)}
                    />
                  </UIFormControlBox>
                )}

                {surveyData?.update_note && (
                  <UIFormControlBox label={'Nota aggiornamento:'}>
                    <UITextarea value={surveyData?.update_note} readOnly={true} />
                  </UIFormControlBox>
                )}
              </UIModalBody>
              <UIModalBody
                sx={{
                  mx: 0,
                  bgcolor: 'transparent'
                }}
              >
                <UIPageTitle
                  title={'Compila protocollo'}
                  sx={{
                    textAlign: 'center',
                    mb: '20px'
                  }}
                />

                <UIModalDivider />

                <UIFormControlBox label={'Titolo'} error={Boolean(touched.title && errors.title)}>
                  <UIInput value={values.title} onChange={() => setFieldValue('title', event.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Sottotitolo'} error={Boolean(touched.subtitle && errors.subtitle)}>
                  <UIInput value={values.subtitle} onChange={() => setFieldValue('subtitle', event.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Descrizione'} error={Boolean(touched.description && errors.description)}>
                  <MDEditor
                    height={200}
                    value={values?.description}
                    onChange={(md) => {
                      setFieldValue('description', md);
                    }}
                    commands={[
                      commands.bold,
                      commands.italic,
                      commands.strikethrough,
                      commands.unorderedListCommand,
                      commands.orderedListCommand,
                      commands.checkedListCommand
                    ]}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Ingredienti'} error={Boolean(touched.ingredients && errors.ingredients)}>
                  <MDEditor
                    height={200}
                    value={values?.ingredients}
                    onChange={(md) => {
                      setFieldValue('ingredients', md);
                    }}
                    commands={[
                      commands.bold,
                      commands.italic,
                      commands.strikethrough,
                      commands.unorderedListCommand,
                      commands.orderedListCommand,
                      commands.checkedListCommand
                    ]}
                  />
                </UIFormControlBox>

                <UIFormControlBox
                  label={'Durata (mesi) prima di poter richiedere un aggiornamento'}
                  error={Boolean(touched.subtitle && errors.subtitle)}
                >
                  <UIInput
                    type={'number'}
                    value={values.custom_duration}
                    onChange={() => setFieldValue('custom_duration', event.target.value)}
                  />
                </UIFormControlBox>

                {surveyData?.by_skinboost && (
                  <UIFormControlBox label={'Fattura'}>
                    {values.invoice_link ? (
                      <Link href={values.invoice_link} underline="none" target={'_blank'}>
                        Visualizza fattura
                      </Link>
                    ) : (
                      <Typography variant={'body1'}>Nessuna fattura caricata</Typography>
                    )}
                    <DropzoneFormikInput
                      variant={'survey_invoice'}
                      setFieldValue={setFieldValue}
                      field={'invoice_link'}
                      setLoading={false}
                      folder={'survey_invoice'}
                      label={'Carica'}
                      type={'all_allowed'}
                      extra_data={{
                        survey_order_uuid: surveyData?.order?.uuid
                      }}
                    />
                  </UIFormControlBox>
                )}

                <Box>
                  <ProductData
                    selectedStep={selectedStepData}
                    handleModalEditOpen={handleModalEditOpen}
                    handleDeleteStep={handleDeleteStep}
                    survey={surveyData}
                    steps={surveyData?.steps}
                    time={'day'}
                  />
                  <ProductData
                    selectedStep={selectedStepData}
                    handleModalEditOpen={handleModalEditOpen}
                    handleDeleteStep={handleDeleteStep}
                    survey={surveyData}
                    steps={surveyData?.steps}
                    time={'night'}
                  />
                </Box>
              </UIModalBody>
              <UIModalButtons>
                <UIButton
                  type={'submit'}
                  icon={<IconTrash stroke={1.64} size={'26px'} />}
                  onClick={() => {
                    setDeleteMode(true);
                  }}
                  sxCustom={{
                    bgcolor: '#FF646C'
                  }}
                />
                <UIButton
                  type={'submit'}
                  icon={<IconDeviceFloppy stroke={1.64} size={'26px'} />}
                  onClick={() => {
                    if (!Object.keys(errors).length) {
                      setSaveMode(true);
                    } else {
                      toast('Serve compilare i campi obbligatori prima di poterlo salvare', {
                        style: {
                          fontSize: '14px',
                          backgroundColor: 'red',
                          color: '#ffffff'
                        }
                      });
                    }
                  }}
                  sxCustom={{
                    bgcolor: '#FECE2E'
                  }}
                />

                {surveyData?.status !== 'completed' && (
                  <UIButton
                    type={'submit'}
                    icon={<IconCheck stroke={1.64} size={'26px'} />}
                    onClick={() => {
                      validateForm().then((errors) => {
                        if (!Object.keys(errors).length) {
                          handleSubmitForm(values, false).then(() => {
                            setConfirmMode(true);
                          });
                        } else {
                          toast('Serve compilare il questionario prima di poterlo inviare', {
                            style: {
                              fontSize: '14px',
                              backgroundColor: 'red',
                              color: '#ffffff'
                            }
                          });
                        }
                      });
                    }}
                  />
                )}
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};

const ProductData = ({ survey, steps, time, handleModalEditOpen, selectedStep, handleDeleteStep }) => {
  const [surveyData, setSurveyDatas] = useState({});

  useEffect(() => {
    setSurveyDatas(survey);
  }, [survey]);

  return (
    <Box
      sx={{
        m: '10px 20px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '5px',
          m: '5px auto'
        }}
      >
        {time === 'day' ? (
          <>
            <IconSun stroke={1.5} size="24px" />
            <UITypography
              variant={'Oxygen700'}
              title={'Step Giorno'}
              size={'18px'}
              sxCustom={{
                color: '#101828'
              }}
            />
          </>
        ) : (
          <>
            <IconMoon stroke={1.5} size="24px" />
            <UITypography
              variant={'Oxygen700'}
              title={'Step Notte'}
              size={'18px'}
              sxCustom={{
                color: '#101828'
              }}
            />
          </>
        )}
      </Box>

      {surveyData?.protocol?.steps.map((step, index) => {
        if (selectedStep?.id === step.id) {
          handleModalEditOpen({
            step_data: step
          });
        }

        if (step.time === time && step.step === steps) {
          return (
            <Box
              key={index}
              sx={{
                bgcolor: '#E0E6ED',
                border: '1px solid #E3E3E3',
                borderRadius: '8px',
                p: '10px',
                m: '10px 0'
              }}
            >
              <UITypography variant={'Oxygen700'} title={step.title} size={'18px'} sxCustom={{ color: '#101828', mb: '10px' }} />

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px'
                }}
              >
                {step?.products?.map((product, index) => (
                  <Card
                    key={index}
                    sx={{
                      width: '48%',
                      alignSelf: 'stretch',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      pb: '30px',
                      mb: '25px',
                      overflow: 'visible',
                      flexWrap: 'wrap'
                    }}
                  >
                    <CardContent
                      sx={{
                        p: 1
                      }}
                    >
                      <UITypography title={product?.brand?.[0]?.name} size={'12px'} sxCustom={{ color: '#101828' }} />
                      <UITypography variant={'Oxygen300'} title={product?.name} size={'12px'} sxCustom={{ color: '#101828' }} />
                    </CardContent>

                    <CardMedia
                      sx={{ height: 100, width: '100%', backgroundSize: 'contain' }}
                      image={product?.media?.[0]?.media_link}
                      title="green iguana"
                    />
                  </Card>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px'
                }}
              >
                <UIButton
                  type={'icon'}
                  icon={<IconTrash stroke={1.5} size="35px" />}
                  sxCustom={{
                    color: '#344054'
                  }}
                  onClick={() => handleDeleteStep(step?.uuid)}
                />
                <UIButton
                  type={'icon'}
                  icon={<IconEdit stroke={1.5} size="35px" />}
                  onClick={() => handleModalEditOpen({ step_data: step })}
                  sxCustom={{
                    color: '#344054'
                  }}
                />
              </Box>
            </Box>

            // <Box key={index} display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} position={'relative'}>
            //   <IconAB2 stroke={1.5} size="1.3rem" />
            //   <Box w={'100%'} ml={10}>
            //     <Box
            //       position={'absolute'}
            //       top={2}
            //       right={10}
            //       onClick={() =>
            //         handleModalEditOpen({
            //           step_data: step
            //         })
            //       }
            //     >
            //       <IconEdit stroke={1.5} size="1.3rem" />
            //     </Box>
            //
            //     <Typography variant="subtitle1">{step.title}</Typography>
            //     <Box>
            //       {step?.products?.map((product, index) => (
            //         <Typography key={index} variant="body1">
            //           {product.name}
            //         </Typography>
            //       ))}
            //     </Box>
            //   </Box>
            // </Box>
          );
        }
      })}

      <UIButton
        label={
          <Box
            sx={{
              display: 'flex',
              gap: '5px'
            }}
          >
            <UITypography
              variant={'Oxygen700'}
              title={'Aggiungi uno step'}
              size={'13px'}
              sxCustom={{
                color: '#344054'
              }}
            />
            <IconPlus stroke={2} size={'18px'} />
          </Box>
        }
        variant={'copy'}
        onClick={() =>
          handleModalEditOpen({
            step: steps,
            time: time
          })
        }
        sxCustom={{
          m: '10px 0'
        }}
      />
    </Box>
  );
};

export default ModalSurveyCompile;
