import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';
import {
  ASSIGN_OFFICES_ADMIN,
  CREATE_OFFICE_ADMIN,
  DELETE_OFFICE_ADMIN,
  LIST_OFFICES,
  UPDATE_OFFICE_ADMIN,
  UPLOAD_OFFICE_IMAGE_ADMIN
} from './graphql/queries';

/** @format */
export const useOffices = () => {
  const { getDataGQL: listOffices } = useQuery(LIST_OFFICES);
  const { getDataGQL: createOfficeAdmin } = useMutation(CREATE_OFFICE_ADMIN);
  const { getDataGQL: updateOfficeAdmin } = useMutation(UPDATE_OFFICE_ADMIN);
  const { getDataGQL: deleteOfficeAdmin } = useMutation(DELETE_OFFICE_ADMIN);
  const { getDataGQL: uploadOfficeImageAdmin } = useMutation(UPLOAD_OFFICE_IMAGE_ADMIN);
  const { getDataGQL: assignOfficesAdmin } = useMutation(ASSIGN_OFFICES_ADMIN);

  const onListOffices = async () => {
    const response = await listOffices();
    if (response?.adminRoute?.listOffices) {
      return response?.adminRoute?.listOffices;
    }
    return null;
  };

  const onCreateOfficeAdmin = async ({ office_data }) => {
    const response = await createOfficeAdmin({
      variables: {
        office_data
      }
    });
    if (response?.adminRoute?.createOfficeAdmin) {
      return response?.adminRoute?.createOfficeAdmin;
    }
    return null;
  };

  const onUpdateOfficeAdmin = async ({ office_id, office_data }) => {
    const response = await updateOfficeAdmin({
      variables: {
        office_id,
        office_data
      }
    });
    if (response?.adminRoute?.updateOfficeAdmin) {
      return response?.adminRoute?.updateOfficeAdmin;
    }
    return null;
  };

  const onDeleteOfficeAdmin = async ({ office_id }) => {
    const response = await deleteOfficeAdmin({
      variables: {
        office_id
      }
    });
    if (response?.adminRoute?.deleteOfficeAdmin) {
      return response?.adminRoute?.deleteOfficeAdmin;
    }
    return null;
  };

  const onUploadOfficeImage = async ({ office_uuid, filename, contentType }) => {
    const response = await uploadOfficeImageAdmin({
      variables: {
        filename,
        contentType,
        office_uuid
      }
    });
    if (response?.adminRoute?.uploadOfficeImageAdmin) {
      return response?.adminRoute?.uploadOfficeImageAdmin;
    }
    return null;
  };

  const onAssignOfficesAdmin = async ({ doctor_id, offices }) => {
    const response = await assignOfficesAdmin({
      variables: {
        doctor_id,
        offices
      }
    });
    if (response?.adminRoute?.assignOfficesAdmin) {
      return response?.adminRoute?.assignOfficesAdmin;
    }
    return null;
  };

  return {
    onListOffices,
    onCreateOfficeAdmin,
    onUpdateOfficeAdmin,
    onDeleteOfficeAdmin,
    onUploadOfficeImage,
    onAssignOfficesAdmin
  };
};
