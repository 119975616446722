// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Register from '../views/pages/authentication/authentication3/Register3';
import Login from '../views/pages/authentication/authentication3/Login3';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/register',
      element: <Register />
    }
  ]
};

export default AuthenticationRoutes;
