import gql from 'graphql-tag';

export const SEND_NOTIFICATION_GLOBAL_ADMIN = gql`
  query sendGlobalNotificationAdmin($notification_data: NotificationInput!) {
    adminRoute {
      sendGlobalNotificationAdmin(notification_data: $notification_data) {
        response
        responseStatus
      }
    }
  }
`;
