import gql from 'graphql-tag';

export const LIST_PRODUCTS_DEVICES_ADMIN = gql`
  query listProductsDevicesAdmin {
    adminRoute {
      listProductsDevicesAdmin {
        id
        name
        icon
      }
    }
  }
`;

export const CREATE_PRODUCTS_DEVICE_ADMIN = gql`
  query createProductDeviceAdmin($product_device_data: ProductDeviceInput!) {
    adminRoute {
      createProductDeviceAdmin(product_device_data: $product_device_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_PRODUCTS_DEVICE_ADMIN = gql`
  query updateProductDeviceAdmin($product_device_data: ProductDeviceInput!, $product_device_id: ID!) {
    adminRoute {
      updateProductDeviceAdmin(product_device_id: $product_device_id, product_device_data: $product_device_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_PRODUCTS_DEVICE_ADMIN = gql`
  query deleteProductDeviceAdmin($product_device_id: ID!) {
    adminRoute {
      deleteProductDeviceAdmin(product_device_id: $product_device_id) {
        response
        responseStatus
      }
    }
  }
`;
