import { Box } from '@chakra-ui/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Modal,
  Typography
} from '@mui/material';
import { IconCheck, IconClock, IconCalendarTime, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
registerLocale('it', it);

const ModalTimeException = ({ modalOpen, handleModalClose, office, exception }) => {
  let [deleteMode, setDeleteMode] = useState(false);

  const { onUpdateExceptionTimeDoctor, onCreateExceptionTimeDoctor, onDeleteExceptionTimeDoctor } = useCalendar();

  const handleSubmit = async (values) => {
    const { start_hour, end_hour, start_date } = values;

    if (exception?.id) {
      onUpdateExceptionTimeDoctor({
        exception_id: exception.id,
        exception_data: {
          start_hour: moment(start_hour).format('HH:mm'),
          end_hour: moment(end_hour).format('HH:mm'),
          start_date: moment(start_date).format('YYYY-MM-DD')
        }
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        handleModalClose();
      });
    } else {
      onCreateExceptionTimeDoctor({
        exception_data: {
          start_hour: moment(start_hour).format('HH:mm'),
          end_hour: moment(end_hour).format('HH:mm'),
          start_date: moment(start_date).format('YYYY-MM-DD'),
          office_id: office
        }
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        handleModalClose();
      });
    }
  };

  const handleDeleteInterval = async () => {
    await onDeleteExceptionTimeDoctor({
      exception_id: exception?.id
    });
    setDeleteMode(false);
    handleModalClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
            <DialogTitle>Elimina eccezione</DialogTitle>
            <DialogContent>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <DialogContentText>Vuoi eliminare questa eccezione?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
                Annulla
              </Button>
              <Button variant={'contained'} color={'success'} onClick={handleDeleteInterval}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
          <Formik
            initialValues={{
              start_hour: exception ? moment(exception?.start_hour, 'HH:mm') : null,
              end_hour: exception ? moment(exception?.end_hour, 'HH:mm') : null,
              start_date: exception?.start_date ? moment(exception?.start_date) : null
            }}
            validationSchema={Yup.object().shape({
              start_hour: Yup.string().required('Orario di inizio richiesto'),
              end_hour: Yup.string().required('Orario di fine richiesto'),
              start_date: Yup.string().required('Data eccezione richiesta')
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        {exception?.id ? 'Modifica eccezione' : 'Aggiungi eccezione'}
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconClock stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.start_hour && errors.start_hour)}>
                            <TimePicker
                              label={'Orario di inizio'}
                              value={values?.start_hour}
                              format={'HH:mm'}
                              onChange={(res) => {
                                setFieldValue('start_hour', res);
                              }}
                              ampm={false}
                              minutesStep={5}
                              minTime={moment('06:30', 'HH:mm')}
                              maxTime={moment('20:00', 'HH:mm')}
                              onBlur={handleBlur}
                            />
                            {touched.start_hour && errors.start_hour && (
                              <FormHelperText error id="standard-weight-helper-text-password-login">
                                {errors.start_hour}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconClock stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.end_hour && errors.end_hour)}>
                            <TimePicker
                              label={'Orario di fine'}
                              value={values?.end_hour}
                              format={'HH:mm'}
                              onChange={(res) => {
                                setFieldValue('end_hour', res);
                              }}
                              ampm={false}
                              minutesStep={5}
                              minTime={moment('06:30', 'HH:mm')}
                              maxTime={moment('20:00', 'HH:mm')}
                              onBlur={handleBlur}
                            />
                            {touched.end_hour && errors.end_hour && (
                              <FormHelperText error id="standard-weight-helper-text-password-login">
                                {errors.end_hour}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} w={'100%'}>
                        <IconCalendarTime stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.start_date && errors.start_date)}>
                            <DatePicker
                              value={values?.start_date}
                              dateFormat="dd/MM/yyyy"
                              label="Data dell'eccezione"
                              onChange={(res) => {
                                setFieldValue('start_date', res);
                              }}
                              locale={'it'}
                              style={{ width: '100%', border: '1px solid red' }}
                              onBlur={handleBlur}
                            />
                            {touched.start_date && errors.start_date && (
                              <FormHelperText error id="standard-weight-helper-text-password-login">
                                {errors.start_date}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      {exception?.id && (
                        <Box d={'flex'} justifyContent={'center'}>
                          <Box
                            fontSize={15}
                            textAlign={'center'}
                            color={'#f44336'}
                            fontFamily={'Montserrat, sans-serif'}
                            mt={10}
                            fontWeight={700}
                            textTransform={'uppercase'}
                            bg={'rgba(200,200,200,0.2)'}
                            p={'5px '}
                            cursor={'pointer'}
                            onClick={() => setDeleteMode(true)}
                          >
                            Elimina eccezione
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'}>
                        <IconX stroke={1.5} size="1rem" onClick={() => setEditMode(false)} />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};
export default ModalTimeException;
