// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import Dashboard from '../views/pages/dashboard/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: '/',
      element: (
        <ProtectedRoute permission={['DOCTOR', 'CLINIC']}>
          <Dashboard />
        </ProtectedRoute>
      )
    }
  ]
};

export default MainRoutes;
