import gql from 'graphql-tag';

export const LIST_OFFICES = gql`
  query listOffices {
    adminRoute {
      listOffices {
        id
        name
        subtitle
        image
        address
        phone
        email
        city_id
        active
        city {
          name
        }
      }
    }
  }
`;

export const CREATE_OFFICE_ADMIN = gql`
  query createOfficeAdmin($office_data: OfficeInput!) {
    adminRoute {
      createOfficeAdmin(office_data: $office_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_OFFICE_ADMIN = gql`
  query updateOfficeAdmin($office_data: OfficeInput!, $office_id: ID!) {
    adminRoute {
      updateOfficeAdmin(office_id: $office_id, office_data: $office_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_OFFICE_ADMIN = gql`
  query deleteOfficeAdmin($office_id: ID!) {
    adminRoute {
      deleteOfficeAdmin(office_id: $office_id) {
        response
        responseStatus
      }
    }
  }
`;

export const UPLOAD_OFFICE_IMAGE_ADMIN = gql`
  query uploadOfficeImageAdmin($office_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadOfficeImageAdmin(office_uuid: $office_uuid, filename: $filename, contentType: $contentType) {
        presignedUrl
        key
      }
    }
  }
`;

export const ASSIGN_OFFICES_ADMIN = gql`
  query assignOfficesAdmin($doctor_id: ID!, $offices: [ID]!) {
    adminRoute {
      assignOfficesAdmin(doctor_id: $doctor_id, offices: $offices) {
        response
        responseStatus
      }
    }
  }
`;
