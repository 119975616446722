// assets
import AttributionIcon from '@mui/icons-material/Attribution';
// constant
const icons = { AttributionIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const notifications = {
  id: 'notifications',
  type: 'group',
  permission: ['ADMIN'],
  children: [
    {
      id: 'notifications_list',
      title: 'Notifiche',
      type: 'collapse',
      icon: icons.AttributionIcon,
      breadcrumbs: false,
      permission: ['ADMIN'],
      children: [
        {
          id: 'notification_send',
          title: 'Notifica globale',
          type: 'item',
          url: '/notification/global',
          permission: ['ADMIN'],
          icon: icons.AttributionIcon,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default notifications;
