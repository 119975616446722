/** @format */
import { AuthInfoKey } from './storageKeyList';

export const getAuthInfo = async () => {
  const data = localStorage.getItem(AuthInfoKey);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};
