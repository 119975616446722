import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './custom-products.css';
import { useShop } from '../../../hooks/Shop/useShop';
import UITable from '../../utilities/Table';
import UIInputSearch from '../../utilities/InputSearch';
import { UIButton } from '../../utilities/Button';
import { UiDialogForm } from '../../utilities/DialogForm';
import { toast } from 'react-toastify';

const ShopProductsWarehouseView = () => {
  const [query, setQuery] = useState('');
  const [refreshMode, setRefreshMode] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [newValue, setNewValue] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListShopWarehousePaginated, onUpdateShopProductVariantQuantity } = useShop();
  const prepareValues = (variants) => {
    return variants?.map((variant) => {
      let quantity = variant?.quantity;
      let color = quantity === 0 ? '#FF3A44' : quantity > 0 && quantity <= 2 ? '#FE922E' : 'inherit';

      return {
        name: variant?.product?.name,
        firstLevel: true,
        subRows: [
          {
            name: variant?.size,
            quantity: (
              <Typography
                color={color}
                sx={{
                  fontWeight: 700
                }}
              >
                {variant?.quantity}
              </Typography>
            ),
            uuid: variant?.uuid,
            price: variant?.price.toFixed(2) + '€',
            actionsEl: (
              <UIButton
                label={'Rifornisci'}
                onClick={() => {
                  setNewValue(variant?.quantity);
                  setSelectedVariant(variant?.uuid);
                  setRefreshMode(true);
                }}
                disableElevation
              />
            )
          }
        ]
      };
    });
  };

  const handleSubmitRefresh = () => {
    let value = Number(newValue);

    if (value && value >= 0) {
      onUpdateShopProductVariantQuantity({
        variant_uuid: selectedVariant,
        quantity: Number(newValue)
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        setRefreshMode(false);
        setForceUpdate(true);
      });
    } else {
      toast('La quantità non può essere vuota o minore di 1', {
        style: {
          fontSize: '14px',
          backgroundColor: 'red',
          color: '#ffffff'
        }
      });
    }
  };

  const handleOnclose = () => {
    setSelectedVariant(null);
    setNewValue(null);
    setRefreshMode(false);
    setForceUpdate(true);
  };

  useEffect(() => {
    setForceUpdate(true);
  }, [query]);

  return (
    <Box className="variant-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <UiDialogForm
        open={refreshMode}
        onClose={handleOnclose}
        title={'Aggiorna quantità'}
        subtitle={"Inserisci la nuova quantità dell'articolo scelto"}
        onConfirm={handleSubmitRefresh}
        setValue={setNewValue}
        value={newValue}
      />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Magazzino Prodotti
      </Typography>

      <Box my={3}>
        <UIInputSearch value={query} setValue={setQuery} />
      </Box>

      <WarehouseTable
        prepareValues={prepareValues}
        call={onListShopWarehousePaginated}
        callData={{
          status: 'terminated',
          query
        }}
        headColor={'#FFE1E1'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
      <WarehouseTable
        prepareValues={prepareValues}
        call={onListShopWarehousePaginated}
        callData={{
          status: 'near_terminated',
          query
        }}
        headColor={'#FFD7B2'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
      <WarehouseTable
        prepareValues={prepareValues}
        call={onListShopWarehousePaginated}
        callData={{
          query
        }}
        headColor={'#EAECF0'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
    </Box>
  );
};

const WarehouseTable = ({ prepareValues, headColor, call, callData, forceUpdate, setForceUpdate }) => {
  return (
    <UITable
      columns={[
        {
          name: 'Prodotto',
          value: 'name',
          size: 180
        },
        {
          name: 'Quantità',
          value: 'quantity',
          size: 50
        },
        {
          name: 'Prezzo',
          value: 'price',
          size: 50
        },
        {
          name: '',
          value: 'actionsEl',
          size: 50
        }
      ]}
      infiniteScrollFunction={call}
      callData={callData}
      prepareValues={prepareValues}
      muiTableHeadProps={() => {
        return {
          sx: {
            tr: {
              bgcolor: headColor,
              boxShadow: 'none'
            }
          }
        };
      }}
      muiTableBodyRowProps={({ row }) => {
        return {
          sx: {
            bgcolor: row?.original?.firstLevel ? '#EAEAEA' : '#FFF',
            color: '#000',
            fontFamily: 'Oxygen',
            fontWeight: '400',
            fontSize: '15px',
            mt: row?.original?.firstLevel ? '5px' : 0
          }
        };
      }}
      enableRowHoverColor={true}
      enableExpanding={true}
      enableCustomFilters={true}
      stateExpanded={true}
      tableHeight={'calc(100vh - 400px)'}
      forceUpdate={forceUpdate}
      setForceUpdate={setForceUpdate}
      infiniteScrollEndMessage={'Non ci sono altri prodotti da mostrare'}
      muiTablePaperPropsSx={{
        bgcolor: 'transparent',
        boxShadow: 'none'
      }}
    />
  );
};

export default ShopProductsWarehouseView;
