import { Box } from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography
} from '@mui/material';
import { Icon123, IconBulb, IconCheck, IconCurrencyDollar, IconSignature, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

const ModalEditVariant = ({ modalOpen, handleModalClose, variant, variants, setFieldValue }) => {
  let [data, setData] = useState(null);
  let [variantsData, setVariants] = useState(variants);

  useEffect(() => {
    if (variants) {
      setVariants(variants);
    } else {
      setVariants([]);
    }
  }, [variants]);

  useEffect(() => {
    if (variant) {
      setData(variant);
    } else {
      setData(null);
    }
  }, [variant]);

  const handleSubmit = async (values) => {
    const { name, size, price, quantity, active } = values;

    if (data?.uuid) {
      variantsData = variantsData?.map((item) => {
        if (item.uuid === data.uuid) {
          return {
            ...item,
            name,
            size,
            price,
            quantity,
            active
          };
        } else {
          return item;
        }
      });
      setFieldValue('variants', variantsData);
    } else {
      let new_array = [...variantsData];
      new_array?.push({
        uuid: uuidv4(),
        name,
        size,
        price,
        quantity,
        active,
        newItem: true
      });
      setFieldValue('variants', new_array);
    }

    handleModalClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Formik
            initialValues={{
              name: data?.name ?? '',
              size: data?.size ?? '',
              price: data?.price ?? '',
              quantity: data?.quantity ?? '',
              active: data?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Il nome è obbligatorio'),
              size: Yup.string().required('La taglia è obbligatoria'),
              price: Yup.number().required('Il prezzo è obbligatorio'),
              quantity: Yup.number().required('La quantità è obbligatoria'),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati Variante
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <InputLabel htmlFor="input-name">Nome</InputLabel>
                            <OutlinedInput
                              id="input-name"
                              type="text"
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={(event) => setFieldValue('name', event.target.value)}
                              label="Nome"
                              inputProps={{
                                maxLength: 12
                              }}
                            />
                            {touched.name && errors.name && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.size && errors.size)}>
                            <InputLabel htmlFor="input-size">Taglia</InputLabel>
                            <OutlinedInput
                              id="input-size"
                              type="text"
                              value={values.size}
                              name="size"
                              onBlur={handleBlur}
                              onChange={(event) => setFieldValue('size', event.target.value)}
                              label="Taglia"
                              inputProps={{
                                maxLength: 14
                              }}
                            />
                            {touched.size && errors.size && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.size}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <Icon123 stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.quantity && errors.quantity)}>
                            <InputLabel htmlFor="input-size">Quantità</InputLabel>
                            <OutlinedInput
                              id="input-quantity"
                              type="number"
                              value={values.quantity}
                              name="quantity"
                              onBlur={handleBlur}
                              onChange={(event) => setFieldValue('quantity', event.target.value)}
                              label="Quantità"
                              inputProps={{
                                step: 1,
                                min: 0,
                                lang: 'en-150'
                              }}
                            />
                            {touched.quantity && errors.quantity && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.quantity}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconCurrencyDollar stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.size && errors.size)}>
                            <InputLabel htmlFor="input-size">Prezzo</InputLabel>
                            <OutlinedInput
                              id="input-price"
                              type="number"
                              value={values.price}
                              name="price"
                              onBlur={handleBlur}
                              onChange={(event) => setFieldValue('price', event.target.value)}
                              label="Prezzo"
                              inputProps={{
                                step: 0.01,
                                min: 0,
                                lang: 'en-150'
                              }}
                            />
                            {touched.price && errors.price && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.price}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.active}
                                  onChange={(event) => setFieldValue('active', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Attivo</Typography>}
                            />
                            {touched.active && errors.active && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.active}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'} onClick={handleModalClose}>
                        <IconX stroke={1.5} size="1rem" />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};

export default ModalEditVariant;
