import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Badge, Box, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2, IconMessage2 } from '@tabler/icons-react';
import { useChats } from '../../../hooks/Chats/useChats';
import { useEffect, useState } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { onCountUnreadMessagesDoctor } = useChats();
  const [unreadMessages, setUnreadMessages] = useState(0);

  const countUnreadMessagesDoctor = () => {
    onCountUnreadMessagesDoctor()
      .then((res) => {
        setUnreadMessages(res ?? 0);
      })
      .catch(() => {});
  };

  useEffect(() => {
    countUnreadMessagesDoctor();
  }, []);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <ButtonBase sx={{ display: { xs: 'block', md: 'none' }, borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
        <Box component="span" sx={{ display: { xs: 'block', md: 'none' }, ml: '20px', flexGrow: 1 }}>
          <LogoSection size={'46px'} />
        </Box>
      </Box>

      {/* header search */}
      {/*<SearchSection />*/}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {/*<NotificationSection />*/}
      <ProfileSection />
      <Box>
        <ButtonBase
          sx={{
            bgcolor: '#FFC433',
            borderRadius: '100%',
            padding: '5px',
            marginLeft: '10px',
            width: '50px',
            height: '48px',
            color: '#FFF'
          }}
          onClick={() => navigate('/chat')}
        >
          <Badge badgeContent={unreadMessages} color="error">
            <IconMessage2 stroke={1.5} size="24px" />
          </Badge>
        </ButtonBase>
      </Box>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
