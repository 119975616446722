import {
  LIST_ARTICLES_ADMIN,
  GET_ARTICLE_ADMIN,
  CREATE_ARTICLE_ADMIN,
  UPDATE_ARTICLE_ADMIN,
  DELETE_ARTICLE_ADMIN,
  UPLOAD_ARTICLE_COVER,
  UPLOAD_ARTICLE_PREVIEW
} from './graphql/queries';
import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';

/** @format */
export const useArticles = () => {
  const { getDataGQL: listArticlesAdmin } = useQuery(LIST_ARTICLES_ADMIN);
  const { getDataGQL: getArticleAdmin } = useQuery(GET_ARTICLE_ADMIN);
  const { getDataGQL: createArticleAdmin } = useMutation(CREATE_ARTICLE_ADMIN);
  const { getDataGQL: updateArticleAdmin } = useMutation(UPDATE_ARTICLE_ADMIN);
  const { getDataGQL: deleteArticleAdmin } = useMutation(DELETE_ARTICLE_ADMIN);
  const { getDataGQL: uploadArticleCover } = useMutation(UPLOAD_ARTICLE_COVER);
  const { getDataGQL: uploadArticlePreview } = useMutation(UPLOAD_ARTICLE_PREVIEW);

  const onListArticlesAdmin = async ({ query, limit, page }) => {
    const response = await listArticlesAdmin({
      variables: {
        query,
        limit,
        page
      }
    });
    if (response?.adminRoute?.listArticlesAdmin) {
      return response?.adminRoute?.listArticlesAdmin;
    }
    return null;
  };

  const onGetArticleAdmin = async ({ article_uuid }) => {
    const response = await getArticleAdmin({
      variables: {
        article_uuid
      }
    });
    if (response?.adminRoute?.getArticleAdmin) {
      return response?.adminRoute?.getArticleAdmin;
    }
    return null;
  };

  const onCreateArticleAdmin = async ({ article_data }) => {
    const response = await createArticleAdmin({
      variables: {
        article_data
      }
    });
    if (response?.adminRoute?.createArticleAdmin) {
      return response?.adminRoute?.createArticleAdmin;
    }
    return null;
  };

  const onUpdateArticleAdmin = async ({ article_uuid, article_data }) => {
    const response = await updateArticleAdmin({
      variables: {
        article_uuid,
        article_data
      }
    });
    if (response?.adminRoute?.updateArticleAdmin) {
      return response?.adminRoute?.updateArticleAdmin;
    }
    return null;
  };

  const onDeleteArticleAdmin = async ({ article_uuid }) => {
    const response = await deleteArticleAdmin({
      variables: {
        article_uuid
      }
    });
    if (response?.adminRoute?.deleteArticleAdmin) {
      return response?.adminRoute?.deleteArticleAdmin;
    }
    return null;
  };

  const onUploadArticleCover = async ({ article_uuid, filename, contentType }) => {
    const response = await uploadArticleCover({
      variables: {
        article_uuid,
        filename,
        contentType
      }
    });
    if (response?.adminRoute?.uploadArticleCover) {
      return response?.adminRoute?.uploadArticleCover;
    }
    return null;
  };

  const onUploadArticlePreview = async ({ article_uuid, filename, contentType }) => {
    const response = await uploadArticlePreview({
      variables: {
        article_uuid,
        filename,
        contentType
      }
    });
    if (response?.adminRoute?.uploadArticlePreview) {
      return response?.adminRoute?.uploadArticlePreview;
    }
    return null;
  };

  return {
    onListArticlesAdmin,
    onGetArticleAdmin,
    onCreateArticleAdmin,
    onUpdateArticleAdmin,
    onDeleteArticleAdmin,
    onUploadArticleCover,
    onUploadArticlePreview
  };
};
