import { SEND_NOTIFICATION_GLOBAL_ADMIN } from './graphql/queries';
import { useMutation } from '../useMutation';

/** @format */
export const useNotifications = () => {
  const { getDataGQL: sendGlobalNotificationAdmin } = useMutation(SEND_NOTIFICATION_GLOBAL_ADMIN);

  const onSendGlobalNotificationAdmin = async ({ notification_data }) => {
    const response = await sendGlobalNotificationAdmin({
      variables: {
        notification_data
      }
    });
    if (response?.adminRoute?.sendGlobalNotificationAdmin) {
      return response?.adminRoute?.sendGlobalNotificationAdmin;
    }
    return null;
  };

  return {
    onSendGlobalNotificationAdmin
  };
};
