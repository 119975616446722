import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { UIModal, UIModalBody, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UITypography } from '../../utilities/Typography';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import ModalAppointmentDetails from '../modals/modalAppointmentDetails';
import moment from 'moment';
import { Box } from '@mui/material';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import { UIButton } from '../../utilities/Button';
import { IconEdit, IconPencil, IconTrash } from '@tabler/icons-react';
import { UiDialog } from '../../utilities/Dialog';
import { toast } from 'react-toastify';
import { statusColor, statusText } from '../../../utils/const';
import ModalCompileAppointment from '../calendar/ModalCompileAppointment';

const ModalUserAppointments = ({ modalOpen, handleModalClose, user, type }) => {
  let [userData, setUserData] = useState(null);
  let [compileMode, setCompileMode] = useState(false);
  let [deleteMode, setDeleteMode] = useState(false);
  let [editAppointmentMode, setEditAppointmentMode] = useState(false);
  let [selectedAppointment, setSelectedAppointment] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  let { onListUserAppointments, onUpdateAppointmentStatus } = useCalendar();

  useEffect(() => {
    if (!user) return;
    setUserData(user);
  }, [user]);

  useEffect(() => {
    setForceUpdate(true);
  }, [selectedAppointment]);

  const handleModalCloseEditAppointment = () => {
    setSelectedAppointment(null);
    setEditAppointmentMode(false);
    setCompileMode(false);
  };

  const handleModalCloseInternal = () => {
    setUserData(null);
    setSelectedAppointment(null);
    handleModalClose();
  };
  const handleRejectAppointment = () => {
    onUpdateAppointmentStatus({
      appointment_id: selectedAppointment?.id,
      status: 'rejected'
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
      setDeleteMode(false);
      setSelectedAppointment(null);
      setForceUpdate(true);
    });
  };

  const columns = [
    {
      name: <UITableHeadCell title={'Appuntamento'} />,
      value: 'appointmentEl',
      maxSize: 120,
      minSize: 100,
      size: 110
    },
    {
      name: '',
      value: 'actionsEl',
      maxSize: 80,
      size: 50
    }
  ];
  const prepareValues = (appointments) => {
    let appointmentsArray = [];

    let analyzed_day = null;

    appointments?.map((appointment) => {
      if (analyzed_day !== moment(appointment?.date).format('YYYY-MM-DD')) {
        let new_date = moment(appointment?.date).format('YYYY-MM-DD');

        appointmentsArray?.push({
          appointment: null,
          appointmentEl: (
            <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {new_date}
            </Box>
          ),
          actionsEl: null,
          date: new_date
        });

        analyzed_day = new_date;
      }

      appointmentsArray?.push({
        appointment: appointment,
        appointmentEl: (
          <Box>
            <UITypography variant={'Oxygen700'} size={'14px'} title={appointment?.start_hour} />
            <UITypography variant={'Oxygen400'} size={'13px'} title={appointment?.treatment?.name} />
            <UITypography
              variant={'Oxygen400'}
              size={'12px'}
              title={`${appointment?.calendar?.doctor?.name} ${appointment?.calendar?.doctor?.surname}`}
            />
            <UITypography variant={'Oxygen400'} size={'12px'} title={appointment?.calendar?.office?.name} />
            <UITypography variant={'Oxygen700'} size={'12px'} title={appointment?.calendar?.office?.city?.name} />
            <UITypography
              variant={'Oxygen700'}
              size={'12px'}
              title={appointment?.patient_not_shown ? 'Non presentato' : statusText(appointment?.status)}
              sxCustom={{
                color: appointment?.patient_not_shown ? '#FF3A44' : statusColor(appointment?.status),
                textTransform: 'uppercase',
                mt: '5px'
              }}
            />
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedAppointment(appointment);
                setEditAppointmentMode(true);
              }}
              icon={
                <IconEdit
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />

            {type === 'new' && (
              <>
                {['pending', 'confirmed'].includes(appointment?.status) && (
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setDeleteMode(true);
                    }}
                    icon={
                      <IconTrash
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                )}
              </>
            )}

            {type === 'old' && ['completed'].includes(appointment?.status) && !appointment.patient_not_shown && (
              <>
                <UIButton
                  type={'icon'}
                  onClick={() => {
                    setSelectedAppointment(appointment);
                    setCompileMode(true);
                  }}
                  icon={
                    <IconPencil
                      stroke={'1.67px'}
                      style={{
                        width: '24px'
                      }}
                    />
                  }
                />

                <Box
                  sx={{
                    borderRadius: '100%',
                    bgcolor: appointment?.compiled ? '#00E676' : '#FE922E',
                    width: '14px',
                    height: '14px',
                    m: '0 auto'
                  }}
                />
              </>
            )}
          </Box>
        )
      });
    });

    return appointmentsArray;
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiDialog
        type={'default'}
        variant={'alert'}
        title={'Vuoi annullare questo appuntamento?'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleRejectAppointment}
      />

      <ModalAppointmentDetails
        appointment={selectedAppointment}
        handleModalClose={handleModalCloseEditAppointment}
        modalOpen={editAppointmentMode}
      />

      <ModalCompileAppointment
        appointment={selectedAppointment}
        handleModalClose={handleModalCloseEditAppointment}
        modalOpen={compileMode}
      />

      <UiModalInternal onClose={handleModalClose} isOperationLoading={pageLoading}>
        <UIModalHeader
          sx={{
            bgcolor: '#FFF'
          }}
        >
          <UITypography
            variant={'Oxygen700'}
            title={`${userData?.name} ${userData?.surname}`}
            size={'15px'}
            sxCustom={{
              textAlign: 'center',
              color: '#101828'
            }}
          />
          <UITypography
            variant={'Oxygen700'}
            title={(type === 'new' && 'Appuntamenti futuri') || (type === 'old' && 'Appuntamenti passati')}
            size={'15px'}
            sxCustom={{
              textAlign: 'center',
              color: '#778093',
              mt: '5px'
            }}
          />
        </UIModalHeader>
        <UIModalBody
          buttonsSpace={false}
          sx={{
            bgcolor: 'transparent',
            p: '10px 20px'
          }}
        >
          <UITable
            columns={columns}
            prepareValues={prepareValues}
            enableInfiniteScroll={false}
            infiniteScrollFunction={onListUserAppointments}
            callData={{
              user_uuid: user?.uuid,
              type: type
            }}
            tableHeight={'auto'}
            forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate}
            setExternalLoading={setPageLoading}
            muiTableBodyRowProps={(data) => {
              return {
                sx: {
                  bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
                  p: 0,
                  '& td': data?.row?.original?.date
                    ? {
                        p: '5px 20px'
                      }
                    : {}
                }
              };
            }}
          />
        </UIModalBody>
      </UiModalInternal>
    </UIModal>
  );
};

export default ModalUserAppointments;
