import { useQuery } from '../useQuery';
import {
  CREATE_APPOINTMENT,
  CREATE_APPOINTMENT_PRODUCT_ADMIN,
  CREATE_BLOCK,
  CREATE_TIME_DOCTOR,
  DELETE_APPOINTMENT,
  DELETE_APPOINTMENT_PRODUCT_ADMIN,
  DELETE_BLOCK,
  DELETE_TIME_DOCTOR,
  DELETE_WAITING_LIST_ADMIN,
  GET_APPOINTMENT,
  GET_CALENDARS,
  GET_DOCTORS_APPOINTMENTS,
  LIST_APPOINTMENT_PRODUCTS_ADMIN,
  LIST_NEAREST_APPOINTMENTS,
  LIST_TIMES_DOCTOR,
  LIST_WAITING_LIST_ADMIN,
  LIST_USER_APPOINTMENTS,
  SET_PATIENT_NOT_SHOWN,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_PRODUCT_ADMIN,
  UPDATE_APPOINTMENT_STATUS,
  UPDATE_TIME_DOCTOR,
  UPLOAD_STAMP,
  ADD_APPOINTMENT_SUGGESTED_PRODUCTS,
  REMOVE_APPOINTMENT_SUGGESTED_PRODUCTS,
  UPDATE_APPOINTMENT_COMPILE_STATUS,
  CREATE_HOLIDAYS_BLOCKS,
  GET_DOCTOR_HOURS,
  CREATE_EXCEPTION_TIME_DOCTOR,
  UPDATE_EXCEPTION_TIME_DOCTOR,
  DELETE_EXCEPTION_TIME_DOCTOR,
  UPDATE_APPOINTMENT_TIME,
  GET_DOCTORS_APPOINTMENTS_TO_PRINT,
  LIST_DAILY_APPOINTMENTS,
  LIST_APPOINTMENTS,
  DELETE_WAITING_LIST_BLOCK
} from './graphql/queries';
import { useMutation } from '../useMutation';

/** @format */
export const useCalendar = () => {
  const { getDataGQL: getDoctorsAppointments } = useQuery(GET_DOCTORS_APPOINTMENTS);
  const { getDataGQL: listAppointmentsHistory } = useQuery(LIST_APPOINTMENTS);
  const { getDataGQL: getDoctorsAppointmentsToPrint } = useQuery(GET_DOCTORS_APPOINTMENTS_TO_PRINT);
  const { getDataGQL: getDoctorHours } = useQuery(GET_DOCTOR_HOURS);
  const { getDataGQL: getAppointment } = useQuery(GET_APPOINTMENT);
  const { getDataGQL: listCalendarsAdmin } = useQuery(GET_CALENDARS);
  const { getDataGQL: createBlock } = useMutation(CREATE_BLOCK);
  const { getDataGQL: createHolidaysBlocks } = useMutation(CREATE_HOLIDAYS_BLOCKS);
  const { getDataGQL: deleteBlock } = useMutation(DELETE_BLOCK);
  const { getDataGQL: createAppointment } = useMutation(CREATE_APPOINTMENT);
  const { getDataGQL: updateAppointment } = useMutation(UPDATE_APPOINTMENT);
  const { getDataGQL: updateAppointmentStatus } = useMutation(UPDATE_APPOINTMENT_STATUS);
  const { getDataGQL: updateAppointmentTime } = useMutation(UPDATE_APPOINTMENT_TIME);
  const { getDataGQL: updateAppointmentCompileStatus } = useMutation(UPDATE_APPOINTMENT_COMPILE_STATUS);
  const { getDataGQL: deleteAppointment } = useMutation(DELETE_APPOINTMENT);
  const { getDataGQL: deleteWaitingListBlock } = useMutation(DELETE_WAITING_LIST_BLOCK);
  const { getDataGQL: uploadStamp } = useMutation(UPLOAD_STAMP);
  const { getDataGQL: listCalendarAppointmentProductsAdmin } = useQuery(LIST_APPOINTMENT_PRODUCTS_ADMIN);
  const { getDataGQL: createCalendarAppointmentProductAdmin } = useMutation(CREATE_APPOINTMENT_PRODUCT_ADMIN);
  const { getDataGQL: updateCalendarAppointmentProductAdmin } = useMutation(UPDATE_APPOINTMENT_PRODUCT_ADMIN);
  const { getDataGQL: deleteCalendarAppointmentProductAdmin } = useMutation(DELETE_APPOINTMENT_PRODUCT_ADMIN);
  const { getDataGQL: addCalendarAppointmentSuggestedProduct } = useMutation(ADD_APPOINTMENT_SUGGESTED_PRODUCTS);
  const { getDataGQL: removeCalendarAppointmentSuggestedProduct } = useMutation(REMOVE_APPOINTMENT_SUGGESTED_PRODUCTS);
  const { getDataGQL: listNearestAppointments } = useMutation(LIST_NEAREST_APPOINTMENTS);
  const { getDataGQL: listDailyAppointments } = useMutation(LIST_DAILY_APPOINTMENTS);
  const { getDataGQL: listUserAppointments } = useMutation(LIST_USER_APPOINTMENTS);
  const { getDataGQL: listTimesDoctor } = useMutation(LIST_TIMES_DOCTOR);
  const { getDataGQL: createTimeDoctor } = useMutation(CREATE_TIME_DOCTOR);
  const { getDataGQL: updateTimeDoctor } = useMutation(UPDATE_TIME_DOCTOR);
  const { getDataGQL: deleteTimeDoctor } = useMutation(DELETE_TIME_DOCTOR);
  const { getDataGQL: createExceptionTimeDoctor } = useMutation(CREATE_EXCEPTION_TIME_DOCTOR);
  const { getDataGQL: updateExceptionTimeDoctor } = useMutation(UPDATE_EXCEPTION_TIME_DOCTOR);
  const { getDataGQL: deleteExceptionTimeDoctor } = useMutation(DELETE_EXCEPTION_TIME_DOCTOR);
  const { getDataGQL: setPatientNotShown } = useMutation(SET_PATIENT_NOT_SHOWN);
  const { getDataGQL: listWaitingListAdmin } = useMutation(LIST_WAITING_LIST_ADMIN);
  const { getDataGQL: deleteWaitingListAdmin } = useMutation(DELETE_WAITING_LIST_ADMIN);

  const onListCalendarsAdmin = async ({ doctor_id = null }) => {
    const response = await listCalendarsAdmin({
      variables: {
        doctor_id: doctor_id ?? null
      },
      fetchPolicy: 'network-only'
    });
    if (response?.adminRoute?.listCalendarsAdmin) {
      return response?.adminRoute?.listCalendarsAdmin;
    }
    return null;
  };

  const onListAppointmentsHistory = async ({ filters }) => {
    const response = await listAppointmentsHistory({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listAppointmentsHistory) {
      return response?.adminRoute?.listAppointmentsHistory;
    }
    return null;
  };
  const onListDoctorHours = async ({ start, end }) => {
    const response = await getDoctorHours({
      variables: {
        start,
        end
      },
      fetchPolicy: 'network-only'
    });
    if (response?.adminRoute?.getDoctorHours) {
      return response?.adminRoute?.getDoctorHours;
    }
    return null;
  };

  const onListNearestAppointments = async ({ filters }) => {
    const response = await listNearestAppointments({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listNearestAppointments) {
      return response?.adminRoute?.listNearestAppointments;
    }
    return null;
  };

  const onListDailyAppointments = async ({ filters }) => {
    const response = await listDailyAppointments({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listDailyAppointments) {
      return response?.adminRoute?.listDailyAppointments;
    }
    return null;
  };

  const onGetDoctorsAppointments = async ({ doctors, offices, start, end }) => {
    const response = await getDoctorsAppointments({
      variables: {
        doctors,
        start,
        end,
        offices
      }
    });
    if (response?.adminRoute?.getDoctorsAppointments) {
      return response?.adminRoute?.getDoctorsAppointments;
    }
    return null;
  };

  const onGetDoctorsAppointmentsToPrint = async ({ doctors, offices, start, end }) => {
    const response = await getDoctorsAppointmentsToPrint({
      variables: {
        doctors,
        start,
        end,
        offices
      }
    });
    if (response?.adminRoute?.getDoctorsAppointmentsToPrint) {
      return response?.adminRoute?.getDoctorsAppointmentsToPrint;
    }
    return null;
  };

  const onGetAppointment = async ({ appointment_id }) => {
    const response = await getAppointment({
      variables: {
        appointment_id
      }
    });
    if (response?.adminRoute?.getAppointment) {
      return response?.adminRoute?.getAppointment;
    }
    return null;
  };

  const onUpdateAppointment = async ({ appointment_id, appointment_data }) => {
    const response = await updateAppointment({
      variables: {
        appointment_id,
        appointment_data
      }
    });
    if (response?.adminRoute?.updateAppointment) {
      return response?.adminRoute?.updateAppointment;
    }
    return null;
  };

  const onUpdateAppointmentStatus = async ({ appointment_id, status }) => {
    const response = await updateAppointmentStatus({
      variables: {
        appointment_id,
        status
      }
    });
    if (response?.adminRoute?.updateAppointmentStatus) {
      return response?.adminRoute?.updateAppointmentStatus;
    }
    return null;
  };

  const onUpdateAppointmentTime = async ({ appointment_id, appointment_data }) => {
    const response = await updateAppointmentTime({
      variables: {
        appointment_id,
        appointment_data
      }
    });
    if (response?.adminRoute?.updateAppointmentTime) {
      return response?.adminRoute?.updateAppointmentTime;
    }
    return null;
  };

  const onUpdateAppointmentCompileStatus = async ({ appointment_id, appointment_data }) => {
    const response = await updateAppointmentCompileStatus({
      variables: {
        appointment_id,
        appointment_data
      }
    });
    if (response?.adminRoute?.updateAppointmentCompileStatus) {
      return response?.adminRoute?.updateAppointmentCompileStatus;
    }
    return null;
  };

  const onCreateAppointment = async ({ appointment_data }) => {
    const response = await createAppointment({
      variables: {
        appointment_data
      }
    });
    if (response?.adminRoute?.createAppointment) {
      return response?.adminRoute?.createAppointment;
    }
    return null;
  };

  const onCreateBlock = async ({ block_data }) => {
    const response = await createBlock({
      variables: {
        block_data
      }
    });
    if (response?.adminRoute?.createBlock) {
      return response?.adminRoute?.createBlock;
    }
    return null;
  };

  const onCreateHolidaysBlocks = async ({ office_id, year }) => {
    const response = await createHolidaysBlocks({
      variables: {
        office_id,
        year
      }
    });
    if (response?.adminRoute?.createHolidaysBlocks) {
      return response?.adminRoute?.createHolidaysBlocks;
    }
    return null;
  };

  const onDeleteBlock = async ({ block_id }) => {
    const response = await deleteBlock({
      variables: {
        block_id
      }
    });
    if (response?.adminRoute?.deleteBlock) {
      return response?.adminRoute?.deleteBlock;
    }
    return null;
  };

  const onDeleteAppointment = async ({ appointment_id }) => {
    const response = await deleteAppointment({
      variables: {
        appointment_id
      }
    });
    if (response?.adminRoute?.deleteAppointment) {
      return response?.adminRoute?.deleteAppointment;
    }
    return null;
  };

  const onDeleteWaitingListBlock = async ({ appointment_id }) => {
    const response = await deleteWaitingListBlock({
      variables: {
        appointment_id
      }
    });
    if (response?.adminRoute?.deleteWaitingListBlock) {
      return response?.adminRoute?.deleteWaitingListBlock;
    }
    return null;
  };

  const onUploadStamp = async ({ filename, contentType, folder }) => {
    const response = await uploadStamp({
      variables: {
        filename,
        contentType,
        folder
      }
    });
    if (response?.adminRoute?.uploadStamp) {
      return response?.adminRoute?.uploadStamp;
    }
    return null;
  };

  const onListCalendarAppointmentProductsAdmin = async ({ appointment_id }) => {
    const response = await listCalendarAppointmentProductsAdmin({
      variables: {
        appointment_id
      }
    });
    if (response?.adminRoute?.listCalendarAppointmentProductsAdmin) {
      return response?.adminRoute?.listCalendarAppointmentProductsAdmin;
    }
    return null;
  };

  const onCreateCalendarAppointmentProductAdmin = async ({ appointment_product_data }) => {
    const response = await createCalendarAppointmentProductAdmin({
      variables: {
        appointment_product_data
      }
    });
    if (response?.adminRoute?.createCalendarAppointmentProductAdmin) {
      return response?.adminRoute?.createCalendarAppointmentProductAdmin;
    }
    return null;
  };

  const onUpdateCalendarAppointmentProductAdmin = async ({ appointment_product_id, appointment_product_data }) => {
    const response = await updateCalendarAppointmentProductAdmin({
      variables: {
        appointment_product_id,
        appointment_product_data
      }
    });
    if (response?.adminRoute?.updateCalendarAppointmentProductAdmin) {
      return response?.adminRoute?.updateCalendarAppointmentProductAdmin;
    }
    return null;
  };

  const onDeleteCalendarAppointmentProductAdmin = async ({ appointment_product_id }) => {
    const response = await deleteCalendarAppointmentProductAdmin({
      variables: {
        appointment_product_id
      }
    });
    if (response?.adminRoute?.deleteCalendarAppointmentProductAdmin) {
      return response?.adminRoute?.deleteCalendarAppointmentProductAdmin;
    }
    return null;
  };

  const onAddCalendarAppointmentSuggestedProduct = async ({ appointment_id, product_uuid }) => {
    const response = await addCalendarAppointmentSuggestedProduct({
      variables: {
        appointment_id,
        product_uuid
      }
    });
    if (response?.adminRoute?.addCalendarAppointmentSuggestedProduct) {
      return response?.adminRoute?.addCalendarAppointmentSuggestedProduct;
    }
    return null;
  };

  const onRemoveCalendarAppointmentSuggestedProduct = async ({ appointment_id, product_uuid }) => {
    const response = await removeCalendarAppointmentSuggestedProduct({
      variables: {
        appointment_id,
        product_uuid
      }
    });
    if (response?.adminRoute?.removeCalendarAppointmentSuggestedProduct) {
      return response?.adminRoute?.removeCalendarAppointmentSuggestedProduct;
    }
    return null;
  };

  const onListTimesDoctor = async ({ office_id = null }) => {
    const response = await listTimesDoctor({
      variables: {
        office_id: office_id,
        exception: false
      }
    });
    if (response?.adminRoute?.listTimesDoctor) {
      return response?.adminRoute?.listTimesDoctor;
    }
    return null;
  };
  const onCreateTimeDoctor = async ({ time_data }) => {
    const response = await createTimeDoctor({
      variables: {
        time_data
      }
    });
    if (response?.adminRoute?.createTimeDoctor) {
      return response?.adminRoute?.createTimeDoctor;
    }
    return null;
  };

  const onUpdateTimeDoctor = async ({ time_group_uuid, time_data }) => {
    const response = await updateTimeDoctor({
      variables: {
        time_group_uuid,
        time_data
      }
    });
    if (response?.adminRoute?.updateTimeDoctor) {
      return response?.adminRoute?.updateTimeDoctor;
    }
    return null;
  };

  const onDeleteTimeDoctor = async ({ group_uuid }) => {
    const response = await deleteTimeDoctor({
      variables: {
        group_uuid
      }
    });
    if (response?.adminRoute?.deleteTimeDoctor) {
      return response?.adminRoute?.deleteTimeDoctor;
    }
    return null;
  };

  const onListExceptionsTimesDoctor = async ({ office_id = null }) => {
    const response = await listTimesDoctor({
      variables: {
        office_id: office_id,
        exception: true
      }
    });
    if (response?.adminRoute?.listTimesDoctor) {
      return response?.adminRoute?.listTimesDoctor;
    }
    return null;
  };

  const onCreateExceptionTimeDoctor = async ({ exception_data }) => {
    const response = await createExceptionTimeDoctor({
      variables: {
        exception_data
      }
    });
    if (response?.adminRoute?.createExceptionTimeDoctor) {
      return response?.adminRoute?.createExceptionTimeDoctor;
    }
    return null;
  };

  const onUpdateExceptionTimeDoctor = async ({ exception_id, exception_data }) => {
    const response = await updateExceptionTimeDoctor({
      variables: {
        exception_id,
        exception_data
      }
    });
    if (response?.adminRoute?.updateExceptionTimeDoctor) {
      return response?.adminRoute?.updateExceptionTimeDoctor;
    }
    return null;
  };

  const onDeleteExceptionTimeDoctor = async ({ exception_id }) => {
    const response = await deleteExceptionTimeDoctor({
      variables: {
        exception_id
      }
    });
    if (response?.adminRoute?.deleteExceptionTimeDoctor) {
      return response?.adminRoute?.deleteExceptionTimeDoctor;
    }
    return null;
  };
  const onSetPatientNotShown = async ({ appointment_id }) => {
    const response = await setPatientNotShown({
      variables: {
        appointment_id
      }
    });
    if (response?.adminRoute?.setPatientNotShown) {
      return response?.adminRoute?.setPatientNotShown;
    }
    return null;
  };

  const onListWaitingListAdmin = async ({ filters } = {}) => {
    const response = await listWaitingListAdmin({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listWaitingListAdmin) {
      return response?.adminRoute?.listWaitingListAdmin;
    }
    return null;
  };

  const onDeleteWaitingListAdmin = async ({ waiting_list_id }) => {
    const response = await deleteWaitingListAdmin({
      variables: {
        waiting_list_id
      }
    });
    if (response?.adminRoute?.deleteWaitingListAdmin) {
      return response?.adminRoute?.deleteWaitingListAdmin;
    }
    return null;
  };

  const onListUserAppointments = async ({ user_uuid, type = null }) => {
    const response = await listUserAppointments({
      variables: {
        user_uuid,
        type
      }
    });
    if (response?.adminRoute?.listUserAppointments) {
      return response?.adminRoute?.listUserAppointments;
    }
    return null;
  };

  return {
    onGetDoctorsAppointments,
    onListAppointmentsHistory,
    onGetDoctorsAppointmentsToPrint,
    onListDoctorHours,
    onCreateBlock,
    onCreateHolidaysBlocks,
    onDeleteBlock,
    onCreateAppointment,
    onUpdateAppointment,
    onUpdateAppointmentStatus,
    onUpdateAppointmentTime,
    onUpdateAppointmentCompileStatus,
    onDeleteAppointment,
    onDeleteWaitingListBlock,
    onGetAppointment,
    onListCalendarsAdmin,
    onUploadStamp,
    onListCalendarAppointmentProductsAdmin,
    onCreateCalendarAppointmentProductAdmin,
    onUpdateCalendarAppointmentProductAdmin,
    onDeleteCalendarAppointmentProductAdmin,
    onAddCalendarAppointmentSuggestedProduct,
    onRemoveCalendarAppointmentSuggestedProduct,
    onListNearestAppointments,
    onListDailyAppointments,
    onListTimesDoctor,
    onCreateTimeDoctor,
    onUpdateTimeDoctor,
    onDeleteTimeDoctor,
    onListExceptionsTimesDoctor,
    onCreateExceptionTimeDoctor,
    onUpdateExceptionTimeDoctor,
    onDeleteExceptionTimeDoctor,
    onListUserAppointments,
    onSetPatientNotShown,
    onListWaitingListAdmin,
    onDeleteWaitingListAdmin
  };
};
