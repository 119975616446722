import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { IconEye } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import ModalDetails from './modalDetails';
import { useShop } from '../../../hooks/Shop/useShop';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import UIInputAutocomplete from '../../utilities/InputSelect';

const ShopReviewsView = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    query: ''
  });

  const { onListShopReviews, onListShopProductsByQuery } = useShop();
  const { onListUsersAdmin } = useAccounts();

  const listReviews = () => {
    onListShopReviews({
      product_id: selectedProduct,
      account_id: selectedAccount,
      page,
      limit: 20
    }).then((res) => {
      setReviews(res?.rows);
      setTotalPages(res?.count);
    });
  };

  const listProducts = () => {
    onListShopProductsByQuery({
      onlyActive: true
    }).then((res) => {
      setProducts(res);
    });
  };

  const listPatients = () => {
    onListUsersAdmin({ filters }).then((res) => {
      setAccounts(res);
    });
  };

  useEffect(() => {
    listReviews();
    listProducts();
    listPatients();
  }, []);

  useEffect(() => {
    listPatients();
  }, [filters]);

  useEffect(() => {
    listReviews();
  }, [selectedProduct, selectedAccount, page]);

  const handleModalClose = useCallback(() => {
    setViewMode(false);
    setSelectedReview(null);
    listReviews();
  }, []);

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalDetails modalOpen={viewMode} handleModalClose={handleModalClose} review={selectedReview} />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Recensioni Prodotti
      </Typography>

      <Box
        my={3}
        display={'flex'}
        justifyContent={matchDownSM ? 'center' : 'flex-start'}
        alignItems={'center'}
        gap={2}
        w={'100%'}
        flexWrap={'wrap'}
      >
        <Box width={matchDownSM ? '45%' : '200px'}>
          <Autocomplete
            id="doctor-select"
            name={'doctor'}
            defaultValue={''}
            fullWidth={true}
            options={products?.map((product) => {
              return {
                id: Number(product?.id),
                label: `${product?.name} ` + (product?.brand?.[0]?.name ? `(${product?.brand?.[0]?.name})` : '')
              };
            })}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            onChange={(e, data) => {
              setSelectedProduct(data?.id);
            }}
            renderInput={(params) => <TextField {...params} label="Prodotto" />}
          />
        </Box>
        <Box width={matchDownSM ? '45%' : '200px'}>
          <UIInputAutocomplete
            label={'Account'}
            value={selectedAccount ?? null}
            setValue={(value) => setSelectedAccount(value?.id)}
            options={accounts?.map((patient) => {
              return {
                id: patient?.id,
                label: `${patient?.name} ${patient?.surname} - ${patient?.email} - ${patient?.phone}`
              };
            })}
            needTimeout={false}
            onTextValueChange={(value) => {
              setFilters({
                ...filters,
                query: value
              });
            }}
          />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Prodotto</TableCell>
              <TableCell align="center">Recensito da</TableCell>
              <TableCell align="center">Preview</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviews?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  component="th"
                  scope="row"
                  align={'center'}
                  sx={{
                    color: row?.reported_by?.length ? 'red' : '#364152',
                    fontWeight: row?.reported_by?.length ? 'bold' : 'normal'
                  }}
                >
                  {row?.product?.name} - {row?.product?.brand?.[0]?.name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align={'center'}
                  sx={{
                    color: row?.reported_by?.length ? 'red' : '#364152',
                    fontWeight: row?.reported_by?.length ? 'bold' : 'normal'
                  }}
                >
                  {row?.reviewer?.name} {row?.reviewer?.surname}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align={'center'}
                  sx={{
                    color: row?.reported_by?.length ? 'red' : '#364152',
                    fontWeight: row?.reported_by?.length ? 'bold' : 'normal'
                  }}
                >
                  {row?.review?.slice(0, 20)}...
                </TableCell>
                <TableCell align="center">
                  <IconEye
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedReview(row);
                      setViewMode(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} color={'primary'} />
      </Box>
    </Box>
  );
};
export default ShopReviewsView;
