import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import './custom-doctors.css';
import { IconLockOpen } from '@tabler/icons-react';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import UITable from '../../utilities/Table';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import { UiDialog } from '../../utilities/Dialog';

const UsersBannedView = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [unlockMode, setUnlockMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListUsersBannedPaginated, onUnbanUserDoctor } = useAccounts();

  const prepareValues = (users) =>
    users.map((user) => {
      return {
        patient: user,
        patientEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {user?.name} {user?.surname}
            </Typography>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'13px'} color={'#667085'}>
              <Link
                href={`mailto:${user?.email}`}
                target={'_blank'}
                rel={'noreferrer'}
                component={'a'}
                underline={'always'}
                color={'#667085'}
              >
                {user?.email}
              </Link>
            </Typography>
            <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'13px'}>
              <Link
                href={`tel:${user?.phone_country?.dial_code}${user?.phone}`}
                target={'_blank'}
                rel={'noreferrer'}
                component={'a'}
                underline={'always'}
                color={'#667085'}
              >
                {user?.phone_country?.dial_code} {user?.phone}
              </Link>
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setUnlockMode(true);
                setSelectedUser(user);
              }}
              icon={
                <IconLockOpen
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
          </Box>
        )
      };
    });

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <>
        <UiDialog
          type={'default'}
          variant={'alert'}
          title={'Vuoi sbloccare questo utente?'}
          open={unlockMode}
          onClose={() => setUnlockMode(false)}
          onConfirm={() => {
            onUnbanUserDoctor({ user_uuid: selectedUser?.uuid }).then(() => {
              setUnlockMode(false);
              setForceUpdate(true);
            });
          }}
        />
      </>

      <UIPageHeader
        title={'Pazienti bloccati'}
        subtitle={"Gestisci qui i pazienti bloccati. I pazienti che non hanno completato l'onboarding non saranno mostrati."}
      />

      <SectionHeader title={'Paziente'} />
      <UITable
        columns={[
          {
            name: 'Paziente',
            value: 'patientEl',
            size: 120
          },
          {
            name: '',
            value: 'actionsEl',
            size: 80
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        searchSubtitle={`E' possibile cercare per nome, cognome, email o numero di cellulare`}
        enableCustomFilters={false}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
        infiniteScrollFunction={onListUsersBannedPaginated}
      />
    </Box>
  );
};
export default UsersBannedView;
