import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, ButtonBase } from '@mui/material';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
import { MENU_OPEN } from 'store/actions';
import { Image } from '@chakra-ui/react';
import logo_image from 'assets/images/Skinboostlogo.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ size = '71px' }) => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
      component={Link}
      to={config.defaultPath}
      style={{
        fontSize: '1.5rem',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        borderRadius: '100%',
        overflow: 'hidden'
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          borderRadius: '100%',
          overflow: 'hidden'
        }}
      >
        <Image src={logo_image} alt="Berry" w={size} h={size} />
      </Box>
    </ButtonBase>
  );
};

export default LogoSection;
