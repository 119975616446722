// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import DoctorsView from '../views/pages/doctors/view';
import UsersView from '../views/pages/users/view';
import ProfileView from '../views/pages/profile/view';
import DoctorsReviewsView from '../views/pages/doctorsReviews/view';
import ChatListView from '../views/pages/chat/view';
import ChatDetailView from '../views/pages/chatDetail/view';
import Dashboard from '../views/pages/dashboard/view';
import EarningsView from '../views/pages/earnings/view';
import UsersBannedView from '../views/pages/usersBanned/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Users = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'doctors',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <DoctorsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'chat',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <ChatListView />
        </ProtectedRoute>
      )
    },
    {
      path: 'chat/:chat_uuid',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <ChatDetailView />
        </ProtectedRoute>
      )
    },
    {
      path: 'doctors_reviews',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <DoctorsReviewsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'users',
      element: (
        <ProtectedRoute permission={['DOCTOR', 'CLINIC']}>
          <UsersView />
        </ProtectedRoute>
      )
    },
    {
      path: 'users_banned',
      element: (
        <ProtectedRoute permission={['DOCTOR', 'CLINIC']}>
          <UsersBannedView />
        </ProtectedRoute>
      )
    },
    {
      path: 'profile',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <ProfileView />
        </ProtectedRoute>
      )
    },
    {
      path: 'dashboard',
      element: (
        <ProtectedRoute permission={['DOCTOR', 'CLINIC']}>
          <Dashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'earnings',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <EarningsView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Users;
