import React from 'react';
import { Box, CircularProgress, Divider, Modal } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { UILoader } from './Loader';

export const UIModal = ({ children, open, onClose, allowFullScreen, className }) => {
  return (
    <Modal open={open} onClose={onClose} allowFullScreen={allowFullScreen} className={className}>
      <Box>
        <>{children}</>
      </Box>
    </Modal>
  );
};

export const UiModalInternal = ({ children, onClose, containerSx = {}, isLoading = false, isOperationLoading = false }) => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        bgcolor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          width: '75%',
          maxWidth: '500px',
          height: '70%',
          bgcolor: '#EEF2F6',
          borderRadius: '8px',
          pointerEvents: 'all',
          position: 'relative',
          overflow: 'hidden',
          ...containerSx
        }}
        className={'container'}
      >
        {isOperationLoading && <UILoader loading={isOperationLoading} />}
        {onClose && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 999,
              top: '2.5%',
              right: '5%',
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
            onClick={onClose}
          >
            <IconX stroke={'1.64'} />
          </Box>
        )}
        {!isLoading ? (
          <Box
            sx={{
              height: '100%',
              overflow: 'auto'
            }}
          >
            {children}
          </Box>
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress
              sx={{
                color: '#FECE2E'
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const UIModalTopData = ({ children }) => {
  return (
    <Box
      className={'top_data'}
      sx={{
        height: '100%',
        overflow: 'auto'
      }}
    >
      {children}
    </Box>
  );
};

export const UIModalBottomData = ({ children }) => {
  return (
    <Box
      className={'bottom_buttons'}
      sx={{
        height: '50px',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: '10px'
      }}
    >
      {children}
    </Box>
  );
};

export const UIModalHeader = ({ children, sx = {} }) => (
  <Box
    className={'modal-header'}
    sx={{
      bgcolor: '#EEF2F6',
      width: '100%',
      boxSizing: 'border-box',
      p: '30px 0 20px 0',
      borderBottom: '1px solid #CFCFCF',
      ...sx
    }}
  >
    {children}
  </Box>
);

export const UIModalBody = ({ children, buttonsSpace = true, sx = {} }) => (
  <Box
    className={'modal-body'}
    m={!buttonsSpace ? '5px 10px' : '5px 10px 80px 10px'}
    sx={{
      bgcolor: '#FFF',
      ...sx
    }}
  >
    {children}
  </Box>
);

export const UIModalButtons = ({ children, sx = {} }) => (
  <Box
    className={'modal-body'}
    sx={{
      bgcolor: 'transparent',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '50px',
      mb: '20px',
      pointerEvents: 'none',
      ...sx
    }}
  >
    {children}
  </Box>
);

export const UIModalDivider = ({ sx }) => <Divider sx={{ m: '10px 20px', ...sx }} />;
