import { Box } from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { IconBulb, IconCategory, IconCheck, IconSignature, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useShop } from '../../../hooks/Shop/useShop';
import Paper from '@mui/material/Paper';

const types = ['function', 'ingredient', 'protocol', 'brand'];

const ModalEdit = ({ modalOpen, handleModalClose, discount }) => {
  let [deleteMode, setDeleteMode] = useState(false);
  let [data, setData] = useState({});
  let [categories, setCategories] = useState(null);
  let [listBrand, setListBrand] = useState([]);
  let [listProtocol, setListProtocol] = useState([]);
  let [listIngredient, setListIngredient] = useState([]);
  let [listFunction, setListFunction] = useState([]);
  let [products, setProducts] = useState([]);
  let [listSelectedProducts, setListSelectedProducts] = useState([]);

  const {
    onGetShopDiscount,
    onCreateShopDiscount,
    onUpdateShopDiscount,
    onDeleteShopDiscount,
    onListShopCategories,
    onListShopProductsByQuery
  } = useShop();

  const getDiscount = async () => {
    onGetShopDiscount({
      discount_uuid: discount?.uuid
    }).then((res) => {
      let categories = [];
      res?.categories?.forEach((category) => {
        if (!categories[category?.type]) {
          categories[category?.type] = [];
        }
        categories?.[category?.type]?.push(category.uuid);
      });

      setListSelectedProducts(res?.products);

      res.categories = categories;
      res.products = res?.products?.map((product) => product?.uuid);

      setDeleteMode(false);
      setData(res);
    });
  };

  const listCategories = async () => {
    onListShopCategories().then((res) => {
      let new_res = {};

      res?.forEach((category) => {
        if (new_res[category.type]) {
          new_res?.[category.type]?.push(category);
        } else {
          new_res[category.type] = [category];
        }
      });

      setCategories(new_res);
    });
  };

  const listProducts = async () => {
    onListShopProductsByQuery({ forceAll: true }).then((res) => {
      setProducts(res);
    });
  };

  const createInitList = () => {
    let list = [];
    for (let type of types) {
      data?.categories?.[type]?.forEach((value) => {
        let category = categories?.[type]?.find((category) => category?.uuid === value);

        if (category) {
          if (!list[category?.type]) {
            list[category?.type] = [];
          }
          list?.[category?.type]?.push({
            uuid: category?.uuid,
            name: category?.name,
            discount_value: category?.discount_value ?? 0,
            discount_type: 'percentage'
          });
        }
      });
    }

    setListBrand(list?.brand ?? []);
    setListProtocol(list?.protocol ?? []);
    setListIngredient(list?.ingredient ?? []);
    setListFunction(list?.function ?? []);
  };

  useEffect(() => {
    if (discount?.uuid) {
      getDiscount();
    } else {
      setData(null);
    }
  }, [discount]);

  useEffect(() => {
    listCategories();
    listProducts();
  }, [data]);

  useEffect(() => {
    createInitList();
  }, [categories]);

  const handleDeleteDiscount = async () => {
    await onDeleteShopDiscount({
      discount_uuid: data?.uuid
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { title, active } = values;

    let product_final = listSelectedProducts?.map((product) => {
      return {
        uuid: product.uuid,
        deleteItem: product.deleteItem,
        variants: product?.variants?.map((variant) => {
          return {
            uuid: variant.uuid,
            discount_price: variant.discount_price
          };
        })
      };
    });

    if (data?.uuid) {
      onUpdateShopDiscount({
        discount_uuid: data?.uuid,
        discount_data: {
          title,
          categories: [...listProtocol, ...listIngredient, ...listFunction, ...listBrand],
          products: product_final,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateShopDiscount({
        discount_data: {
          title,
          categories: [...listProtocol, ...listIngredient, ...listFunction, ...listBrand],
          products: product_final,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  const getCategoriesByType = (type) => {
    switch (type) {
      case 'brand':
        return listBrand;
      case 'protocol':
        return listProtocol;
      case 'ingredient':
        return listIngredient;
      case 'function':
        return listFunction;
      default:
        break;
    }
  };

  const handleSelectCategory = (value, type) => {
    let new_value = [...getCategoriesByType(type)];

    for (let item of value) {
      if (!new_value?.find((element) => element?.uuid === item)) {
        let category = categories?.[type]?.find((category) => category?.uuid === item);

        if (category) {
          new_value?.push({
            uuid: category?.uuid,
            name: category?.name,
            discount_value: category?.discount_value ?? 0,
            discount_type: 'percentage'
          });
        }
      }
    }

    for (let item of Object.keys(new_value)) {
      new_value[item].deleteItem = !value?.includes(new_value[item]?.uuid);
    }

    switch (type) {
      case 'brand':
        setListBrand(new_value);
        break;
      case 'protocol':
        setListProtocol(new_value);
        break;
      case 'ingredient':
        setListIngredient(new_value);
        break;
      case 'function':
        setListFunction(new_value);
        break;
    }
  };

  const handleSelectProduct = (value) => {
    let new_value = [...listSelectedProducts];

    for (let item of value) {
      if (!new_value?.find((element) => element?.uuid === item)) {
        let product = products?.find((product) => product?.uuid === item);

        if (product) {
          new_value?.push({
            uuid: product?.uuid,
            name: product?.name,
            discount_value: product?.discount_value ?? 0,
            discount_type: 'percentage',
            variants: product?.variants
          });
        }
      }
    }

    for (let item of Object.keys(new_value)) {
      new_value[item].deleteItem = !value?.includes(new_value[item]?.uuid);
    }

    setListSelectedProducts(new_value);
  };

  const handleDeleteProduct = (value) => {
    let new_value = [...listSelectedProducts];

    let element_key = new_value?.findIndex((element) => element?.uuid === value);

    if (element_key >= 0) {
      new_value[element_key].deleteItem = true;
    }

    setListSelectedProducts(new_value);
  };

  const handleSetListValue = (value, category, type) => {
    let new_value = [...getCategoriesByType(type)];

    let element_key = new_value?.findIndex((element) => element?.uuid === category?.uuid);

    if (element_key >= 0) {
      new_value[element_key].discount_value = Number(value);
    } else {
      new_value?.push({
        uuid: category?.uuid,
        name: category?.name,
        discount_value: Number(value),
        discount_type: 'percentage'
      });
    }

    switch (type) {
      case 'brand':
        setListBrand(new_value);
        break;
      case 'protocol':
        setListProtocol(new_value);
        break;
      case 'ingredient':
        setListIngredient(new_value);
        break;
      case 'function':
        setListFunction(new_value);
        break;
    }
  };

  const handleSetListProductValue = (value, product, variant) => {
    let new_value = [...listSelectedProducts];

    let productIndex = new_value?.findIndex((element) => element?.uuid === product?.uuid);
    let variantIndex = new_value[productIndex]?.variants?.findIndex((element) => element?.uuid === variant?.uuid);
    new_value[productIndex].variants[variantIndex].discount = true;
    new_value[productIndex].variants[variantIndex].discount_price = Number(value);

    setListSelectedProducts(new_value);
  };

  const handleDeleteCategory = ({ value, type }) => {
    let new_value = [...getCategoriesByType(type)];

    let element_key = new_value?.findIndex((element) => element?.uuid === value);

    if (element_key >= 0) {
      new_value[element_key] = {
        ...new_value[element_key],
        deleteItem: true
      };

      switch (type) {
        case 'brand':
          setListBrand(new_value);
          break;
        case 'protocol':
          setListProtocol(new_value);
          break;
        case 'ingredient':
          setListIngredient(new_value);
          break;
        case 'function':
          setListFunction(new_value);
          break;
      }
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-description"
    >
      <Box>
        <>
          <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
            <DialogTitle>Elimina categoria</DialogTitle>
            <DialogContent>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <DialogContentText>Vuoi eliminare lo sconto "{data?.title}"?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
                Annulla
              </Button>
              <Button variant={'contained'} color={'success'} onClick={handleDeleteDiscount}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
          <Formik
            initialValues={{
              title: data?.title ?? '',
              categories_function: data?.categories?.function ?? [],
              categories_protocol: data?.categories?.protocol ?? [],
              categories_ingredient: data?.categories?.ingredient ?? [],
              categories_brand: data?.categories?.brand ?? [],
              products: data?.products ?? [],
              active: data?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Il nome sconto è obbligatorio'),
              categories_function: Yup.array().nullable(),
              categories_protocol: Yup.array().nullable(),
              categories_ingredient: Yup.array().nullable(),
              categories_brand: Yup.array().nullable(),
              products: Yup.array().nullable(),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati Categoria
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.title && errors.title)}>
                            <InputLabel htmlFor="input-title">Nome sconto</InputLabel>
                            <OutlinedInput
                              id="input-title"
                              type="text"
                              value={values.title}
                              name="title"
                              onBlur={handleBlur}
                              onChange={(event) => setFieldValue('title', event.target.value)}
                              label="Nome sconto"
                            />
                            {touched.title && errors.title && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.title}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      {types?.map((type, index) => (
                        <SelectCategoryType
                          key={index}
                          type={type}
                          categories={categories}
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          selectedCategories={getCategoriesByType(type)}
                          handleSelectCategory={handleSelectCategory}
                          handleSetListValue={handleSetListValue}
                          handleDeleteCategory={handleDeleteCategory}
                        />
                      ))}

                      <SelectProducts
                        products={products}
                        errors={errors}
                        touched={touched}
                        values={values?.products}
                        setFieldValue={setFieldValue}
                        selectedProducts={listSelectedProducts}
                        handleSelectProduct={handleSelectProduct}
                        handleSetListProductValue={handleSetListProductValue}
                        handleDeleteProduct={handleDeleteProduct}
                      />

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.active}
                                  onChange={(event) => setFieldValue('active', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Attivo</Typography>}
                            />
                            {touched.active && errors.active && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.active}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      {discount?.uuid && (
                        <Box d={'flex'} justifyContent={'center'}>
                          <Box
                            fontSize={15}
                            textAlign={'center'}
                            color={'#f44336'}
                            fontFamily={'Montserrat, sans-serif'}
                            mt={10}
                            fontWeight={700}
                            textTransform={'uppercase'}
                            bg={'rgba(200,200,200,0.2)'}
                            p={'5px '}
                            cursor={'pointer'}
                            onClick={() => setDeleteMode(true)}
                          >
                            Elimina Categoria
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'}>
                        <IconX stroke={1.5} size="1rem" onClick={() => handleModalClose()} />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};

const SelectProducts = ({
  index,
  type,
  products,
  touched,
  errors,
  values,
  setFieldValue,
  selectedProducts,
  handleSelectProduct,
  handleSetListProductValue,
  handleDeleteProduct
}) => {
  return (
    <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} key={index}>
      <IconCategory stroke={1.5} size="1.3rem" />
      <Box ml={10} w={'85%'}>
        <FormControl fullWidth error={Boolean(touched.categories && errors.categories)}>
          <InputLabel id="products-label">Prodotti</InputLabel>
          <Select
            labelId="products-label"
            id="products-select"
            multiple={true}
            value={values}
            onChange={(event) => {
              handleSelectProduct(event?.target?.value);
              setFieldValue('products', event?.target?.value);
            }}
            input={<OutlinedInput id="categories-input" label="Categorie" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value, index) => (
                  <ChipCustomProducts
                    key={index}
                    type={type}
                    value={value}
                    products={products}
                    handleDeleteProduct={handleDeleteProduct}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250
                }
              }
            }}
          >
            {products?.map((product, index) => (
              <MenuItem key={index} value={product?.uuid}>
                {product?.name}
              </MenuItem>
            ))}
          </Select>

          {touched.products && errors.products && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {errors.products}
            </FormHelperText>
          )}
        </FormControl>

        <SelectedProductsList
          selectedProducts={selectedProducts}
          products={products}
          handleSetListProductValue={handleSetListProductValue}
        />
      </Box>
    </Box>
  );
};

const SelectCategoryType = ({
  index,
  type,
  categories,
  touched,
  errors,
  values,
  setFieldValue,
  selectedCategories,
  handleSelectCategory,
  handleSetListValue,
  handleDeleteCategory
}) => {
  let [title, setTitle] = useState('');

  useEffect(() => {
    switch (type) {
      case 'function':
        setTitle('Funzioni');
        break;
      case 'protocol':
        setTitle('Protocolli');
        break;
      case 'ingredient':
        setTitle('Ingredienti');
        break;
      case 'brand':
        setTitle('Marca');
        break;
      default:
        setTitle('Categoria');
        break;
    }
  }, [type, categories]);

  const disabledControl = (category) => {
    let item = selectedCategories?.find((item) => item?.uuid === category?.uuid);

    return Boolean(!item && category?.discounts?.length);
  };

  return (
    <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} key={index}>
      <IconCategory stroke={1.5} size="1.3rem" />
      <Box ml={10} w={'85%'}>
        <FormControl fullWidth error={Boolean(touched.categories && errors.categories)}>
          <InputLabel id="categories-label">{title}</InputLabel>
          <Select
            labelId="categories-label"
            id="categories-select"
            multiple={true}
            value={values['categories_' + type]}
            onChange={(event) => {
              handleSelectCategory(event?.target?.value, type);
              setFieldValue('categories_' + type, event.target.value);
            }}
            input={<OutlinedInput id="categories-input" label="Categorie" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value, index) => (
                  <ChipCustom
                    key={index}
                    type={type}
                    value={value}
                    categories={categories}
                    handleDeleteCategory={handleDeleteCategory}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250
                }
              }
            }}
          >
            {categories?.[type]?.map((category, index) => {
              let disabled = disabledControl(category);

              return (
                <MenuItem key={index} value={category?.uuid} disabled={disabled}>
                  {category?.name} {disabled && ' - Sconto già applicato'}
                </MenuItem>
              );
            })}
          </Select>

          {touched.categories && errors.categories && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {errors.categories}
            </FormHelperText>
          )}
        </FormControl>

        <SelectedCategoriesList
          selectedCategories={selectedCategories}
          type={type}
          categories={categories}
          handleSetListValue={handleSetListValue}
        />
      </Box>
    </Box>
  );
};

const ChipCustomProducts = ({ products, value, type }) => {
  let [label, setLabel] = useState('');

  useEffect(() => {
    products?.forEach((product) => {
      if (product?.uuid === value) setLabel(product?.name);
    });
  }, [value, products, type]);

  if (!label) return <></>;
  return (
    <Chip
      key={value}
      label={label}
      // clickable
      sx={{
        ml: 0.5
      }}
    />
  );
};

const ChipCustom = ({ categories, value, type }) => {
  let [label, setLabel] = useState('');

  useEffect(() => {
    categories?.[type]?.forEach((category) => {
      if (category?.uuid === value) setLabel(category?.name);
    });
  }, [value, categories, type]);

  if (!label) return <></>;
  return (
    <Chip
      key={value}
      label={label}
      clickable
      sx={{
        ml: 0.5
      }}
    />
  );
};

const SelectedCategoriesList = ({ selectedCategories, type, handleSetListValue }) => {
  return (
    <>
      {selectedCategories?.some((el) => !el.deleteItem) && (
        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
          <Box ml={10} w={'85%'}>
            <TableContainer
              component={Paper}
              sx={{
                width: '50%'
              }}
            >
              <Table sx={{ width: '100%' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Categoria</TableCell>
                    <TableCell align="center">Percentuale di sconto</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCategories?.map((category, index) => {
                    if (category?.deleteItem) return <React.Fragment key={index}></React.Fragment>;

                    return (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" align={'center'}>
                          {category.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <OutlinedInput
                            id={'discount_value_' + category?.uuid}
                            sx={{
                              overflow: 'hidden',
                              width: '100px'
                            }}
                            type="number"
                            value={category?.discount_value}
                            name="discount_value"
                            onChange={(e) => {
                              handleSetListValue(e.target.value, category, type);
                            }}
                            label={''}
                            inputProps={{
                              step: 0.1,
                              min: 0,
                              lang: 'en-150'
                            }}
                          />
                          <Typography ml={2} variant={'h3'}>
                            %
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

const SelectedProductsList = ({ selectedProducts, handleSetListProductValue }) => {
  return (
    <>
      {selectedProducts?.some((el) => !el.deleteItem) && (
        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
          <Box ml={10} w={'85%'}>
            <TableContainer
              component={Paper}
              sx={{
                width: '50%'
              }}
            >
              <Table sx={{ width: '100%' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Prodotto</TableCell>
                    <TableCell align="center">Prezzo</TableCell>
                    <TableCell align="center">Prezzo scontato</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProducts?.map((product, index) => {
                    if (product?.deleteItem) return <React.Fragment key={index}></React.Fragment>;

                    return (
                      <>
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            component="th"
                            scope="row"
                            align={'center'}
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              backgroundColor: 'lightgrey',
                              color: 'white'
                            }}
                          >
                            {product.name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align={'center'}
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              backgroundColor: 'lightgrey',
                              color: 'white'
                            }}
                          />
                          <TableCell
                            component="th"
                            scope="row"
                            align={'center'}
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              backgroundColor: 'lightgrey',
                              color: 'white'
                            }}
                          />
                        </TableRow>

                        {product?.variants?.map((variant, index) => (
                          <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" align={'center'}>
                              {variant?.name} - {variant?.size}
                            </TableCell>
                            <TableCell component="th" scope="row" align={'center'}>
                              {variant.price} €
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <OutlinedInput
                                id={'product_' + product?.uuid}
                                sx={{
                                  overflow: 'hidden',
                                  width: '100px'
                                }}
                                type="number"
                                value={variant?.discount ? variant?.discount_price : ''}
                                name="discount_price"
                                onChange={(e) => {
                                  handleSetListProductValue(e.target.value, product, variant);
                                }}
                                label={''}
                                inputProps={{
                                  step: 0.01,
                                  min: 0,
                                  lang: 'en-150'
                                }}
                              />
                              <Typography ml={2} variant={'h3'}>
                                €
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ModalEdit;
