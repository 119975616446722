let colors = [
  {
    background: 'rgb(254, 240, 187)',
    border: 'rgb(254, 205, 30)',
    color: 'rgb(127, 102, 15)'
  },
  {
    background: 'rgb(254, 225, 231)',
    border: 'rgb(254, 157, 175',
    color: 'rgb(127, 78, 87)'
  },
  {
    background: 'rgb(214, 240, 212)',
    border: 'rgb(120, 206, 112)',
    color: 'rgb(72, 124, 67)'
  },
  {
    background: 'rgb(21, 182, 24)',
    border: 'rgb(175, 109, 165)',
    color: 'white'
  },
  {
    background: 'rgb(8, 109, 41)',
    border: 'rgb(189, 135, 200)',
    color: 'white'
  },
  {
    background: 'rgb(107, 190, 20)',
    border: 'rgb(248, 116, 84)',
    color: 'white'
  },
  {
    background: 'rgb(97, 246, 130)',
    border: 'rgb(23, 41, 5)',
    color: 'white'
  },
  {
    background: 'rgb(38, 58, 113)',
    border: 'rgb(246, 40, 225)',
    color: 'white'
  },
  {
    background: 'rgb(252, 115, 225)',
    border: 'rgb(99, 104, 37)',
    color: 'white'
  },
  {
    background: 'rgb(64, 44, 124)',
    border: 'rgb(236, 64, 24)',
    color: 'white'
  },
  {
    background: 'rgb(112, 238, 217)',
    border: 'rgb(141, 120, 141)',
    color: 'white'
  },
  {
    background: 'rgb(32, 85, 183)',
    border: 'rgb(37, 71, 204)',
    color: 'white'
  },
  {
    background: 'rgb(47, 37, 192)',
    border: 'rgb(161, 243, 110)',
    color: 'white'
  },
  {
    background: 'rgb(46, 189, 188)',
    border: 'rgb(204, 219, 204)',
    color: 'white'
  },
  {
    background: 'rgb(15, 44, 120)',
    border: 'rgb(48, 166, 219)',
    color: 'white'
  },
  {
    background: 'rgb(251, 146, 12)',
    border: 'rgb(186, 57, 12)',
    color: 'white'
  },
  {
    background: 'rgb(199, 233, 246)',
    border: 'rgb(196, 220, 171)',
    color: 'white'
  },
  {
    background: 'rgb(15, 127, 27)',
    border: 'rgb(187, 224, 240)',
    color: 'white'
  },
  {
    background: 'rgb(234, 44, 138)',
    border: 'rgb(65, 143, 186)',
    color: 'white'
  },
  {
    background: 'rgb(10, 244, 204)',
    border: 'rgb(5, 100, 199)',
    color: 'white'
  },
  {
    background: 'rgb(243, 210, 183)',
    border: 'rgb(186, 194, 244)',
    color: 'white'
  },
  {
    background: 'rgb(103, 45, 239)',
    border: 'rgb(15, 166, 188)',
    color: 'white'
  },
  {
    background: 'rgb(240, 196, 206)',
    border: 'rgb(222, 11, 110)',
    color: 'white'
  },
  {
    background: 'rgb(197, 154, 195)',
    border: 'rgb(163, 0, 76)',
    color: 'white'
  },
  {
    background: 'rgb(169, 205, 79)',
    border: 'rgb(139, 212, 196)',
    color: 'white'
  },
  {
    background: 'rgb(52, 75, 14)',
    border: 'rgb(79, 43, 30)',
    color: 'white'
  },
  {
    background: 'rgb(241, 174, 4)',
    border: 'rgb(221, 92, 182)',
    color: 'white'
  },
  {
    background: 'rgb(64, 174, 244)',
    border: 'rgb(17, 204, 158)',
    color: 'white'
  },
  {
    background: 'rgb(223, 75, 3)',
    border: 'rgb(180, 157, 249)',
    color: 'white'
  },
  {
    background: 'rgb(243, 27, 123)',
    border: 'rgb(129, 70, 54)',
    color: 'white'
  },
  {
    background: 'rgb(85, 203, 230)',
    border: 'rgb(11, 72, 177)',
    color: 'white'
  },
  {
    background: 'rgb(148, 168, 53)',
    border: 'rgb(176, 87, 45)',
    color: 'white'
  },
  {
    background: 'rgb(53, 93, 53)',
    border: 'rgb(108, 55, 3)',
    color: 'white'
  },
  {
    background: 'rgb(9, 118, 225)',
    border: 'rgb(130, 190, 54)',
    color: 'white'
  },
  {
    background: 'rgb(110, 207, 183)',
    border: 'rgb(95, 36, 158)',
    color: 'white'
  },
  {
    background: 'rgb(135, 34, 118)',
    border: 'rgb(120, 41, 233)',
    color: 'white'
  },
  {
    background: 'rgb(158, 187, 24)',
    border: 'rgb(23, 161, 202)',
    color: 'white'
  },
  {
    background: 'rgb(141, 44, 146)',
    border: 'rgb(221, 74, 249)',
    color: 'white'
  },
  {
    background: 'rgb(22, 144, 21)',
    border: 'rgb(107, 224, 195)',
    color: 'white'
  },
  {
    background: 'rgb(93, 112, 176)',
    border: 'rgb(53, 160, 146)',
    color: 'white'
  },
  {
    background: 'rgb(7, 244, 182)',
    border: 'rgb(90, 159, 112)',
    color: 'white'
  },
  {
    background: 'rgb(9, 0, 95)',
    border: 'rgb(82, 118, 8)',
    color: 'white'
  },
  {
    background: 'rgb(99, 23, 174)',
    border: 'rgb(10, 154, 172)',
    color: 'white'
  },
  {
    background: 'rgb(254, 82, 140)',
    border: 'rgb(53, 42, 177)',
    color: 'white'
  },
  {
    background: 'rgb(244, 60, 221)',
    border: 'rgb(122, 23, 41)',
    color: 'white'
  },
  {
    background: 'rgb(117, 125, 89)',
    border: 'rgb(76, 96, 22)',
    color: 'white'
  },
  {
    background: 'rgb(41, 185, 143)',
    border: 'rgb(246, 59, 34)',
    color: 'white'
  },
  {
    background: 'rgb(187, 59, 21)',
    border: 'rgb(219, 29, 165)',
    color: 'white'
  },
  {
    background: 'rgb(44, 123, 121)',
    border: 'rgb(195, 80, 240)',
    color: 'white'
  },
  {
    background: 'rgb(139, 85, 45)',
    border: 'rgb(232, 18, 121)',
    color: 'white'
  }
];

export default colors;
