// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import TreatmentsView from '../views/pages/treatments/view';
import TreatmentsAssignView from '../views/pages/treatmentsAssign/view';
import TreatmentsManageView from '../views/pages/treatmentsManage/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Treatments = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'treatments',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <TreatmentsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'treatments/assign',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <TreatmentsAssignView />
        </ProtectedRoute>
      )
    },
    {
      path: 'treatments/manage',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <TreatmentsManageView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Treatments;
