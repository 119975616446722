import React, { useCallback, useEffect, useState } from 'react';
import { Box, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { IconEdit, IconPlus, IconSearch } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import ModalEdit from './modalEdit';
import { useGlossary } from '../../../hooks/Glossary/useGlossary';

const GlossaryView = () => {
  const [glossaries, setGlossaries] = useState([]);
  const [selectedGlossary, setSelectedGlossary] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { onListGlossariesAdmin } = useGlossary();

  const listGlossaries = useCallback(async () => {
    onListGlossariesAdmin({
      query,
      page: page,
      limit: 20
    }).then((res) => {
      setGlossaries(res?.rows);
      setTotalPages(res?.count);
    });
  }, [query, page]);

  useEffect(() => {
    listGlossaries();
  }, [query, page]);

  const handleModalClose = useCallback(() => {
    listGlossaries().then(() => {
      setEditMode(false);
    });
  }, []);

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedGlossary(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>
      <ModalEdit modalOpen={editMode} handleModalClose={handleModalClose} glossary={selectedGlossary} />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Glossario
      </Typography>

      <Box my={3}>
        <TextField
          id="outlined-select-currency-native"
          width={'30%'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          SelectProps={{
            native: true
          }}
          InputProps={{
            startAdornment: (
              <IconSearch
                style={{
                  marginRight: 10
                }}
              />
            )
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {glossaries?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'}>
                  {row?.title}
                </TableCell>
                <TableCell align="center">
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Box
                      onClick={() => {
                        setSelectedGlossary(row);
                        setEditMode(true);
                      }}
                    >
                      <IconEdit cursor={'pointer'} />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} color={'primary'} />
      </Box>
    </Box>
  );
};
export default GlossaryView;
