import { IconSearch } from '@tabler/icons-react';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const UIInputSearch = ({ value, setValue, isLoading, label = null }) => {
  const [internalValue, setInternalValue] = useState(value);
  const [internalTimeout, setInternalTimeout] = useState(null);

  useEffect(() => {
    if (internalTimeout) {
      clearTimeout(internalTimeout);
    }
    setInternalTimeout(
      setTimeout(() => {
        setValue(internalValue);
      }, 500)
    );
  }, [internalValue]);

  return (
    <TextField
      id="outlined-select-currency-native"
      value={internalValue}
      disabled={isLoading}
      onChange={(e) => setInternalValue(e.target.value)}
      sx={{
        width: '100%',
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#667085',
            fontFamily: 'Oxygen',
            fontWeight: '700',
            fontSize: '16px'
          }
        }
      }}
      SelectProps={{
        native: true
      }}
      inputProps={{
        style: {
          fontFamily: 'Oxygen',
          fontWeight: '700',
          color: '#667085',
          width: '100%'
        }
      }}
      inputroot={{
        style: {
          width: '100%'
        }
      }}
      placeholder={label ?? 'Cerca'}
      InputProps={{
        sx: {
          width: '100%',
          '& input.Mui-disabled': {
            cursor: 'wait'
          }
        },
        startAdornment: (
          <IconSearch
            style={{
              marginRight: 10,
              color: '#667085'
            }}
          />
        )
      }}
    />
  );
};

export default UIInputSearch;
