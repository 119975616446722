import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardActions, CardContent, CardMedia } from '@mui/material';
import { Box } from '@chakra-ui/react';
import { IconCheck, IconEdit, IconTrash } from '@tabler/icons-react';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { useShop } from '../../../hooks/Shop/useShop';
import { toast } from 'react-toastify';
import ModalAddProduct from './modalAddProduct';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { UIModal, UIModalBody, UIModalButtons, UIModalDivider, UiModalInternal } from '../../utilities/Modal';
import { UiDialog } from '../../utilities/Dialog';
import { UIPageHeader, UIPageTitle } from '../../utilities/Headers';
import { UIFormControlBox } from '../../utilities/Form';
import { UIButton } from '../../utilities/Button';
import UITypography from '../../utilities/Typography';
import UIInputAutocomplete from '../../utilities/InputSelect';
import { UICheckbox, UITextarea } from '../../utilities/Input';

const ModalCompileAppointment = ({ modalOpen, handleModalClose, appointment }) => {
  let [appointmentData, setAppointmentData] = useState(null);
  let [appointmentProducts, setAppointmentProductData] = useState(null);
  let [products, setProducts] = useState([]);
  let [query, setQuery] = useState('');
  let [productMode, setProductMode] = useState(false);
  let [selectedProduct, setSelectedProduct] = useState(null);
  const [deleteProductMode, setDeleteProductMode] = useState(false);
  let [operationLoading, setOperationLoading] = useState(false);
  let [pageLoading, setPageLoading] = useState(true);

  let {
    onGetAppointment,
    onAddCalendarAppointmentSuggestedProduct,
    onRemoveCalendarAppointmentSuggestedProduct,
    onDeleteCalendarAppointmentProductAdmin,
    onUpdateAppointmentCompileStatus
  } = useCalendar();
  let { onListShopProductsByQuery } = useShop();
  const getAppointmentData = () => {
    onGetAppointment({ appointment_id: appointment?.id }).then((res) => {
      setAppointmentData(res);
      setAppointmentProductData(res?.suggested_products);
      setPageLoading(false);
      setOperationLoading(false);
    });
  };

  const listShopProductsByQuery = useCallback(async () => {
    let res = await onListShopProductsByQuery({ query: query });
    setProducts(res);
  }, [appointmentData, query]);

  useEffect(() => {
    if (appointment && modalOpen) {
      getAppointmentData();
    }
  }, [appointment, modalOpen]);

  useEffect(() => {
    listShopProductsByQuery();
  }, [query]);

  const handleAddProduct = async (product_id) => {
    if (!product_id) return;

    setOperationLoading(true);
    await onAddCalendarAppointmentSuggestedProduct({
      appointment_id: appointment?.id,
      product_uuid: product_id
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: res?.responseStatus ? '#00e676' : '#FECE2E',
          color: '#ffffff'
        }
      });
      getAppointmentData();
    });
  };

  const handleDeleteProduct = async () => {
    setOperationLoading(true);
    await onDeleteCalendarAppointmentProductAdmin({
      appointment_product_id: selectedProduct?.id
    }).then(() => {
      setDeleteProductMode(false);
      getAppointmentData();
    });
  };

  const handleDeleteShopProduct = async (product_uuid) => {
    setOperationLoading(true);
    await onRemoveCalendarAppointmentSuggestedProduct({
      appointment_id: appointment?.id,
      product_uuid: product_uuid
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: res?.responseStatus ? '#00e676' : '#FECE2E',
          color: '#ffffff'
        }
      });
      getAppointmentData();
    });
  };

  const handleAddModalClose = () => {
    setSelectedProduct(null);
    setProductMode(false);
    getAppointmentData();
  };

  const handleInternalModalClose = () => {
    setSelectedProduct(null);
    setAppointmentData(null);
    setPageLoading(true);
    setOperationLoading(false);
    handleModalClose();
  };

  const handleSubmit = (values) => {
    let { note_public, compiled } = values;

    setOperationLoading(true);
    onUpdateAppointmentCompileStatus({
      appointment_id: appointmentData.id,
      appointment_data: {
        note_public,
        compiled
      }
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
      handleInternalModalClose();
    });
  };

  return (
    <UIModal open={modalOpen} onClose={handleInternalModalClose} allowFullScreen={true} className={'modal'}>
      <UiDialog
        open={deleteProductMode}
        onClose={() => setDeleteProductMode(false)}
        title={'Rimuovere prodotto?'}
        onConfirm={handleDeleteProduct}
        labelConfirm={'Elimina'}
      />

      <ModalAddProduct
        modalOpen={productMode}
        handleModalClose={handleAddModalClose}
        appointment={appointmentData}
        appointment_product={selectedProduct}
      />

      <UiModalInternal onClose={handleInternalModalClose} isLoading={pageLoading} isOperationLoading={operationLoading}>
        <Formik
          initialValues={{
            note_public: appointmentData?.note_public ?? '',
            compiled: appointmentData?.compiled ?? false
          }}
          validationSchema={Yup.object().shape({
            note_public: Yup.string().max(255),
            compiled: Yup.boolean().nullable()
          })}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, handleSubmit, touched, values, setFieldValue, submitForm }) => (
            <form noValidate onSubmit={handleSubmit}>
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <UIPageHeader
                  title={'Compila appuntamento'}
                  titleSize={'18px'}
                  sx={{
                    textAlign: 'center'
                  }}
                />

                <UIPageTitle
                  title={
                    appointmentData?.patient
                      ? `${appointmentData?.patient?.name} ${appointmentData?.patient?.surname}`
                      : appointmentData?.patient_alias
                  }
                  titleSize={'15px'}
                  sx={{
                    m: '20px 10px 5px 10px',
                    textAlign: 'center'
                  }}
                />

                <UIFormControlBox
                  label={'Usati in studio'}
                  sx={{
                    mb: '20px'
                  }}
                >
                  <UIButton
                    variant={'copy'}
                    label={'Aggiungi prodotto'}
                    onClick={() => {
                      setSelectedProduct(null);
                      setProductMode(true);
                    }}
                  />
                  {appointmentData?.products?.map((product, index) => (
                    <Card key={index} sx={{ m: '10px 0 20px 0px', position: 'relative', pb: '30px', overflow: 'visible' }}>
                      <CardContent
                        sx={{
                          py: 2
                        }}
                      >
                        <UITypography
                          title={product?.product?.name}
                          size={'12px'}
                          sxCustom={{
                            color: '#101828'
                          }}
                        />

                        <UITypography
                          variant={'Oxygen300'}
                          title={`Quantità: ${product?.quantity}`}
                          size={'12px'}
                          sxCustom={{
                            color: '#101828'
                          }}
                        />

                        <UITypography
                          variant={'Oxygen300'}
                          title={` Tecnica: ${product?.technique?.name}`}
                          size={'12px'}
                          sxCustom={{
                            color: '#101828'
                          }}
                        />

                        <UITypography
                          variant={'Oxygen300'}
                          title={` Target: ${product?.target?.name}`}
                          size={'14px'}
                          sxCustom={{
                            color: '#101828'
                          }}
                        />

                        <UITypography
                          variant={'Oxygen300'}
                          title={` Dispositivo: ${product?.device?.name}`}
                          size={'12px'}
                          sxCustom={{
                            color: '#101828'
                          }}
                        />
                      </CardContent>
                      <CardActions
                        sx={{
                          py: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          bottom: '-30px',
                          width: '100%'
                        }}
                      >
                        <UIButton
                          type={'submit'}
                          onClick={() => {
                            setSelectedProduct(product);
                            setProductMode(true);
                          }}
                          icon={<IconEdit stroke={1.64} size="25px" />}
                          sxCustom={{
                            bgcolor: '#FDF2B5',
                            color: '#344054'
                          }}
                        />

                        <UIButton
                          type={'submit'}
                          onClick={() => {
                            setSelectedProduct(product);
                            setDeleteProductMode(true);
                          }}
                          icon={<IconTrash stroke={1.64} size="25px" />}
                          sxCustom={{
                            bgcolor: '#FF646C',
                            color: '#344054'
                          }}
                        />
                      </CardActions>
                    </Card>
                  ))}
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox
                  label={'Prodotti consigliati'}
                  sx={{
                    mb: '20px'
                  }}
                >
                  <UIInputAutocomplete
                    options={products?.map((product) => {
                      return {
                        id: product?.uuid,
                        label: `${product?.name}`
                      };
                    })}
                    onKeyUp={(e) => {
                      setQuery(e.target.value);
                    }}
                    setValue={(value) => {
                      handleAddProduct(value?.id);
                    }}
                  />
                  <Box
                    sx={{
                      mt: '10px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '5px',
                      gridAutoRows: '1fr'
                    }}
                  >
                    {appointmentProducts?.map((product, index) => (
                      <Card
                        key={index}
                        sx={{
                          width: '48%',
                          alignSelf: 'stretch',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          pb: '30px',
                          mb: '25px',
                          overflow: 'visible'
                        }}
                      >
                        <CardContent
                          sx={{
                            p: 1
                          }}
                        >
                          <UITypography title={product?.brand?.[0]?.name} size={'12px'} sxCustom={{ color: '#101828' }} />
                          <UITypography variant={'Oxygen300'} title={product?.name} size={'12px'} sxCustom={{ color: '#101828' }} />
                        </CardContent>

                        <CardMedia
                          sx={{ height: 100, width: '100%', backgroundSize: 'contain' }}
                          image={product?.media?.[0]?.media_link}
                          title="green iguana"
                        />

                        <CardActions
                          sx={{
                            py: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            position: 'absolute',
                            bottom: '-30px',
                            width: '100%'
                          }}
                        >
                          <UIButton
                            type={'submit'}
                            onClick={() => handleDeleteShopProduct(product?.uuid)}
                            icon={<IconTrash stroke={1.64} size="25px" />}
                            sxCustom={{
                              bgcolor: '#FF646C',
                              color: '#344054'
                            }}
                          />
                        </CardActions>
                      </Card>
                    ))}
                  </Box>
                </UIFormControlBox>

                <UIModalDivider />

                <UIFormControlBox
                  label={'Note pubbliche'}
                  subtitle={'Note visibili al paziente'}
                  sx={{
                    mb: '20px'
                  }}
                  error={Boolean(touched.note_public && errors.note_public)}
                >
                  <UITextarea
                    name={'note_public'}
                    value={values?.note_public}
                    onChange={(event) => setFieldValue('note_public', event.target.value)}
                    expandable={false}
                  />
                </UIFormControlBox>
                <UIFormControlBox error={Boolean(touched.compiled && errors.compiled)}>
                  <UICheckbox
                    name={'compiled'}
                    label={'Spunta se la compilazione è definitiva'}
                    checked={values?.compiled}
                    onChange={(event) => setFieldValue('compiled', event.target.checked)}
                  />
                </UIFormControlBox>
              </UIModalBody>
              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()} icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};
export default ModalCompileAppointment;
