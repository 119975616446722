// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import ArticlesView from '../views/pages/articles/view';
import GlossaryView from '../views/pages/glossary/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Products = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'articles',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ArticlesView />
        </ProtectedRoute>
      )
    },
    {
      path: 'glossary',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <GlossaryView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Products;
