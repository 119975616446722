import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IconAlertCircle, IconCopy, IconPhone, IconPoint, IconX } from '@tabler/icons-react';
import { UIButton } from './Button';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import moment from 'moment';

export const UiDialog = ({
  type = 'default',
  open,
  onClose,
  closeButton = true,
  onConfirm = () => {},
  title = '',
  subtitle = '',
  labelConfirm = 'Conferma',
  variant = 'alert',
  secondaryButton = false,
  onSecondary = () => {},
  labelSecondary = 'Elimina',
  values = [],
  value = null,
  maxDate = null,
  disablePast = false,
  disableFuture = false
}) => {
  let sx = {
    pb: '20px'
  };

  switch (type) {
    case 'phone':
      sx = {
        ...sx,
        m: '0 auto',
        '& .MuiPaper-root ': {
          width: '240px',
          pb: '15px'
        }
      };
      break;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'xs'} sx={sx} fullWidth={true}>
      {type === 'default' && (
        <UIDialogDefault
          variant={variant}
          title={title}
          subtitle={subtitle}
          labelConfirm={labelConfirm}
          onConfirm={onConfirm}
          onClose={onClose}
          closeButton={closeButton}
        />
      )}
      {type === 'phone' && (
        <UIDialogPhone
          variant={variant}
          title={title}
          subtitle={subtitle}
          labelConfirm={labelConfirm}
          onConfirm={onConfirm}
          onClose={onClose}
          onSecondary={onSecondary}
        />
      )}
      {type === 'date' && (
        <UIDialogDate
          variant={variant}
          title={title}
          labelConfirm={labelConfirm}
          onConfirm={onConfirm}
          secondaryButton={secondaryButton}
          onSecondary={onSecondary}
          labelSecondary={labelSecondary}
          value={value}
          maxDate={maxDate}
          disablePast={disablePast}
          disableFuture={disableFuture}
        />
      )}
      {type === 'radio' && (
        <UIDialogRadio
          variant={variant}
          title={title}
          labelConfirm={labelConfirm}
          onConfirm={onConfirm}
          secondaryButton={secondaryButton}
          onSecondary={onSecondary}
          labelSecondary={labelSecondary}
          values={values}
        />
      )}
      {type === 'checkbox' && (
        <UIDialogCheckbox
          variant={variant}
          title={title}
          labelConfirm={labelConfirm}
          onConfirm={onConfirm}
          secondaryButton={secondaryButton}
          onSecondary={onSecondary}
          labelSecondary={labelSecondary}
          values={values}
        />
      )}
      {type === 'holidays_choose' && (
        <UIDialogHolidaysChoose
          variant={variant}
          title={title}
          subtitle={subtitle}
          labelConfirm={labelConfirm}
          onConfirm={onConfirm}
          onSecondary={onSecondary}
          labelSecondary={labelSecondary}
          onClose={onClose}
          value={value}
          values={values}
        />
      )}
      {type === 'holidays_result' && (
        <UIDialogHolidaysResults title={title} subtitle={subtitle} labelConfirm={labelConfirm} onConfirm={onConfirm} values={values} />
      )}
    </Dialog>
  );
};

const UIDialogDefault = ({ variant, title, subtitle, labelConfirm, onConfirm, onClose, closeButton = true }) => {
  let sx = {
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    title: {
      fontFamily: 'Oxygen',
      fontWeight: '700',
      fontSize: '13px',
      letterSpacing: '0.5px',
      textAlign: 'center',
      pb: subtitle ? '0px' : '10px'
    },
    subtitle: {
      fontFamily: 'Oxygen',
      fontWeight: '300',
      fontSize: '11px',
      letterSpacing: '0.5px',
      textAlign: 'center'
    }
  };

  switch (variant) {
    case 'alert':
      sx = {
        ...sx
      };
      break;
    case 'confirm':
      sx = {
        ...sx
      };
      break;
  }

  return (
    <>
      <DialogTitle
        sx={{
          position: 'relative',
          ...sx.icon
        }}
      >
        {closeButton && (
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '20px'
            }}
          >
            <UIButton type={'icon'} icon={<IconX stroke={'1.64'} size={'25px'} />} onClick={onClose} />
          </Box>
        )}
        {variant === 'alert' && <IconAlertCircle width={'60px'} height={'60px'} stroke={2} color={'#FE922E'} />}
        {variant === 'confirm' && <IconAlertCircle width={'60px'} height={'60px'} stroke={2} color={'#FE922E'} />}
        {variant === 'cancel' && <IconAlertCircle width={'60px'} height={'60px'} stroke={2} color={'#FE922E'} />}
        {variant === 'phone' && <IconPhone width={'30px'} height={'30px'} stroke={2} color={'#667085'} />}
      </DialogTitle>
      <DialogTitle sx={sx.title}>{title}</DialogTitle>
      {subtitle && <DialogTitle sx={sx.subtitle}>{subtitle}</DialogTitle>}
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <UIButton variant={variant} label={labelConfirm} onClick={onConfirm} />
      </DialogActions>
    </>
  );
};

const UIDialogPhone = ({ title, subtitle, labelConfirm, onConfirm, onSecondary }) => {
  let sx = {
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    title: {
      fontFamily: 'Oxygen',
      fontWeight: '700',
      fontSize: '13px',
      letterSpacing: '0.5px',
      textAlign: 'center',
      py: '0px'
    },
    subtitle: {
      fontFamily: 'Oxygen',
      letterSpacing: '0.5px',
      textAlign: 'center',
      color: '#000',
      fontSize: '20px',
      fontWeight: '700',
      pt: '0px'
    }
  };

  return (
    <>
      <DialogTitle
        sx={{
          ...sx.icon
        }}
      >
        <IconPhone width={'30px'} height={'30px'} stroke={2} color={'#667085'} />
      </DialogTitle>
      <DialogTitle sx={sx.title}>{title}</DialogTitle>
      {subtitle && <DialogTitle sx={sx.subtitle}>{subtitle}</DialogTitle>}
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <UIButton type={'button'} variant={'copy'} label={<IconCopy stroke={'1.64'} size={'24px'} />} onClick={onSecondary} />
        <UIButton variant={'phone'} label={labelConfirm} onClick={onConfirm} />
      </DialogActions>
    </>
  );
};

const UIDialogDate = ({
  variant,
  title,
  labelConfirm,
  onConfirm,
  secondaryButton,
  onSecondary,
  labelSecondary,
  value,
  maxDate,
  disablePast,
  disableFuture
}) => {
  const [date, setDate] = useState(value);

  return (
    <>
      <DialogTitle
        sx={{
          fontFamily: 'Oxygen',
          fontWeight: '700',
          fontSize: '13px',
          letterSpacing: '0.5px',
          textAlign: 'center'
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DateCalendar
          defaultValue={value ? moment(value) : moment()}
          label="Data di inizio intervallo"
          onChange={(res) => {
            setDate(moment(res).utc(true).format('YYYY-MM-DD'));
          }}
          disableHighlightToday
          disablePast={disablePast}
          disableFuture={disableFuture}
          maxDate={maxDate}
          sx={{
            '& .MuiPickersDay-root': {
              '&.Mui-selected': {
                backgroundColor: '#000 !important',
                color: '#fff',
                borderRadius: 0
              }
            }
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {secondaryButton && <UIButton variant={'empty'} label={labelSecondary} onClick={onSecondary} />}
        <UIButton
          variant={variant}
          label={labelConfirm}
          onClick={() => {
            onConfirm(date);
            setDate(null);
          }}
        />
      </DialogActions>
    </>
  );
};

const UIDialogRadio = ({ variant, title, labelConfirm, onConfirm, secondaryButton, onSecondary, labelSecondary, values }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(values?.find((item) => item?.checked)?.value);
  }, []);

  const handleRadioChange = (value) => {
    setSelected(value);
  };

  return (
    <>
      <DialogTitle
        sx={{
          fontFamily: 'Oxygen',
          fontWeight: '700',
          fontSize: '13px',
          letterSpacing: '0.5px',
          textAlign: 'center'
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          maxHeight: '50vh',
          overflow: 'auto'
        }}
      >
        {values?.map((value, index) => (
          <Box key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected === value?.value}
                  onChange={() => handleRadioChange(value?.value)}
                  name="checked"
                  color="primary"
                  style={{
                    color: '#FECE2E'
                  }}
                />
              }
              label={<Typography variant="subtitle1">{value?.label}</Typography>}
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {secondaryButton && <UIButton variant={'empty'} label={labelSecondary} onClick={onSecondary} />}
        <UIButton
          variant={variant}
          label={labelConfirm}
          onClick={() => {
            onConfirm(selected);
            setSelected(null);
          }}
        />
      </DialogActions>
    </>
  );
};

const UIDialogHolidaysChoose = ({ title, subtitle, labelConfirm, onConfirm, onClose, onSecondary, labelSecondary, value, values }) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <DialogContentText>{subtitle}</DialogContentText>
        <Select
          sx={{
            mt: 2
          }}
          name={'year'}
          fullWidth={true}
          value={value}
          onChange={(e) => {
            onSecondary(e.target.value);
          }}
          autoWidth
        >
          {values?.map((value, key) => {
            return (
              <MenuItem key={key} value={value?.label}>
                {value?.value}
              </MenuItem>
            );
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} color={'error'} onClick={onClose}>
          {labelSecondary}
        </Button>
        <Button variant={'contained'} color={'success'} onClick={onConfirm}>
          {labelConfirm}
        </Button>
      </DialogActions>
    </>
  );
};

const UIDialogHolidaysResults = ({ title, subtitle, onConfirm, labelConfirm, values }) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <DialogContentText>{subtitle}</DialogContentText>
        <List>
          {values?.map((holiday, index) => (
            <ListItem
              key={index}
              sx={{
                p: 0
              }}
            >
              <ListItemAvatar>
                <IconPoint />
              </ListItemAvatar>
              <ListItemText icon primary={holiday?.holiday_name} secondary={moment(holiday?.date)?.format('DD/MM/YYYY')} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} color={'success'} onClick={onConfirm}>
          {labelConfirm}
        </Button>
      </DialogActions>
    </>
  );
};

const UIDialogCheckbox = ({ variant, title, labelConfirm, onConfirm, secondaryButton, onSecondary, labelSecondary, values }) => {
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    setChecked(values?.filter((item) => item?.checked)?.map((item) => item?.value));
  }, []);

  const handleCheckboxChange = (value) => {
    if (checked.includes(value)) {
      setChecked(checked.filter((item) => item !== value));
    } else {
      setChecked([...checked, value]);
    }
  };

  return (
    <>
      <DialogTitle
        sx={{
          fontFamily: 'Oxygen',
          fontWeight: '700',
          fontSize: '13px',
          letterSpacing: '0.5px',
          textAlign: 'center'
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          maxHeight: '50vh',
          overflow: 'auto'
        }}
      >
        {values?.map((value, index) => (
          <Box key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.includes(value?.value)}
                  onChange={() => handleCheckboxChange(value?.value)}
                  name="checked"
                  color="primary"
                  style={{
                    color: '#FECE2E'
                  }}
                />
              }
              label={<Typography variant="subtitle1">{value?.label}</Typography>}
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {secondaryButton && <UIButton variant={'empty'} label={labelSecondary} onClick={onSecondary} />}
        <UIButton
          variant={variant}
          label={labelConfirm}
          onClick={() => {
            onConfirm(checked);
            setChecked([]);
          }}
        />
      </DialogActions>
    </>
  );
};
