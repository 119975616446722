import React from 'react';
import { Box, FormControl, Typography } from '@mui/material';
import { UIFormCellHeader } from './Headers';
import { UIButton } from './Button';
import { IconCheck } from '@tabler/icons-react';

export const UIFormControlBox = ({
  children,
  subtitle = null,
  label = null,
  error = false,
  error_message = null,
  sx = {},
  sxInput = {}
}) => {
  return (
    <Box
      sx={{
        py: '2px',
        px: '20px',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...sx
      }}
    >
      {label && <UIFormCellHeader title={label} />}
      <FormControl
        fullWidth
        error={error}
        sx={{
          ...sxInput
        }}
      >
        {children}
      </FormControl>
      {subtitle && (
        <Typography fontFamily={'Oxygen'} fontWeight={300} fontSize={'11px'} color={'#616877'} sx={{ m: '5px 0 0 10px' }} width={'90%'}>
          {subtitle}
        </Typography>
      )}
      {error && (
        <Typography fontFamily={'Oxygen'} fontWeight={300} fontSize={'11px'} color={'#e74c3c'} sx={{ m: '5px 0 0 10px' }} width={'90%'}>
          {error_message ?? 'Obbligatorio'}
        </Typography>
      )}
    </Box>
  );
};

export const UIFormSubmit = ({ onClick, sx = {} }) => {
  return (
    <Box
      className={'form-submit'}
      sx={{
        py: '5px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '10px',
        left: 0,
        ...sx
      }}
    >
      <UIButton
        type={'submit'}
        onClick={onClick}
        icon={<IconCheck />}
        customSx={{
          backgroundColor: ''
        }}
      />
    </Box>
  );
};
