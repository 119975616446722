import { Box, Image } from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography
} from '@mui/material';
import { IconBulb, IconCheck, IconPhoto, IconSignature } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { v4 as uuidv4 } from 'uuid';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useGlossary } from '../../../hooks/Glossary/useGlossary';

const ModalEdit = ({ modalOpen, handleModalClose, glossary }) => {
  let [deleteMode, setDeleteMode] = useState(false);
  let [data, setData] = useState(null);
  const [uuid, setUuid] = React.useState(uuidv4());

  useEffect(() => {
    setUuid(uuidv4());
  }, [glossary]);

  const { onGetGlossaryAdmin, onUpdateGlossaryAdmin, onCreateGlossaryAdmin, onDeleteGlossaryAdmin } = useGlossary();

  const getGlossary = async () => {
    onGetGlossaryAdmin({
      glossary_uuid: glossary.uuid
    }).then((res) => {
      setDeleteMode(false);
      setData(res);
    });
  };

  useEffect(() => {
    if (glossary?.uuid) {
      getGlossary();
    } else {
      setData(null);
    }
  }, [glossary]);

  const handleDeleteGlossary = async () => {
    await onDeleteGlossaryAdmin({
      glossary_uuid: glossary.uuid
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { title, content, cover, active } = values;

    if (data?.uuid) {
      onUpdateGlossaryAdmin({
        glossary_uuid: data?.uuid,
        glossary_data: {
          title,
          content,
          cover,
          active
        }
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        handleModalClose();
      });
    } else {
      onCreateGlossaryAdmin({
        glossary_data: {
          uuid: uuid,
          title,
          content,
          cover,
          active
        }
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        handleModalClose();
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-description"
    >
      <Box>
        <>
          <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
            <DialogTitle>Elimina glossario</DialogTitle>
            <DialogContent>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <DialogContentText>Vuoi eliminare il glossario "{glossary?.title}"?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
                Annulla
              </Button>
              <Button variant={'contained'} color={'success'} onClick={handleDeleteGlossary}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
          <Formik
            initialValues={{
              title: data?.title ?? '',
              content: data?.content ?? '',
              cover: data?.cover ?? '',
              active: data?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Il titolo è obbligatorio'),
              content: Yup.string().required('Il contenuto è obbligatorio'),
              cover: Yup.string().nullable(),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati Glossario
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.title && errors.title)}>
                            <InputLabel htmlFor="input-title">Titolo</InputLabel>
                            <OutlinedInput
                              inputProps={{
                                maxLength: 70
                              }}
                              id="input-title"
                              type="text"
                              value={values.title}
                              name="title"
                              onBlur={handleBlur}
                              onChange={(event) => setFieldValue('title', event.target.value)}
                              label="Titolo"
                            />
                            {touched.title && errors.title && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.title}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.content && errors.content)} w={'100%'}>
                            <Typography id="modal-modal-content" variant="body1" fontWeight={'bold'}>
                              Contenuto
                            </Typography>
                            <MDEditor
                              height={200}
                              value={values?.content}
                              onChange={(md) => {
                                setFieldValue('content', md);
                              }}
                              commands={[
                                commands.bold,
                                commands.italic,
                                commands.strikethrough,
                                commands.unorderedListCommand,
                                commands.orderedListCommand,
                                commands.checkedListCommand,
                                commands.image
                              ]}
                            />

                            {touched.content && errors.content && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.content}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhoto stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.cover && errors.cover)} w={'100%'}>
                            <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'} mb={1} ml={1}>
                              Cover
                            </Typography>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                              <Image src={values.cover} w={'100px'} h={'100px'} objectFit={'contain'} />
                              <DropzoneFormikInput
                                variant={'glossary_cover'}
                                setFieldValue={setFieldValue}
                                field={'cover'}
                                folder={'glossary'}
                                label={'Carica'}
                                extra_data={{
                                  glossary_uuid: data?.uuid ?? uuid
                                }}
                              />
                            </Box>
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.active}
                                  onChange={(event) => setFieldValue('active', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Attivo</Typography>}
                            />
                            {touched.active && errors.active && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.active}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      {glossary?.uuid && (
                        <Box d={'flex'} justifyContent={'center'}>
                          <Box
                            fontSize={15}
                            textAlign={'center'}
                            color={'#f44336'}
                            fontFamily={'Montserrat, sans-serif'}
                            mt={10}
                            fontWeight={700}
                            textTransform={'uppercase'}
                            bg={'rgba(200,200,200,0.2)'}
                            p={'5px '}
                            cursor={'pointer'}
                            onClick={() => setDeleteMode(true)}
                          >
                            Elimina glossario
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};

export default ModalEdit;
