import { useAuthentication } from '../../../utils/useAuthentication';
import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { IconEdit } from '@tabler/icons-react';
import { useProtocols } from '../../../hooks/Protocols/useProtocols';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import { UIButton } from '../../utilities/Button';
import moment from 'moment/moment';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import ModalSurveyCompile from '../modals/modalSurveyCompile';

const ProtocolsUpdateView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [filtersData] = useState({
    status: 'sent',
    to_update: true
  });

  const { onListSurveysPaginated } = useProtocols();

  const handleModalClose = useCallback(() => {
    setEditMode(false);
    setForceUpdate(true);
  }, []);

  const prepareValues = (surveys) => {
    let last_day = null;
    let surveysArray = [];

    surveys?.forEach((survey) => {
      let createdAtDay = survey?.updatedAt ? moment(Number(survey?.updatedAt)) : moment(Number(survey?.createdAt));

      if (last_day === null || last_day.format('DD/MM/YYYY') !== createdAtDay.format('DD/MM/YYYY')) {
        surveysArray?.push({
          survey: null,
          patientEl: (
            <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {createdAtDay.format('DD MMMM YYYY')}
            </Box>
          ),
          actionsEl: null,
          date: createdAtDay.format('YYYY-MM-DD')
        });
        last_day = createdAtDay;
      }

      surveysArray?.push({
        survey,
        patientEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {survey?.patient?.name} {survey?.patient?.surname}
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedSurvey(survey);
                setEditMode(true);
              }}
              icon={
                <IconEdit
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
          </Box>
        )
      });
    });

    return surveysArray;
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalSurveyCompile
        user={user}
        modalOpen={editMode}
        handleModalClose={handleModalClose}
        survey={selectedSurvey}
        setEditMode={setEditMode}
      />

      {/*** HEADERS ***/}
      <>
        <UIPageHeader
          title={'Protocolli da aggiornare'}
          subtitle={'Qui puoi visualizzare la lista dei protocolli in attesa di aggiornamento.'}
        />
        <SectionHeader title={'Paziente'} />
      </>
      <UITable
        columns={[
          {
            name: <UITableHeadCell title={'Paziente'} />,
            value: 'patientEl',
            size: 120
          },
          {
            name: '',
            value: 'actionsEl',
            size: 80
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        filtersData={filtersData}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
        infiniteScrollFunction={onListSurveysPaginated}
        muiTableBodyRowProps={(data) => {
          return {
            sx: {
              bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
              p: 0,
              '& td': data?.row?.original?.date
                ? {
                    p: '5px 20px'
                  }
                : {}
            }
          };
        }}
      />
    </Box>
  );
};
export default ProtocolsUpdateView;
