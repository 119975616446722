import gql from 'graphql-tag';

export const LIST_PRODUCTS_TECHNIQUES_ADMIN = gql`
  query listProductsTechniquesAdmin {
    adminRoute {
      listProductsTechniquesAdmin {
        id
        name
        icon
      }
    }
  }
`;

export const CREATE_PRODUCTS_TECHNIQUE_ADMIN = gql`
  query createProductTechniqueAdmin($product_technique_data: ProductTechniqueInput!) {
    adminRoute {
      createProductTechniqueAdmin(product_technique_data: $product_technique_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_PRODUCTS_TECHNIQUE_ADMIN = gql`
  query updateProductTechniqueAdmin($product_technique_data: ProductTechniqueInput!, $product_technique_id: ID!) {
    adminRoute {
      updateProductTechniqueAdmin(product_technique_id: $product_technique_id, product_technique_data: $product_technique_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_PRODUCTS_TECHNIQUE_ADMIN = gql`
  query deleteProductTechniqueAdmin($product_technique_id: ID!) {
    adminRoute {
      deleteProductTechniqueAdmin(product_technique_id: $product_technique_id) {
        response
        responseStatus
      }
    }
  }
`;
