// project imports
import { ProtectedRoute } from '../utils/ProtectedRoute';
import MainLayout from '../layout/MainLayout';
import NotificationGlobalView from '../views/pages/notificationGlobal/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Notifications = {
  path: '/',
  element: (
    <ProtectedRoute permission={'DOCTOR'}>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'notification/global',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <NotificationGlobalView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Notifications;
