// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import ProtocolsPendingView from '../views/pages/protocolsPending/view';
import ProtocolsDefaultView from '../views/pages/protocolsDefault/view';
import ProtocolsCompletedView from '../views/pages/protocolsCompleted/view';
import ProtocolsUpdateView from '../views/pages/protocolsUpdating/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Protocols = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'protocols/list',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <ProtocolsPendingView />
        </ProtectedRoute>
      )
    },
    {
      path: 'protocols/update',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <ProtocolsUpdateView />
        </ProtectedRoute>
      )
    },
    {
      path: 'protocols/archive',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <ProtocolsCompletedView />
        </ProtectedRoute>
      )
    },
    {
      path: 'protocols/default',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ProtocolsDefaultView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Protocols;
