import { useApolloClient } from '@apollo/client';
import { manageError } from '../utils/errors';

export const useMutation = (mutation) => {
  const client = useApolloClient();

  const getDataGQL = async (params) => {
    return client
      .mutate({
        mutation,
        variables: params?.variables
      })
      .catch((error) => {
        manageError(error, mutation);
        return error;
      })
      .then((res) => {
        return res?.data;
      });
  };

  return { getDataGQL };
};
