import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Box as BoxChakra } from '@chakra-ui/react';
import './custom-chats.css';
import { useChats } from '../../../hooks/Chats/useChats';
import { Image } from '@chakra-ui/react';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import SkinboostImage from '../../../assets/images/skinboost_message.svg';
import { useAuthentication } from '../../../utils/useAuthentication';
import { IconBan, IconPlus } from '@tabler/icons-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import UISwitch from '../../utilities/Switch';
import ModalAddChat from './modalAddChat';
import UIInputSearch from '../../utilities/InputSearch';

const ChatListView = () => {
  let user_data = useAuthentication();
  let user = user_data?.user?.account_data;
  const [chats, setChats] = useState([]);
  const [adminChat, setAdminChat] = useState(null);
  const [addMode, setAddMode] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState('');
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const navigate = useNavigate();

  const { onListChatsDoctor, onGetChatWithAdminDoctor } = useChats();

  const calcTime = (date) => {
    if (moment().diff(moment(date), 'minutes') < 60) {
      return moment().diff(moment(date), 'minutes') + ' min.';
    } else if (moment().isSame(date, 'day')) {
      return moment(date).format('HH:mm');
    } else if (moment().isSame(date, 'week')) {
      return moment(date).format('dddd');
    } else {
      return moment(date).format('DD/MM/YYYY');
    }
  };

  const listChats = useCallback(
    (manual_page = null) => {
      onListChatsDoctor({
        limit: 10,
        page: manual_page ?? page,
        query,
        deleted_too: includeDeleted
      }).then((res) => {
        if (manual_page) {
          setChats([...(res?.rows ?? [])]);
          setPage(1);
        } else {
          setChats([...chats, ...(res?.rows ?? [])]);
        }
        if (res?.rows?.length < 10) {
          setHasMore(false);
        }
      });
    },
    [page, query, includeDeleted]
  );

  useEffect(() => {
    listChats();
  }, [page]);

  useEffect(() => {
    setChats([]);
    setHasMore(true);
    listChats(1);
  }, [query, includeDeleted]);

  useEffect(() => {
    if (!user?.admin) {
      onGetChatWithAdminDoctor(
        {
          limit: 1,
          page: 1
        },
        []
      ).then((res) => {
        setAdminChat(res);
      });
    }
  }, []);

  const fetchMoreData = () => {
    setPage(page + 1);
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalAddChat open={addMode} onClose={() => setAddMode(false)} />
      <BoxChakra
        className={'plus-button'}
        position={'fixed'}
        display={'flex'}
        zIndex={999}
        right={10}
        bottom={10}
        bg={'#FDF2B5'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        color={'#000'}
        boxShadow={'0px 4px 4px 0 rgba(0, 0, 0, 0.15)'}
        onClick={() => {
          setAddMode(true);
        }}
      >
        <IconPlus stroke={1} size="2rem" />
      </BoxChakra>

      <Box bgcolor={'white'} py={'10px'}>
        <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'20px'} textAlign={'center'} bg>
          Chat
        </Typography>
        {!user?.admin && (
          <Box
            mx={'auto'}
            my={'5px'}
            width={'95%'}
            bgcolor={'#FAC000'}
            borderRadius={'8px'}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            p={'10px'}
            boxSizing={'border-box'}
            color={'#101828'}
            underline={'none'}
            onClick={() => navigate(`/chat/${adminChat?.uuid}`)}
          >
            <Image src={SkinboostImage} borderRadius={'100%'} boxSize={'41px'} ml={'5px'} />
            <Typography ml={'15px'} fontFamily={'Oxygen'} fontWeight={'700'} width={'60%'} fontSize={'16px'} textAlign={'left'}>
              Contatta Skinboost
            </Typography>
            <Box width={'20%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              {adminChat?.patient_unread_messages > 0 && (
                <Box
                  bgcolor={'#FF0000'}
                  borderRadius={'100%'}
                  width={'18px'}
                  height={'18px'}
                  textAlign={'center'}
                  color={'#FFF'}
                  border={'2px solid #FFF'}
                  fontFamily={'Oxygen'}
                  fontWeight={'700'}
                  fontSize={'9px'}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box my={2} mx={'auto'} width={'95%'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <Box width={'calc(100% - 150px)'}>
          <UIInputSearch value={query} setValue={setQuery} />
        </Box>
        <Box>
          <UISwitch
            onChange={(checked) => setIncludeDeleted(checked)}
            className={'switch-delete'}
            label={'Includi eliminate'}
            checked={includeDeleted}
          />
        </Box>
      </Box>
      <Box id={'chats_container'} height={'65vh'} overflow={'auto'} width={'95%'} m={'0 auto'}>
        <InfiniteScroll
          dataLength={chats.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <CircularProgress
                sx={{
                  color: '#FFC433'
                }}
              />
            </Box>
          }
          scrollableTarget="chats_container"
          endMessage={
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'13px'} textAlign={'center'} mt={'5px'}>
              Fine lista chat
            </Typography>
          }
        >
          {chats?.map((row, index) => (
            <Box
              key={index}
              mx={'auto'}
              my={'5px'}
              bgcolor={'white'}
              borderRadius={'8px'}
              minHeight={'90px'}
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              px={'10px'}
              boxSizing={'border-box'}
              color={'#101828'}
              underline={'none'}
              onClick={() => navigate(`/chat/${row?.uuid}`)}
              sx={{
                cursor: 'pointer'
              }}
            >
              <Image src={row?.patient?.profile_pic} borderRadius={'100%'} boxSize={'67px'} />
              <Box ml={'10px'} overflow={'hidden'} width={'60%'} whiteSpace={'nowrap'}>
                <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'16px'} textAlign={'left'}>
                  {row?.patient?.name} {row?.patient?.surname}
                </Typography>
                <Typography
                  fontFamily={'Oxygen'}
                  fontWeight={'400'}
                  fontSize={'13px'}
                  textAlign={'left'}
                  mt={'10px'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                >
                  {row?.messages?.[0]?.message}
                </Typography>
              </Box>
              <Box width={'15%'} display={'flex'} flexDirection={'column'} alignItems={'flex-end'} justifyContent={'space-between'}>
                <Typography
                  fontFamily={'Oxygen'}
                  fontWeight={'700'}
                  fontSize={'12px'}
                  textAlign={'left'}
                  color={row?.doctor_unread_messages > 0 && !row?.blocked ? '#FECE2E' : '#C3C3C3'}
                  textTransform={'capitalize'}
                >
                  {calcTime(Number(row?.last_message_time))}
                </Typography>

                {!row?.blocked && row?.doctor_unread_messages > 0 && (
                  <Box
                    bgcolor={'#FECE2E'}
                    borderRadius={'100%'}
                    width={'18px'}
                    height={'18px'}
                    textAlign={'center'}
                    color={'#FFF'}
                    fontFamily={'Oxygen'}
                    fontWeight={'700'}
                    fontSize={'9px'}
                    mt={'15px'}
                  >
                    {row?.doctor_unread_messages >= 100 ? '99+' : row?.doctor_unread_messages}
                  </Box>
                )}

                {row.blocked && (
                  <Box width={'18px'} height={'18px'} textAlign={'center'} color={'#FF3A44'} mt={'15px'}>
                    <IconBan stroke={'2px'} />
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};
export default ChatListView;
