import { Box, Image } from '@chakra-ui/react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useShop } from '../../../hooks/Shop/useShop';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { v4 as uuidv4 } from 'uuid';
import { UIPageHeader, UIPageTitle } from '../../utilities/Headers';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UIFormControlBox } from '../../utilities/Form';
import { UICheckbox, UIInput } from '../../utilities/Input';
import { UIButton } from '../../utilities/Button';
import UITypography from '../../utilities/Typography';
import { UIInputSelect } from '../../utilities/InputSelect';

const ModalEdit = ({ modalOpen, handleModalClose, banner }) => {
  let [deleteMode, setDeleteMode] = useState(false);
  let [data, setData] = useState(null);
  let [backgrounds, setBackgrounds] = useState([]);
  let [discounts, setDiscounts] = useState([]);
  const [uuid, setUuid] = React.useState(uuidv4());

  useEffect(() => {
    setUuid(uuidv4());
  }, [banner]);

  const { onGetShopBanner, onUpdateShopBanner, onCreateShopBanner, onDeleteShopBanner, onListShopBannersBackgrounds, onListShopDiscounts } =
    useShop();

  const getBanner = async () => {
    onGetShopBanner({
      banner_uuid: banner.uuid
    }).then((res) => {
      setDeleteMode(false);
      setData(res);
    });
  };

  const getBackgrounds = async () => {
    onListShopBannersBackgrounds().then((res) => {
      setBackgrounds(res);
    });
  };

  const getDiscounts = async () => {
    onListShopDiscounts().then((res) => {
      setDiscounts(res);
    });
  };

  useEffect(() => {
    if (banner?.uuid) {
      getBanner();
    } else {
      setData(null);
    }
    getBackgrounds();
    getDiscounts();
  }, [banner]);

  const handleDeleteProduct = async () => {
    await onDeleteShopBanner({
      banner_uuid: banner.uuid
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { title, subtitle, background_uuid, image, image_web, image_web_position, active, discounts } = values;

    if (data?.uuid) {
      onUpdateShopBanner({
        banner_uuid: data?.uuid,
        banner_data: {
          title,
          subtitle,
          background_uuid,
          image,
          image_web,
          image_web_position,
          active,
          discounts
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateShopBanner({
        banner_data: {
          uuid,
          title,
          subtitle,
          background_uuid,
          image,
          image_web,
          image_web_position,
          active,
          discounts
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalClose} allowFullScreen={true} className={'modal'}>
      <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
        <DialogTitle>Elimina banner</DialogTitle>
        <DialogContent>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <DialogContentText>Vuoi eliminare il banner "{banner?.title}"?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
            Annulla
          </Button>
          <Button variant={'contained'} color={'success'} onClick={handleDeleteProduct}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      <UiModalInternal
        onClose={handleModalClose}
        isLoading={banner && data === null}
        containerSx={{
          bgcolor: '#FFF'
        }}
      >
        <Formik
          initialValues={{
            title: data?.title ?? '',
            subtitle: data?.subtitle ?? '',
            background_uuid: data?.background?.uuid ?? '',
            discounts: data?.discounts?.map((discount) => discount.uuid) ?? [],
            image: data?.image ?? '',
            image_web: data?.image_web ?? '',
            image_web_position: data?.image_web_position ?? 'L',
            active: data?.active ?? false
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Il titolo è obbligatorio'),
            subtitle: Yup.string().required('Il sottotitolo è obbligatorio'),
            background_uuid: Yup.string().required('Il background è obbligatorio'),
            discounts: Yup.array().required('Almeno uno sconto è obbligatorio'),
            image: Yup.string().required("L'immagine è obbligatoria"),
            image_web: Yup.string().required("L'immagine web è obbligatoria"),
            image_web_position: Yup.string().required("La posizione dell'immagine web è obbligatoria"),
            active: Yup.boolean().nullable()
          })}
          onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
          enableReinitialize={true}
        >
          {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue, submitForm }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalHeader>
                <UIPageHeader
                  title={'Dati Banner'}
                  sx={{
                    textAlign: 'center'
                  }}
                />
              </UIModalHeader>
              <UIModalBody>
                <UIFormControlBox label={'Titolo'} error={Boolean(touched.title && errors.title)}>
                  <UIInput value={values.title} onChange={() => setFieldValue('title', event.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Sottotitolo'} error={Boolean(touched.subtitle && errors.subtitle)}>
                  <UIInput value={values.subtitle} onChange={() => setFieldValue('subtitle', event.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Sconti associati'} error={Boolean(touched.discounts && errors.discounts)}>
                  <Select
                    labelId="discounts-label"
                    id="discounts-select"
                    multiple
                    value={values?.discounts}
                    onChange={(event) => {
                      setFieldValue('discounts', event?.target?.value);
                    }}
                    input={<OutlinedInput id="discounts-input" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((value, index) => (
                          <ChipCustom key={index} value={value} discounts={discounts} />
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 250
                        }
                      }
                    }}
                  >
                    {discounts?.map((discount, index) => (
                      <MenuItem key={index} value={discount?.uuid}>
                        {discount?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </UIFormControlBox>

                <UIFormControlBox label={'Colore sfondo'} error={Boolean(touched.background_uuid && errors.background_uuid)}>
                  <Select
                    name={'type'}
                    fullWidth={true}
                    labelId="type-label"
                    id="type-select"
                    value={values?.background_uuid}
                    label="Tema"
                    onChange={(e) => {
                      setFieldValue('background_uuid', e.target.value);
                    }}
                    onBlur={handleBlur}
                    inputProps={{
                      sx: {
                        backgroundColor: backgrounds?.find((background) => background.uuid === values?.background_uuid)?.color
                      }
                    }}
                  >
                    {backgrounds?.map((background, index) => (
                      <MenuItem
                        sx={{
                          backgroundColor: background.color
                        }}
                        key={index}
                        value={background.uuid}
                      >
                        {background.color}
                      </MenuItem>
                    ))}
                  </Select>
                </UIFormControlBox>

                <UIFormControlBox error={Boolean(touched.active && errors.active)}>
                  <UICheckbox
                    label={<UITypography title={'Attivo'} />}
                    name={'active'}
                    readOnly={true}
                    checked={values?.active}
                    onChange={(event) => setFieldValue('active', event.target.checked)}
                  />
                </UIFormControlBox>
                <UIPageTitle title={'Immagine Mobile'} />

                <UIFormControlBox label={'Immagine'} error={Boolean(touched.image && errors.image)}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                    <Image src={values.image} w={'100px'} h={'100px'} objectFit={'image'} />
                    <DropzoneFormikInput
                      variant={'banner'}
                      setFieldValue={setFieldValue}
                      field={'image'}
                      folder={'banners'}
                      label={'Carica'}
                      extra_data={{
                        banner_uuid: data?.uuid ?? uuid,
                        image_type: 'mobile'
                      }}
                    />
                  </Box>
                </UIFormControlBox>

                <UIPageTitle title={'Immagine Web'} />

                <UIFormControlBox label={'Immagine'} error={Boolean(touched.image_web && errors.image_web)}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                    <Image src={values.image_web} w={'100px'} h={'100px'} objectFit={'cover'} />
                    <DropzoneFormikInput
                      variant={'banner'}
                      setFieldValue={setFieldValue}
                      field={'image_web'}
                      folder={'banners'}
                      label={'Carica'}
                      extra_data={{
                        banner_uuid: data?.uuid ?? uuid,
                        image_type: 'web'
                      }}
                    />
                  </Box>
                </UIFormControlBox>

                <UIFormControlBox label={'Posizione Immagine Web'} error={Boolean(touched.image_web_position && errors.image_web_position)}>
                  <UIInputSelect
                    value={values.image_web_position}
                    onChange={(e) => {
                      setFieldValue('image_web_position', e.target.value);
                    }}
                    options={[
                      {
                        value: 'L',
                        label: 'Sinistra'
                      },
                      {
                        value: 'R',
                        label: 'Destra'
                      }
                    ]}
                  />
                </UIFormControlBox>
              </UIModalBody>

              <UIModalButtons>
                <UIButton
                  type={'submit'}
                  onClick={() => {
                    submitForm();
                  }}
                  icon={<IconCheck stroke={1.64} size="25px" />}
                />
                <UIButton
                  type={'submit'}
                  variant={'cancel'}
                  onClick={() => setDeleteMode(true)}
                  icon={<IconTrash stroke={1.64} size={'25px'} />}
                  sxCustom={{
                    width: '50px',
                    bgcolor: '#FF646C'
                  }}
                />
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};

const ChipCustom = ({ discounts, value }) => {
  let [label, setLabel] = useState('');

  useEffect(() => {
    discounts?.forEach((discount) => {
      if (discount?.uuid === value) setLabel(discount?.title);
    });
  }, [value, discounts]);

  if (!label) return <></>;
  return (
    <Chip
      key={value}
      label={label}
      // clickable
      sx={{
        ml: 0.5
      }}
      // deleteIcon={<IconX stroke={1.5} size="1rem" onMouseDown={(event) => event.stopPropagation()} />}
      // onDelete={(e) => {
      //   handleDeleteProduct(value);
      //   setFieldValue(
      //     'products',
      //     products?.filter((item) => item?.uuid !== value)
      //   );
      // }}
    />
  );
};

export default ModalEdit;
