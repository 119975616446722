// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import CalendarView from '../views/pages/calendar/view';
import CalendarNearestView from '../views/pages/calendarNearest/view';
import CalendarTimes from '../views/pages/calendarTimes/view';
import CalendarWaitingListsView from '../views/pages/calendarAwaiting/view';
import CalendarHistoryView from '../views/pages/calendarHistory/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Calendar = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'calendar',
      element: (
        <ProtectedRoute permission={['DOCTOR', 'CLINIC']}>
          <CalendarView />
        </ProtectedRoute>
      )
    },
    {
      path: 'calendar/nearest',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <CalendarNearestView />
        </ProtectedRoute>
      )
    },
    {
      path: 'calendar/times',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <CalendarTimes />
        </ProtectedRoute>
      )
    },
    {
      path: 'calendar/waiting_lists',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <CalendarWaitingListsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'calendar/history',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <CalendarHistoryView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Calendar;
