import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useAuthentication } from '../../../../../utils/useAuthentication';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();
  const data = useAuthentication();
  const user = data?.user?.account_data;

  let user_admin = user?.admin;
  let user_doctor = user?.doctor;
  let user_clinic = user?.clinic;

  if (
    !(item?.permission?.includes('ADMIN') && user_admin) &&
    !(item?.permission?.includes('CLINIC') && user_clinic) &&
    !(item?.permission?.includes('DOCTOR') && user_doctor)
  ) {
    return null;
  }

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    if (
      (menu?.permission?.includes('ADMIN') && user_admin) ||
      (menu?.permission?.includes('CLINIC') && user_clinic && !user_admin) ||
      (menu?.permission?.includes('DOCTOR') && user_doctor && !user_admin)
    ) {
      switch (menu.type) {
        case 'divider':
          return <hr key={menu?.id} color={'#f8f8f8'} />;
        case 'collapse':
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        case 'item':
          return <NavItem key={menu.id} item={menu} level={1} />;
        default:
          return (
            <Typography key={menu.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    }

    return null;
  });

  return (
    <>
      <List
        sx={{
          p: 0
        }}
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
