import React, { useEffect, useState } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { IconCheck } from '@tabler/icons-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useProducts } from '../../../hooks/Products/useProducts';
import { useProductsDevices } from '../../../hooks/ProductsDevices/useProductsDevices';
import { useProductsTechniques } from '../../../hooks/ProductsTechniques/useProductsTechniques';
import { useProductsTargets } from '../../../hooks/ProductsTargets/useProductsTargets';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UIPageTitle } from '../../utilities/Headers';
import { UIFormControlBox } from '../../utilities/Form';
import { UIInput, UITextarea } from '../../utilities/Input';
import { UIButton } from '../../utilities/Button';
import UIInputAutocomplete from '../../utilities/InputSelect';

const ModalAddProduct = ({ modalOpen, handleModalClose, appointment_product, appointment }) => {
  let [appointmentProduct, setAppointmentProductData] = useState(null);
  let [appointmentData, setAppointmentData] = useState(null);
  let [products, setProducts] = useState([]);
  let [devices, setDevices] = useState([]);
  let [techniques, setTechniques] = useState([]);
  let [targets, setTargets] = useState([]);
  let [loading, setLoading] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);

  let { onListProductsAdmin } = useProducts();
  let { onListProductsDevicesAdmin } = useProductsDevices();
  let { onListProductsTechniquesAdmin } = useProductsTechniques();
  let { onListProductsTargetsAdmin } = useProductsTargets();
  let { onCreateCalendarAppointmentProductAdmin, onUpdateCalendarAppointmentProductAdmin } = useCalendar();

  useEffect(() => {
    onListProductsAdmin().then((response) => {
      setProducts(response);
    });
    onListProductsDevicesAdmin().then((response) => {
      setDevices(response);
    });
    onListProductsTechniquesAdmin().then((response) => {
      setTechniques(response);
    });
    onListProductsTargetsAdmin().then((response) => {
      setTargets(response);
    });
  }, []);

  useEffect(() => {
    setAppointmentProductData(appointment_product);
    setAppointmentData(appointment);
  }, [appointment_product, appointment]);

  const handleSubmit = (values) => {
    setOperationLoading(true);
    if (appointmentProduct?.id) {
      onUpdateCalendarAppointmentProductAdmin({
        appointment_product_id: appointmentProduct.id,
        appointment_product_data: {
          product_id: values?.product,
          device_id: values?.device,
          technique_id: values?.technique,
          target_id: values?.target,
          quantity: values?.quantity,
          note: values?.note,
          stamp: values?.stamp
        }
      }).then((res) => {
        if (res) {
          handleInternalClose();
        }
      });
    } else {
      onCreateCalendarAppointmentProductAdmin({
        appointment_product_data: {
          appointment_id: appointmentData.id,
          product_id: values?.product,
          device_id: values?.device,
          technique_id: values?.technique,
          target_id: values?.target,
          quantity: values?.quantity,
          note: values?.note,
          stamp: values?.stamp
        }
      }).then((res) => {
        if (res) {
          handleInternalClose();
        }
      });
    }
  };

  const handleInternalClose = () => {
    setOperationLoading(false);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleInternalClose} allowFullScreen={true} className={'modal'}>
      <UiModalInternal
        onClose={handleInternalClose}
        isLoading={
          (appointment_product && appointmentProduct === null) || techniques.length === 0 || devices?.length === 0 || targets?.length === 0
        }
        isOperationLoading={operationLoading}
      >
        <Formik
          initialValues={{
            product: appointmentProduct?.product_id ?? '',
            device: appointmentProduct?.device_id ?? '',
            technique: appointmentProduct?.technique_id ?? '',
            target: appointmentProduct?.target_id ?? '',
            quantity: appointmentProduct?.quantity ?? '',
            note: appointmentProduct?.note ?? '',
            stamp: appointmentProduct?.stamp ?? ''
          }}
          validationSchema={Yup.object().shape({
            product: Yup.number().required('Seleziona un prodotto'),
            device: Yup.number().required('Seleziona un dispositivo'),
            technique: Yup.number().required('Seleziona una tecnica'),
            target: Yup.number().required('Seleziona un target'),
            quantity: Yup.string().required('Inserisci una quantità'),
            note: Yup.string().nullable(),
            stamp: Yup.string().nullable()
          })}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, submitForm, handleSubmit, touched, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <UIPageTitle title={'Inserisci prodotto in appuntamento'} />

                <UIFormControlBox label={'Prodotto'} error={Boolean(touched.product && errors.product)}>
                  <UIInputAutocomplete
                    options={products?.map((product) => {
                      return {
                        id: product?.id,
                        label: `${product?.name}`
                      };
                    })}
                    value={values?.product}
                    setValue={(data) => {
                      setFieldValue('product', data?.id ?? '');
                    }}
                    label={''}
                    sx={{
                      mr: '10px'
                    }}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Quantità'} error={Boolean(touched.quantity && errors.quantity)}>
                  <UIInput value={values.quantity} label={'Quantità'} onChange={() => setFieldValue('quantity', event.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Dispositivo'} error={Boolean(touched.device && errors.device)}>
                  <UIInputAutocomplete
                    options={devices?.map((device) => {
                      return {
                        id: device?.id,
                        label: `${device?.name}`
                      };
                    })}
                    value={values?.device}
                    setValue={(data) => {
                      setFieldValue('device', data?.id ?? '');
                    }}
                    label={''}
                    sx={{
                      mr: '10px'
                    }}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Tecnica'} error={Boolean(touched.technique && errors.technique)}>
                  <UIInputAutocomplete
                    options={techniques?.map((technique) => {
                      return {
                        id: technique?.id,
                        label: `${technique?.name}`
                      };
                    })}
                    value={values?.technique}
                    setValue={(data) => {
                      setFieldValue('technique', data?.id ?? '');
                    }}
                    label={''}
                    sx={{
                      mr: '10px'
                    }}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Target'} error={Boolean(touched.target && errors.target)}>
                  <UIInputAutocomplete
                    options={targets?.map((target) => {
                      return {
                        id: target?.id,
                        label: `${target?.name}`
                      };
                    })}
                    value={values?.target}
                    setValue={(data) => {
                      setFieldValue('target', data?.id ?? '');
                    }}
                    label={''}
                    sx={{
                      mr: '10px'
                    }}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Note'} error={Boolean(touched.note && errors.note)}>
                  <UITextarea
                    expandable={false}
                    value={values.note}
                    label={'Note'}
                    onChange={() => setFieldValue('note', event.target.value)}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Tagliando'} error={Boolean(touched.stamp && errors.stamp)}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                    <Image src={values.stamp} w={'100px'} h={'100px'} />
                    {!loading && (
                      <DropzoneFormikInput
                        variant={'stamps'}
                        setFieldValue={setFieldValue}
                        field={'stamp'}
                        folder={appointmentData?.id}
                        setLoading={setLoading}
                        label={'Carica tagliando'}
                      />
                    )}
                  </Box>
                </UIFormControlBox>
              </UIModalBody>
              <UIModalButtons>
                <UIButton
                  type={'submit'}
                  onClick={() => {
                    submitForm();
                  }}
                  icon={<IconCheck stroke={1.64} size="25px" />}
                />
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};
export default ModalAddProduct;
