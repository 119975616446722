import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { UIModal, UIModalBody, UIModalButtons, UIModalDivider, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UIPageTitle } from '../../utilities/Headers';
import { UITypography, UITypographyBox } from '../../utilities/Typography';
import { UIButton } from '../../utilities/Button';
import { Box } from '@mui/material';
import { IconClock, IconEdit, IconPencil, IconPhone, IconStethoscope, IconThumbUp, IconUserOff, IconVaccine } from '@tabler/icons-react';
import ModalUserDetails from './modalUserDetails';
import moment from 'moment/moment';
import { UIFormControlBox } from '../../utilities/Form';
import { statusColor, statusText } from '../../../utils/const';
import { UiDialog } from '../../utilities/Dialog';
import ModalAppointmentEdit from './modalAppointmentEdit';
import ModalCompileAppointment from '../calendar/ModalCompileAppointment';

const ModalAppointmentDetails = ({ modalOpen, handleModalClose, appointment }) => {
  let [appointmentData, setAppointmentData] = useState(null);
  let [userDetailsMode, setUserDetailsMode] = useState(false);
  const [confirmMode, setConfirmMode] = useState(false);
  const [rejectMode, setRejectMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [compileMode, setCompileMode] = useState(false);
  const [notShownMode, setNotShownMode] = useState(false);

  const { onGetAppointment, onUpdateAppointmentStatus, onSetPatientNotShown } = useCalendar();
  const getAppointmentData = async () => {
    onGetAppointment({ appointment_id: appointment?.id }).then((res) => {
      setAppointmentData(res);
    });
  };

  useEffect(() => {
    if (appointment) {
      getAppointmentData();
    }
  }, [appointment]);

  const handleAppointmentConfirm = () => {
    onUpdateAppointmentStatus({
      appointment_id: appointmentData?.id,
      status: 'confirmed'
    }).then(() => {
      setConfirmMode(false);
      getAppointmentData();
    });
  };

  const handleAppointmentReject = () => {
    onUpdateAppointmentStatus({
      appointment_id: appointmentData?.id,
      status: 'rejected'
    }).then(() => {
      setRejectMode(false);
      getAppointmentData();
    });
  };

  const handleAppointmentNotShown = () => {
    onSetPatientNotShown({
      appointment_id: appointmentData?.id
    }).then(() => {
      setNotShownMode(false);
      getAppointmentData();
    });
  };

  const handleModalCloseEditAppointment = () => {
    setEditMode(false);
    getAppointmentData();
  };

  const handleModalCloseCompileAppointment = () => {
    setCompileMode(false);
    getAppointmentData();
  };

  const handleModalCloseInternal = () => {
    setAppointmentData(null);
    setEditMode(false);
    setUserDetailsMode(false);
    setConfirmMode(false);
    setRejectMode(false);
    setNotShownMode(false);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiDialog
        type={'default'}
        variant={'confirm'}
        title={'Vuoi confermare l’appuntamento al posto del paziente?'}
        open={confirmMode}
        onClose={() => setConfirmMode(false)}
        onConfirm={handleAppointmentConfirm}
      />

      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi rifiutare l’appuntamento? Questo farà apparire una notifica di cancellazione al paziente'}
        open={rejectMode}
        onClose={() => setRejectMode(false)}
        onConfirm={handleAppointmentReject}
      />

      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Confermi che il paziente non si è presentato?'}
        open={notShownMode}
        onClose={() => setNotShownMode(false)}
        onConfirm={handleAppointmentNotShown}
      />

      <ModalUserDetails modalOpen={userDetailsMode} handleModalClose={() => setUserDetailsMode(false)} userRow={appointmentData?.patient} />

      <ModalAppointmentEdit
        appointment={appointmentData}
        handleModalClose={handleModalCloseEditAppointment}
        handleModaDetailsClose={handleModalCloseInternal}
        modalOpen={editMode}
      />

      <ModalCompileAppointment
        appointment={appointmentData}
        handleModalClose={handleModalCloseCompileAppointment}
        modalOpen={compileMode}
      />

      <UiModalInternal
        onClose={handleModalCloseInternal}
        containerSx={{
          bgcolor: '#FFF'
        }}
        isLoading={appointmentData === null}
      >
        <UIModalHeader
          sx={{
            p: '20px 0 20px 0'
          }}
        >
          <UIPageTitle
            title={'Riepilogo appuntamento'}
            sx={{
              textAlign: 'center',
              color: '#101828'
            }}
          />
          <UITypography
            variant={'Oxygen700'}
            title={
              appointmentData?.patient
                ? `${appointmentData?.patient?.name} ${appointment?.patient?.surname}`
                : appointmentData?.patient_alias
            }
            subtitle={appointmentData?.patient ? 'Paziente' : 'Paziente non registrato'}
            size={'15px'}
            sxCustom={{
              textAlign: 'center',
              color: appointmentData?.patient ? '#101828' : '#53acee'
            }}
          />
          <UITypography
            variant={'Oxygen400'}
            title={
              appointmentData?.patient
                ? `${appointmentData?.patient?.phone_country?.dial_code} ${appointmentData?.patient?.phone}`
                : appointmentData?.patient_phone
            }
            size={'14px'}
            sxCustom={{
              textAlign: 'center',
              color: '#101828',
              mt: '2px'
            }}
          />

          <UITypography
            variant={'Oxygen700'}
            title={appointmentData?.patient ? `${appointmentData?.patient?.email}` : appointmentData?.patient_email}
            size={'14px'}
            sxCustom={{
              textAlign: 'center',
              textDecoration: 'underline',
              mt: '2px'
            }}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              mt: '10px'
            }}
          >
            <UIButton
              variant={'phone'}
              label={
                <>
                  <IconPhone stroke={'1.64'} size={'20px'} />
                  <UITypography
                    variant={'Oxygen700'}
                    title={'Chiama'}
                    size={'14px'}
                    sxCustom={{
                      ml: '5px',
                      color: '#344054'
                    }}
                  />
                </>
              }
              onClick={() => {
                if (!appointmentData?.patient) {
                  window.open(`tel:${appointmentData?.patient_phone}`, '_blank');
                } else {
                  window.open(`tel:${appointmentData?.patient?.phone_country?.dial_code}${appointmentData?.patient?.phone}`, '_blank');
                }
              }}
            />
            {appointmentData?.patient && (
              <UIButton
                variant={'copy'}
                label={
                  <UITypography
                    variant={'Oxygen700'}
                    title={'Scheda Paziente'}
                    size={'14px'}
                    sxCustom={{
                      ml: '5px',
                      color: '#344054'
                    }}
                  />
                }
                onClick={() => setUserDetailsMode(true)}
              />
            )}
          </Box>
        </UIModalHeader>
        <UIModalBody>
          <UITypographyBox
            title={moment(appointmentData?.date).format('dddd, DD MMMM YYYY')}
            subtitle={`${moment(appointmentData?.start_hour, 'HH:mm:ss').format('HH:mm')} - ${moment(
              appointmentData?.end_hour,
              'HH:mm:ss'
            ).format('HH:mm')}`}
            icon={<IconClock stroke={'1.64'} size={'25px'} />}
            sxText={{
              color: '#101828'
            }}
          />
          <UITypographyBox
            title={appointmentData?.treatment?.name}
            subtitle={`${moment(appointment?.end_hour, 'HH:mm').diff(moment(appointment?.start_hour, 'HH:mm'), 'minute')} min`}
            icon={<IconVaccine stroke={'1.64'} size={'25px'} />}
            sxText={{
              color: '#101828'
            }}
          />
          <UITypographyBox
            title={`${appointmentData?.calendar?.doctor?.surname}, ${appointmentData?.calendar?.doctor?.name}`}
            subtitle={`${appointmentData?.calendar?.office?.name} - ${appointmentData?.calendar?.office?.city?.name}`}
            icon={<IconStethoscope stroke={'1.64'} size={'25px'} />}
            sxText={{
              color: '#101828'
            }}
          />

          <UIModalDivider />

          <UIFormControlBox>
            <UITypography variant={'Oxygen700'} title={'Note pubbliche:'} size={'13px'} />
            <UITypography variant={'Oxygen400'} title={appointmentData?.note_public} size={'13px'} />
          </UIFormControlBox>

          <UIFormControlBox>
            <UITypography variant={'Oxygen700'} title={'Note private:'} size={'13px'} />
            <UITypography variant={'Oxygen400'} title={appointmentData?.note} size={'13px'} />
          </UIFormControlBox>

          <UIModalDivider />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
              p: '0 10px'
            }}
          >
            <StatusBox status={appointmentData?.status} patient_not_shown={appointmentData?.patient_not_shown} />
            <Box width={'50px'}>
              {['pending'].includes(appointmentData?.status) && (
                <UIButton
                  type={'submit'}
                  variant={'confirm'}
                  icon={<IconThumbUp stroke={'1.64'} size={'25px'} />}
                  onClick={() => setConfirmMode(true)}
                />
              )}
              {['completed'].includes(appointmentData?.status) && !appointmentData?.patient_not_shown && (
                <UIButton
                  type={'submit'}
                  variant={''}
                  icon={<IconUserOff stroke={'1.64'} size={'25px'} />}
                  sxCustom={{
                    bgcolor: '#FF646C',
                    boxShadow: '0px 4px 4px 0px #00000026'
                  }}
                  onClick={() => setNotShownMode(true)}
                />
              )}
            </Box>
          </Box>

          {['completed'].includes(appointmentData?.status) && !appointmentData?.patient_not_shown && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '10px',
                  p: '0 10px'
                }}
              >
                <CompileBox compiled={appointmentData?.compiled} />
                <Box width={'50px'}>
                  <UIButton
                    type={'submit'}
                    variant={'copy'}
                    icon={<IconPencil stroke={'1.64'} size={'25px'} />}
                    sxCustom={{
                      bgcolor: '#FDF2B5',
                      color: '#344054',
                      boxShadow: '0px 4px 4px 0px #00000026'
                    }}
                    onClick={() => setCompileMode(true)}
                  />
                </Box>
              </Box>
            </>
          )}

          {['pending'].includes(appointmentData?.status) && (
            <Box
              sx={{
                width: '90%',
                m: '10px auto'
              }}
            >
              <UIButton
                type={'button'}
                variant={'cancel'}
                label={'Rifiuta appuntamento'}
                sxCustom={{
                  width: '100%',
                  borderRadius: '8px',
                  border: '1px solid #D0D5DD'
                }}
                onClick={() => setRejectMode(true)}
              />
            </Box>
          )}
        </UIModalBody>
        <UIModalButtons>
          <UIButton
            type={'modal-option'}
            sxCustom={{
              lineHeight: '14.5px',
              textTransform: 'none',
              letterSpacing: '0.5px'
            }}
            icon={<IconEdit size={'26px'} stroke={'1.67'} />}
            onClick={() => setEditMode(true)}
          />
        </UIModalButtons>
      </UiModalInternal>
    </UIModal>
  );
};

const StatusBox = ({ status, patient_not_shown }) => {
  return (
    <Box
      sx={{
        width: '90%',
        m: '10px auto 5px auto',
        p: '10px 15px',
        border: '1px solid #E3E3E3',
        borderRadius: '8px',
        bgcolor: '#FFF'
      }}
    >
      <UITypography
        variant={'Oxygen700'}
        size={'12px'}
        title={'Status:'}
        sxCustom={{
          color: '#101828'
        }}
      />
      <UITypography
        variant={'Oxygen700'}
        size={'11px'}
        title={patient_not_shown ? 'Non presentato' : statusText(status)}
        sxCustom={{
          color: patient_not_shown ? '#FF3A44' : statusColor(status),
          textTransform: 'uppercase'
        }}
      />
    </Box>
  );
};

const CompileBox = ({ compiled }) => {
  return (
    <Box
      sx={{
        width: '90%',
        m: '0px auto',
        p: '10px 15px',
        border: '1px solid #E3E3E3',
        borderRadius: '8px',
        bgcolor: '#FFF'
      }}
    >
      <UITypography
        variant={'Oxygen700'}
        size={'12px'}
        title={'Compilato:'}
        sxCustom={{
          color: '#101828'
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            borderRadius: '100%',
            width: '10px',
            height: '10px',
            bgcolor: compiled ? '#00E676' : '#FE922E',
            mr: '5px'
          }}
        />
        <UITypography variant={'Oxygen700'} size={'11px'} title={compiled ? 'Compilato' : 'In attesa'} />
      </Box>
    </Box>
  );
};

export default ModalAppointmentDetails;
