import { useApolloClient } from '@apollo/client';
import { manageError } from '../utils/errors';

export const useQuery = (query) => {
  const client = useApolloClient();

  const getDataGQL = async (params) => {
    return client
      .query({ query, variables: params?.variables })
      .catch((error) => {
        manageError(error, query);
        return error;
      })
      .then((res) => {
        return res?.data;
      });
  };

  return { getDataGQL };
};
