// assets
import { IconUsers } from '@tabler/icons-react';
// constant
const icons = { IconUsers };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const accounts = {
  id: 'users',
  type: 'group',
  permission: ['ADMIN', 'DOCTOR', 'CLINIC'],
  children: [
    {
      id: 'users_list',
      title: 'Pazienti',
      type: 'collapse',
      permission: ['ADMIN'],
      icon: icons.IconUsers,
      breadcrumbs: false,
      children: [
        {
          id: 'users',
          title: 'Pazienti',
          type: 'item',
          url: '/users',
          permission: ['ADMIN'],
          breadcrumbs: false
        },
        {
          id: 'doctors',
          title: 'Dottori',
          type: 'item',
          url: '/doctors',
          permission: ['ADMIN'],
          breadcrumbs: false
        },
        {
          id: 'doctors_reviews',
          title: 'Recensioni',
          type: 'item',
          url: '/doctors_reviews',
          permission: ['ADMIN'],
          icon: icons.AttributionIcon,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'users_list',
      title: 'Pazienti',
      type: 'collapse',
      permission: ['DOCTOR'],
      icon: icons.IconUsers,
      breadcrumbs: false,
      children: [
        {
          id: 'users',
          title: 'Pazienti',
          type: 'item',
          url: '/users',
          permission: ['DOCTOR'],
          breadcrumbs: false
        },
        {
          id: 'users_banned',
          title: 'Pazienti bloccati',
          type: 'item',
          url: '/users_banned',
          permission: ['DOCTOR'],
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'users_list',
      title: 'Pazienti',
      type: 'collapse',
      permission: ['CLINIC'],
      icon: icons.IconUsers,
      url: '/users',
      breadcrumbs: false
    }
  ]
};

export default accounts;
