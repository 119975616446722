import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import './custom-notifications.css';
import { IconCheck } from '@tabler/icons-react';
import * as Yup from 'yup';
import { Textarea } from '@chakra-ui/react';
import { Formik } from 'formik';
import { useNotifications } from '../../../hooks/Notifications/useNotifications';
import { toast } from 'react-toastify';

const NotificationGlobalView = () => {
  const [confirmMode, setConfirmMode] = useState(false);

  const { onSendGlobalNotificationAdmin } = useNotifications();

  useEffect(() => {}, []);

  const handleSendNotification = (values, setFieldValue) => {
    onSendGlobalNotificationAdmin({
      notification_data: {
        title: values?.title,
        text: values?.text
      }
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
      setFieldValue('text', '');
      setFieldValue('title', '');
    });
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Invia notifica globale
      </Typography>

      <Formik
        initialValues={{
          title: '',
          text: ''
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Il titolo è obbligatorio'),
          text: Yup.string().required('La descrizione è obbligatoria')
        })}
        onSubmit={() => setConfirmMode(true)}
        enableReinitialize={true}
      >
        {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} id={'appointment-form'}>
            <Dialog open={confirmMode} onClose={() => setConfirmMode(false)}>
              <DialogTitle>Inviare notifica?</DialogTitle>
              <DialogContent>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <DialogContentText>Vuoi inviare la notifica?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant={'contained'} color={'error'} onClick={() => setConfirmMode(false)}>
                  Annulla
                </Button>
                <Button
                  variant={'contained'}
                  color={'success'}
                  onClick={() => {
                    handleSendNotification(values, setFieldValue);
                    setConfirmMode(false);
                  }}
                >
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>
            <Box display={'flex'} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} w={'full'}>
              <FormControl fullWidth error={Boolean(touched.title && errors.title)}>
                <InputLabel htmlFor="input-name">Titolo</InputLabel>
                <OutlinedInput
                  id="input-title"
                  type="text"
                  value={values.title}
                  name="title"
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('title', event.target.value)}
                  label="Titolo"
                  inputProps={{
                    maxLength: 23
                  }}
                />
                {touched.title && errors.title && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.title}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box display={'flex'} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
              <FormControl fullWidth error={Boolean(touched.text && errors.text)} w={'100%'}>
                <Textarea
                  id="input-text"
                  value={values.text}
                  name="text"
                  onBlur={handleBlur}
                  onChange={() => setFieldValue('text', event.target.value)}
                  label="Testo"
                  color={'#121926'}
                  bg={'#f8fafc'}
                  borderRadius={'12px'}
                  placeholder={'Testo'}
                  p={'10px'}
                  w={'100%'}
                  minH={'100px'}
                  resize={'none'}
                  border={errors?.text ? '1px solid red' : null}
                  inputProps={{
                    maxLength: 80
                  }}
                />
                {touched.text && errors.text && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.text}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Divider />
            <Box className={'bottom_buttons'} mt={2} width={'75%'} m={'20px auto'} color={'white'}>
              <Button
                variant={'contained'}
                color={'success'}
                type={'submit'}
                fullWidth
                sx={{
                  color: 'white'
                }}
              >
                <IconCheck stroke={2.5} size="1.5rem" />
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
export default NotificationGlobalView;
