import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth } from '../../../../hooks/Auth/useAuth';
import { useAuthentication } from '../../../../utils/useAuthentication';
import { UIButton } from '../../../utilities/Button';

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
  const { onLogin } = useAuth();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const data = useAuthentication();

  const handleLogin = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      onLogin(values).then(async (res) => {
        if (res?.response) {
          await data.login(res);
        }
        setSubmitting(false);
      });
    } catch (_e) {
      setSubmitting(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        mt: '50px'
      }}
    >
      <Formik
        initialValues={{
          email: '',
          password: '',
          device_id: '0'
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('La mail deve essere in un formato valido').max(255).required('Email obbligatoria'),
          password: Yup.string().max(255).required('Password obbligatoria')
        })}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          handleLogin(values, { setErrors, setStatus, setSubmitting });
        }}
      >
        {({ errors, handleBlur, submitForm, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-email-login">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Email Address / Username"
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.password && errors.password)} sx={{ ...theme.typography.customInput }}>
              <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                inputProps={{}}
              />
              {touched.password && errors.password && (
                <FormHelperText error id="standard-weight-helper-text-password-login">
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}></Stack>
            {errors.submit && (
              <Box sx={{ mt: 3, position: 'absolute' }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <UIButton
              formButtonType={'submit'}
              label={'Accedi'}
              variant={'confirm'}
              onClick={() => submitForm()}
              disabled={isSubmitting}
              sxCustom={{
                width: '150px',
                height: '50px',
                color: '#FFF',
                bgcolor: '#000',
                m: '80px auto 0 auto',
                display: 'block',
                fontSize: '18px',
                borderRadius: '30px'
              }}
            />
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default FirebaseLogin;
