// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import ProductsView from '../views/pages/products/view';
import ProductsTargetsView from '../views/pages/productsTargets/view';
import ProductsDevicesView from '../views/pages/productsDevices/view';
import ProductsTechniquesView from '../views/pages/productsTechniques/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Products = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'products',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ProductsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'products/targets',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ProductsTargetsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'products/devices',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ProductsDevicesView />
        </ProtectedRoute>
      )
    },
    {
      path: 'products/techniques',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ProductsTechniquesView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Products;
