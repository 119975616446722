import {
  ADD_PROTOCOL_PRODUCT,
  CONFIRM_PROTOCOL,
  CREATE_PROTOCOL,
  CREATE_PROTOCOL_STEP,
  DELETE_PROTOCOL,
  DELETE_PROTOCOL_PRODUCT,
  DELETE_PROTOCOL_STEP,
  GET_PROTOCOL_ADMIN,
  GET_SURVEY,
  LIST_DEFAULT_PROTOCOLS,
  LIST_SURVEYS_ADMIN,
  LIST_SURVEYS_ADMIN_PAGINATED,
  UPDATE_PROTOCOL,
  UPDATE_PROTOCOL_STEP,
  UPLOAD_PROTOCOL_COVER,
  UPLOAD_SURVEY_INVOICE
} from './graphql/queries';
import { useQuery } from '../useQuery';

/** @format */
export const useProtocols = () => {
  const { getDataGQL: listSurveysAdmin } = useQuery(LIST_SURVEYS_ADMIN);
  const { getDataGQL: listSurveysAdminPaginated } = useQuery(LIST_SURVEYS_ADMIN_PAGINATED);
  const { getDataGQL: getSurvey } = useQuery(GET_SURVEY);
  const { getDataGQL: uploadSurveyInvoice } = useQuery(UPLOAD_SURVEY_INVOICE);
  const { getDataGQL: listDefaultProtocols } = useQuery(LIST_DEFAULT_PROTOCOLS);
  const { getDataGQL: getProtocolAdmin } = useQuery(GET_PROTOCOL_ADMIN);
  const { getDataGQL: createProtocol } = useQuery(CREATE_PROTOCOL);
  const { getDataGQL: uploadProtocolCover } = useQuery(UPLOAD_PROTOCOL_COVER);
  const { getDataGQL: updateProtocol } = useQuery(UPDATE_PROTOCOL);
  const { getDataGQL: deleteProtocol } = useQuery(DELETE_PROTOCOL);
  const { getDataGQL: confirmProtocol } = useQuery(CONFIRM_PROTOCOL);
  const { getDataGQL: createProtocolStep } = useQuery(CREATE_PROTOCOL_STEP);
  const { getDataGQL: updateProtocolStep } = useQuery(UPDATE_PROTOCOL_STEP);
  const { getDataGQL: deleteProtocolStep } = useQuery(DELETE_PROTOCOL_STEP);
  const { getDataGQL: addProtocolProduct } = useQuery(ADD_PROTOCOL_PRODUCT);
  const { getDataGQL: deleteProtocolProduct } = useQuery(DELETE_PROTOCOL_PRODUCT);

  const onListPendingSurveys = async ({ query = '' } = {}) => {
    const response = await listSurveysAdmin({
      variables: {
        status: 'sent',
        query
      }
    });
    if (response?.adminRoute?.listSurveysAdmin) {
      return response?.adminRoute?.listSurveysAdmin;
    }
    return null;
  };

  const onListCompletedSurveys = async ({ query = '' } = {}) => {
    const response = await listSurveysAdmin({
      variables: {
        status: 'completed',
        query
      }
    });
    if (response?.adminRoute?.listSurveysAdmin) {
      return response?.adminRoute?.listSurveysAdmin;
    }
    return null;
  };

  const onListSurveysPaginated = async ({ filters } = {}) => {
    const response = await listSurveysAdminPaginated({
      variables: { filters }
    });
    if (response?.adminRoute?.listSurveysAdminPaginated) {
      return response?.adminRoute?.listSurveysAdminPaginated;
    }
    return null;
  };
  const onGetSurvey = async ({ survey_uuid }) => {
    const response = await getSurvey({
      variables: {
        survey_uuid: survey_uuid
      }
    });
    if (response?.adminRoute?.getSurvey) {
      return response?.adminRoute?.getSurvey;
    }
    return null;
  };

  const onUploadSurveyInvoice = async ({ survey_order_uuid, filename, contentType }) => {
    const response = await uploadSurveyInvoice({
      variables: {
        survey_order_uuid,
        filename,
        contentType
      }
    });
    if (response?.adminRoute?.uploadSurveyInvoice) {
      return response?.adminRoute?.uploadSurveyInvoice;
    }
    return null;
  };

  //! PROTOCOLS

  const onListDefaultProtocols = async () => {
    const response = await listDefaultProtocols();
    if (response?.adminRoute?.listDefaultProtocols) {
      return response?.adminRoute?.listDefaultProtocols;
    }
    return null;
  };

  const onGetProtocolAdmin = async ({ protocol_uuid }) => {
    const response = await getProtocolAdmin({
      variables: {
        protocol_uuid
      }
    });
    if (response?.adminRoute?.getProtocolAdmin) {
      return response?.adminRoute?.getProtocolAdmin;
    }
    return null;
  };

  const onCreateProtocol = async ({ protocol_data }) => {
    const response = await createProtocol({
      variables: {
        protocol_data
      }
    });
    if (response?.adminRoute?.createProtocol) {
      return response?.adminRoute?.createProtocol;
    }
    return null;
  };

  const onUploadProtocolCover = async ({ protocol_uuid, filename, contentType }) => {
    const response = await uploadProtocolCover({
      variables: {
        protocol_uuid,
        filename,
        contentType
      }
    });
    if (response?.adminRoute?.uploadProtocolCover) {
      return response?.adminRoute?.uploadProtocolCover;
    }
    return null;
  };

  const onUpdateProtocol = async ({ protocol_uuid, protocol_data }) => {
    const response = await updateProtocol({
      variables: {
        protocol_uuid,
        protocol_data
      }
    });
    if (response?.adminRoute?.updateProtocol) {
      return response?.adminRoute?.updateProtocol;
    }
    return null;
  };

  const onDeleteProtocol = async ({ protocol_uuid }) => {
    const response = await deleteProtocol({
      variables: {
        protocol_uuid
      }
    });
    if (response?.adminRoute?.deleteProtocol) {
      return response?.adminRoute?.deleteProtocol;
    }
    return null;
  };

  const onConfirmProtocol = async ({ protocol_uuid }) => {
    const response = await confirmProtocol({
      variables: {
        protocol_uuid
      }
    });
    if (response?.adminRoute?.confirmProtocol) {
      return response?.adminRoute?.confirmProtocol;
    }
    return null;
  };

  //! PROTOCOLS STEPS
  const onCreateProtocolStep = async ({ protocol_uuid, step_data }) => {
    const response = await createProtocolStep({
      variables: {
        protocol_uuid,
        step_data
      }
    });
    if (response?.adminRoute?.createProtocolStep) {
      return response?.adminRoute?.createProtocolStep;
    }
    return null;
  };

  const onUpdateProtocolStep = async ({ step_uuid, step_data }) => {
    const response = await updateProtocolStep({
      variables: {
        step_uuid,
        step_data
      }
    });
    if (response?.adminRoute?.updateProtocolStep) {
      return response?.adminRoute?.updateProtocolStep;
    }
    return null;
  };

  const onDeleteProtocolStep = async ({ step_uuid }) => {
    const response = await deleteProtocolStep({
      variables: {
        step_uuid
      }
    });
    if (response?.adminRoute?.deleteProtocolStep) {
      return response?.adminRoute?.deleteProtocolStep;
    }
    return null;
  };

  //! PROTOCOLS STEPS PRODUCTS
  const onAddProtocolProduct = async ({ step_uuid, product_uuid }) => {
    const response = await addProtocolProduct({
      variables: {
        step_uuid,
        product_uuid
      }
    });
    if (response?.adminRoute?.addProtocolProduct) {
      return response?.adminRoute?.addProtocolProduct;
    }
    return null;
  };

  const onDeleteProtocolProduct = async ({ step_uuid, product_uuid }) => {
    const response = await deleteProtocolProduct({
      variables: {
        step_uuid,
        product_uuid
      }
    });
    if (response?.adminRoute?.deleteProtocolProduct) {
      return response?.adminRoute?.deleteProtocolProduct;
    }
    return null;
  };

  return {
    onListPendingSurveys,
    onListCompletedSurveys,
    onListSurveysPaginated,
    onGetSurvey,
    onUploadSurveyInvoice,
    onListDefaultProtocols,
    onGetProtocolAdmin,
    onCreateProtocol,
    onUploadProtocolCover,
    onUpdateProtocol,
    onDeleteProtocol,
    onConfirmProtocol,
    onCreateProtocolStep,
    onUpdateProtocolStep,
    onDeleteProtocolStep,
    onAddProtocolProduct,
    onDeleteProtocolProduct
  };
};
