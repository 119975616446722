import { Navigate } from 'react-router-dom';
import { useAuthentication } from './useAuthentication';
import Error from '../views/pages/errors/error404';

export const ProtectedRoute = (props) => {
  let children = props?.children;
  let permission = props?.permission;

  const data = useAuthentication();

  if (!data?.user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else {
    if (permission) {
      let account_data = data?.user?.account_data;

      if (typeof permission === 'string') {
        switch (permission) {
          case 'ADMIN':
            if (!account_data?.admin) {
              return <Error />;
            }
            break;
          case 'DOCTOR':
            if (!account_data?.doctor) {
              return <Error />;
            }
            break;
          case 'CLINIC':
            if (!account_data?.clinic) {
              return <Error />;
            }
            break;
        }
      } else if (typeof permission === 'object') {
        let has_permission = false;

        for (let i = 0; i < permission.length; i++) {
          switch (permission[i]) {
            case 'ADMIN':
              if (account_data?.admin) {
                has_permission = true;
              }
              break;
            case 'DOCTOR':
              if (account_data?.doctor) {
                has_permission = true;
              }
              break;
            case 'CLINIC':
              if (account_data?.clinic) {
                has_permission = true;
              }
              break;
          }
        }

        if (!has_permission) {
          return <Error />;
        }
      }
    }
  }
  return children;
};
