import gql from 'graphql-tag';

export const GET_DOCTORS_APPOINTMENTS = gql`
  query GetDoctorsAppointments($doctors: [ID]!, $offices: [ID]!, $start: String!, $end: String!) {
    adminRoute {
      getDoctorsAppointments(doctors: $doctors, offices: $offices, start: $start, end: $end) {
        appointments {
          id
          date
          start_hour
          end_hour
          status
          patient_alias
          patient_phone
          patient_email
          patient_not_shown
          holiday_name
          note
          calendar {
            doctor_id
            office_id
          }
          patient {
            id
            name
            surname
          }
          treatment {
            id
            name
          }
        }
        hours {
          start
          end
          office_id
        }
      }
    }
  }
`;

export const LIST_APPOINTMENTS = gql`
  query listAppointmentsHistory($filters: mixedFilters) {
    adminRoute {
      listAppointmentsHistory(filters: $filters) {
        rows {
          id
          date
          start_hour
          end_hour
          status
          patient_alias
          patient_phone
          patient_email
          patient_not_shown
          holiday_name
          compiled
          note
          calendar {
            doctor_id
            office_id
            doctor {
              name
              surname
            }
            office {
              name
              city {
                name
              }
            }
          }
          patient {
            id
            name
            surname
            email
            phone
            phone_country {
              dial_code
            }
          }
          treatment {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_DOCTORS_APPOINTMENTS_TO_PRINT = gql`
  query getDoctorsAppointmentsToPrint($doctors: [ID]!, $offices: [ID]!, $start: String!, $end: String!) {
    adminRoute {
      getDoctorsAppointmentsToPrint(doctors: $doctors, offices: $offices, start: $start, end: $end) {
        id
        date
        start_hour
        end_hour
        status
        patient_alias
        patient_phone
        patient_email
        patient_not_shown
        holiday_name
        note
        calendar {
          doctor_id
          office_id
          office {
            id
            name
          }
          doctor {
            name
            surname
          }
        }
        patient {
          id
          name
          surname
          email
          phone
          phone_country {
            dial_code
          }
        }
        treatment {
          id
          name
          doctor_treatment {
            id
            price
            time
          }
        }
      }
    }
  }
`;

export const GET_DOCTOR_HOURS = gql`
  query GetDoctorHours($start: String!, $end: String!) {
    adminRoute {
      getDoctorHours(start: $start, end: $end) {
        day
        office_id
      }
    }
  }
`;

export const CREATE_BLOCK = gql`
  query createBlock($block_data: BlockInput!) {
    adminRoute {
      createBlock(block_data: $block_data) {
        response
        responseStatus
      }
    }
  }
`;

export const CREATE_HOLIDAYS_BLOCKS = gql`
  query createHolidaysBlocks($office_id: ID!, $year: Int!) {
    adminRoute {
      createHolidaysBlocks(office_id: $office_id, year: $year) {
        id
        date
        holiday_name
      }
    }
  }
`;

export const DELETE_BLOCK = gql`
  query deleteBlock($block_id: ID!) {
    adminRoute {
      deleteBlock(block_id: $block_id) {
        response
        responseStatus
      }
    }
  }
`;

export const CREATE_APPOINTMENT = gql`
  query createAppointment($appointment_data: AppointmentDataInput!) {
    adminRoute {
      createAppointment(appointment_data: $appointment_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_APPOINTMENT = gql`
  query updateAppointment($appointment_id: ID!, $appointment_data: AppointmentDataInput!) {
    adminRoute {
      updateAppointment(appointment_id: $appointment_id, appointment_data: $appointment_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_APPOINTMENT_STATUS = gql`
  query updateAppointmentStatus($appointment_id: ID!, $status: String!) {
    adminRoute {
      updateAppointmentStatus(appointment_id: $appointment_id, status: $status) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_APPOINTMENT_TIME = gql`
  query updateAppointmentTime($appointment_id: ID!, $appointment_data: AppointmentDataInput!) {
    adminRoute {
      updateAppointmentTime(appointment_id: $appointment_id, appointment_data: $appointment_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_APPOINTMENT_COMPILE_STATUS = gql`
  query updateAppointmentCompileStatus($appointment_id: ID!, $appointment_data: AppointmentDataInput!) {
    adminRoute {
      updateAppointmentCompileStatus(appointment_id: $appointment_id, appointment_data: $appointment_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_APPOINTMENT = gql`
  query deleteAppointment($appointment_id: ID!) {
    adminRoute {
      deleteAppointment(appointment_id: $appointment_id) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_WAITING_LIST_BLOCK = gql`
  query deleteAppointment($appointment_id: ID!) {
    adminRoute {
      deleteWaitingListBlock(appointment_id: $appointment_id) {
        response
        responseStatus
      }
    }
  }
`;

export const GET_APPOINTMENT = gql`
  query getAppointment($appointment_id: ID!) {
    adminRoute {
      getAppointment(appointment_id: $appointment_id) {
        id
        date
        start_hour
        end_hour
        calendar_id
        treatment_id
        patient_id
        patient_alias
        patient_phone
        patient_email
        patient_not_shown
        note
        note_public
        compiled
        status
        patient {
          id
          uuid
          name
          surname
          dial_code_country
          phone_country {
            dial_code
          }
          phone
          email
          profile_pic
        }
        treatment {
          id
          name
        }
        calendar {
          doctor_id
          doctor {
            id
            name
            surname
          }
          office {
            id
            name
            city {
              id
              name
            }
          }
        }
        doctor_treatment {
          id
          price
          time
        }
        products {
          id
          appointment_id
          note
          stamp
          product_id
          product {
            name
          }
          target_id
          target {
            name
          }
          quantity
          technique_id
          technique {
            name
          }
          device_id
          device {
            name
          }
        }
        suggested_products {
          id
          uuid
          name
          brand {
            name
          }
          media {
            id
            uuid
            media_link
          }
        }
      }
    }
  }
`;

export const GET_CALENDARS = gql`
  query listCalendarsAdmin($doctor_id: ID) {
    adminRoute {
      listCalendarsAdmin(doctor_id: $doctor_id) {
        id
        office_id
        doctor_id
        doctor {
          id
          name
          surname
        }
        office {
          id
          name
          city {
            id
            name
          }
        }
        calendar_treatments {
          id
          calendar_id
          doctor_treatment_id
          doctor_treatment {
            id
            doctor_id
            treatment_id
          }
        }
      }
    }
  }
`;

export const UPLOAD_STAMP = gql`
  query uploadStamp($contentType: String!, $filename: String!, $folder: String!) {
    adminRoute {
      uploadStamp(contentType: $contentType, filename: $filename, folder: $folder) {
        presignedUrl
        key
      }
    }
  }
`;

export const LIST_NEAREST_APPOINTMENTS = gql`
  query listNearestAppointments($filters: mixedFilters) {
    adminRoute {
      listNearestAppointments(filters: $filters) {
        count
        rows {
          id
          calendar_id
          treatment_id
          patient_id
          patient_alias
          patient_phone
          patient_email
          patient_not_shown
          date
          start_hour
          end_hour
          note
          status
          patient {
            id
            name
            surname
            dial_code_country
            phone_country {
              dial_code
            }
            phone
            email
            profile_pic
          }
          treatment {
            id
            name
          }
          calendar {
            doctor_id
            doctor {
              id
              name
              surname
            }
            office {
              id
              name
              city {
                id
                name
              }
            }
          }

          doctor_treatment {
            id
            price
            time
          }
        }
      }
    }
  }
`;
export const LIST_DAILY_APPOINTMENTS = gql`
  query listDailyAppointments($filters: mixedFilters) {
    adminRoute {
      listDailyAppointments(filters: $filters) {
        count
        rows {
          id
          calendar_id
          treatment_id
          patient_id
          patient_alias
          patient_phone
          patient_email
          patient_not_shown
          date
          start_hour
          end_hour
          note
          status
          patient {
            id
            name
            surname
            dial_code_country
            phone_country {
              dial_code
            }
            phone
            email
            profile_pic
          }
          treatment {
            id
            name
          }
          calendar {
            doctor_id
            doctor {
              id
              name
              surname
            }
            office {
              id
              name
              city {
                id
                name
              }
            }
          }

          doctor_treatment {
            id
            price
            time
          }
        }
      }
    }
  }
`;

export const LIST_USER_APPOINTMENTS = gql`
  query listUserAppointments($user_uuid: String!, $type: String) {
    adminRoute {
      listUserAppointments(user_uuid: $user_uuid, type: $type) {
        rows {
          id
          calendar_id
          treatment_id
          patient_id
          patient_alias
          patient_phone
          patient_email
          patient_not_shown
          date
          start_hour
          end_hour
          note
          note_public
          compiled
          status
          patient {
            id
            name
            surname
            dial_code_country
            phone_country {
              dial_code
            }
            phone
            email
            profile_pic
          }
          treatment {
            id
            name
          }
          calendar {
            doctor_id
            doctor {
              id
              name
              surname
            }
            office {
              id
              name
              city {
                id
                name
              }
            }
          }
          doctor_treatment {
            id
            price
            time
          }
        }
      }
    }
  }
`;

export const LIST_APPOINTMENT_PRODUCTS_ADMIN = gql`
  query listAppointmentProductsAdmin($appointment_id: ID!) {
    adminRoute {
      listCalendarAppointmentProductsAdmin(appointment_id: $appointment_id) {
        id
        appointment_id
        product_id
        target_id
        quantity
        product_id
        technique_id
      }
    }
  }
`;

export const CREATE_APPOINTMENT_PRODUCT_ADMIN = gql`
  query createCalendarAppointmentProductAdmin($appointment_product_data: CalendarAppointmentProductInput!) {
    adminRoute {
      createCalendarAppointmentProductAdmin(appointment_product_data: $appointment_product_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_APPOINTMENT_PRODUCT_ADMIN = gql`
  query updateProductAdmin($appointment_product_data: CalendarAppointmentProductInput!, $appointment_product_id: ID!) {
    adminRoute {
      updateCalendarAppointmentProductAdmin(
        appointment_product_data: $appointment_product_data
        appointment_product_id: $appointment_product_id
      ) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_APPOINTMENT_PRODUCT_ADMIN = gql`
  query deleteProductAdmin($appointment_product_id: ID!) {
    adminRoute {
      deleteCalendarAppointmentProductAdmin(appointment_product_id: $appointment_product_id) {
        response
        responseStatus
      }
    }
  }
`;

export const ADD_APPOINTMENT_SUGGESTED_PRODUCTS = gql`
  query addCalendarAppointmentSuggestedProduct($appointment_id: ID!, $product_uuid: String!) {
    adminRoute {
      addCalendarAppointmentSuggestedProduct(appointment_id: $appointment_id, product_uuid: $product_uuid) {
        response
        responseStatus
      }
    }
  }
`;

export const REMOVE_APPOINTMENT_SUGGESTED_PRODUCTS = gql`
  query removeCalendarAppointmentSuggestedProduct($appointment_id: ID!, $product_uuid: String!) {
    adminRoute {
      removeCalendarAppointmentSuggestedProduct(appointment_id: $appointment_id, product_uuid: $product_uuid) {
        response
        responseStatus
      }
    }
  }
`;

export const LIST_TIMES_DOCTOR = gql`
  query listTimesDoctor($office_id: ID, $exception: Boolean) {
    adminRoute {
      listTimesDoctor(office_id: $office_id, exception: $exception) {
        id
        calendar_id
        day
        intervals {
          id
          calendar_day_id
          group_uuid
          start_hour
          end_hour
          repeat_every
          start_date
          end_date
        }
      }
    }
  }
`;

export const CREATE_TIME_DOCTOR = gql`
  query createTimeDoctor($time_data: TimeDoctorInput!) {
    adminRoute {
      createTimeDoctor(time_data: $time_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_TIME_DOCTOR = gql`
  query updateTimeDoctor($time_data: TimeDoctorInput!, $time_group_uuid: String!) {
    adminRoute {
      updateTimeDoctor(time_data: $time_data, time_group_uuid: $time_group_uuid) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_TIME_DOCTOR = gql`
  query deleteTimeDoctor($group_uuid: ID!) {
    adminRoute {
      deleteTimeDoctor(group_uuid: $group_uuid) {
        response
        responseStatus
      }
    }
  }
`;

export const CREATE_EXCEPTION_TIME_DOCTOR = gql`
  query createExceptionTimeDoctor($exception_data: TimeExceptionDoctorInput!) {
    adminRoute {
      createExceptionTimeDoctor(exception_data: $exception_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_EXCEPTION_TIME_DOCTOR = gql`
  query updateExceptionTimeDoctor($exception_id: ID!, $exception_data: TimeExceptionDoctorInput!) {
    adminRoute {
      updateExceptionTimeDoctor(exception_id: $exception_id, exception_data: $exception_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_EXCEPTION_TIME_DOCTOR = gql`
  query deleteExceptionTimeDoctor($exception_id: ID!) {
    adminRoute {
      deleteExceptionTimeDoctor(exception_id: $exception_id) {
        response
        responseStatus
      }
    }
  }
`;

export const SET_PATIENT_NOT_SHOWN = gql`
  query setPatientNotShown($appointment_id: ID!) {
    adminRoute {
      setPatientNotShown(appointment_id: $appointment_id) {
        response
        responseStatus
      }
    }
  }
`;

export const LIST_WAITING_LIST_ADMIN = gql`
  query listWaitingListAdmin($filters: mixedFilters) {
    adminRoute {
      listWaitingListAdmin(filters: $filters) {
        oldest_date
        count
        rows {
          id
          createdAt
          patient {
            id
            name
            surname
            phone
            email
            phone_country {
              dial_code
            }
          }
          calendar {
            office {
              id
              name
              city {
                id
                name
              }
            }
            doctor {
              id
              name
              surname
            }
          }
          treatment {
            name
          }
        }
      }
    }
  }
`;

export const DELETE_WAITING_LIST_ADMIN = gql`
  query deleteWaitingListAdmin($waiting_list_id: ID!) {
    adminRoute {
      deleteWaitingListAdmin(waiting_list_id: $waiting_list_id) {
        response
        responseStatus
      }
    }
  }
`;
