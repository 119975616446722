import { useAuthentication } from '../../../utils/useAuthentication';
import React, { useCallback, useEffect, useState } from 'react';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import './custom-treatments.css';
import { IconEdit, IconCheckbox, IconPlus } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import ModalTreatmentEditAdmin from './modalTreatmentEditAdmin';
import ModalTreatmentEditDoctor from './modalTreatmentEditDoctor';

const TreatmentsManageView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [treatments, setTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const { onListTreatmentsAdmin } = useTreatments();

  useEffect(() => {
    onListTreatmentsAdmin().then((res) => {
      setTreatments(res);
    });
  }, []);

  const handleModalClose = useCallback(() => {
    onListTreatmentsAdmin().then((res) => {
      setTreatments(res);
      setEditMode(false);
    });
  }, []);

  return (
    <Box className="treatments-view" w={'100%'} h={'100%'} overflow={'auto'}>
      {user?.admin ? (
        <>
          <Box
            position={'fixed'}
            display={'flex'}
            right={10}
            bottom={10}
            bgcolor={'#2c3e50'}
            p={'10px'}
            borderRadius={'100%'}
            w={'50px'}
            h={'50px'}
            color={'white'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => {
              setSelectedTreatment(null);
              setEditMode(true);
            }}
          >
            <IconPlus stroke={3} size="2rem" />
          </Box>
          <ModalTreatmentEditAdmin
            modalOpen={editMode}
            handleModalClose={handleModalClose}
            treatment={selectedTreatment}
            setEditMode={setEditMode}
          />
        </>
      ) : (
        <ModalTreatmentEditDoctor
          modalOpen={editMode}
          handleModalClose={handleModalClose}
          treatment={selectedTreatment}
          setEditMode={setEditMode}
        />
      )}

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Trattamenti
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">{user?.admin ? <>Attivo per i dottori</> : <>Prenotabile</>}</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {treatments?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'}>
                  {row.name}
                </TableCell>
                {!user?.admin ? (
                  <TableCell align="center">{row?.doctor_treatment && row?.doctor_treatment?.active && <IconCheckbox />}</TableCell>
                ) : (
                  <TableCell align="center">{row?.active && <IconCheckbox />}</TableCell>
                )}
                <TableCell align="center">
                  <IconEdit
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedTreatment(row);
                      setEditMode(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default TreatmentsManageView;
