import { Box } from '@chakra-ui/react';
import { Card, CardActions, CardContent, CardMedia } from '@mui/material';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useProtocols } from '../../../hooks/Protocols/useProtocols';
import { useShop } from '../../../hooks/Shop/useShop';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UIPageTitle } from '../../utilities/Headers';
import { UIFormControlBox } from '../../utilities/Form';
import { UIInput, UITextarea } from '../../utilities/Input';
import UITypography from '../../utilities/Typography';
import { UIButton } from '../../utilities/Button';
import UIInputAutocomplete from '../../utilities/InputSelect';

const ModalSurveyStepEdit = ({ modalOpen, handleModalClose, handleRefreshData, protocol_uuid, step_data, step, time }) => {
  let [query, setQuery] = useState('');
  let [products, setProducts] = useState([]);
  let [stepData, setStepData] = useState(false);
  let [productsToAdd, setProductsToAdd] = useState([]);
  let [selectedProducts, setSelectedProducts] = useState([]);

  const { onDeleteProtocolProduct, onAddProtocolProduct, onCreateProtocolStep, onUpdateProtocolStep } = useProtocols();
  const { onListShopProductsByQuery, onListShopProductsByUuids } = useShop();

  const getProducts = async () => {
    onListShopProductsByQuery({ query, onlyActive: true }).then((res) => {
      setProducts(res);
    });
  };

  useEffect(() => {
    if (query.length > 2 || query.length === 0) {
      getProducts();
    }
  }, [query]);

  useEffect(() => {
    if (!stepData?.uuid) {
      onListShopProductsByUuids({ products_uuids: productsToAdd }).then((res) => {
        setSelectedProducts(res);
      });
    }
  }, [productsToAdd]);

  useEffect(() => {
    setStepData(step_data);
  }, [step_data]);

  const handleAddProduct = async (product_uuid) => {
    if (product_uuid) {
      if (!stepData?.uuid) {
        setProductsToAdd([...productsToAdd, product_uuid]);
      } else {
        await onAddProtocolProduct({
          step_uuid: stepData?.uuid,
          product_uuid
        });
        handleRefreshData();
      }
    }
  };

  const handleDeleteProduct = async (product_uuid) => {
    if (!stepData?.uuid) {
      setProductsToAdd(productsToAdd.filter((item) => item !== product_uuid));
    } else {
      await onDeleteProtocolProduct({
        step_uuid: stepData?.uuid,
        product_uuid
      });
      handleRefreshData();
    }
  };

  const internalHandleModalClose = () => {
    setSelectedProducts([]);
    setProductsToAdd([]);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { title, note } = values;

    if (stepData?.uuid) {
      onUpdateProtocolStep({
        step_uuid: stepData?.uuid,
        step_data: {
          title,
          note,
          step,
          time
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          internalHandleModalClose();
        }
      });
    } else {
      onCreateProtocolStep({
        protocol_uuid,
        step_data: {
          title,
          note,
          step,
          time
        }
      }).then(async (res) => {
        if (res?.response) {
          for (let product of selectedProducts) {
            await onAddProtocolProduct({
              step_uuid: res?.new_uuid,
              product_uuid: product.uuid
            });
          }

          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          internalHandleModalClose();
        }
      });
    }
  };

  return (
    <UIModal open={modalOpen} onClose={internalHandleModalClose} allowFullScreen={true} className={'modal'}>
      <UiModalInternal onClose={internalHandleModalClose} isLoading={step_data && stepData === null}>
        <Formik
          initialValues={{
            title: stepData?.title ?? '',
            note: stepData?.note ?? ''
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Il titolo è obbligatorio'),
            note: Yup.string().nullable()
          })}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({ errors, handleSubmit, touched, values, setFieldValue, submitForm }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <UIPageTitle
                  title={`${stepData?.uuid ? 'Modifica' : 'Aggiungi'} Step ${(stepData?.time ?? time) === 'day' ? 'Giorno' : 'Notte'}`}
                />

                <UIFormControlBox label={'Titolo'} error={Boolean(touched.title && errors.title)}>
                  <UIInput value={values?.title} onChange={() => setFieldValue('title', event.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Nota'} error={Boolean(touched.note && errors.note)}>
                  <UITextarea value={values?.note} onChange={(event) => setFieldValue('note', event.target.value)} resize={'vertical'} />
                </UIFormControlBox>

                <UIFormControlBox label={'Prodotti dello step'}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px'
                    }}
                  >
                    {stepData?.products?.map((product, index) => (
                      <Card
                        key={index}
                        sx={{
                          width: '48%',
                          alignSelf: 'stretch',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          pb: '10px',
                          overflow: 'visible',
                          flexWrap: 'wrap'
                        }}
                      >
                        <CardContent
                          sx={{
                            p: 1
                          }}
                        >
                          <UITypography title={product?.brand?.[0]?.name} size={'12px'} sxCustom={{ color: '#101828' }} />
                          <UITypography variant={'Oxygen300'} title={product?.name} size={'12px'} sxCustom={{ color: '#101828' }} />
                        </CardContent>

                        <CardMedia
                          sx={{ height: 100, width: '100%', backgroundSize: 'contain' }}
                          image={product?.media?.[0]?.media_link}
                          title="green iguana"
                        />

                        <CardActions sx={{ p: '5px 0 0 0', justifyContent: 'center' }}>
                          <UIButton
                            type={'button'}
                            variant={'cancel'}
                            label={<IconTrash stroke={1.64} size={'25px'} />}
                            onClick={() => handleDeleteProduct(product?.uuid)}
                          />
                        </CardActions>
                      </Card>
                    ))}

                    {selectedProducts?.map((product, index) => (
                      <Card
                        key={index}
                        sx={{
                          width: '48%',
                          alignSelf: 'stretch',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          pb: '10px',
                          overflow: 'visible',
                          flexWrap: 'wrap'
                        }}
                      >
                        <CardContent
                          sx={{
                            p: 1
                          }}
                        >
                          <UITypography title={product?.brand?.[0]?.name} size={'12px'} sxCustom={{ color: '#101828' }} />
                          <UITypography variant={'Oxygen300'} title={product?.name} size={'12px'} sxCustom={{ color: '#101828' }} />
                        </CardContent>

                        <CardMedia
                          sx={{ height: 100, width: '100%', backgroundSize: 'contain' }}
                          image={product?.media?.[0]?.media_link}
                          title="green iguana"
                        />
                        <CardActions sx={{ p: '5px 0 0 0', justifyContent: 'center' }}>
                          <UIButton
                            type={'button'}
                            variant={'cancel'}
                            label={<IconTrash stroke={1.64} size={'25px'} />}
                            onClick={() => handleDeleteProduct(product?.uuid)}
                          />
                        </CardActions>
                      </Card>
                    ))}
                  </Box>
                </UIFormControlBox>

                <UIFormControlBox label={'Aggiungi un prodotto'}>
                  <UIInputAutocomplete
                    options={products?.map((product) => {
                      return {
                        id: product?.uuid,
                        label: `${product?.brand?.[0]?.name ?? 'No brand'} - ${product?.name}`
                      };
                    })}
                    value={values?.product}
                    setValue={(data) => {
                      setFieldValue('product', data?.id ?? '');
                    }}
                    label={''}
                    sx={{
                      mr: '10px'
                    }}
                    onKeyUp={(e) => {
                      setQuery(e.target.value);
                    }}
                  />

                  <UIButton
                    variant={'confirm'}
                    label={'Aggiungi prodotto'}
                    onClick={() => {
                      handleAddProduct(values?.product);
                    }}
                    sxCustom={{
                      mt: '10px'
                    }}
                  />
                </UIFormControlBox>
              </UIModalBody>
              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()} icon={<IconCheck stroke={1.5} size="30px" />} variant={'confirm'} />
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};

export default ModalSurveyStepEdit;
