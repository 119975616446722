// assets
import { IconVaccine } from '@tabler/icons-react';
// constant
const icons = { IconVaccine };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const treatments = {
  id: 'treatments',
  type: 'group',
  permission: ['ADMIN', 'DOCTOR'],
  children: [
    {
      id: 'treatments_list',
      title: 'Trattamenti',
      type: 'collapse',
      icon: icons.IconVaccine,
      breadcrumbs: false,
      url: '/treatments',
      permission: ['DOCTOR']
    },
    {
      id: 'treatments_list',
      title: 'Trattamenti',
      type: 'collapse',
      icon: icons.IconVaccine,
      breadcrumbs: false,
      permission: ['ADMIN'],
      children: [
        {
          id: 'treatments_data',
          title: 'Trattamenti',
          type: 'item',
          url: '/treatments/manage',
          breadcrumbs: false,
          permission: ['ADMIN', 'DOCTOR']
        }
      ]
    }
  ]
};

export default treatments;
