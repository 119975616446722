import gql from 'graphql-tag';

export const LIST_SURVEYS_ADMIN = gql`
  query listSurveysAdmin($status: String!, $query: String) {
    adminRoute {
      listSurveysAdmin(status: $status, query: $query) {
        id
        uuid
        createdAt
        patient {
          name
          surname
        }
      }
    }
  }
`;

export const LIST_SURVEYS_ADMIN_PAGINATED = gql`
  query listSurveysAdminPaginated($filters: mixedFilters) {
    adminRoute {
      listSurveysAdminPaginated(filters: $filters) {
        rows {
          id
          uuid
          createdAt
          updatedAt
          completedAt
          patient {
            name
            surname
            email
          }
        }
      }
    }
  }
`;

export const GET_SURVEY = gql`
  query getSurvey($survey_uuid: String!) {
    adminRoute {
      getSurvey(survey_uuid: $survey_uuid) {
        id
        uuid
        steps
        cheeks
        cheeks_dry
        skin_dry_hours
        products_reaction
        products_reaction_description
        acne
        inflammation
        skin_sun_hours
        sun_stains
        city_pollution
        wrinkles
        brightness
        flexibility
        wrinkles_upgrade
        eye_area
        comfort
        sebo
        skin_products_note
        note
        update_note
        status
        createdAt
        by_skinboost
        targets {
          key
          value
        }
        media {
          id
          media_link
          for_update
        }
        skin_tone {
          id
          color
        }
        patient {
          name
          surname
          age
          pregnant
          sex
          permanent_makeup
        }
        allergies {
          name
        }
        skin_problems {
          name
        }
        skin_products {
          name
        }
        order {
          uuid
          invoice_link
        }
        protocol {
          id
          uuid
          title
          subtitle
          description
          ingredients
          custom_duration
          steps {
            id
            uuid
            title
            note
            step
            time
            products {
              id
              uuid
              name
              description
              brand {
                name
              }
              media {
                id
                uuid
                media_link
              }
            }
          }
        }
      }
    }
  }
`;

export const UPLOAD_SURVEY_INVOICE = gql`
  query uploadSurveyInvoice($survey_order_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadSurveyInvoice(survey_order_uuid: $survey_order_uuid, filename: $filename, contentType: $contentType) {
        presignedUrl
        key
      }
    }
  }
`;

//! PROTOCOLS
export const LIST_DEFAULT_PROTOCOLS = gql`
  query listDefaultProtocols {
    adminRoute {
      listDefaultProtocols {
        id
        uuid
        title
        subtitle
        description
        ingredients
        custom_duration
        steps {
          id
          uuid
          title
          step
          time
          products {
            id
            uuid
            name
            description
            media {
              id
              uuid
              media_link
            }
          }
        }
      }
    }
  }
`;

export const GET_PROTOCOL_ADMIN = gql`
  query getProtocolAdmin($protocol_uuid: String!) {
    adminRoute {
      getProtocolAdmin(protocol_uuid: $protocol_uuid) {
        id
        uuid
        cover
        title
        subtitle
        description
        ingredients
        custom_duration
        steps {
          id
          uuid
          title
          step
          time
          products {
            id
            uuid
            name
            description
            media {
              id
              uuid
              media_link
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PROTOCOL = gql`
  query createProtocol($protocol_data: ProtocolInput!) {
    adminRoute {
      createProtocol(protocol_data: $protocol_data) {
        response
        responseStatus
      }
    }
  }
`;
export const UPLOAD_PROTOCOL_COVER = gql`
  query uploadProtocolCover($protocol_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadProtocolCover(protocol_uuid: $protocol_uuid, filename: $filename, contentType: $contentType) {
        presignedUrl
        key
      }
    }
  }
`;

export const UPDATE_PROTOCOL = gql`
  query updateProtocol($protocol_uuid: String!, $protocol_data: ProtocolInput!) {
    adminRoute {
      updateProtocol(protocol_uuid: $protocol_uuid, protocol_data: $protocol_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_PROTOCOL = gql`
  query deleteProtocol($protocol_uuid: String!) {
    adminRoute {
      deleteProtocol(protocol_uuid: $protocol_uuid) {
        response
        responseStatus
      }
    }
  }
`;

export const CONFIRM_PROTOCOL = gql`
  query confirmProtocol($protocol_uuid: String!) {
    adminRoute {
      confirmProtocol(protocol_uuid: $protocol_uuid) {
        response
        responseStatus
      }
    }
  }
`;

//! PROTOCOLS STEPS
export const CREATE_PROTOCOL_STEP = gql`
  query createProtocolStep($protocol_uuid: String!, $step_data: ProtocolStepInput!) {
    adminRoute {
      createProtocolStep(protocol_uuid: $protocol_uuid, step_data: $step_data) {
        response
        responseStatus
        new_uuid
      }
    }
  }
`;

export const UPDATE_PROTOCOL_STEP = gql`
  query updateProtocolStep($step_uuid: String!, $step_data: ProtocolStepInput!) {
    adminRoute {
      updateProtocolStep(step_uuid: $step_uuid, step_data: $step_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_PROTOCOL_STEP = gql`
  query deleteProtocolStep($step_uuid: String!) {
    adminRoute {
      deleteProtocolStep(step_uuid: $step_uuid) {
        response
        responseStatus
      }
    }
  }
`;

//! PROTOCOLS STEPS PRODUCTS
export const ADD_PROTOCOL_PRODUCT = gql`
  query addProtocolProduct($product_uuid: String!, $step_uuid: String!) {
    adminRoute {
      addProtocolProduct(product_uuid: $product_uuid, step_uuid: $step_uuid) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_PROTOCOL_PRODUCT = gql`
  query deleteProtocolProduct($product_uuid: String!, $step_uuid: String!) {
    adminRoute {
      deleteProtocolProduct(product_uuid: $product_uuid, step_uuid: $step_uuid) {
        response
        responseStatus
      }
    }
  }
`;
