import { Box, Image, Textarea } from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import { IconAt, IconBulb, IconCheck, IconColorFilter, IconPhoto, IconSignature, IconX } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

const ModalDoctorAdmin = ({ modalOpen, handleModalClose, doctor, setEditMode }) => {
  const [loadingProfilePic, setLoadingProfilePic] = React.useState(false);
  const [uuid, setUuid] = React.useState(uuidv4());

  const { onUpdateDoctorAdmin, onCreateDoctorAdmin } = useAccounts();

  useEffect(() => {
    setUuid(uuidv4());
  }, [doctor, modalOpen]);

  const handleSubmit = async (values) => {
    const { name, surname, sex, email, profile_pic, doctor_specialization, instagram_link, formation, about_me, active } = values;

    if (doctor?.id) {
      onUpdateDoctorAdmin({
        doctor_id: doctor.id,
        doctor_data: {
          name,
          surname,
          sex,
          email,
          profile_pic,
          doctor_specialization,
          instagram_link,
          formation,
          about_me,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateDoctorAdmin({
        doctor_data: {
          uuid,
          name,
          surname,
          sex,
          email,
          profile_pic,
          doctor_specialization,
          instagram_link,
          formation,
          about_me,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Formik
            initialValues={{
              name: doctor?.name ?? '',
              surname: doctor?.surname ?? '',
              sex: doctor?.sex ?? '',
              email: doctor?.email ?? '',
              profile_pic: doctor?.profile_pic ?? '',
              doctor_specialization: doctor?.doctor_specialization ?? '',
              instagram_link: doctor?.instagram_link ?? '',
              formation: doctor?.formation ?? '',
              about_me: doctor?.about_me ?? '',
              active: doctor?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Il nome è obbligatorio'),
              surname: Yup.string().required('La descrizione è obbligatoria'),
              sex: Yup.string().required('Il sesso è obbligatorio'),
              email: Yup.string().required('La mail è obbligatoria'),
              profile_pic: Yup.string().nullable(),
              doctor_specialization: Yup.string().nullable(),
              instagram_link: Yup.string().nullable(),
              formation: Yup.string().nullable(),
              about_me: Yup.string().nullable(),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati Dottore
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <InputLabel htmlFor="input-name">Nome</InputLabel>
                            <OutlinedInput
                              id="input-name"
                              type="text"
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('name', event.target.value)}
                              label="Nome"
                            />
                            {touched.name && errors.name && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.surname && errors.surname)}>
                            <InputLabel htmlFor="input-surname">Cognome</InputLabel>
                            <OutlinedInput
                              id="input-surname"
                              type="text"
                              value={values.surname}
                              name="surname"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('surname', event.target.value)}
                              label="Cognome"
                            />
                            {touched.surname && errors.surname && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.surname}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} w={'100%'}>
                        <IconColorFilter stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.sex && errors.sex)}>
                            <InputLabel id="sex-label">Sesso</InputLabel>
                            <Select
                              name={'sex'}
                              fullWidth={true}
                              value={values?.sex}
                              label="Sesso"
                              labelId="sex-label"
                              onChange={(e) => {
                                setFieldValue('sex', e.target.value);
                              }}
                              onBlur={handleBlur}
                              autoWidth
                            >
                              <MenuItem value={'M'}>Uomo</MenuItem>
                              <MenuItem value={'F'}>Donna</MenuItem>
                              <MenuItem value={'Other'}>Altro</MenuItem>
                            </Select>
                            {touched.sex && errors.sex && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.sex}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconAt stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                            <InputLabel htmlFor="input-surname">Email</InputLabel>
                            <OutlinedInput
                              id="input-email"
                              type="text"
                              value={values.email}
                              name="email"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('email', event.target.value)}
                              label="Email"
                            />
                            {touched.email && errors.email && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.email}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhoto stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.profile_pic && errors.profile_pic)} w={'100%'}>
                            {loadingProfilePic && <>Loading</>}
                            <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'} mb={1} ml={1}>
                              Immagine profilo
                            </Typography>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                              <Image src={values.profile_pic} w={'100px'} h={'100px'} objectFit={'cover'} />
                              <DropzoneFormikInput
                                variant={'profile_pic'}
                                setFieldValue={setFieldValue}
                                field={'profile_pic'}
                                setLoading={setLoadingProfilePic}
                                folder={'profile_pic'}
                                label={'Carica'}
                                extra_data={{
                                  account_uuid: doctor?.uuid ?? uuid
                                }}
                              />
                            </Box>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.doctor_specialization && errors.doctor_specialization)}>
                            <InputLabel htmlFor="input-doctor_specialization">Specializzazione</InputLabel>
                            <OutlinedInput
                              id="input-doctor_specialization"
                              type="text"
                              value={values.doctor_specialization}
                              name="doctor_specialization"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('doctor_specialization', event.target.value)}
                              label="Specializzazione"
                              inputProps={{
                                maxLength: 45
                              }}
                            />
                            {touched.doctor_specialization && errors.doctor_specialization && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.doctor_specialization}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.instagram_link && errors.instagram_link)}>
                            <InputLabel htmlFor="input-instagram_link">Link instagram</InputLabel>
                            <OutlinedInput
                              id="input-instagram_link"
                              type="text"
                              value={values.instagram_link}
                              name="instagram_link"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('instagram_link', event.target.value)}
                              label="Link Instagram"
                            />
                            {touched.instagram_link && errors.instagram_link && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.instagram_link}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.formation && errors.formation)} w={'100%'}>
                            <Typography id="modal-formation" variant="body1" fontWeight={'bold'}>
                              Formazione
                            </Typography>
                            <Textarea
                              id="input-formation"
                              value={values.formation}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('formation', event.target.value)}
                              label="Formazione"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Formazione'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.formation ? '1px solid red' : null}
                            />
                            {touched.description && errors.description && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.description}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.about_me && errors.about_me)} w={'100%'}>
                            <Typography id="modal-about_me" variant="body1" fontWeight={'bold'}>
                              Su di me
                            </Typography>
                            <Textarea
                              id="input-about_me"
                              value={values.about_me}
                              name="about_me"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('about_me', event.target.value)}
                              label="Su di me"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Su di me'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.about_me ? '1px solid red' : null}
                            />
                            {touched.description && errors.description && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.description}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.active}
                                  onChange={(event) => setFieldValue('active', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Attivo</Typography>}
                            />
                            {touched.active && errors.active && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.active}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      {errors.general && (
                        <FormHelperText
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '10px',
                            fontSize: '16px',
                            color: '#f44336'
                          }}
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.general}
                        </FormHelperText>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'}>
                        <IconX stroke={1.5} size="1rem" onClick={() => setEditMode(false)} />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};
export default ModalDoctorAdmin;
