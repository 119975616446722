import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const UIInputAutocomplete = ({
  value,
  setValue,
  options,
  onTextValueChange = () => {},
  isLoading = false,
  label = null,
  sx = {},
  needTimeout = true,
  needTimeoutText = true,
  ...rest
}) => {
  // Select
  const [internalValue, setInternalValue] = useState(null);
  const [internalTimeout, setInternalTimeout] = useState(null);

  //Text
  const [internalValueText, setInternalValueText] = useState(null);
  const [internalTimeoutText, setInternalTimeoutText] = useState(null);

  useEffect(() => {
    if (!needTimeout) {
      if (!setValue) return;
      setValue(internalValue);
    }
    if (internalTimeout) {
      clearTimeout(internalTimeout);
    }
    setInternalTimeout(
      setTimeout(() => {
        if (!setValue) return;
        setValue(internalValue);
      }, 500)
    );
  }, [internalValue]);

  useEffect(() => {
    if (!needTimeoutText) {
      if (!setValue) return;
      onTextValueChange(internalValueText);
    }
    if (internalTimeoutText) {
      clearTimeout(internalTimeoutText);
    }
    setInternalTimeoutText(
      setTimeout(() => {
        if (!setValue) return;
        onTextValueChange(internalValueText);
      }, 300)
    );
  }, [internalValueText]);

  useEffect(() => {
    if (!value) return;
    setInternalValue({
      id: value,
      label: options?.find((option) => option?.id === value)?.label
    });
  }, []);

  if (!options) return <></>;

  return (
    <Autocomplete
      value={internalValue?.id ? internalValue : null}
      disabled={isLoading}
      fullWidth={true}
      sx={{
        mr: '10px'
      }}
      options={options?.map((value) => {
        return {
          id: value?.id,
          label: value?.label
        };
      })}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      onChange={(e, value) => {
        setInternalValue({
          id: value?.id,
          label: value?.label
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? ''}
          sx={sx}
          onChange={(e) => {
            setInternalValueText(e.target.value);
          }}
        />
      )}
      {...rest}
    />
  );
};

export const UIInputSelect = ({ value, name = null, onChange = () => {}, label = '', fullWidth = false, options }) => {
  return (
    <Select name={name} fullWidth={fullWidth} value={value} label={label} onChange={onChange}>
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default UIInputAutocomplete;
