import { useAuthentication } from '../../../utils/useAuthentication';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import './custom-products.css';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import ModalEdit from './modalEdit';
import { useShop } from '../../../hooks/Shop/useShop';

const categories_types = [
  { title: 'Brand', type: 'brand' },
  { title: 'Function', type: 'function' },
  { title: 'Ingredient', type: 'ingredient' },
  { title: 'Protocol', type: 'protocol' }
];

const ShopCategoriesView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const { onListShopCategories } = useShop();

  const getCategories = useCallback(async () => {
    let categories_list = [];
    for (let type of categories_types) {
      categories_list[type?.type] = await onListShopCategories({
        type: type?.type
      });
    }
    setCategories(categories_list);
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  const handleModalClose = useCallback(() => {
    getCategories();
    setEditMode(false);
  }, []);

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedCategory(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>

      <ModalEdit
        user={user}
        modalOpen={editMode}
        handleModalClose={handleModalClose}
        category={selectedCategory}
        setEditMode={setEditMode}
      />

      {categories_types?.map((type, index) => (
        <React.Fragment key={index}>
          <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
            {type?.title}
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Attivo</TableCell>
                  <TableCell align="center">Azioni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories?.[type?.type]?.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" align={'center'} width={'33%'}>
                      {row.name}
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox checked={row.active} color="primary" />
                    </TableCell>
                    <TableCell align="center">
                      <IconEdit
                        cursor={'pointer'}
                        onClick={() => {
                          setSelectedCategory(row);
                          setEditMode(true);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ))}
    </Box>
  );
};
export default ShopCategoriesView;
