import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Image, Textarea } from '@chakra-ui/react';
import { Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import {
  IconAt,
  IconCake,
  IconCheck,
  IconColorFilter,
  IconPhone,
  IconPhoto,
  IconSignature,
  IconLock,
  IconBulb,
  IconCalendarCog
} from '@tabler/icons-react';
import DropzoneFormikInput from '../../utilities/Dropzone';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { useAuth } from '../../../hooks/Auth/useAuth';
import { UICheckbox } from '../../utilities/Input';
import UITypography from '../../utilities/Typography';
import { UIFormControlBox } from '../../utilities/Form';

const ProfileView = () => {
  const [loadingProfilePic, setLoadingProfilePic] = React.useState(false);
  const { onUpdateMineUser } = useAccounts();
  const { getMe } = useAuth();
  const [user, setUser] = React.useState(null);

  useEffect(() => {
    getMe().then((res) => {
      setUser(res);
    });
  }, []);

  const handleSubmit = async (values) => {
    const {
      name,
      surname,
      phone,
      email,
      password,
      profile_pic,
      age,
      sex,
      formation,
      about_me,
      instagram_link,
      doctor_specialization,
      pregnant,
      permanent_makeup,
      anamnesi,
      allergies,
      other_allergies,
      survey_enabled,
      email_new_appointment,
      email_confirmed_appointment,
      email_rejected_appointment,
      appointments_direct_delete
    } = values;

    onUpdateMineUser({
      user_data: {
        name,
        surname,
        phone,
        email,
        password,
        profile_pic,
        age,
        sex,
        pregnant,
        permanent_makeup,
        anamnesi,
        allergies,
        other_allergies,
        formation,
        instagram_link,
        doctor_specialization,
        about_me,
        survey_enabled,
        email_new_appointment,
        email_confirmed_appointment,
        email_rejected_appointment,
        appointments_direct_delete
      }
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
    });
  };

  return (
    <Box>
      <>
        <Formik
          initialValues={{
            name: user?.name ?? '',
            surname: user?.surname ?? '',
            phone: user?.phone ?? '',
            email: user?.email ?? '',
            password: '',
            profile_pic: user?.profile_pic ?? '',
            age: user?.age ?? '',
            sex: user?.sex ?? '',
            formation: user?.formation ?? '',
            about_me: user?.about_me ?? '',
            doctor_specialization: user?.doctor_specialization ?? '',
            instagram_link: user?.instagram_link ?? '',
            survey_enabled: user?.survey_enabled ?? false,
            email_new_appointment: user?.email_new_appointment ?? false,
            email_confirmed_appointment: user?.email_confirmed_appointment ?? false,
            email_rejected_appointment: user?.email_rejected_appointment ?? false,
            appointments_direct_delete: user?.appointments_direct_delete ?? false
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().nullable(),
            surname: Yup.string().nullable(),
            email: Yup.string().required('La mail è obbligatoria'),
            phone: Yup.string().nullable(),
            profile_pic: Yup.string().nullable(),
            age: Yup.number().nullable(),
            sex: Yup.string().nullable(),
            formation: Yup.string().nullable(),
            about_me: Yup.string().nullable(),
            doctor_specialization: Yup.string().nullable(),
            instagram_link: Yup.string().nullable(),
            survey_enabled: Yup.boolean().nullable(),
            email_new_appointment: Yup.boolean().nullable(),
            email_confirmed_appointment: Yup.boolean().nullable(),
            email_rejected_appointment: Yup.boolean().nullable()
          })}
          onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
          enableReinitialize={true}
        >
          {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              {user && (
                <Box
                  w={'100%'}
                  h={'100%'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'100%'}
                    h={'100%'}
                    bg={'white'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Modifica Profilo
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <InputLabel htmlFor="input-name">Nome</InputLabel>
                            <OutlinedInput
                              id="input-name"
                              type="text"
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('name', event.target.value)}
                              label="Nome"
                            />
                            {touched.name && errors.name && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.surname && errors.surname)}>
                            <InputLabel htmlFor="input-surname">Cognome</InputLabel>
                            <OutlinedInput
                              id="input-surname"
                              type="text"
                              value={values.surname}
                              name="surname"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('surname', event.target.value)}
                              label="Cognome"
                            />
                            {touched.surname && errors.surname && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.surname}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhone stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.phone && errors.phone)}>
                            <InputLabel htmlFor="input-phone">Cellulare</InputLabel>
                            <OutlinedInput
                              id="input-phone"
                              type="text"
                              value={values.phone}
                              name="phone"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('phone', event.target.value)}
                              label="Cellulare"
                            />
                            {touched.phone && errors.phone && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.phone}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconAt stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                            <InputLabel htmlFor="input-surname">Email</InputLabel>
                            <OutlinedInput
                              id="input-email"
                              type="text"
                              value={values.email}
                              name="email"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('email', event.target.value)}
                              label="Email"
                            />
                            {touched.email && errors.email && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.email}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconLock stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.password && errors.password)}>
                            <InputLabel htmlFor="input-surname">Password</InputLabel>
                            <OutlinedInput
                              id="input-password"
                              type="password"
                              value={values.password}
                              name="password"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('password', event.target.value)}
                              label="password"
                            />
                            <Typography variant={'caption'}>Lascia vuoto per non modificare</Typography>

                            {touched.password && errors.password && (
                              <FormHelperText error id="standard-weight-helper-text-password-login">
                                {errors.password}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhoto stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.profile_pic && errors.profile_pic)} w={'100%'}>
                            {loadingProfilePic && <>Loading</>}
                            <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'} mb={1} ml={1}>
                              Immagine profilo
                            </Typography>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                              <Image src={values.profile_pic} w={'100px'} h={'100px'} objectFit={'cover'} />
                              <DropzoneFormikInput
                                variant={'profile_pic'}
                                setFieldValue={setFieldValue}
                                field={'profile_pic'}
                                setLoading={setLoadingProfilePic}
                                folder={'profile_pic'}
                                label={'Carica'}
                                extra_data={{
                                  account_uuid: user?.uuid
                                }}
                              />
                            </Box>
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconCake stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.age && errors.age)}>
                            <InputLabel htmlFor="input-age">Età</InputLabel>
                            <OutlinedInput
                              id="input-age"
                              type="number"
                              value={values.age}
                              name="age"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('age', event.target.value)}
                              label="Età"
                            />
                            {touched.age && errors.age && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.age}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} w={'100%'}>
                        <IconColorFilter stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.sex && errors.sex)}>
                            <InputLabel id="sex-label">Sesso</InputLabel>
                            <Select
                              name={'sex'}
                              fullWidth={true}
                              value={values?.sex}
                              label="Sesso"
                              labelId="sex-label"
                              onChange={(e) => {
                                setFieldValue('sex', e.target.value);
                              }}
                              onBlur={handleBlur}
                              autoWidth
                            >
                              <MenuItem value={'M'}>Uomo</MenuItem>
                              <MenuItem value={'F'}>Donna</MenuItem>
                              <MenuItem value={'Other'}>Altro</MenuItem>
                            </Select>
                            {touched.color_id && errors.color_id && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.color_id}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.doctor_specialization && errors.doctor_specialization)}>
                            <InputLabel htmlFor="input-doctor_specialization">Specializzazione</InputLabel>
                            <OutlinedInput
                              id="input-doctor_specialization"
                              type="text"
                              value={values.doctor_specialization}
                              name="doctor_specialization"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('doctor_specialization', event.target.value)}
                              label="Specializzazione"
                              inputProps={{
                                maxLength: 45
                              }}
                            />
                            {touched.doctor_specialization && errors.doctor_specialization && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.doctor_specialization}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.instagram_link && errors.instagram_link)}>
                            <InputLabel htmlFor="input-instagram_link">Link instagram</InputLabel>
                            <OutlinedInput
                              id="input-instagram_link"
                              type="text"
                              value={values.instagram_link}
                              name="instagram_link"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('instagram_link', event.target.value)}
                              label="Link Instagram"
                            />
                            {touched.instagram_link && errors.instagram_link && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.instagram_link}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.formation && errors.formation)} w={'100%'}>
                            <Typography id="modal-formation" variant="body1" fontWeight={'bold'}>
                              Formazione
                            </Typography>
                            <Textarea
                              id="input-formation"
                              value={values.formation}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('formation', event.target.value)}
                              label="Formazione"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Formazione'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.formation ? '1px solid red' : null}
                            />
                            {touched.formation && errors.formation && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.formation}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.about_me && errors.about_me)} w={'100%'}>
                            <Typography id="modal-about_me" variant="body1" fontWeight={'bold'}>
                              Su di me
                            </Typography>
                            <Textarea
                              id="input-about_me"
                              value={values.about_me}
                              name="about_me"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('about_me', event.target.value)}
                              label="Su di me"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Su di me'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.about_me ? '1px solid red' : null}
                            />
                            {touched.about_me && errors.about_me && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.about_me}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <UIFormControlBox>
                            <UICheckbox
                              label={<UITypography variant={'Oxygen700'} title={'Fornisci protocolli personalizzati'} size={'12px'} />}
                              checked={values?.survey_enabled}
                              onChange={(event) => setFieldValue('survey_enabled', event.target.checked)}
                            />
                          </UIFormControlBox>

                          <UIFormControlBox>
                            <UICheckbox
                              label={
                                <UITypography
                                  variant={'Oxygen700'}
                                  title={'Ricevi email quando un paziente prende un nuovo appuntamento'}
                                  size={'12px'}
                                />
                              }
                              checked={values?.email_new_appointment}
                              onChange={(event) => setFieldValue('email_new_appointment', event.target.checked)}
                            />
                          </UIFormControlBox>

                          <UIFormControlBox>
                            <UICheckbox
                              label={
                                <UITypography
                                  variant={'Oxygen700'}
                                  title={'Ricevi email quando un paziente conferma un appuntamento'}
                                  size={'12px'}
                                />
                              }
                              checked={values?.email_confirmed_appointment}
                              onChange={(event) => setFieldValue('email_confirmed_appointment', event.target.checked)}
                            />
                          </UIFormControlBox>

                          <UIFormControlBox>
                            <UICheckbox
                              label={
                                <UITypography
                                  variant={'Oxygen700'}
                                  title={'Ricevi email quando un paziente rifiuta un appuntamento'}
                                  size={'12px'}
                                />
                              }
                              checked={values?.email_rejected_appointment}
                              onChange={(event) => setFieldValue('email_rejected_appointment', event.target.checked)}
                            />
                          </UIFormControlBox>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconCalendarCog stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <UIFormControlBox>
                            <UICheckbox
                              label={
                                <UITypography
                                  variant={'Oxygen700'}
                                  title={"Eliminazione diretta appuntamenti a cancellazione dell'utente"}
                                  size={'12px'}
                                />
                              }
                              checked={values?.appointments_direct_delete}
                              onChange={(event) => setFieldValue('appointments_direct_delete', event.target.checked)}
                            />
                          </UIFormControlBox>
                        </Box>
                      </Box>
                      <Box
                        className={'bottom_buttons'}
                        h={'50px'}
                        w={'100%'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        px={20}
                      >
                        <Button variant={'contained'} color={'success'} type={'submit'}>
                          <IconCheck stroke={1.5} size="1rem" />
                        </Button>
                      </Box>
                    </Box>
                    <Divider />
                  </Box>
                </Box>
              )}
            </form>
          )}
        </Formik>
      </>
    </Box>
  );
};
export default ProfileView;
