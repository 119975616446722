import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Switch } from '@mui/material';

const UISwitch = ({ onChange, label = '', labelPlacement = 'bottom', className, checked = false }) => {
  const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 50,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(29px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#FECE2E',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#FECE2E',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 17,
      height: 17,
      marginTop: 2.5,
      marginLeft: 2
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  }));

  return (
    <FormControlLabel
      control={<IOSSwitch onChange={(e) => onChange(e.target.checked, e)} checked={checked} />}
      label={label}
      labelPlacement={labelPlacement}
      className={className}
    />
  );
};

export default UISwitch;
