// assets
import { IconClipboardHeart } from '@tabler/icons-react';

// constant
const icons = { IconClipboardHeart };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const protocols = {
  id: 'protocols',
  type: 'group',
  permission: ['ADMIN', 'DOCTOR'],
  children: [
    {
      id: 'protocols_list',
      title: 'Protocolli',
      type: 'collapse',
      icon: icons.IconClipboardHeart,
      breadcrumbs: false,
      permission: ['ADMIN', 'DOCTOR'],
      children: [
        {
          id: 'protocols',
          title: 'Da compilare',
          type: 'item',
          url: '/protocols/list',
          breadcrumbs: false,
          permission: ['DOCTOR', 'ADMIN']
        },
        {
          id: 'protocols_update',
          title: 'Da aggiornare',
          type: 'item',
          url: '/protocols/update',
          breadcrumbs: false,
          permission: ['DOCTOR', 'ADMIN']
        },
        {
          id: 'protocols_completed',
          title: 'Compilati',
          type: 'item',
          url: '/protocols/archive',
          breadcrumbs: false,
          permission: ['DOCTOR', 'ADMIN']
        },
        {
          id: 'protocols_default',
          title: 'Default',
          type: 'item',
          url: '/protocols/default',
          breadcrumbs: false,
          permission: ['ADMIN']
        }
      ]
    }
  ]
};

export default protocols;
