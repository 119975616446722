import { Box } from '@chakra-ui/react';
import { Button, Link, Modal, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import UITable from '../../utilities/Table';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { useChats } from '../../../hooks/Chats/useChats';
import { useNavigate } from 'react-router-dom';

const ModalAddChat = ({ open, onClose }) => {
  const navigate = useNavigate();

  const { onListUsersAdminPaginated } = useAccounts();
  const { onStartChatDoctor } = useChats();

  const startChatDoctor = useCallback(({ patient_uuid }) => {
    onStartChatDoctor({
      patient_uuid: patient_uuid
    }).then((res) => {
      navigate(`/chat/${res?.new_uuid}`);
    });
  }, []);

  const handleRowClick = (row) => {
    startChatDoctor({ patient_uuid: row?.patient?.uuid });
  };

  const prepareValues = (users) =>
    users?.map((user) => {
      return {
        patient: user,
        patientEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {user?.name} {user?.surname}
            </Typography>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'13px'} color={'#667085'}>
              <Link
                href={`mailto:${user?.email}`}
                target={'_blank'}
                rel={'noreferrer'}
                component={'a'}
                underline={'always'}
                color={'#667085'}
              >
                {user?.email}
              </Link>
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#B5FDC1',
              color: '#344054',
              fontFamily: 'Oxygen',
              fontWeight: '700',
              fontSize: '13px',
              '&:hover': {
                backgroundColor: '#fff',
                color: '#344054'
              }
            }}
            disableElevation
            onClick={() => {
              startChatDoctor({ patient_uuid: user?.uuid });
            }}
          >
            Contatta
          </Button>
        )
      };
    });

  return (
    <Modal
      open={open}
      onClose={onClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        width={'100%'}
        h={'100%'}
        bg={'transparent'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        pointerEvents={'none'}
      >
        <Box
          className={'container'}
          w={'85%'}
          h={'70%'}
          bg={'#FDF2B5'}
          borderRadius={'8px'}
          pointerEvents={'all'}
          position={'relative'}
          overflow={'hidden'}
        >
          <Box bg={'white'} width={'100%'} height={'70px'}>
            <Typography
              fontFamily={'Oxygen'}
              fontWeight={'700'}
              fontSize={'15px'}
              textAlign={'center'}
              py={'25px'}
              borderBottom={'1px solid #CFCFCF'}
            >
              Aggiungi una chat
            </Typography>
          </Box>
          <Box width={'90%'} m={'0 auto'}>
            <UITable
              columns={[
                {
                  name: 'Paziente',
                  value: 'patientEl'
                },
                {
                  name: '',
                  value: 'actionsEl'
                }
              ]}
              enableSearch={true}
              prepareValues={prepareValues}
              tableHoverEnabled={true}
              onRowClick={handleRowClick}
              infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
              infiniteScrollFunction={onListUsersAdminPaginated}
              enableTopToolbar={true}
              minifiedToolbar={true}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default ModalAddChat;
