import { useAuthentication } from '../../../utils/useAuthentication';
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import './custom-treatments.css';
import { IconChecks } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { toast } from 'react-toastify';

const TreatmentsAssignView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [calendars, setCalendars] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [selectedTreatments, setSelectedTreatments] = useState(null);

  const { onListCalendarsAdmin } = useCalendar();
  const { onListTreatmentsDoctor, onAssignTreatmentsToCalendar } = useTreatments();

  useEffect(() => {
    onListCalendarsAdmin({
      doctor_id: user?.id
    }).then((res) => {
      setCalendars(res);
    });
    onListTreatmentsDoctor().then((res) => {
      setTreatments(res);
    });
  }, []);

  useEffect(() => {
    updateSelectedTreatments().then((res) => {
      setSelectedTreatments(res);
    });
  }, [selectedCalendar]);

  const updateSelectedTreatments = async () => {
    let calendar = calendars?.find((calendar) => Number(calendar?.id) === selectedCalendar);

    if (calendar) {
      let calendarTreatments = calendar?.calendar_treatments;
      let treatments = [];

      calendarTreatments?.forEach((calendarTreatment) => {
        treatments?.push(calendarTreatment?.doctor_treatment?.treatment_id);
      });

      return treatments;
    }
  };

  const handleSelection = (treatment_id) => {
    if (selectedTreatments?.includes(treatment_id)) {
      setSelectedTreatments(selectedTreatments.filter((item) => item !== treatment_id));
    } else {
      setSelectedTreatments([...selectedTreatments, treatment_id]);
    }
  };

  const confirmTreatments = () => {
    onAssignTreatmentsToCalendar({
      calendar_id: selectedCalendar,
      treatments: selectedTreatments
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });

      onListTreatmentsDoctor().then((res) => {
        setTreatments(res);
      });
    });
  };

  return (
    <Box className="treatments-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Trattamenti
      </Typography>

      {selectedCalendar && (
        <Tooltip title={'Salva'} placement="top">
          <Box
            position={'fixed'}
            display={'flex'}
            right={10}
            bottom={10}
            bgcolor={'#00e676'}
            p={'10px'}
            borderRadius={'100%'}
            w={'50px'}
            h={'50px'}
            color={'white'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={confirmTreatments}
          >
            <IconChecks stroke={3} size="2rem" />
          </Box>
        </Tooltip>
      )}

      <Box w={'100%'} mt={2} mb={2}>
        <Autocomplete
          id="demo-simple-select"
          fullWidth={true}
          name={'calendar_id'}
          defaultValue={() => {
            return '';
          }}
          options={calendars?.map((calendar) => {
            return {
              id: Number(calendar?.id),
              label: `${calendar?.office?.name} - ${calendar?.office?.city?.name}`
            };
          })}
          onChange={(e, data) => {
            setSelectedCalendar(data?.id);
          }}
          renderInput={(params) => <TextField {...params} label="Agenda" />}
        />
      </Box>

      {selectedCalendar && (
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Nome</TableCell>
                <TableCell align="center">{user?.admin ? <>Attivo per i dottori</> : <>Prenotabile</>}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {treatments?.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" align={'center'}>
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={selectedTreatments?.includes(row?.id) ?? false}
                      onChange={() => handleSelection(row.id)}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
export default TreatmentsAssignView;
