import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import {
  Checkbox,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { IconEdit, IconPlus, IconSearch } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { useShop } from '../../../hooks/Shop/useShop';
import ModalEdit from './modalEdit';

const ShopCouponsView = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [query, setQuery] = useState('');

  const { onListShopCouponsAdmin } = useShop();

  const listCoupons = () => {
    onListShopCouponsAdmin({
      page,
      limit: 20,
      query
    }).then((res) => {
      setCoupons(res?.rows);
      setTotalPages(res?.count);
    });
  };

  useEffect(() => {
    listCoupons();
  }, [query, page]);

  const handleModalClose = useCallback(() => {
    listCoupons();
    setEditMode(false);
    setSelectedCoupon(null);
  }, []);

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalEdit modalOpen={editMode} handleModalClose={handleModalClose} coupon={selectedCoupon} />
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bg={'#2c3e50'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        zIndex={999}
        onClick={() => {
          setSelectedCoupon(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>
      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Lista coupons
      </Typography>

      <Box
        my={3}
        display={'flex'}
        justifyContent={matchDownSM ? 'center' : 'flex-start'}
        alignItems={'center'}
        gap={2}
        w={'100%'}
        flexWrap={'wrap'}
      >
        <Box my={3}>
          <TextField
            id="outlined-select-currency-native"
            width={'30%'}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            SelectProps={{
              native: true
            }}
            InputProps={{
              startAdornment: (
                <IconSearch
                  style={{
                    marginRight: 10
                  }}
                />
              )
            }}
          />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Codice</TableCell>
              <TableCell align="center">Attivo</TableCell>
              <TableCell align="center">Usato</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'}>
                  <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                    {row?.code}
                  </Typography>
                  <Typography id="modal-modal-description" variant="body1">
                    {row?.discount_value} {row?.discount_type === 'percentage' ? '%' : '€'}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row" align={'center'}>
                  <Checkbox checked={row?.active} disabled color="primary" />
                </TableCell>
                <TableCell component="th" scope="row" align={'center'}>
                  <Typography id="modal-modal-description" variant="body1">
                    {row?.used}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <IconEdit
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedCoupon(row);
                      setEditMode(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} color={'primary'} />
      </Box>
    </Box>
  );
};

export default ShopCouponsView;
