import { Box, Textarea } from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography
} from '@mui/material';
import { IconSignature, IconFlame, IconBulb, IconX, IconCheck } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { toast } from 'react-toastify';

const ModalTreatmentEditAdmin = ({ modalOpen, handleModalClose, treatment, setEditMode }) => {
  let [deleteMode, setDeleteMode] = useState(false);

  const { onUpdateTreatmentAdmin, onCreateTreatmentAdmin, onDeleteTreatmentAdmin } = useTreatments();

  useEffect(() => {}, [treatment]);

  const handleDeleteTreatment = async () => {
    await onDeleteTreatmentAdmin({
      treatment_id: treatment.id
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { name, description, active, sponsored } = values;

    if (treatment?.id) {
      onUpdateTreatmentAdmin({
        treatment_id: treatment.id,
        treatment_data: {
          name,
          description,
          active,
          sponsored
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateTreatmentAdmin({
        treatment_data: {
          name,
          description,
          active,
          sponsored
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
            <DialogTitle>Elimina Trattamento</DialogTitle>
            <DialogContent>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <DialogContentText>Vuoi eliminare il trattamento "{treatment?.name}"?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
                Annulla
              </Button>
              <Button variant={'contained'} color={'success'} onClick={handleDeleteTreatment}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
          <Formik
            initialValues={{
              name: treatment?.name ?? '',
              description: treatment?.description ?? '',
              active: treatment?.active ?? false,
              sponsored: treatment?.sponsored ?? false
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Il nome è obbligatorio'),
              description: Yup.string().required('La descrizione è obbligatoria'),
              active: Yup.boolean().nullable(),
              sponsored: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati Generali Trattamento
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <InputLabel htmlFor="input-name">Nome</InputLabel>
                            <OutlinedInput
                              id="input-name"
                              type="text"
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('name', event.target.value)}
                              label="Nome"
                              inputProps={{
                                maxLength: 35
                              }}
                            />
                            {touched.name && errors.name && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.description && errors.description)} w={'100%'}>
                            <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                              Descrizione
                            </Typography>
                            <Textarea
                              id="input-description"
                              value={values.description}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('description', event.target.value)}
                              label="Descrizione"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Descrizione'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.description ? '1px solid red' : null}
                            />
                            {touched.description && errors.description && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.description}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.active}
                                  onChange={(event) => setFieldValue('active', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Attivo</Typography>}
                            />
                            {touched.active && errors.active && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.active}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.sponsored && errors.sponsored)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.sponsored}
                                  onChange={(event) => setFieldValue('sponsored', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Sponsorizzato</Typography>}
                            />
                            {touched.sponsored && errors.sponsored && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.sponsored}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconFlame stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                            Appuntamenti prenotati
                          </Typography>
                          <Typography id="modal-modal-description" variant="body1">
                            {treatment?.most_wanted}
                          </Typography>
                        </Box>
                      </Box>

                      {treatment?.id && (
                        <Box d={'flex'} justifyContent={'center'}>
                          <Box
                            fontSize={15}
                            textAlign={'center'}
                            color={'#f44336'}
                            fontFamily={'Montserrat, sans-serif'}
                            mt={10}
                            fontWeight={700}
                            textTransform={'uppercase'}
                            bg={'rgba(200,200,200,0.2)'}
                            p={'5px '}
                            cursor={'pointer'}
                            onClick={() => setDeleteMode(true)}
                          >
                            Elimina trattamento
                          </Box>
                        </Box>
                      )}

                      {errors.general && (
                        <FormHelperText
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '10px',
                            fontSize: '16px',
                            color: '#f44336'
                          }}
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.general}
                        </FormHelperText>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'}>
                        <IconX stroke={1.5} size="1rem" onClick={() => setEditMode(false)} />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};
export default ModalTreatmentEditAdmin;
