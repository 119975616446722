import { Box, Image } from '@chakra-ui/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography
} from '@mui/material';
import { IconAB2, IconCheck, IconEdit, IconPhoto, IconSignature, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useProtocols } from '../../../hooks/Protocols/useProtocols';
import ModalEditStep from './ModalEditStep';
import { v4 as uuidv4 } from 'uuid';
import DropzoneFormikInput from '../../utilities/Dropzone';
import MDEditor, { commands } from '@uiw/react-md-editor';

const ModaEditProtocol = ({ modalOpen, handleModalClose, protocol }) => {
  let [protocolData, setProtocolData] = useState(false);
  let [selectedStepData, setSelectedStepData] = useState(false);
  let [selectedStepNumber, setSelectedStepNumber] = useState(false);
  let [selectedStepTime, setSelectedStepTime] = useState(false);
  let [addStepMode, setAddStepMode] = useState(false);
  let [deleteMode, setDeleteMode] = useState(false);
  let [loadingCover, setLoadingCover] = useState(false);
  const [uuid, setUuid] = React.useState(uuidv4());

  const { onUpdateProtocol, onDeleteProtocol, onGetProtocolAdmin, onCreateProtocol } = useProtocols();

  useEffect(() => {
    setUuid(uuidv4());
  }, [protocol, modalOpen]);

  useEffect(() => {
    if (!protocol) {
      setProtocolData(null);
      return;
    }
    getProtocol();
  }, [protocol]);

  const getProtocol = () => {
    onGetProtocolAdmin({
      protocol_uuid: protocol?.uuid
    }).then((res) => {
      setProtocolData(res);
    });
  };

  const handleRefreshData = () => {
    getProtocol();
  };
  const handleModalEditOpen = ({ step_data, step, time }) => {
    setSelectedStepNumber(step);
    setSelectedStepTime(time);
    setSelectedStepData(step_data ?? null);
    setAddStepMode(true);
  };
  const handleModalEditClose = () => {
    setAddStepMode(false);
    setSelectedStepData(null);
    setSelectedStepNumber(null);
    setSelectedStepTime(null);
    getProtocol();
  };

  const handleDeleteProtocol = async () => {
    await onDeleteProtocol({
      protocol_uuid: protocol?.uuid
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { cover, title, subtitle, description, ingredients } = values;

    if (protocolData?.uuid) {
      onUpdateProtocol({
        protocol_uuid: protocolData?.uuid,
        protocol_data: {
          cover,
          title,
          subtitle,
          description,
          ingredients
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateProtocol({
        protocol_data: {
          uuid,
          cover,
          title,
          subtitle,
          description,
          ingredients
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <ModalEditStep
            modalOpen={addStepMode}
            handleModalClose={handleModalEditClose}
            handleRefreshData={handleRefreshData}
            protocol_uuid={protocolData?.uuid}
            step_data={selectedStepData}
            time={selectedStepTime}
            step={selectedStepNumber}
          />
          <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
            <DialogTitle>Elimina protocollo</DialogTitle>
            <DialogContent>
              <DialogContentText>Vuoi eliminare il protocollo?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
                Annulla
              </Button>
              <Button variant={'contained'} color={'success'} onClick={handleDeleteProtocol}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>

          <Formik
            initialValues={{
              cover: protocolData?.cover ?? '',
              title: protocolData?.title ?? '',
              subtitle: protocolData?.subtitle ?? '',
              description: protocolData?.description ?? '',
              ingredients: protocolData?.ingredients ?? ''
            }}
            validationSchema={Yup.object().shape({
              cover: Yup.string().required('La copertina è obbligatoria'),
              title: Yup.string().required('Il titolo è obbligatorio'),
              subtitle: Yup.string().required('Il sottotitolo è obbligatorio'),
              description: Yup.string().required('La descrizione è obbligatoria'),
              ingredients: Yup.string().required('La lista ingredienti è obbligatoria')
            })}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Box>
                        <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                          Protocollo
                        </Typography>
                        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                          <IconPhoto stroke={1.5} size="1.3rem" />
                          <Box ml={10} w={'85%'}>
                            <FormControl fullWidth error={Boolean(touched.cover && errors.cover)} w={'100%'}>
                              {loadingCover && <>Loading</>}
                              <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'} mb={1} ml={1}>
                                Cover
                              </Typography>
                              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                                <Image src={values.cover} w={'100px'} h={'100px'} objectFit={'cover'} />
                                <DropzoneFormikInput
                                  variant={'cover'}
                                  setFieldValue={setFieldValue}
                                  field={'cover'}
                                  setLoading={setLoadingCover}
                                  folder={'cover'}
                                  label={'Carica'}
                                  extra_data={{
                                    protocol_uuid: protocolData?.uuid ?? uuid
                                  }}
                                />
                              </Box>
                            </FormControl>
                          </Box>
                        </Box>
                        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                          <IconSignature stroke={1.5} size="1.3rem" />
                          <Box ml={10}>
                            <FormControl fullWidth error={Boolean(touched.title && errors.title)}>
                              <InputLabel htmlFor="input-title">Titolo</InputLabel>
                              <OutlinedInput
                                id="input-title"
                                type="text"
                                value={values.title}
                                name="title"
                                onBlur={handleBlur}
                                onChange={() => setFieldValue('title', event.target.value)}
                                label="Titolo"
                                inputProps={{
                                  maxLength: 18
                                }}
                              />
                              {touched.title && errors.title && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                  {errors.title}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                          <IconSignature stroke={1.5} size="1.3rem" />
                          <Box ml={10}>
                            <FormControl fullWidth error={Boolean(touched.subtitle && errors.subtitle)}>
                              <InputLabel htmlFor="input-subtitle">Sottotitolo</InputLabel>
                              <OutlinedInput
                                id="input-subtitle"
                                type="text"
                                value={values.subtitle}
                                name="subtitle"
                                onBlur={handleBlur}
                                onChange={() => setFieldValue('subtitle', event.target.value)}
                                label="Sottotitolo"
                                inputProps={{
                                  maxLength: 35
                                }}
                              />
                              {touched.subtitle && errors.subtitle && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                  {errors.subtitle}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                          <IconSignature stroke={1.5} size="1.3rem" />
                          <Box ml={10} w={'85%'}>
                            <FormControl fullWidth error={Boolean(touched.description && errors.description)} w={'100%'}>
                              <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                                Descrizione
                              </Typography>
                              <MDEditor
                                height={200}
                                value={values?.description}
                                onChange={(md) => {
                                  setFieldValue('description', md);
                                }}
                                commands={[
                                  commands.bold,
                                  commands.italic,
                                  commands.strikethrough,
                                  commands.unorderedListCommand,
                                  commands.orderedListCommand,
                                  commands.checkedListCommand
                                ]}
                              />
                              {touched.description && errors.description && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                  {errors.description}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                          <IconSignature stroke={1.5} size="1.3rem" />
                          <Box ml={10} w={'85%'}>
                            <FormControl fullWidth error={Boolean(touched.ingredients && errors.ingredients)} w={'100%'}>
                              <Typography id="modal-modal-ingredients" variant="body1" fontWeight={'bold'}>
                                Ingredienti
                              </Typography>
                              <MDEditor
                                height={200}
                                value={values?.ingredients}
                                onChange={(md) => {
                                  setFieldValue('ingredients', md);
                                }}
                                commands={[
                                  commands.bold,
                                  commands.italic,
                                  commands.strikethrough,
                                  commands.unorderedListCommand,
                                  commands.orderedListCommand,
                                  commands.checkedListCommand
                                ]}
                              />
                              {touched.ingredients && errors.ingredients && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                  {errors.ingredients}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                      {protocolData?.uuid && (
                        <>
                          <Box>
                            <Typography
                              id="modal-modal-title"
                              variant="h4"
                              mt={4}
                              px={1.5}
                              py={1}
                              borderTop={'1px solid black'}
                              borderBottom={'1px solid black'}
                            >
                              3 Step
                            </Typography>
                            <ProductData
                              selectedStep={selectedStepData}
                              handleModalEditOpen={handleModalEditOpen}
                              protocol={protocolData}
                              steps={3}
                              time={'day'}
                            />
                            <ProductData
                              selectedStep={selectedStepData}
                              handleModalEditOpen={handleModalEditOpen}
                              protocol={protocolData}
                              steps={3}
                              time={'night'}
                            />
                          </Box>
                          <Box>
                            <Typography
                              id="modal-modal-title"
                              variant="h4"
                              mt={4}
                              px={1.5}
                              py={1}
                              borderTop={'1px solid black'}
                              borderBottom={'1px solid black'}
                            >
                              5 Step
                            </Typography>
                            <ProductData
                              selectedStep={selectedStepData}
                              handleModalEditOpen={handleModalEditOpen}
                              protocol={protocolData}
                              steps={5}
                              time={'day'}
                            />
                            <ProductData
                              selectedStep={selectedStepData}
                              handleModalEditOpen={handleModalEditOpen}
                              protocol={protocolData}
                              steps={5}
                              time={'night'}
                            />
                          </Box>
                          <Box d={'flex'} justifyContent={'center'}>
                            <Box
                              fontSize={15}
                              textAlign={'center'}
                              color={'#f44336'}
                              fontFamily={'Montserrat, sans-serif'}
                              mt={20}
                              fontWeight={700}
                              textTransform={'uppercase'}
                              bg={'rgba(200,200,200,0.2)'}
                              p={'5px '}
                              cursor={'pointer'}
                              onClick={() => setDeleteMode(true)}
                            >
                              Elimina protocollo
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'} onClick={handleModalClose}>
                        <IconX stroke={1.5} size="1rem" />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};

const ProductData = ({ protocol, steps, time, handleModalEditOpen, selectedStep }) => {
  const [protocolData, setProtocolData] = useState({});

  useEffect(() => {
    setProtocolData(protocol);
  }, [protocol]);

  return (
    <Box>
      <Typography
        id="modal-modal-title"
        variant="body1"
        mt={4}
        ml={1.5}
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        {time === 'day' ? 'Giorno' : 'Notte'}
        <Button
          variant={'contained'}
          color={'primary'}
          cursor={'pointer'}
          style={{
            marginLeft: '10px',
            marginBottom: '5px',
            fontSize: '12px',
            padding: '0 10px',
            textTransform: 'none'
          }}
          onClick={() =>
            handleModalEditOpen({
              step: steps,
              time: time
            })
          }
        >
          Aggiungi step
        </Button>
      </Typography>

      {protocolData?.steps?.map((step, index) => {
        if (selectedStep?.id === step.id) {
          handleModalEditOpen({
            step_data: step
          });
        }

        if (step.time === time && step.step === steps) {
          return (
            <Box key={index} display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} position={'relative'}>
              <IconAB2 stroke={1.5} size="1.3rem" />
              <Box w={'100%'} ml={10}>
                <Box
                  position={'absolute'}
                  top={2}
                  right={10}
                  onClick={() =>
                    handleModalEditOpen({
                      step_data: step
                    })
                  }
                >
                  <IconEdit stroke={1.5} size="1.3rem" />
                </Box>

                <Typography variant="subtitle1">{step.title}</Typography>
                <Box>
                  {step?.products?.map((product, index) => (
                    <Typography key={index} variant="body1">
                      {product.name}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default ModaEditProtocol;
