import {
  CREATE_PRODUCTS_DEVICE_ADMIN,
  DELETE_PRODUCTS_DEVICE_ADMIN,
  LIST_PRODUCTS_DEVICES_ADMIN,
  UPDATE_PRODUCTS_DEVICE_ADMIN
} from './graphql/queries';
import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';

/** @format */
export const useProductsDevices = () => {
  const { getDataGQL: listProductsDevicesAdmin } = useQuery(LIST_PRODUCTS_DEVICES_ADMIN);
  const { getDataGQL: createProductDeviceAdmin } = useMutation(CREATE_PRODUCTS_DEVICE_ADMIN);
  const { getDataGQL: updateProductDeviceAdmin } = useMutation(UPDATE_PRODUCTS_DEVICE_ADMIN);
  const { getDataGQL: deleteProductDeviceAdmin } = useMutation(DELETE_PRODUCTS_DEVICE_ADMIN);

  const onListProductsDevicesAdmin = async () => {
    const response = await listProductsDevicesAdmin();
    if (response?.adminRoute?.listProductsDevicesAdmin) {
      return response?.adminRoute?.listProductsDevicesAdmin;
    }
    return null;
  };

  const onCreateProductDeviceAdmin = async ({ product_device_data }) => {
    const response = await createProductDeviceAdmin({
      variables: {
        product_device_data
      }
    });
    if (response?.adminRoute?.createProductDeviceAdmin) {
      return response?.adminRoute?.createProductDeviceAdmin;
    }
    return null;
  };

  const onUpdateProductDeviceAdmin = async ({ product_device_id, product_device_data }) => {
    const response = await updateProductDeviceAdmin({
      variables: {
        product_device_id,
        product_device_data
      }
    });
    if (response?.adminRoute?.updateProductDeviceAdmin) {
      return response?.adminRoute?.updateProductDeviceAdmin;
    }
    return null;
  };

  const onDeleteProductDeviceAdmin = async ({ product_device_id }) => {
    const response = await deleteProductDeviceAdmin({
      variables: {
        product_device_id
      }
    });
    if (response?.adminRoute?.deleteProductDeviceAdmin) {
      return response?.adminRoute?.deleteProductDeviceAdmin;
    }
    return null;
  };

  return {
    onListProductsDevicesAdmin,
    onCreateProductDeviceAdmin,
    onUpdateProductDeviceAdmin,
    onDeleteProductDeviceAdmin
  };
};
