import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import './custom-offices.css';
import Paper from '@mui/material/Paper';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { useAuthentication } from '../../../utils/useAuthentication';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { IconChecks } from '@tabler/icons-react';
import { toast } from 'react-toastify';

const OfficesAssign = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [offices, setOffices] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(user?.id);
  const [selectedOffices, setSelectedOffices] = useState([]);

  const { onListOffices, onAssignOfficesAdmin } = useOffices();
  const { onListDoctors } = useAccounts();
  const { onListCalendarsAdmin } = useCalendar();

  useEffect(() => {
    onListOffices().then((res) => {
      setOffices(res);
    });
    onListDoctors().then((res) => {
      setDoctors(res);
    });
    onListCalendarsAdmin({
      doctor_id: selectedDoctor
    }).then((res) => {
      setCalendars(res);
    });
  }, []);

  useEffect(() => {
    onListCalendarsAdmin({
      doctor_id: selectedDoctor
    }).then((res) => {
      setCalendars(res);
    });
  }, [selectedDoctor]);

  useEffect(() => {
    setSelectedOffices([]);
    updateSelectedOffices().then((res) => {
      setSelectedOffices(res);
    });
  }, [calendars]);

  const updateSelectedOffices = async () => {
    setSelectedOffices([]);

    let offices_list = [];

    offices?.forEach((office) => {
      let calendar = calendars.find((calendar) => {
        return calendar.office_id === office.id;
      });

      if (calendar) {
        offices_list?.push(office.id);
      }
    });

    return offices_list;
  };

  const handleSelection = (officeId) => {
    if (selectedOffices.includes(officeId)) {
      setSelectedOffices(selectedOffices.filter((item) => item !== officeId));
    } else {
      setSelectedOffices([...selectedOffices, officeId]);
    }
  };

  const confirmOffices = () => {
    onAssignOfficesAdmin({
      doctor_id: selectedDoctor,
      offices: selectedOffices
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });

      onListOffices().then((res) => {
        setOffices(res);
      });
    });
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'} position={'relative'}>
      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Assegnazione uffici
      </Typography>

      {selectedDoctor && (
        <Tooltip title={'Salva'} placement="top">
          <Box
            position={'fixed'}
            display={'flex'}
            right={10}
            bottom={10}
            bgcolor={'#00e676'}
            p={'10px'}
            borderRadius={'100%'}
            w={'50px'}
            h={'50px'}
            color={'white'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={confirmOffices}
          >
            <IconChecks stroke={3} size="2rem" />
          </Box>
        </Tooltip>
      )}

      {doctors.length > 0 && (
        <Box mb={2}>
          <Autocomplete
            id="demo-simple-select"
            fullWidth={true}
            name={'product'}
            defaultValue={() => {
              let doctor = doctors.find((doctor) => {
                return doctor?.id === user?.id;
              });

              if (!doctor) {
                return '';
              }

              return {
                id: Number(doctor?.id),
                label: `${doctor?.name} ${doctor?.surname}`
              };
            }}
            options={doctors?.map((doctor) => {
              return {
                id: Number(doctor?.id),
                label: `${doctor?.name} ${doctor?.surname}`
              };
            })}
            onChange={(e, data) => {
              setSelectedDoctor(data?.id);
            }}
            renderInput={(params) => <TextField {...params} label="Dottore" />}
          />
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Assegnato</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offices?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'}>
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  <Checkbox checked={selectedOffices.includes(row.id)} onChange={() => handleSelection(row.id)} color="primary" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default OfficesAssign;
