import gql from 'graphql-tag';

export const LIST_GLOSSARIES_ADMIN = gql`
  query listGlossariesAdmin($query: String, $limit: Int, $page: Int) {
    adminRoute {
      listGlossariesAdmin(query: $query, limit: $limit, page: $page) {
        count
        rows {
          id
          uuid
          cover
          title
          content
          active
        }
      }
    }
  }
`;

export const GET_GLOSSARY_ADMIN = gql`
  query getGlossaryAdmin($glossary_uuid: String!) {
    adminRoute {
      getGlossaryAdmin(glossary_uuid: $glossary_uuid) {
        id
        uuid
        cover
        title
        content
        active
      }
    }
  }
`;

export const CREATE_GLOSSARY_ADMIN = gql`
  query createGlossaryAdmin($glossary_data: GlossaryInput!) {
    adminRoute {
      createGlossaryAdmin(glossary_data: $glossary_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_GLOSSARY_ADMIN = gql`
  query updateGlossaryAdmin($glossary_uuid: String!, $glossary_data: GlossaryInput!) {
    adminRoute {
      updateGlossaryAdmin(glossary_uuid: $glossary_uuid, glossary_data: $glossary_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_GLOSSARY_ADMIN = gql`
  query deleteGlossaryAdmin($glossary_uuid: String!) {
    adminRoute {
      deleteGlossaryAdmin(glossary_uuid: $glossary_uuid) {
        response
        responseStatus
      }
    }
  }
`;

export const UPLOAD_GLOSSARY_COVER = gql`
  query uploadArticleCover($glossary_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadGlossaryCover(glossary_uuid: $glossary_uuid, filename: $filename, contentType: $contentType) {
        presignedUrl
        key
      }
    }
  }
`;
