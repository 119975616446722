import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import UITable from '../../utilities/Table';
import { UIPageHeader } from '../../utilities/Headers';
import { useShop } from '../../../hooks/Shop/useShop';
import moment from 'moment/moment';
import { IconCoinEuro } from '@tabler/icons-react';
import { UIButton } from '../../utilities/Button';
import { UiDialog } from '../../utilities/Dialog';
import { toast } from 'react-toastify';

const ShopEarnsView = () => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedCommission, setSelectedCommission] = useState(null);
  const [payedMode, setPayedMode] = useState(false);

  const { onListShopMonthlyCommissionsAdmin, onSetCommissionPayed } = useShop();

  const prepareValues = (commissions) =>
    commissions?.map((commission) => {
      return {
        commission,
        doctorEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {commission?.doctor?.name} {commission?.doctor?.surname}
            </Typography>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'13px'} color={'#667085'}>
              <Link
                href={`mailto:${commission?.doctor?.email}`}
                target={'_blank'}
                rel={'noreferrer'}
                component={'a'}
                underline={'always'}
                color={'#667085'}
              >
                {commission?.doctor?.email}
              </Link>
            </Typography>
          </Box>
        ),
        commissionEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {commission?.amount} €
            </Typography>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'13px'} color={'#667085'}>
              {moment(commission?.month).format('MMMM YYYY')}
            </Typography>
          </Box>
        ),
        payedEl: (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            borderRadius={2}
            bgcolor={commission?.payed ? 'green' : 'red'}
            color={'white'}
            p={1}
          >
            <Typography variant={'body1'}>{commission.payed ? 'Pagato' : 'Non pagato'}</Typography>
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
            {!commission.payed && (
              <UIButton
                type={'icon'}
                onClick={() => {
                  setSelectedCommission(commission);
                  setPayedMode(true);
                }}
                icon={
                  <IconCoinEuro
                    stroke={'1.67px'}
                    style={{
                      width: '24px'
                    }}
                  />
                }
              />
            )}
          </Box>
        )
      };
    });

  const handlePayed = async () => {
    onSetCommissionPayed({
      commission_uuid: selectedCommission?.uuid
    }).then((res) => {
      if (res?.response) {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        setPayedMode(false);
        setForceUpdate(true);
      }
    });
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <UiDialog
        open={payedMode}
        onClose={() => setPayedMode(false)}
        title={'Segnare come pagato?'}
        onConfirm={handlePayed}
        labelConfirm={'Conferma'}
      />

      <UIPageHeader
        title={'Guadagni dottori'}
        subtitle={'Qui puoi visualizzare i guadagni dei dottori. Seleziona un dottore per visualizzare i dettagli.'}
      />

      <UITable
        columns={[
          {
            name: 'Dottore',
            value: 'doctorEl',
            size: 120
          },
          {
            name: 'Commissione',
            value: 'commissionEl',
            size: 120
          },
          {
            name: 'Stato',
            value: 'payedEl',
            size: 120
          },
          {
            name: '',
            value: 'actionsEl',
            size: 80
          }
        ]}
        prepareValues={prepareValues}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
        infiniteScrollFunction={onListShopMonthlyCommissionsAdmin}
      />
    </Box>
  );
};
export default ShopEarnsView;
