import { useAuthentication } from '../../../utils/useAuthentication';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import './custom-products.css';
import { IconEdit, IconPlus, IconSearch } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import ModalEdit from './modalEdit';
import { useShop } from '../../../hooks/Shop/useShop';

const ShopProductsView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [query, setQuery] = useState('');

  const { onListShopProductsByQuery } = useShop();

  useEffect(() => {
    onListShopProductsByQuery({
      query
    }).then((res) => {
      setProducts(res);
      setEditMode(false);
    });
  }, [query]);

  const handleModalClose = useCallback(() => {
    onListShopProductsByQuery({
      query
    }).then((res) => {
      setProducts(res);
      setEditMode(false);
    });
  }, []);

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedProduct(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>

      <ModalEdit user={user} modalOpen={editMode} handleModalClose={handleModalClose} product={selectedProduct} />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Lista Prodotti
      </Typography>

      <Box my={3}>
        <TextField
          id="outlined-select-currency-native"
          width={'30%'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          SelectProps={{
            native: true
          }}
          InputProps={{
            startAdornment: (
              <IconSearch
                style={{
                  marginRight: 10
                }}
              />
            )
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'}>
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  <IconEdit
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedProduct(row);
                      setEditMode(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ShopProductsView;
