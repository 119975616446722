// assets
import { IconCalendarCog } from '@tabler/icons-react';

// constant
const icons = { IconCalendarCog };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const calendar = {
  id: 'calendar',
  // title: 'calendari',
  type: 'group',
  permission: ['ADMIN', 'DOCTOR', 'CLINIC'],
  children: [
    {
      id: 'appointments_list',
      title: 'Appuntamenti',
      type: 'collapse',
      icon: icons.IconCalendarCog,
      breadcrumbs: false,
      permission: ['ADMIN', 'DOCTOR', 'CLINIC'],
      children: [
        {
          id: 'appointments_calendar',
          title: 'Calendario',
          type: 'item',
          url: '/calendar',
          breadcrumbs: false,
          permission: ['DOCTOR', 'ADMIN', 'CLINIC']
        },
        {
          id: 'appointments_nearest',
          title: 'Appuntamenti imminenti ',
          type: 'item',
          url: '/calendar/nearest',
          breadcrumbs: false,
          permission: ['DOCTOR', 'ADMIN']
        },
        {
          id: 'appointments_times',
          title: 'Imposta orari',
          type: 'item',
          url: '/calendar/times',
          breadcrumbs: false,
          permission: ['DOCTOR']
        },
        {
          id: 'appointments_waiting_lists',
          title: "Lista d'attesa",
          type: 'item',
          url: '/calendar/waiting_lists',
          breadcrumbs: false,
          permission: ['DOCTOR', 'ADMIN']
        },
        {
          id: 'appointments_history',
          title: 'Storico',
          type: 'item',
          url: '/calendar/history',
          breadcrumbs: false,
          permission: ['DOCTOR', 'ADMIN']
        }
      ]
    }
  ]
};

export default calendar;
