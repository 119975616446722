/*eslint-disable*/
import { Box } from '@chakra-ui/react';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useShop } from '../../../hooks/Shop/useShop';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import UIInputAutocomplete, { UIInputSelect } from '../../utilities/InputSelect';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UIPageHeader } from '../../utilities/Headers';
import { UICheckbox, UIInput } from '../../utilities/Input';
import { UIFormControlBox } from '../../utilities/Form';
import { UIButton } from '../../utilities/Button';
import { UiDialog } from '../../utilities/Dialog';

registerLocale('it', it);

const ModalEdit = ({ modalOpen, handleModalClose, coupon }) => {
  const [couponData, setCouponData] = useState(coupon);
  const [accounts, setAccounts] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [filters, setFilters] = useState({
    query: ''
  });
  const [pageLoading, setPageLoading] = useState(true);

  const { onGetShopCouponAdmin, onUpdateShopCouponAdmin, onCreateShopCouponAdmin, onDeleteShopCouponAdmin } = useShop();
  const { onListUsersAdmin } = useAccounts();

  const getCoupon = async () => {
    if (!coupon) return;
    return await onGetShopCouponAdmin({
      coupon_uuid: coupon.uuid
    })
  }

  const listPatients = async () => {
    let res = await onListUsersAdmin({ filters });
    setAccounts(res);
  };

  const handleDeleteCoupon = async () => {
    onDeleteShopCouponAdmin({
      coupon_uuid: couponData.uuid
    }).then((res) => {
      if (res?.response) {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        handleModalCloseInternal();
      }
    });
  };

  const handleModalCloseInternal = () => {
    setCouponData(null);
    setAccounts([]);
    setFilters({
      query: ''
    });
    setPageLoading(true);
    handleModalClose();
  };

  useEffect(() => {
    if (!modalOpen) return;
    getCoupon().then((res) => {
      setCouponData(res);
      if(res?.account_id) {
        setFilters({
          query: res?.account_id ? `${res?.account?.name} ${res?.account?.surname}` : ''
        });
      } else {
        listPatients().then(() => {
          setPageLoading(false);
        });
      }
    });
  }, [modalOpen]);

  useEffect(() => {
    if (!modalOpen) return;
    listPatients().then(() => {
      setPageLoading(false);
    });
  }, [filters?.query]);

  const handleSubmit = async (values) => {
    if (!pageLoading) {
      const { code, discount_type, discount_value, redeem_limit, account_id, expiration_date, active } = values;

      let expiration = moment(expiration_date).utc(true);

      if (couponData?.uuid) {
        onUpdateShopCouponAdmin({
          coupon_uuid: couponData.uuid,
          coupon_data: {
            code,
            discount_type,
            discount_value: Number(discount_value),
            redeem_limit: Number(redeem_limit),
            account_id: account_id ?? null,
            expiration_date: expiration,
            active
          }
        }).then((res) => {
          if (res?.response) {
            toast(res?.response, {
              style: {
                fontSize: '14px',
                backgroundColor: '#00e676',
                color: '#ffffff'
              }
            });
            handleModalCloseInternal();
          }
        });
      } else {
        onCreateShopCouponAdmin({
          coupon_data: {
            code,
            discount_type,
            discount_value: Number(discount_value),
            redeem_limit: Number(redeem_limit),
            account_id: account_id ?? null,
            expiration_date: expiration,
            active
          }
        }).then((res) => {
          if (res?.response) {
            toast(res?.response, {
              style: {
                fontSize: '14px',
                backgroundColor: '#00e676',
                color: '#ffffff'
              }
            });
            handleModalCloseInternal();
          }
        });
      }
    }
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi eliminare definitivamente il coupon?'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleDeleteCoupon}
      />

      <UiModalInternal onClose={handleModalCloseInternal} isLoading={pageLoading}>
        <Formik
          initialValues={{
            code: couponData?.code ?? '',
            discount_type: couponData?.discount_type ?? '',
            discount_value: couponData?.discount_value ?? '',
            redeem_limit: couponData?.redeem_limit ?? undefined,
            account_id: couponData?.account_id ?? undefined,
            expiration_date: couponData?.expiration_date ? moment(Number(couponData?.expiration_date)) : null,
            active: couponData?.active ?? false
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().required('Inserisci il codice'),
            discount_type: Yup.string().required('Seleziona il tipo di sconto'),
            discount_value: Yup.string().required('Inserisci il valore dello sconto'),
            redeem_limit: Yup.string().nullable(),
            account_id: Yup.string().nullable(),
            expiration_date: Yup.string().nullable(),
            active: Yup.string().nullable()
          })}
          onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
          enableReinitialize
        >
          {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue, submitForm }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <UIPageHeader
                  title={coupon ? 'Modifica coupon' : 'Aggiungi coupon'}
                  sx={{
                    textAlign: 'center'
                  }}
                />

                <UIFormControlBox label={'Code'} error={Boolean(touched.code && errors.code)}>
                  <UIInput value={values.code} onChange={(e) => setFieldValue('code', e.target.value)} />
                </UIFormControlBox>

                <Box display={'flex'} w={'100%'}>
                  <UIFormControlBox label={'Sconto'} error={Boolean(touched.discount_value && errors.discount_value)}>
                    <UIInput value={values.discount_value} onChange={(e) => setFieldValue('discount_value', e.target.value)} />
                  </UIFormControlBox>
                  <UIFormControlBox label={'Sconto'} error={Boolean(touched.discount_type && errors.discount_type)}>
                    <UIInputSelect
                      name={'discount_type'}
                      fullWidth={true}
                      value={values?.discount_type}
                      onChange={(e) => setFieldValue('discount_type', e.target.value)}
                      options={[
                        {
                          value: 'percentage',
                          label: '%'
                        },
                        {
                          value: 'price',
                          label: '€'
                        }
                      ]}
                    />{' '}
                  </UIFormControlBox>
                </Box>

                <UIFormControlBox label={'Limite usi'} error={Boolean(touched.redeem_limit && errors.redeem_limit)}>
                  <UIInput value={values.redeem_limit} onChange={(e) => setFieldValue('redeem_limit', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Account associato'} error={Boolean(touched.account_id && errors.account_id)}>
                  <UIInputAutocomplete
                    value={Number(values?.account_id)}
                    setValue={(value) => setFieldValue('account_id', value?.id)}
                    options={accounts?.map((patient) => {
                      return {
                        id: Number(patient?.id),
                        label: `${patient?.name} ${patient?.surname} - ${patient?.email} - ${patient?.phone}`
                      };
                    })}
                    needTimeout={false}
                    onTextValueChange={(value) => {
                      if (!value) return;
                      setFilters({
                        ...filters,
                        query: value
                      });
                    }}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Data di scadenza'} error={Boolean(touched.expiration_date && errors.expiration_date)}>
                  <DatePicker
                    value={values?.expiration_date}
                    dateFormat="dd/MM/yyyy"
                    onChange={(res) => {
                      setFieldValue('expiration_date', res);
                    }}
                    locale="it"
                    style={{ width: '100%', border: '1px solid red' }}
                    onBlur={handleBlur}
                  />
                </UIFormControlBox>

                <UIFormControlBox sx={{}}>
                  <UICheckbox
                    name={'active'}
                    label={'Attivo'}
                    checked={values?.active}
                    onChange={(event) => setFieldValue('active', event.target.checked)}
                  />
                </UIFormControlBox>

                <UIModalButtons>
                  <UIButton type={'submit'} onClick={() => submitForm()} icon={<IconCheck stroke={'1.64'} size={'26px'} />} />

                  {coupon?.id && (
                    <UIButton
                      type={'submit'}
                      onClick={() => setDeleteMode(true)}
                      icon={<IconTrash stroke={'1.64'} size={'26px'} />}
                      sxCustom={{
                        bgcolor: '#FF646C'
                      }}
                    />
                  )}
                </UIModalButtons>
              </UIModalBody>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};
export default ModalEdit;
