import { Box, Typography } from '@mui/material';

export const UITypography = ({ variant = 'Oxygen700', size = '12px', title = '', sxCustom = {}, children = <></> }) => {
  let sx;

  switch (variant) {
    case 'Oxygen700':
      sx = {
        fontFamily: 'Oxygen',
        fontWeight: 700,
        color: '#667085',
        fontSize: size,
        ...sxCustom
      };
      break;
    case 'Oxygen400':
      sx = {
        fontFamily: 'Oxygen',
        fontWeight: 400,
        color: '#667085',
        fontSize: size,
        ...sxCustom
      };
      break;
    case 'Oxygen300':
      sx = {
        fontFamily: 'Oxygen',
        fontWeight: 400,
        color: '#667085',
        fontSize: size,
        ...sxCustom
      };
      break;
  }

  return <Typography sx={sx}>{title || children}</Typography>;
};

export const UITypographyBox = ({ title, subtitle = '', sx = {}, sxText = {}, icon = null, iconColor = null }) => {
  return (
    <Box
      sx={{
        width: '90%',
        m: '10px auto',
        p: '10px 15px',
        border: '1px solid #E3E3E3',
        borderRadius: '8px',
        bgcolor: '#EEF2F6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...sx
      }}
    >
      {icon && (
        <Box
          sx={{
            p: '0 10px 0 0',
            color: iconColor ?? '#667085'
          }}
        >
          {icon}
        </Box>
      )}

      <Box>
        <UITypography variant={'Oxygen700'} size={'13px'} sxCustom={sxText}>
          {title}
        </UITypography>
        <UITypography variant={'Oxygen400'} size={'13px'} sxCustom={sxText}>
          {subtitle}
        </UITypography>
      </Box>
    </Box>
  );
};

export default UITypography;
