import React, { useEffect, useState } from 'react';
import { Box, Link } from '@mui/material';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { UIPageHeader, SectionHeader } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import { IconEdit, IconPencil, IconPhone } from '@tabler/icons-react';
import { UiDialog } from '../../utilities/Dialog';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { useAuthentication } from '../../../utils/useAuthentication';
import ModalAppointmentDetails from '../modals/modalAppointmentDetails';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import UITypography from '../../utilities/Typography';
import ModalCompileAppointment from '../calendar/ModalCompileAppointment';

const CalendarHistoryView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;

  /**** GENERAL ****/
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [offices, setOffices] = useState([]);

  const { onListAppointmentsHistory } = useCalendar();
  const { onListTreatmentsDoctor, onListTreatmentsAdmin } = useTreatments();
  const { onListOffices } = useOffices();

  useEffect(() => {
    if (user.admin) {
      onListTreatmentsAdmin().then((res) => {
        setTreatments(res);
      });
    } else {
      onListTreatmentsDoctor().then((res) => {
        setTreatments(res);
      });
    }
    onListOffices().then((res) => {
      setOffices(res);
    });
  }, []);

  const handleModalClose = () => {
    setSelectedAppointment(null);
    setEditMode(false);
    setCompileMode(false);
    setForceUpdate(true);
  };

  /*** DIALOGS ***/
  const [editMode, setEditMode] = useState(false);
  const [dateMode, setDateMode] = useState(false);
  const [treatmentsMode, setTreatmentsMode] = useState(false);
  const [officeMode, setOfficeMode] = useState(false);
  const [phoneMode, setPhoneMode] = useState(false);
  const [compileMode, setCompileMode] = useState(false);

  /*** TABLE ***/
  const [forceUpdate, setForceUpdate] = useState(false);
  const [filtersData, setFiltersData] = useState({
    date: null,
    treatment_ids: [],
    office_id: null,
    doctor_id: user.id
  });

  const prepareValues = (appointments) => {
    let appointmentsArray = [];

    let analyzed_day = null;

    appointments?.map((appointment) => {
      if (analyzed_day !== moment(appointment?.date).format('YYYY-MM-DD')) {
        let new_date = moment(appointment?.date).format('YYYY-MM-DD');

        appointmentsArray?.push({
          appointment: null,
          appointmentEl: (
            <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {new_date}
            </Box>
          ),
          actionsEl: null,
          date: new_date
        });

        analyzed_day = new_date;
      }

      appointmentsArray?.push({
        appointment: appointment,
        appointmentEl: (
          <Box
            sx={{
              width: '100%'
            }}
          >
            <UITypography variant={'Oxygen700'} size={'14px'} title={appointment?.start_hour} />
            <UITypography
              variant={'Oxygen700'}
              size={'13px'}
              title={appointment?.patient ? `${appointment?.patient?.name} ${appointment?.patient?.surname}` : appointment?.patient_alias}
              sxCustom={{
                wordBreak: 'break-all',
                mt: '5px'
              }}
            />

            <Link
              href={`mailto:${appointment?.patient ? appointment?.patient?.email : appointment?.patient_email}`}
              target={'_blank'}
              rel={'noreferrer'}
              component={'a'}
              underline={'always'}
              color={'#000'}
            >
              <UITypography
                variant={'Oxygen700'}
                size={'11px'}
                title={appointment?.patient ? appointment?.patient?.email : appointment?.patient_email}
                sxCustom={{
                  mb: '5px',
                  wordBreak: 'break-all'
                }}
              />
            </Link>
            <UITypography
              variant={'Oxygen400'}
              size={'12px'}
              title={appointment?.treatment?.name}
              sxCustom={{
                wordBreak: 'break-all'
              }}
            />
            <UITypography
              variant={'Oxygen400'}
              size={'12px'}
              title={appointment?.calendar?.office?.name}
              sxCustom={{
                wordBreak: 'break-all'
              }}
            />
            <UITypography variant={'Oxygen400'} size={'12px'} title={appointment?.calendar?.office?.city?.name} />
          </Box>
        ),
        compiledEl: (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                borderRadius: '100%',
                width: '10px',
                height: '10px',
                bgcolor: appointment?.compiled ? '#00E676' : '#FE922E',
                mr: '5px'
              }}
            />
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedAppointment(appointment);
                setPhoneMode(true);
              }}
              icon={
                <IconPhone
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedAppointment(appointment);
                setEditMode(true);
              }}
              icon={
                <IconEdit
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedAppointment(appointment);
                setCompileMode(true);
              }}
              icon={
                <IconPencil
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
          </Box>
        )
      });
    });

    return appointmentsArray;
  };

  const filtersButtons = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px'
      }}
    >
      <Box width={'120px'}>
        <UIButton
          checked={filtersData?.date !== null}
          label={'Data'}
          type={'filter'}
          onClick={() => {
            setDateMode(true);
          }}
        />
      </Box>
      <Box width={'120px'}>
        <UIButton
          checked={filtersData?.treatment_ids?.length > 0}
          label={'Trattamento'}
          type={'filter'}
          onClick={() => {
            setTreatmentsMode(true);
          }}
        />
      </Box>
      <Box width={'120px'}>
        <UIButton
          checked={filtersData?.office_id !== null}
          label={'Studio'}
          type={'filter'}
          onClick={() => {
            setOfficeMode(true);
          }}
        />
      </Box>
    </Box>
  );

  const columns = [
    {
      name: <UITableHeadCell title={'Appuntamento'} />,
      value: 'appointmentEl',
      size: 100
    },
    {
      name: <UITableHeadCell title={'Compilato'} />,
      value: 'compiledEl',
      size: 80,
      muiTableHeadCellProps: {
        align: 'center'
      },
      muiTableBodyCellProps: {
        align: 'center'
      }
    },
    {
      name: '',
      value: 'actionsEl',
      size: 100
    }
  ];

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      {/*** MODALS ***/}
      <ModalAppointmentDetails modalOpen={editMode} handleModalClose={handleModalClose} appointment={selectedAppointment} />
      <ModalCompileAppointment appointment={selectedAppointment} handleModalClose={handleModalClose} modalOpen={compileMode} />
      {/*** DIALOGS ***/}
      <>
        {/*** PHONE DIALOG ***/}
        <UiDialog
          type={'phone'}
          title={`${selectedAppointment?.patient?.name} ${selectedAppointment?.patient?.surname}`}
          subtitle={`${selectedAppointment?.patient?.phone_country?.dial_code} ${selectedAppointment?.patient?.phone}`}
          open={phoneMode}
          labelConfirm={
            <>
              <IconPhone stroke={'1.64'} size={'20px'} />
              <UITypography
                variant={'Oxygen700'}
                title={'Chiama'}
                size={'14px'}
                sxCustom={{
                  ml: '5px',
                  color: '#344054'
                }}
              />
            </>
          }
          onClose={() => setPhoneMode(false)}
          onConfirm={() => {
            window.open(`tel:${selectedAppointment?.patient?.phone_country?.dial_code}${selectedAppointment?.patient?.phone}`, '_blank');
          }}
          onSecondary={() => {
            navigator.clipboard.writeText(
              `${selectedAppointment?.patient?.phone_country?.dial_code} ${selectedAppointment?.patient?.phone}`
            );
            toast('Numero copiato negli appunti', {
              style: {
                fontSize: '14px',
                backgroundColor: '#00e676',
                color: '#ffffff'
              }
            });
            setSelectedAppointment(null);
            setPhoneMode(false);
          }}
        />

        {/*** DATE FILTER DIALOG ***/}
        <UiDialog
          type={'date'}
          open={dateMode}
          onClose={() => setDateMode(false)}
          title={'Seleziona una data'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              date: value
            });
            setDateMode(false);
          }}
          value={filtersData.date}
          labelConfirm={'Applica'}
          secondaryButton={true}
          disableFuture={true}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              date: null
            });
            setDateMode(false);
          }}
        />

        {/*** TREATMENTS FILTER DIALOG ***/}
        <UiDialog
          type={'checkbox'}
          open={treatmentsMode}
          onClose={() => setTreatmentsMode(false)}
          title={'Seleziona trattamenti'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              treatment_ids: value
            });
            setTreatmentsMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={treatments?.map((treatment) => {
            return {
              label: treatment.name,
              value: treatment.id,
              checked: filtersData.treatment_ids.includes(treatment.id)
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              treatment_ids: []
            });
            setTreatmentsMode(false);
          }}
        />

        {/*** OFFICE FILTER DIALOG ***/}
        <UiDialog
          type={'radio'}
          open={officeMode}
          onClose={() => setOfficeMode(false)}
          title={'Seleziona ufficio'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              office_id: value
            });
            setOfficeMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={offices?.map((office) => {
            return {
              label: office.name,
              value: office.id,
              checked: filtersData.office_id === office.id
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              office_id: null
            });
            setOfficeMode(false);
          }}
        />
      </>

      {/*** HEADERS ***/}
      <>
        <UIPageHeader
          title={'Storico appuntamenti'}
          subtitle={'Qui puoi visualizzare gli appuntamenti passati e compilare il riepilogo per il paziente.'}
        />
        <SectionHeader title={'Paziente'} />
      </>

      {/*** TABLE ***/}
      <UITable
        columns={columns}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        filtersData={filtersData}
        filtersButtons={filtersButtons}
        infiniteScrollEndMessage={'Non ci sono altri appuntamenti da mostrare'}
        infiniteScrollFunction={onListAppointmentsHistory}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        muiTableBodyRowProps={(data) => {
          return {
            sx: {
              bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
              p: 0,
              '& td': data?.row?.original?.date
                ? {
                    p: '5px 20px'
                  }
                : {}
            }
          };
        }}
      />
    </Box>
  );
};
export default CalendarHistoryView;
