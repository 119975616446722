import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { IconPhone, IconTrash } from '@tabler/icons-react';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { useAuthentication } from '../../../utils/useAuthentication';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { UIButton } from '../../utilities/Button';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import { UiDialog } from '../../utilities/Dialog';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import UITypography from '../../utilities/Typography';

const CalendarWaitingListsView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;

  /**** GENERAL ****/
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [offices, setOffices] = useState([]);

  const { onListWaitingListAdmin, onDeleteWaitingListAdmin } = useCalendar();
  const { onListOffices } = useOffices();
  const { onListTreatmentsAdmin, onListTreatmentsDoctor } = useTreatments();

  const [dateMode, setDateMode] = useState(false);
  const [treatmentsMode, setTreatmentsMode] = useState(false);
  const [officeMode, setOfficeMode] = useState(false);
  const [phoneMode, setPhoneMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [initData, setInitData] = useState(null);

  useEffect(() => {
    if (user.admin) {
      onListTreatmentsAdmin().then((res) => {
        setTreatments(res);
      });
    } else {
      onListTreatmentsDoctor().then((res) => {
        setTreatments(res);
      });
    }
    onListOffices().then((res) => {
      setOffices(res);
    });
  }, []);

  /*** TABLE ***/
  const [forceUpdate, setForceUpdate] = useState(false);
  const [filtersData, setFiltersData] = useState({
    date: null,
    treatment_ids: [],
    office_id: null
  });

  const prepareValues = (appointments, plainData) => {
    if (plainData?.oldest_date) {
      setInitData(plainData?.oldest_date);
    }

    if (!appointments) return [];
    if (!plainData) return [];

    let appointmentsArray = [];

    let analyzed_day = initData ? moment(Number(initData)) : moment().subtract(365, 'days');
    let last_day = moment();

    while (analyzed_day.isBefore(last_day)) {
      let appointmentsData = appointments.filter(
        (appointment) => moment(Number(appointment?.createdAt)).format('YYYY-MM-DD') === analyzed_day.format('YYYY-MM-DD')
      );

      if (appointmentsData?.length > 0) {
        appointmentsArray?.push({
          appointment: null,
          patientEl: (
            <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {analyzed_day.format('DD MMMM YYYY')}
            </Box>
          ),
          actionsEl: null,
          date: analyzed_day.format('YYYY-MM-DD')
        });

        appointmentsArray?.push(
          ...appointmentsData.map((appointment) => {
            return {
              appointment: appointment,
              patientEl: (
                <Box>
                  <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                    {`${appointment?.patient?.name} ${appointment?.patient?.surname}`}
                  </Typography>
                  <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'14px'}>
                    {`${appointment?.treatment?.name}`}
                  </Typography>
                  <Typography
                    fontFamily={'Oxygen'}
                    fontWeight={'700'}
                    fontSize={'12px'}
                    sx={{
                      textDecoration: 'underline'
                    }}
                  >
                    {`${appointment?.patient?.email}`}
                  </Typography>
                </Box>
              ),
              officeEl: (
                <Box>
                  <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                    {appointment?.calendar?.office?.city?.name}{' '}
                  </Typography>
                  <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'14px'}>
                    {appointment?.calendar?.doctor?.name} {appointment?.calendar?.doctor?.surname}
                  </Typography>
                  <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'14px'}>
                    {appointment?.calendar?.office?.name}
                  </Typography>
                </Box>
              ),
              actionsEl: (
                <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setPhoneMode(true);
                    }}
                    icon={
                      <IconPhone
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setDeleteMode(true);
                    }}
                    icon={
                      <IconTrash
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                </Box>
              )
            };
          })
        );
      }

      analyzed_day.add(1, 'days');
    }

    return appointmentsArray;
  };

  const filtersButtons = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px'
      }}
    >
      <Box
        sx={{
          width: '120px'
        }}
      >
        <UIButton
          checked={filtersData?.date !== null}
          label={'Data'}
          type={'filter'}
          onClick={() => {
            setDateMode(true);
          }}
        />
      </Box>
      <Box
        sx={{
          width: '120px'
        }}
      >
        <UIButton
          checked={filtersData?.treatment_ids?.length > 0}
          label={'Trattamento'}
          type={'filter'}
          onClick={() => {
            setTreatmentsMode(true);
          }}
        />
      </Box>
      <Box
        sx={{
          width: '120px'
        }}
      >
        <UIButton
          checked={filtersData?.office_id !== null}
          label={'Studio'}
          type={'filter'}
          onClick={() => {
            setOfficeMode(true);
          }}
        />
      </Box>
    </Box>
  );

  const columns = [
    {
      name: <UITableHeadCell title={'Paziente'} />,
      value: 'patientEl',
      size: 120
    },
    {
      name: <UITableHeadCell title={'Studio'} />,
      value: 'officeEl',
      size: 100
    },
    {
      name: '',
      value: 'actionsEl',
      size: 80
    }
  ];

  const handleDeleteAppointment = () => {
    onDeleteWaitingListAdmin({ waiting_list_id: selectedAppointment?.id }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
      setSelectedAppointment(null);
      setDeleteMode(false);
      setForceUpdate(true);
    });
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      {/*** DIALOGS ***/}
      <>
        {/*** CONFIRM DIALOG ***/}
        <UiDialog
          type={'default'}
          variant={'alert'}
          title={'Vuoi rimuovere il paziente dalla lista d’attesa?'}
          open={deleteMode}
          onClose={() => setDeleteMode(false)}
          onConfirm={handleDeleteAppointment}
        />

        {/*** PHONE DIALOG ***/}
        <UiDialog
          type={'phone'}
          title={`${selectedAppointment?.patient?.name} ${selectedAppointment?.patient?.surname}`}
          subtitle={`${selectedAppointment?.patient?.phone_country?.dial_code} ${selectedAppointment?.patient?.phone}`}
          open={phoneMode}
          labelConfirm={
            <>
              <IconPhone stroke={'1.64'} size={'20px'} />
              <UITypography
                variant={'Oxygen700'}
                title={'Chiama'}
                size={'14px'}
                sxCustom={{
                  ml: '5px',
                  color: '#344054'
                }}
              />
            </>
          }
          onClose={() => setPhoneMode(false)}
          onConfirm={() => {
            window.open(`tel:${selectedAppointment?.patient?.phone_country?.dial_code}${selectedAppointment?.patient?.phone}`, '_blank');
          }}
          onSecondary={() => {
            navigator.clipboard.writeText(
              `${selectedAppointment?.patient?.phone_country?.dial_code} ${selectedAppointment?.patient?.phone}`
            );
            toast('Numero copiato negli appunti', {
              style: {
                fontSize: '14px',
                backgroundColor: '#00e676',
                color: '#ffffff'
              }
            });
            setSelectedAppointment(null);
            setPhoneMode(false);
          }}
        />

        {/*** DATE FILTER DIALOG ***/}
        <UiDialog
          type={'date'}
          open={dateMode}
          onClose={() => setDateMode(false)}
          title={"Seleziona la data di inserimento in lista d'attesa"}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              date: value
            });
            setDateMode(false);
          }}
          value={filtersData.date}
          labelConfirm={'Applica'}
          secondaryButton={true}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              date: null
            });
            setDateMode(false);
          }}
        />

        {/*** TREATMENTS FILTER DIALOG ***/}
        <UiDialog
          type={'checkbox'}
          open={treatmentsMode}
          onClose={() => setTreatmentsMode(false)}
          title={'Seleziona trattamenti'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              treatment_ids: value
            });
            setTreatmentsMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={treatments?.map((treatment) => {
            return {
              label: treatment.name,
              value: treatment.id,
              checked: filtersData.treatment_ids.includes(treatment.id)
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              treatment_ids: []
            });
            setTreatmentsMode(false);
          }}
        />

        {/*** OFFICE FILTER DIALOG ***/}
        <UiDialog
          type={'radio'}
          open={officeMode}
          onClose={() => setOfficeMode(false)}
          title={'Seleziona ufficio'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              office_id: value
            });
            setOfficeMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={offices?.map((office) => {
            return {
              label: office.name,
              value: office.id,
              checked: filtersData.office_id === office.id
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              office_id: null
            });
            setOfficeMode(false);
          }}
        />
      </>

      {/*** HEADERS ***/}
      <>
        <UIPageHeader title={'Liste d’attesa'} subtitle={'Qui puoi visualizzare chi si è messo in attesa per avere un appuntamento.'} />
        <SectionHeader title={'Paziente'} />
      </>

      {/*** TABLE ***/}
      <UITable
        columns={columns}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        filtersData={filtersData}
        filtersButtons={filtersButtons}
        infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
        infiniteScrollFunction={onListWaitingListAdmin}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        muiTableBodyRowProps={(data) => {
          return {
            sx: {
              bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
              p: 0,
              '& td': data?.row?.original?.date
                ? {
                    p: '5px 20px'
                  }
                : {}
            }
          };
        }}
      />
    </Box>
  );
};
export default CalendarWaitingListsView;
