import React, { useCallback, useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import './custom-doctors.css';
import { IconEdit, IconPhoto, IconPlus } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import ModalDoctorAdmin from './modalDoctorAdmin';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import ModalUserPicsDoctor from './ModalUserPicsDoctor';

const DoctorsView = () => {
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [picsMode, setPicsMode] = useState(false);

  const { onListDoctors } = useAccounts();

  useEffect(() => {
    onListDoctors().then((res) => {
      setDoctors(res);
    });
  }, []);

  const handleModalClose = useCallback(() => {
    onListDoctors().then((res) => {
      setDoctors(res);
      setEditMode(false);
      setPicsMode(false);
    });
  }, []);

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'} position={'relative'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedDoctor(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>

      <ModalDoctorAdmin modalOpen={editMode} handleModalClose={handleModalClose} doctor={selectedDoctor} setEditMode={setEditMode} />
      <ModalUserPicsDoctor modalOpen={picsMode} handleModalClose={handleModalClose} user={selectedDoctor} />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Dottori
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {doctors?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'}>
                  {row.name} {row.surname}
                </TableCell>
                <TableCell align="center">
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Box
                      mr={2}
                      onClick={() => {
                        setSelectedDoctor(row);
                        setPicsMode(true);
                      }}
                    >
                      <IconPhoto cursor={'pointer'} />
                    </Box>
                    <Box
                      onClick={() => {
                        setSelectedDoctor(row);
                        setEditMode(true);
                      }}
                    >
                      <IconEdit cursor={'pointer'} />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default DoctorsView;
