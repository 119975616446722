import {
  CREATE_PRODUCTS_TARGET_ADMIN,
  DELETE_PRODUCTS_TARGET_ADMIN,
  LIST_PRODUCTS_TARGETS_ADMIN,
  UPDATE_PRODUCTS_TARGET_ADMIN
} from './graphql/queries';
import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';

/** @format */
export const useProductsTargets = () => {
  const { getDataGQL: listProductsTargetsAdmin } = useQuery(LIST_PRODUCTS_TARGETS_ADMIN);
  const { getDataGQL: createProductTargetAdmin } = useMutation(CREATE_PRODUCTS_TARGET_ADMIN);
  const { getDataGQL: updateProductTargetAdmin } = useMutation(UPDATE_PRODUCTS_TARGET_ADMIN);
  const { getDataGQL: deleteProductTargetAdmin } = useMutation(DELETE_PRODUCTS_TARGET_ADMIN);

  const onListProductsTargetsAdmin = async () => {
    const response = await listProductsTargetsAdmin();
    if (response?.adminRoute?.listProductsTargetsAdmin) {
      return response?.adminRoute?.listProductsTargetsAdmin;
    }
    return null;
  };

  const onCreateProductTargetAdmin = async ({ product_target_data }) => {
    const response = await createProductTargetAdmin({
      variables: {
        product_target_data
      }
    });
    if (response?.adminRoute?.createProductTargetAdmin) {
      return response?.adminRoute?.createProductTargetAdmin;
    }
    return null;
  };

  const onUpdateProductTargetAdmin = async ({ product_target_id, product_target_data }) => {
    const response = await updateProductTargetAdmin({
      variables: {
        product_target_id,
        product_target_data
      }
    });
    if (response?.adminRoute?.updateProductTargetAdmin) {
      return response?.adminRoute?.updateProductTargetAdmin;
    }
    return null;
  };

  const onDeleteProductTargetAdmin = async ({ product_target_id }) => {
    const response = await deleteProductTargetAdmin({
      variables: {
        product_target_id
      }
    });
    if (response?.adminRoute?.deleteProductTargetAdmin) {
      return response?.adminRoute?.deleteProductTargetAdmin;
    }
    return null;
  };

  return {
    onListProductsTargetsAdmin,
    onCreateProductTargetAdmin,
    onUpdateProductTargetAdmin,
    onDeleteProductTargetAdmin
  };
};
