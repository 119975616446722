// assets
import QrCodeIcon from '@mui/icons-material/QrCode';
// constant
const icons = { QrCodeIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const products = {
  id: 'products',
  type: 'group',
  permission: ['ADMIN'],
  children: [
    {
      id: 'products_menu',
      title: 'Prodotti',
      type: 'collapse',
      icon: icons.QrCodeIcon,
      breadcrumbs: false,
      permission: ['ADMIN'],
      children: [
        {
          id: 'products_list',
          title: 'Prodotti',
          type: 'item',
          url: '/products',
          permission: ['ADMIN'],
          icon: icons.QrCodeIcon,
          breadcrumbs: false
        },
        {
          id: 'products_target',
          title: 'Target',
          type: 'item',
          url: '/products/targets',
          permission: ['ADMIN'],
          icon: icons.QrCodeIcon,
          breadcrumbs: false
        },
        {
          id: 'products_devices',
          title: 'Dispositivi',
          type: 'item',
          url: '/products/devices',
          permission: ['ADMIN'],
          icon: icons.QrCodeIcon,
          breadcrumbs: false
        },
        {
          id: 'products_techniques',
          title: 'Tecniche',
          type: 'item',
          url: '/products/techniques',
          permission: ['ADMIN'],
          icon: icons.QrCodeIcon,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default products;
