import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { UIPageHeader, SectionHeader } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import { IconEdit, IconPhone, IconThumbUp } from '@tabler/icons-react';
import { UiDialog } from '../../utilities/Dialog';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { useAuthentication } from '../../../utils/useAuthentication';
import ModalAppointmentDetails from '../modals/modalAppointmentDetails';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import UITypography from '../../utilities/Typography';

const CalendarNearestView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;

  /**** GENERAL ****/
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [offices, setOffices] = useState([]);

  const { onListNearestAppointments, onUpdateAppointmentStatus } = useCalendar();
  const { onListTreatmentsDoctor, onListTreatmentsAdmin } = useTreatments();
  const { onListOffices } = useOffices();

  useEffect(() => {
    if (user.admin) {
      onListTreatmentsAdmin().then((res) => {
        setTreatments(res);
      });
    } else {
      onListTreatmentsDoctor().then((res) => {
        setTreatments(res);
      });
    }
    onListOffices().then((res) => {
      setOffices(res);
    });
  }, []);

  const handleModalClose = () => {
    setSelectedAppointment(null);
    setEditMode(false);
  };

  const handleConfirmAppointment = async () => {
    onUpdateAppointmentStatus({
      appointment_id: selectedAppointment.id,
      status: 'confirmed'
    }).then(() => {
      setSelectedAppointment(null);
      setConfirmMode(false);
      setForceUpdate(true);
    });
  };

  /*** DIALOGS ***/
  const [editMode, setEditMode] = useState(false);
  const [dateMode, setDateMode] = useState(false);
  const [treatmentsMode, setTreatmentsMode] = useState(false);
  const [officeMode, setOfficeMode] = useState(false);
  const [confirmMode, setConfirmMode] = useState(false);
  const [phoneMode, setPhoneMode] = useState(false);

  /*** TABLE ***/
  const [forceUpdate, setForceUpdate] = useState(false);
  const [filtersData, setFiltersData] = useState({
    date: null,
    treatment_ids: [],
    office_id: null
  });

  const prepareValues = (appointments) => {
    let appointmentsArray = [];

    let last_day = moment().add(4, 'days').format('YYYY-MM-DD');
    let analyzed_day = moment();

    while (analyzed_day.isBefore(last_day)) {
      let appointmentsData = appointments.filter((appointment) => appointment?.date === analyzed_day.format('YYYY-MM-DD'));

      if (appointmentsData.length > 0) {
        appointmentsArray?.push({
          appointment: null,
          appointmentEl: (
            <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {analyzed_day.format('DD MMMM YYYY')}
            </Box>
          ),
          actionsEl: null,
          date: analyzed_day.format('YYYY-MM-DD')
        });

        appointmentsArray?.push(
          ...appointmentsData.map((appointment) => {
            return {
              appointment: appointment,
              appointmentEl: (
                <Box>
                  <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                    {`${appointment?.start_hour}`}
                    <span
                      style={{
                        fontWeight: 300,
                        marginLeft: '5px'
                      }}
                    >
                      {appointment?.treatment?.name}
                    </span>
                  </Typography>
                  <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                    {appointment?.patient_id
                      ? `${appointment?.patient?.name} ${appointment?.patient?.surname}`
                      : appointment?.patient_alias}
                  </Typography>
                </Box>
              ),
              actionsEl: (
                <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setPhoneMode(true);
                    }}
                    icon={
                      <IconPhone
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setEditMode(true);
                    }}
                    icon={
                      <IconEdit
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setConfirmMode(true);
                    }}
                    icon={
                      <IconThumbUp
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                </Box>
              )
            };
          })
        );
      }

      analyzed_day.add(1, 'days');
    }

    return appointmentsArray;
  };

  const filtersButtons = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px'
      }}
    >
      <Box
        sx={{
          width: '120px'
        }}
      >
        <UIButton
          checked={filtersData?.date !== null}
          label={'Data'}
          type={'filter'}
          onClick={() => {
            setDateMode(true);
          }}
        />
      </Box>
      <Box
        sx={{
          width: '120px'
        }}
      >
        <UIButton
          checked={filtersData?.treatment_ids?.length > 0}
          label={'Trattamento'}
          type={'filter'}
          onClick={() => {
            setTreatmentsMode(true);
          }}
        />
      </Box>
      <Box
        sx={{
          width: '120px'
        }}
      >
        <UIButton
          checked={filtersData?.office_id !== null}
          label={'Studio'}
          type={'filter'}
          onClick={() => {
            setOfficeMode(true);
          }}
        />
      </Box>
    </Box>
  );

  const columns = [
    {
      name: <UITableHeadCell title={'Appuntamento'} />,
      value: 'appointmentEl'
    },
    {
      name: '',
      value: 'actionsEl'
    }
  ];

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      {/*** MODALS ***/}
      <ModalAppointmentDetails modalOpen={editMode} handleModalClose={handleModalClose} appointment={selectedAppointment} />

      {/*** DIALOGS ***/}
      <>
        {/*** CONFIRM DIALOG ***/}
        <UiDialog
          type={'default'}
          variant={'alert'}
          title={'Vuoi confermare l’appuntamento al posto del paziente?'}
          open={confirmMode}
          onClose={() => setConfirmMode(false)}
          onConfirm={handleConfirmAppointment}
        />

        {/*** PHONE DIALOG ***/}
        <UiDialog
          type={'phone'}
          title={`${selectedAppointment?.patient?.name} ${selectedAppointment?.patient?.surname}`}
          subtitle={`${selectedAppointment?.patient?.phone_country?.dial_code} ${selectedAppointment?.patient?.phone}`}
          open={phoneMode}
          labelConfirm={
            <>
              <IconPhone stroke={'1.64'} size={'20px'} />
              <UITypography
                variant={'Oxygen700'}
                title={'Chiama'}
                size={'14px'}
                sxCustom={{
                  ml: '5px',
                  color: '#344054'
                }}
              />
            </>
          }
          onClose={() => setPhoneMode(false)}
          onConfirm={() => {
            window.open(`tel:${selectedAppointment?.patient?.phone_country?.dial_code}${selectedAppointment?.patient?.phone}`, '_blank');
          }}
          onSecondary={() => {
            navigator.clipboard.writeText(
              `${selectedAppointment?.patient?.phone_country?.dial_code} ${selectedAppointment?.patient?.phone}`
            );
            toast('Numero copiato negli appunti', {
              style: {
                fontSize: '14px',
                backgroundColor: '#00e676',
                color: '#ffffff'
              }
            });
            setSelectedAppointment(null);
            setPhoneMode(false);
          }}
        />

        {/*** DATE FILTER DIALOG ***/}
        <UiDialog
          type={'date'}
          open={dateMode}
          onClose={() => setDateMode(false)}
          title={'Seleziona una data'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              date: value
            });
            setDateMode(false);
          }}
          value={filtersData.date}
          labelConfirm={'Applica'}
          secondaryButton={true}
          maxDate={moment().add(3, 'days')}
          disablePast={true}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              date: null
            });
            setDateMode(false);
          }}
        />

        {/*** TREATMENTS FILTER DIALOG ***/}
        <UiDialog
          type={'checkbox'}
          open={treatmentsMode}
          onClose={() => setTreatmentsMode(false)}
          title={'Seleziona trattamenti'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              treatment_ids: value
            });
            setTreatmentsMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={treatments?.map((treatment) => {
            return {
              label: treatment.name,
              value: treatment.id,
              checked: filtersData.treatment_ids.includes(treatment.id)
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              treatment_ids: []
            });
            setTreatmentsMode(false);
          }}
        />

        {/*** OFFICE FILTER DIALOG ***/}
        <UiDialog
          type={'radio'}
          open={officeMode}
          onClose={() => setOfficeMode(false)}
          title={'Seleziona ufficio'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              office_id: value
            });
            setOfficeMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={offices?.map((office) => {
            return {
              label: office.name,
              value: office.id,
              checked: filtersData.office_id === office.id
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              office_id: null
            });
            setOfficeMode(false);
          }}
        />
      </>

      {/*** HEADERS ***/}
      <>
        <UIPageHeader
          title={'Appuntamenti non confermati'}
          subtitle={
            'Qui puoi visualizzare gli appuntamenti che non sono stati confermati dagli utenti entro 3 giorni dalla data dell’appuntamento.'
          }
        />
        <SectionHeader title={'Paziente'} />
      </>

      {/*** TABLE ***/}
      <UITable
        columns={columns}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        filtersData={filtersData}
        filtersButtons={filtersButtons}
        infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
        infiniteScrollFunction={onListNearestAppointments}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        muiTableBodyRowProps={(data) => {
          return {
            sx: {
              bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
              p: 0,
              '& td': data?.row?.original?.date
                ? {
                    p: '5px 20px'
                  }
                : {}
            }
          };
        }}
      />
    </Box>
  );
};
export default CalendarNearestView;
