import gql from 'graphql-tag';

export const UPLOAD_ICON = gql`
  query uploadIcon($filename: String!, $contentType: String!, $folder: String!) {
    adminRoute {
      uploadIcon(filename: $filename, contentType: $contentType, folder: $folder) {
        presignedUrl
        key
      }
    }
  }
`;

export const LIST_COUNTRIES = gql`
  query listCountries {
    adminRoute {
      listCountries {
        id
        name
        dial_code
      }
    }
  }
`;
