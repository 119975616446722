import gql from 'graphql-tag';

export const Login = gql`
  query Login($email: String!, $password: String!, $device_id: String!) {
    login(email: $email, password: $password, device_id: $device_id, backoffice: true) {
      response
      responseStatus
      token
      account_data {
        on_boarding_complete
        name
        surname
        email
        id
        uuid
        admin
        doctor
        clinic
        reviews_average
      }
    }
  }
`;

export const Me = gql`
  query GetMe {
    getMe {
      id
      name
      surname
      phone
      email
      profile_pic
      age
      sex
      on_boarding_complete
      uuid
      total_appointments
      formation
      about_me
      instagram_link
      doctor_specialization
      survey_enabled
      reviews_average
      email_confirmed_appointment
      email_new_appointment
      email_rejected_appointment
      appointments_direct_delete
    }
  }
`;
