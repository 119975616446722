import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { UIModal, UiModalInternal, UIModalHeader, UIModalBody } from '../../utilities/Modal';
import { UITypography } from '../../utilities/Typography';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import { useShop } from '../../../hooks/Shop/useShop';
import { Box } from '@mui/material';
import moment from 'moment/moment';

const ModalEarningsMonth = ({ modalOpen, handleModalClose, commission }) => {
  let [commissionData, setCommissionData] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  let { onGetCommissionItems } = useShop();

  useEffect(() => {
    if (commission) {
      setCommissionData(commission);
    }
  }, [commission]);

  useEffect(() => {
    setForceUpdate(true);
  }, [commissionData]);

  const handleModalCloseInternal = () => {
    setCommissionData(null);
    handleModalClose();
  };

  const columns = [
    {
      name: <UITableHeadCell title={'Prodotto'} />,
      value: 'productEl',
      size: 150
    },
    {
      name: <UITableHeadCell title={'Guadagno'} />,
      value: 'earningEl',
      size: 100,

      muiTableHeadCellProps: {
        align: 'right'
      },
      muiTableBodyCellProps: {
        align: 'right'
      }
    }
  ];
  const prepareValues = (commissions) => {
    let last_day = null;
    let commissionArray = [];
    let total = 0;

    commissions?.forEach((commission) => {
      let createdAtDay = moment(Number(commission?.commissionAt));

      if (last_day === null || last_day.format('DD/MM/YYYY') !== createdAtDay.format('DD/MM/YYYY')) {
        if (last_day !== null) {
          commissionArray?.push({
            commission,
            productEl: (
              <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                TOTAL
              </Box>
            ),
            earningEl: `${total.toFixed(2)}€`,
            total: true
          });
        }
        commissionArray?.push({
          commission,
          productEl: (
            <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {createdAtDay.format('DD MMMM YYYY')}
            </Box>
          ),
          earningEl: null,
          date: createdAtDay.format('YYYY-MM-DD')
        });

        last_day = createdAtDay;
        total = 0;
      }

      total += commission?.amount;

      commissionArray?.push({
        commission,
        productEl: (
          <Box>
            <UITypography
              variant={'Oxygen700'}
              sxCustom={{
                color: '#101828'
              }}
              title={commission?.item?.variant?.product?.brand?.[0]?.name}
            />
            <UITypography
              variant={'Oxygen400'}
              title={commission?.item?.variant?.product?.name}
              sxCustom={{
                color: '#101828'
              }}
            />
          </Box>
        ),
        earningEl: <UITypography title={`${commission?.amount?.toFixed(2)}€`} />
      });
    });

    commissionArray?.push({
      commission,
      productEl: (
        <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
          TOTAL
        </Box>
      ),
      earningEl: `${total.toFixed(2)}€`,
      total: true
    });

    return commissionArray;
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiModalInternal onClose={handleModalCloseInternal}>
        <UIModalHeader
          sx={{
            bgcolor: '#FFF'
          }}
        >
          <UITypography
            variant={'Oxygen700'}
            title={moment(commission?.month).format('MMMM')}
            size={'15px'}
            sxCustom={{
              textAlign: 'center',
              color: '#344054',
              textTransform: 'uppercase'
            }}
          />
          <UITypography
            variant={'Oxygen700'}
            title={'Riepilogo mese'}
            size={'15px'}
            sxCustom={{
              textAlign: 'center',
              color: '#101828',
              mt: '5px'
            }}
          />
        </UIModalHeader>
        <UIModalBody
          buttonsSpace={false}
          sx={{
            bgcolor: 'transparent',
            p: '10px 20px'
          }}
        >
          <UITable
            columns={columns}
            prepareValues={prepareValues}
            enableInfiniteScroll={false}
            infiniteScrollFunction={onGetCommissionItems}
            callData={{
              month: commission?.month
            }}
            tableHeight={'auto'}
            forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate}
            muiTableBodyRowProps={(data) => {
              let row =
                data?.row?.original?.date || data?.row?.original?.total
                  ? {
                      p: '5px 20px'
                    }
                  : {
                      p: '10px 20px'
                    };

              return {
                sx: {
                  bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
                  '& td': {
                    ...row,
                    border: 'none',
                    borderTop: data?.row?.original?.total ? '1px solid #E3E3E3' : 'none'
                  }
                }
              };
            }}
          />
        </UIModalBody>
      </UiModalInternal>
    </UIModal>
  );
};

export default ModalEarningsMonth;
