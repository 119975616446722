import { Box } from '@chakra-ui/react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Modal, Typography } from '@mui/material';
import { IconBellMinus, IconEdit, IconTrashX, IconUserCircle } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ModalEdit from './modalEdit';
import { toast } from 'react-toastify';
import { useShop } from '../../../hooks/Shop/useShop';

const ModalDetails = ({ modalOpen, handleModalClose, review }) => {
  let [reviewData, setReviewData] = useState(null);
  let [editMode, setEditMode] = useState(false);
  let [deleteMode, setDeleteMode] = useState(false);
  let [ignoreMode, setIgnoreMode] = useState(false);
  let [product, setProduct] = useState(null);
  let [reviewer, setReviewer] = useState(null);
  let [reports, setReports] = useState(null);

  const { onGetShopProductReview, onSetShopHiddenReview, onSetShopIgnoredReviewReports } = useShop();

  const getReview = () => {
    onGetShopProductReview({ review_uuid: review?.uuid }).then((res) => {
      setReviewData(res);
      setReviewer(res?.reviewer);
      setProduct(res?.product);
      setReports(res?.reported_by);
    });
  };

  useEffect(() => {
    if (!review) return;
    getReview();
  }, [review]);

  const handleDeleteReview = () => {
    onSetShopHiddenReview({ review_uuid: reviewData?.uuid }).then((res) => {
      if (res?.response) {
        getReview();
        handleModalClose();
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
      }
    });
  };

  const handleIgnoreReview = () => {
    onSetShopIgnoredReviewReports({ review_uuid: reviewData?.uuid }).then((res) => {
      if (res?.response) {
        getReview();
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
      }
    });
  };

  const handleModalEditClose = () => {
    setEditMode(false);
    getReview();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box w={'100%'} h={'100%'} bg={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'} pointerEvents={'none'}>
        <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
          <DialogTitle>Elimina Recensione</DialogTitle>
          <DialogContent>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <DialogContentText>Vuoi eliminare questa recensione?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
              Annulla
            </Button>
            <Button
              variant={'contained'}
              color={'success'}
              onClick={() => {
                handleDeleteReview();
                setDeleteMode(false);
              }}
            >
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={ignoreMode} onClose={() => setIgnoreMode(false)}>
          <DialogTitle>Ignora Segnalazioni</DialogTitle>
          <DialogContent>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <DialogContentText>Vuoi rimuovere le segnalazioni?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant={'contained'} color={'error'} onClick={() => setIgnoreMode(false)}>
              Annulla
            </Button>
            <Button
              variant={'contained'}
              color={'success'}
              onClick={() => {
                handleIgnoreReview();
                setIgnoreMode(false);
              }}
            >
              Conferma
            </Button>
          </DialogActions>
        </Dialog>

        <ModalEdit handleModalClose={handleModalEditClose} modalOpen={editMode} reviewData={reviewData} />
        <Box
          className={'container'}
          w={'85%'}
          h={'70%'}
          bg={'white'}
          borderRadius={'20px'}
          pointerEvents={'all'}
          position={'relative'}
          overflow={'hidden'}
        >
          <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
            <Box
              position={'absolute'}
              top={'2%'}
              right={'2%'}
              w={'20px'}
              h={'20px'}
              bg={'lightgrey'}
              borderRadius={'20px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              fontWeight={'bold'}
              cursor={'pointer'}
              onClick={handleModalClose}
            >
              <Typography>X</Typography>
            </Box>

            <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Prodotto:
            </Typography>
            <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
              <CalendarMonthIcon stroke={1.5} size="1.3rem" />
              <Box ml={10}>
                <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                  {product?.name} - {product?.brand?.[0]?.name}
                </Typography>
              </Box>
            </Box>
            <Typography id="modal-modal-title" variant="h4" mt={1} ml={1.5}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Recensione di:
            </Typography>
            <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
              <IconUserCircle stroke={1.5} size="1.3rem" />
              <Box ml={10}>
                <Typography id="modal-modal-description" variant="body1">
                  {reviewer?.name} {reviewer?.surname}
                </Typography>
                <a href={`tel:${reviewer?.phone_country?.dial_code}${reviewer?.phone}`}>
                  <Typography id="modal-modal-description">
                    {reviewer?.phone_country?.dial_code} {reviewer?.phone}
                  </Typography>
                </a>
                <Typography id="modal-modal-description">{reviewer?.email}</Typography>
              </Box>
            </Box>
            {reports?.length > 0 && (
              <>
                <Typography id="modal-modal-title" variant="h4" mt={1} ml={1.5}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Segnalato da:
                </Typography>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconUserCircle stroke={1.5} size="1.3rem" />
                  <Box ml={10}>
                    {reports?.length > 1 ? (
                      <Typography id="modal-modal-description" variant="body1">
                        Segnalato da {reports?.length} utenti
                      </Typography>
                    ) : (
                      <>
                        <Typography id="modal-modal-description" variant="body1">
                          {reports?.[0]?.name} {reports?.[0]?.surname}
                        </Typography>
                        <a href={`tel:${reports?.[0]?.phone_country?.dial_code}${reports?.[0]?.phone}`}>
                          <Typography id="modal-modal-description">
                            {reports?.[0]?.phone_country?.dial_code} {reports?.[0]?.phone}
                          </Typography>
                        </a>
                        <Typography id="modal-modal-description">{reports?.[0]?.email}</Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
            <Typography id="modal-modal-title" variant="h4" mt={1} ml={1.5}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Recensione:
            </Typography>
            <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
              <Box ml={10}>
                <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'}>
                  Stelle: {reviewData?.rating}
                </Typography>
                <Typography id="modal-modal-description">{reviewData?.review}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            className={'bottom_buttons'}
            h={'50px'}
            position={'absolute'}
            bottom={0}
            w={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={20}
          >
            <Button
              variant={'contained'}
              style={{
                backgroundColor: 'grey',
                color: 'white'
              }}
              onClick={() => setIgnoreMode(true)}
            >
              <IconBellMinus stroke={1.5} size="1rem" />
            </Button>
            <Button variant={'contained'} onClick={() => setEditMode(true)}>
              <IconEdit stroke={1.5} size="1rem" />
            </Button>
            <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(true)}>
              <IconTrashX stroke={1.5} size="1rem" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default ModalDetails;
