import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import UIInputSearch from './InputSearch';
import { Box } from '@mui/material';
import { IconArrowDown } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import UITypography from './Typography';

const UITable = ({
  prepareValues,
  columns,
  enableInfiniteScroll = true,
  enableSearch = false,
  searchLabel = null,
  searchSubtitle = null,
  enableCustomFilters = false,
  filtersButtons = <></>,
  filtersData = null,
  tableHeight = '50vh',
  infiniteScrollFunction = () => {},
  callData = {},
  enableRowHoverColor = true,
  rowClick = () => {},
  enableTopToolbar = false,
  enablePagination = false,
  enableBottomToolbar = false,
  enableRowVirtualization = true,
  enableColumnActions = false,
  enableColumnFilters = false,
  enableFilters = false,
  enableSorting = false,
  enableRowActions = false,
  enableHiding = false,
  enableFullScreenToggle = false,
  enableDensityToggle = false,
  minifiedToolbar = false,
  customLimit = 25,
  enableExpanding = false,
  enableExpandAll = false,
  stateExpanded = false,
  enableExpandingColumn = false,
  muiTableBodyRowProps = () => {},
  muiTableHeadProps = () => {},
  muiTablePaperPropsSx = {},
  forceUpdate = false,
  setForceUpdate = () => {},
  setExternalLoading = () => {}
}) => {
  const tableContainerRef = useRef(null);
  const rowVirtualizerInstanceRef = useRef(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [sorting, setSorting] = useState([]);
  const [columnsOpt, setColumnsOpt] = useState([]);
  const [data, setData] = useState([]);
  const [plainData, setPlainData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [limit] = useState(customLimit);

  const flatData = useMemo(() => data ?? [], [data]);

  const prepareColumnsOpt = () => {
    const newColumns = columns?.map((column) => {
      return {
        header: column?.name,
        accessorKey: column?.value,
        ...column
      };
    });
    setColumnsOpt(newColumns);
  };

  const prepareData = () => {
    setData(prepareValues(rawData, plainData));
  };

  useEffect(() => {
    prepareColumnsOpt();
  }, [columns]);

  useEffect(() => {
    prepareData();
  }, [rawData]);

  const execQuery = (manual_page) => {
    if (!isLoading && page > 0) {
      setIsLoading(true);
      setExternalLoading(true);
      infiniteScrollFunction({ filters: { query, page: manual_page ?? page, limit, ...filtersData }, ...callData })
        .then((res) => {
          if (res?.rows?.length < limit) {
            setHasMore(false);
          }

          if (page === 1) {
            setRawData([...(res?.rows ?? [])]);
          } else {
            setRawData([...rawData, ...(res?.rows ?? [])]);
          }

          setPlainData(res);

          setIsLoading(false);
          setExternalLoading(false);
        })
        .catch(() => {
          toast('Errore sconosciuto', {
            style: {
              fontSize: '14px',
              backgroundColor: 'red',
              color: '#ffffff'
            }
          });
          setIsLoading(false);
          setExternalLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!isLoading && page > 0) {
      execQuery();
    }
  }, [page]);

  useEffect(() => {
    if (!isLoading && page > 0) {
      execQuery(1);
      setPage(1);
    }
  }, [query, filtersData]);

  useEffect(() => {
    if (forceUpdate) {
      execQuery(1);
      setPage(1);
      setForceUpdate(false);
    }
  }, [forceUpdate]);
  const infiniteScrollFetchMoreData = () => {
    if (!isLoading && page > 0) {
      setPage(page + 1);
    }
  };

  const fetchMoreOnBottomReached = (containerRefElement) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      let scrollH = scrollHeight - scrollTop - clientHeight;
      if (scrollH < 250 && scrollH > 0 && !isLoading && hasMore && enableInfiniteScroll) {
        infiniteScrollFetchMoreData();
      }
    }
  };

  useEffect(() => {
    let currentOffset = rowVirtualizerInstanceRef?.current?.scrollOffset;

    if (currentOffset > 0) {
      try {
        rowVirtualizerInstanceRef?.current?.scrollToIndex?.(0);
      } catch (error) {
        console.error(error);
      }
    }
  }, [sorting, columnFilters, globalFilter]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, []);

  return (
    <Box>
      {enableSearch && (
        <Box mb={2}>
          {searchSubtitle && (
            <UITypography
              variant={'Oxygen300'}
              size={'13px'}
              title={searchSubtitle}
              sxCustom={{
                ml: 1,
                mb: 1
              }}
            />
          )}
          <UIInputSearch label={searchLabel} value={query} setValue={setQuery} isLoading={isLoading} />
        </Box>
      )}
      {enableCustomFilters && (
        <Box mb={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'10px'}>
          {filtersButtons}
        </Box>
      )}
      <MaterialReactTable
        columns={columnsOpt}
        data={flatData ?? []}
        enablePagination={enablePagination}
        enableBottomToolbar={enableBottomToolbar}
        enableRowVirtualization={enableRowVirtualization}
        enableColumnActions={enableColumnActions}
        enableColumnFilters={enableColumnFilters}
        enableFilters={enableFilters}
        enableTopToolbar={enableTopToolbar}
        enableSorting={enableSorting}
        enableRowActions={enableRowActions}
        enableHiding={enableHiding}
        enableFullScreenToggle={enableFullScreenToggle}
        enableDensityToggle={enableDensityToggle}
        enableExpanding={enableExpanding}
        enableExpandAll={enableExpandAll}
        detailPanelColumnStyle={{ display: 'none' }}
        detailPanel={[
          {
            icon: () => null,
            render: () => <>test</>
          }
        ]}
        muiTablePaperProps={{
          sx: { width: '100%', height: tableHeight, overflow: 'hidden', my: '5px', ...muiTablePaperPropsSx } //hide the table's scrollbar
        }}
        muiTopToolbarProps={{
          sx: { height: minifiedToolbar ? '5px' : '64px', minHeight: minifiedToolbar ? '5px' : '64px' }
        }}
        muiTableContainerProps={{
          ref: tableContainerRef,
          sx: { maxHeight: '100%', width: '100%', tableLayout: 'fixed' },
          onScroll: (event) => fetchMoreOnBottomReached(event.target)
        }}
        state={{
          isLoading: isLoading && !flatData.length,
          showProgressBars: isLoading && !!flatData.length,
          expanded: stateExpanded
        }}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        rowVirtualizerOptions={{ overscan: 4 }}
        layoutMode={'grid'}
        muiTableHeadProps={() => {
          let rowProps = muiTableHeadProps(data);

          return {
            sx: {
              tr: {
                bgcolor: '#EAECF0'
              },
              th: {
                color: '#8A92A6',
                fontWeight: '700',
                fontFamily: 'Oxygen',
                fontSize: '12px'
              },
              ...rowProps?.sx
            },
            ...rowProps
          };
        }}
        muiTableBodyRowProps={(data) => {
          let rowProps = muiTableBodyRowProps(data);

          return {
            ...rowProps,
            onClick: () => rowClick(data),
            sx: {
              ...rowProps?.sx,
              '&:hover td': !enableRowHoverColor
                ? {
                    cursor: 'default',
                    bgcolor: 'inherit'
                  }
                : {}
            }
          };
        }}
        initialState={{
          columnVisibility: {
            'mrt-row-expand': enableExpandingColumn
          }
        }}
      />
    </Box>
  );
};

export const UITableHeadCell = ({ title }) => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box mr={'5px'}>{title}</Box> <IconArrowDown stroke={'2'} size={'15px'} />
    </Box>
  );
};

export default UITable;
