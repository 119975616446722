export const statusColor = (status) => {
  switch (status) {
    case 'confirmed':
      return '#00E676';
    case 'rejected':
    case 'canceled':
      return '#FF3A44';
    case 'completed':
      return '#00E676';
    case 'pending':
    default:
      return '#FE922E';
  }
};

export const statusText = (status) => {
  switch (status) {
    case 'confirmed':
      return 'Confermato';
    case 'rejected':
    case 'canceled':
      return 'Annullato';
    case 'completed':
      return 'Completato';
    case 'pending':
    default:
      return 'Da confermare';
  }
};
