import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { UIModal, UiModalInternal, UIModalHeader, UIModalBody } from '../../utilities/Modal';
import { UITypography } from '../../utilities/Typography';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import { useShop } from '../../../hooks/Shop/useShop';
import { Box } from '@mui/material';
import { UIButton } from '../../utilities/Button';

const ModalEarningUsers = ({ modalOpen, handleModalClose }) => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const [numberOrders, setNumberOrders] = useState(false);
  const [filtersData, setFiltersData] = useState({
    order: 'ASC'
  });

  let { onGetSuggestedUsers } = useShop();

  useEffect(() => {
    setForceUpdate(true);
  }, [numberOrders, filtersData]);

  const handleModalCloseInternal = () => {
    handleModalClose();
  };

  const columns = [
    {
      name: <UITableHeadCell title={'Paziente'} />,
      value: 'userEl',
      size: 100
    },
    {
      name: <UITableHeadCell title={'Numero ordini'} />,
      value: 'ordersEl',
      size: 100,
      muiTableHeadCellProps: {
        align: 'right'
      },
      muiTableBodyCellProps: {
        align: 'right'
      }
    }
  ];

  const sortOrder = (a, b) => {
    if (a.orders?.length < b.orders?.length) {
      return 1;
    }
    if (a.orders?.length > b.orders?.length) {
      return -1;
    }
    return 0;
  };

  const prepareValues = (users) => {
    if (numberOrders) {
      users?.sort(sortOrder);
    }

    return users?.map((user) => {
      return {
        user: user,
        userEl: <UITypography variant={'Oxygen700'} size={'14px'} title={`${user?.name} ${user?.surname}`} />,
        ordersEl: <UITypography variant={'Oxygen700'} size={'14px'} title={`${user?.orders?.length}`} />
      };
    });
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiModalInternal onClose={handleModalCloseInternal}>
        <UIModalHeader
          sx={{
            bgcolor: '#FFF'
          }}
        >
          <UITypography
            variant={'Oxygen700'}
            title={`Chi ha ordinato prodotti consigliati`}
            size={'15px'}
            sxCustom={{
              textAlign: 'center',
              color: '#101828'
            }}
          />
        </UIModalHeader>
        <UIModalBody
          buttonsSpace={false}
          sx={{
            bgcolor: 'transparent',
            p: '10px 20px'
          }}
        >
          <Box mb={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'10px'}>
            <Box width={'30%'}>
              <UIButton
                checked={filtersData?.order !== 'ASC'}
                label={'ABC'}
                type={'filter'}
                onClick={() => {
                  setFiltersData({
                    ...filtersData,
                    order: filtersData?.order === 'ASC' ? 'DESC' : 'ASC'
                  });
                }}
                sxCustom={{
                  whiteSpace: 'nowrap'
                }}
              />
            </Box>
            <Box width={'70%'}>
              <UIButton
                checked={numberOrders}
                label={'Numero ordini'}
                type={'filter'}
                onClick={() => {
                  setNumberOrders(!numberOrders);
                }}
                sxCustom={{
                  whiteSpace: 'nowrap'
                }}
              />
            </Box>
          </Box>

          <UITable
            columns={columns}
            prepareValues={prepareValues}
            enableInfiniteScroll={false}
            infiniteScrollFunction={onGetSuggestedUsers}
            tableHeight={'auto'}
            forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate}
            enableSearch={true}
            filtersData={filtersData}
            muiTableBodyRowProps={(data) => {
              return {
                sx: {
                  bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
                  p: 0,
                  '& td': data?.row?.original?.date
                    ? {
                        p: '5px 20px'
                      }
                    : {}
                }
              };
            }}
          />
        </UIModalBody>
      </UiModalInternal>
    </UIModal>
  );
};

export default ModalEarningUsers;
