import { useAuthentication } from '../../../utils/useAuthentication';
import React, { useCallback, useEffect, useState } from 'react';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { Box, Typography } from '@mui/material';
import './custom-treatments.css';
import { IconCheckbox, IconEdit, IconTrash } from '@tabler/icons-react';
import ModalTreatmentEditAdmin from './modalTreatmentEditAdmin';
import ModalTreatmentEditDoctor from './modalTreatmentEditDoctor';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import { UiDialog } from '../../utilities/Dialog';
import { toast } from 'react-toastify';
import UIInputAutocomplete from '../../utilities/InputSelect';

const TreatmentsView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [treatmentsToAdd, setTreatmentsToAdd] = useState([]);
  const [treatmentToAdd, setTreatmentToAdd] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListTreatmentsDoctorPaginated, onListTreatmentsToAddDoctor, onHideTreatmentDoctor } = useTreatments();

  const listTreatmentsToAdd = async () => {
    onListTreatmentsToAddDoctor().then((res) => {
      setTreatmentsToAdd(res);
    });
  };

  useEffect(() => {
    listTreatmentsToAdd().then(() => {});
  }, []);

  const handleModalClose = useCallback(() => {
    listTreatmentsToAdd().then(() => {
      setSelectedTreatment(null);
      setTreatmentToAdd(null);
      setEditMode(false);
      setForceUpdate(true);
    });
  }, []);

  const columns = [
    {
      name: <UITableHeadCell title={'Pren.'} />,
      value: 'prenEl',
      size: 80
    },
    {
      name: <UITableHeadCell title={'Trattamento'} />,
      value: 'treatmentEl',
      size: 100
    },
    {
      name: '',
      value: 'actionsEl',
      size: 80
    }
  ];

  const prepareValues = (treatments) =>
    treatments?.map((treatment) => {
      return {
        treatment: treatment,
        prenEl: <Box>{treatment?.doctor_treatment && treatment?.doctor_treatment?.active && <IconCheckbox />}</Box>,
        treatmentEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {treatment?.name}
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedTreatment(treatment);
                setEditMode(true);
              }}
              icon={
                <IconEdit
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedTreatment(treatment);
                setDeleteMode(true);
              }}
              icon={
                <IconTrash
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
          </Box>
        )
      };
    });

  const handleDeleteTreatment = () => {
    onHideTreatmentDoctor({ treatment_id: selectedTreatment?.id }).then((res) => {
      if (res?.response) {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
        listTreatmentsToAdd().then(() => {
          setDeleteMode(false);
          setForceUpdate(true);
          setTreatmentToAdd(null);
        });
      }
    });
  };

  return (
    <Box className="treatments-view" w={'100%'} h={'100%'} overflow={'auto'}>
      {user?.admin ? (
        <ModalTreatmentEditAdmin
          modalOpen={editMode}
          handleModalClose={handleModalClose}
          treatment={selectedTreatment}
          setEditMode={setEditMode}
        />
      ) : (
        <ModalTreatmentEditDoctor
          modalOpen={editMode}
          handleModalClose={handleModalClose}
          treatment={selectedTreatment}
          setEditMode={setEditMode}
        />
      )}

      <>
        {/*** CONFIRM DIALOG ***/}
        <UiDialog
          type={'default'}
          variant={'alert'}
          title={
            'Vuoi rimuovere il trattamento dalla lista dei tuoi trattamenti? Questa operazione non cancellerà gli appuntamenti già prenotati dai tuoi pazienti per questo trattamento'
          }
          subtitle={'Potrai sempre aggiungere nuovamente il trattamento alla tua lista'}
          open={deleteMode}
          onClose={() => setDeleteMode(false)}
          onConfirm={handleDeleteTreatment}
        />
      </>

      <UIPageHeader
        title={'Trattamenti'}
        subtitle={
          'Seleziona dalla lista i trattamenti che effettuerai. Se un trattamento non è presente, richiedi l’aggiunta a Skinboost.\n' +
          'Quando hai finito di compilare un trattamento, ricorda di renderlo prenotabile perchè sia disponibile ai pazienti.'
        }
      />

      <Box
        sx={{
          bgcolor: '#FDF2B5',
          my: '10px',
          p: '5px 10px 10px 10px',
          borderRadius: '8px'
        }}
      >
        <SectionHeader
          title={'Aggiungi un trattamento'}
          sx={{
            m: '5px 10px 10px 10px'
          }}
        />
        <Box display={'flex'}>
          <UIInputAutocomplete
            options={treatmentsToAdd?.map((treatment) => {
              return {
                id: treatment?.id,
                label: treatment?.name
              };
            })}
            value={treatmentToAdd}
            setValue={setTreatmentToAdd}
            label={''}
            sx={{
              mr: '10px'
            }}
          />
          <UIButton
            type={'button'}
            variant={'phone'}
            disabled={!treatmentToAdd}
            onClick={() => {
              if (treatmentsToAdd) {
                setSelectedTreatment(treatmentsToAdd.find((treatment) => treatment?.id === treatmentToAdd?.id));
                setEditMode(true);
              }
            }}
            label={'Aggiungi'}
          />
        </Box>
      </Box>

      <SectionHeader title={'Trattamento'} />
      <UITable
        columns={columns}
        prepareValues={prepareValues}
        enableSearch={true}
        infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
        infiniteScrollFunction={onListTreatmentsDoctorPaginated}
        tableHeight={'calc(100vh - 450px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        muiTableBodyRowProps={(data) => {
          return {
            sx: {
              bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
              p: 0,
              '& td': data?.row?.original?.date
                ? {
                    p: '5px 20px'
                  }
                : {}
            }
          };
        }}
      />
    </Box>
  );
};
export default TreatmentsView;
