import {
  LIST_GLOSSARIES_ADMIN,
  GET_GLOSSARY_ADMIN,
  CREATE_GLOSSARY_ADMIN,
  UPDATE_GLOSSARY_ADMIN,
  DELETE_GLOSSARY_ADMIN,
  UPLOAD_GLOSSARY_COVER
} from './graphql/queries';
import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';

/** @format */
export const useGlossary = () => {
  const { getDataGQL: listGlossariesAdmin } = useQuery(LIST_GLOSSARIES_ADMIN);
  const { getDataGQL: getGlossaryAdmin } = useQuery(GET_GLOSSARY_ADMIN);
  const { getDataGQL: createGlossaryAdmin } = useMutation(CREATE_GLOSSARY_ADMIN);
  const { getDataGQL: updateGlossaryAdmin } = useMutation(UPDATE_GLOSSARY_ADMIN);
  const { getDataGQL: deleteGlossaryAdmin } = useMutation(DELETE_GLOSSARY_ADMIN);
  const { getDataGQL: uploadGlossaryCover } = useMutation(UPLOAD_GLOSSARY_COVER);

  const onListGlossariesAdmin = async ({ query, limit, page }) => {
    const response = await listGlossariesAdmin({
      variables: {
        query,
        limit,
        page
      }
    });
    if (response?.adminRoute?.listGlossariesAdmin) {
      return response?.adminRoute?.listGlossariesAdmin;
    }
    return null;
  };

  const onGetGlossaryAdmin = async ({ glossary_uuid }) => {
    const response = await getGlossaryAdmin({
      variables: {
        glossary_uuid
      }
    });
    if (response?.adminRoute?.getGlossaryAdmin) {
      return response?.adminRoute?.getGlossaryAdmin;
    }
    return null;
  };

  const onCreateGlossaryAdmin = async ({ glossary_data }) => {
    const response = await createGlossaryAdmin({
      variables: {
        glossary_data
      }
    });
    if (response?.adminRoute?.createGlossaryAdmin) {
      return response?.adminRoute?.createGlossaryAdmin;
    }
    return null;
  };

  const onUpdateGlossaryAdmin = async ({ glossary_uuid, glossary_data }) => {
    const response = await updateGlossaryAdmin({
      variables: {
        glossary_uuid,
        glossary_data
      }
    });
    if (response?.adminRoute?.updateGlossaryAdmin) {
      return response?.adminRoute?.updateGlossaryAdmin;
    }
    return null;
  };

  const onDeleteGlossaryAdmin = async ({ glossary_uuid }) => {
    const response = await deleteGlossaryAdmin({
      variables: {
        glossary_uuid
      }
    });
    if (response?.adminRoute?.deleteGlossaryAdmin) {
      return response?.adminRoute?.deleteGlossaryAdmin;
    }
    return null;
  };

  const onUploadGlossaryCover = async ({ glossary_uuid, filename, contentType }) => {
    const response = await uploadGlossaryCover({
      variables: {
        glossary_uuid,
        filename,
        contentType
      }
    });
    if (response?.adminRoute?.uploadGlossaryCover) {
      return response?.adminRoute?.uploadGlossaryCover;
    }
    return null;
  };

  return {
    onListGlossariesAdmin,
    onGetGlossaryAdmin,
    onCreateGlossaryAdmin,
    onUpdateGlossaryAdmin,
    onDeleteGlossaryAdmin,
    onUploadGlossaryCover
  };
};
