// project imports
import { ProtectedRoute } from '../utils/ProtectedRoute';
import MainLayout from '../layout/MainLayout';
import OfficesView from '../views/pages/offices/view';
import OfficesCitiesView from '../views/pages/officesCities/view';
import OfficesAssign from '../views/pages/officesAssign/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Offices = {
  path: '/',
  element: (
    <ProtectedRoute permission={'DOCTOR'}>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'offices',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <OfficesView />
        </ProtectedRoute>
      )
    },
    {
      path: 'offices/cities',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <OfficesCitiesView />
        </ProtectedRoute>
      )
    },
    {
      path: 'offices/assign',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <OfficesAssign />
        </ProtectedRoute>
      )
    }
  ]
};

export default Offices;
