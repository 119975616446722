import { Box, Image } from '@chakra-ui/react';
import { Divider, Modal, Typography } from '@mui/material';
import { IconAt, IconColorFilter, IconPhone, IconPhoto, IconSignature } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';

const ModalDoctorDetails = ({ modalOpen, handleModalClose, doctor }) => {
  const [data, setData] = useState(null);

  const { onGetDoctorAdmin } = useAccounts();

  const getDoctor = async () => {
    if (!doctor?.uuid) return;
    onGetDoctorAdmin({ doctor_uuid: doctor?.uuid }).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    getDoctor();
  }, [doctor?.uuid]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Box
            w={'100vw'}
            h={'100vh'}
            bg={'transparent'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            pointerEvents={'none'}
          >
            <Box
              className={'container'}
              w={'85%'}
              h={'70%'}
              bg={'white'}
              borderRadius={'20px'}
              pointerEvents={'all'}
              position={'relative'}
              overflow={'hidden'}
            >
              <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                <Box
                  position={'absolute'}
                  top={'2%'}
                  right={'2%'}
                  w={'20px'}
                  h={'20px'}
                  bg={'lightgrey'}
                  borderRadius={'20px'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  fontWeight={'bold'}
                  cursor={'pointer'}
                  onClick={handleModalClose}
                >
                  <Typography>X</Typography>
                </Box>

                <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                  Dati Dottore
                </Typography>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconSignature stroke={1.5} size="1.3rem" />
                  <Box ml={10}>
                    {data?.name} {data?.surname}
                  </Box>
                </Box>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconPhoto stroke={1.5} size="1.3rem" />
                  <Box ml={10} w={'85%'}>
                    <Image src={data?.profile_pic} w={'100px'} h={'100px'} objectFit={'cover'} />
                  </Box>
                </Box>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconAt stroke={1.5} size="1.3rem" />
                  <Box ml={10}>{data?.email}</Box>
                </Box>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconPhone stroke={1.5} size="1.3rem" />
                  <Box ml={10}>
                    <a href={`tel:${data?.phone_country?.dial_code}${data?.phone}`}>
                      <Typography id="modal-modal-description" variant="body1">
                        {`${data?.phone_country?.dial_code} ${data?.phone}`}
                      </Typography>
                    </a>
                  </Box>
                </Box>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} w={'100%'}>
                  <IconColorFilter stroke={1.5} size="1.3rem" />
                  <Box ml={10} w={'85%'}>
                    {data?.sex === 'M' ? 'Uomo' : data?.sex === 'F' ? 'Donna' : 'Altro'}
                  </Box>
                </Box>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconSignature stroke={1.5} size="1.3rem" />
                  <Box ml={10}>{data?.doctor_specialization}</Box>
                </Box>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconSignature stroke={1.5} size="1.3rem" />
                  <Box ml={10}>{data?.instagram_link}</Box>
                </Box>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconSignature stroke={1.5} size="1.3rem" />
                  <Box ml={10} w={'85%'}>
                    {data?.formation}
                  </Box>
                </Box>
                <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                  <IconSignature stroke={1.5} size="1.3rem" />
                  <Box ml={10} w={'85%'}>
                    {data?.about_me}
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                className={'bottom_buttons'}
                h={'50px'}
                position={'absolute'}
                bottom={0}
                w={'100%'}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={20}
              ></Box>
            </Box>
          </Box>
        </>
      </Box>
    </Modal>
  );
};
export default ModalDoctorDetails;
