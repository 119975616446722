// assets
import { IconShoppingCart } from '@tabler/icons-react';
// constant
const icons = { IconShoppingCart };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const shop = {
  id: 'shop',
  type: 'group',
  permission: ['ADMIN'],
  children: [
    {
      id: 'shop_list',
      title: 'Negozio',
      type: 'collapse',
      icon: icons.IconShoppingCart,
      breadcrumbs: false,
      permission: ['ADMIN'],
      children: [
        {
          id: 'shop_categories',
          title: 'Categorie',
          type: 'item',
          url: '/shop/categories',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'shop_products',
          title: 'Prodotti',
          type: 'item',
          url: '/shop/products',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'shop_warehouse',
          title: 'Magazzino',
          type: 'item',
          url: '/shop/warehouse',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'divider_discount',
          type: 'divider',
          permission: ['ADMIN', 'DOCTOR']
        },
        {
          id: 'shop_discounts',
          title: 'Sconti',
          type: 'item',
          url: '/shop/discounts',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'shop_banners',
          title: 'Banners',
          type: 'item',
          url: '/shop/banners',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'shop_coupons',
          title: 'Coupons',
          type: 'item',
          url: '/shop/coupons',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'divider_orders',
          type: 'divider',
          permission: ['ADMIN', 'DOCTOR']
        },
        {
          id: 'shop_reviews',
          title: 'Recensioni',
          type: 'item',
          url: '/shop/reviews',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'shop_orders',
          title: 'Ordini',
          type: 'item',
          url: '/shop/orders',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'shop_earns',
          title: 'Guadagni dottori',
          type: 'item',
          url: '/shop/earns',
          icon: icons.IconShoppingCart,
          breadcrumbs: false,
          permission: ['ADMIN']
        }
      ]
    }
  ]
};

export default shop;
