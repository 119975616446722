import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { IconCheck } from '@tabler/icons-react';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UIFormControlBox } from '../../utilities/Form';
import { UIButton } from '../../utilities/Button';
import UIInputAutocomplete from '../../utilities/InputSelect';

registerLocale('it', it);

const ModalBlock = ({ modalOpen, handleModalClose }) => {
  let [calendars, setCalendars] = useState([]);

  const { onListCalendarsAdmin, onCreateBlock } = useCalendar();

  useEffect(() => {
    onListCalendarsAdmin({
      doctor_id: null
    }).then((res) => {
      setCalendars(res);
    });
  }, []);

  const handleSubmit = (values) => {
    let { date, start_hour, end_hour, calendar } = values;
    let start_time = moment(date).utc(true).startOf('day').add(start_hour.hours(), 'hour').add(start_hour.minutes(), 'minute');
    let end_time = moment(date).utc(true).startOf('day').add(end_hour.hours(), 'hour').add(end_hour.minutes(), 'minute');

    onCreateBlock({
      block_data: {
        calendar_id: calendar,
        start_date: start_time.toDate(),
        end_date: end_time.toDate()
      }
    }).then((res) => {
      if (res) {
        handleModalClose();
      }
    });
  };

  const handleInternalModalClose = () => {
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleInternalModalClose} allowFullScreen={true} className={'modal'}>
      <UiModalInternal onClose={handleInternalModalClose}>
        <Formik
          initialValues={{
            calendar: null,
            date: moment(),
            start_hour: moment('06:30', 'HH:mm'),
            end_hour: moment('06:30', 'HH:mm')
          }}
          validationSchema={Yup.object().shape({
            calendar: Yup.number().nullable().required('Calendario richiesto'),
            date: Yup.string().nullable().required('Data richiesta'),
            start_hour: Yup.string().nullable().required('Ora di inizio richiesta'),
            end_hour: Yup.string().nullable().required('Ora di fine richiesta')
          })}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue, submitForm }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <UIFormControlBox
                  label={'Data'}
                  error_message={errors?.date}
                  error={Boolean(touched.date && errors.date)}
                  sx={{
                    mt: 5
                  }}
                >
                  <DatePicker
                    value={values?.date}
                    dateFormat="dd/MM/yyyy"
                    onChange={(res) => setFieldValue('date', res)}
                    locale={'it'}
                    style={{ width: '100%', border: '1px solid red' }}
                    onBlur={handleBlur}
                    disablePast={true}
                  />
                </UIFormControlBox>

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'top'}>
                  <UIFormControlBox
                    label={'Orario di inizio'}
                    error_message={errors?.start_hour}
                    error={Boolean(touched.start_hour && errors.start_hour)}
                  >
                    <TimePicker
                      value={values?.start_hour}
                      format={'HH:mm'}
                      onChange={(res) => setFieldValue('start_hour', res)}
                      ampm={false}
                      minutesStep={5}
                      minTime={moment('06:30', 'HH:mm')}
                      maxTime={moment('20:00', 'HH:mm')}
                      onBlur={handleBlur}
                    />
                  </UIFormControlBox>
                  <UIFormControlBox
                    label={'Orario di fine'}
                    error_message={errors?.end_hour}
                    error={Boolean(touched.end_hour && errors.end_hour)}
                  >
                    <TimePicker
                      value={values?.end_hour}
                      format={'HH:mm'}
                      onChange={(res) => setFieldValue('end_hour', res)}
                      ampm={false}
                      minutesStep={5}
                      minTime={moment('06:30', 'HH:mm')}
                      maxTime={moment('20:00', 'HH:mm')}
                      onBlur={handleBlur}
                    />
                  </UIFormControlBox>
                </Box>

                <UIFormControlBox
                  label={'Calendario'}
                  error_message={errors?.calendar}
                  error={Boolean(touched.calendar && errors.calendar)}
                >
                  <UIInputAutocomplete
                    value={values?.calendar}
                    setValue={(value) => setFieldValue('calendar', value?.id)}
                    options={calendars?.map((calendar) => {
                      return {
                        id: Number(calendar?.id),
                        label: `${calendar?.doctor?.name} ${calendar?.doctor?.surname} - ${calendar?.office?.name} - ${calendar?.office?.city?.name}`
                      };
                    })}
                    needTimeout={false}
                    handleBlur={handleBlur}
                  />
                </UIFormControlBox>
              </UIModalBody>

              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()} icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};
export default ModalBlock;
