import { Box } from '@chakra-ui/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography
} from '@mui/material';
import { IconCheck, IconSignature, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useProductsDevices } from '../../../hooks/ProductsDevices/useProductsDevices';
import { toast } from 'react-toastify';

const ModalProductDeviceEdit = ({ modalOpen, handleModalClose, device, setEditMode }) => {
  let [deleteMode, setDeleteMode] = useState(false);

  const { onUpdateProductDeviceAdmin, onCreateProductDeviceAdmin, onDeleteProductDeviceAdmin } = useProductsDevices();

  useEffect(() => {}, [device]);

  const handleDeleteProduct = async () => {
    await onDeleteProductDeviceAdmin({
      product_device_id: device.id
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { name } = values;

    if (device?.id) {
      onUpdateProductDeviceAdmin({
        product_device_id: device.id,
        product_device_data: {
          name
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateProductDeviceAdmin({
        product_device_data: {
          name
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Dialog open={deleteMode} onClose={() => setDeleteMode(false)}>
            <DialogTitle>Elimina dispositivo</DialogTitle>
            <DialogContent>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <DialogContentText>Vuoi eliminare il dispositivo "{device?.name}"?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'contained'} color={'error'} onClick={() => setDeleteMode(false)}>
                Annulla
              </Button>
              <Button variant={'contained'} color={'success'} onClick={handleDeleteProduct}>
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
          <Formik
            initialValues={{
              name: device?.name ?? ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Il nome è obbligatorio')
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati dispositivo
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <InputLabel htmlFor="input-name">Nome</InputLabel>
                            <OutlinedInput
                              id="input-name"
                              type="text"
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('name', event.target.value)}
                              label="Nome"
                            />
                            {touched.name && errors.name && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      {device?.id && (
                        <Box d={'flex'} justifyContent={'center'}>
                          <Box
                            fontSize={15}
                            textAlign={'center'}
                            color={'#f44336'}
                            fontFamily={'Montserrat, sans-serif'}
                            mt={10}
                            fontWeight={700}
                            textTransform={'uppercase'}
                            bg={'rgba(200,200,200,0.2)'}
                            p={'5px '}
                            cursor={'pointer'}
                            onClick={() => setDeleteMode(true)}
                          >
                            Elimina dispositivo
                          </Box>
                        </Box>
                      )}

                      {errors.general && (
                        <FormHelperText
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '10px',
                            fontSize: '16px',
                            color: '#f44336'
                          }}
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.general}
                        </FormHelperText>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'}>
                        <IconX stroke={1.5} size="1rem" onClick={() => setEditMode(false)} />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};

export default ModalProductDeviceEdit;
