/*eslint-disable*/
import { Box, Image, Textarea } from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import {
  IconAt,
  IconBabyBottle,
  IconBulb,
  IconCake,
  IconCheck,
  IconColorFilter,
  IconInfinity,
  IconPhone,
  IconPhoto,
  IconSignature
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useGeneral } from '../../../hooks/General/useGeneral';

const ModalUserAdmin = ({ modalOpen, handleModalClose, user }) => {
  const [loadingProfilePic, setLoadingProfilePic] = useState(false);
  const [uuid, setUuid] = useState(uuidv4());
  const [countries, setCountries] = useState([]);

  const { onUpdateUserAdmin, onCreateUserAdmin } = useAccounts();
  const { onListCountries } = useGeneral();

  useEffect(() => {
    if (modalOpen) {
      onListCountries().then((res) => {
        setCountries(res);
      });
    }
  }, [modalOpen]);

  useEffect(() => {
    setUuid(uuidv4());
  }, [user, modalOpen]);

  const handleSubmit = async (values) => {
    const {
      name,
      surname,
      phone,
      dial_code_country,
      email,
      profile_pic,
      age,
      sex,
      pregnant,
      permanent_makeup,
      anamnesi,
      allergies,
      other_allergies,
      active
    } = values;

    if (user?.id) {
      onUpdateUserAdmin({
        user_id: user.id,
        user_data: {
          name,
          surname,
          phone,
          dial_code_country,
          email,
          profile_pic,
          age,
          sex,
          pregnant,
          permanent_makeup,
          anamnesi,
          allergies,
          other_allergies,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateUserAdmin({
        user_data: {
          uuid,
          name,
          surname,
          phone,
          dial_code_country,
          email,
          profile_pic,
          age,
          sex,
          pregnant,
          permanent_makeup,
          anamnesi,
          allergies,
          other_allergies,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Formik
            initialValues={{
              name: user?.name ?? '',
              surname: user?.surname ?? '',
              phone: user?.phone ?? '',
              dial_code_country: user?.dial_code_country ?? '',
              email: user?.email ?? '',
              profile_pic: user?.profile_pic ?? '',
              age: user?.age ?? '',
              sex: user?.sex ?? '',
              pregnant: user?.pregnant ?? false,
              permanent_makeup: user?.permanent_makeup ?? false,
              anamnesi: user?.anamnesi ?? '',
              allergies: user?.allergies ?? '',
              other_allergies: user?.other_allergies ?? '',
              active: user?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().nullable(),
              surname: Yup.string().nullable(),
              email: Yup.string().required('La mail è obbligatoria'),
              phone: Yup.string().nullable(),
              profile_pic: Yup.string().nullable(),
              age: Yup.number().nullable(),
              sex: Yup.string().nullable(),
              pregnant: Yup.boolean().nullable(),
              permanent_makeup: Yup.boolean().nullable(),
              anamnesi: Yup.string().nullable(),
              allergies: Yup.string().nullable(),
              other_allergies: Yup.string().nullable(),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati Utente
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <InputLabel htmlFor="input-name">Nome</InputLabel>
                            <OutlinedInput
                              id="input-name"
                              type="text"
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('name', event.target.value)}
                              label="Nome"
                            />
                            {touched.name && errors.name && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.surname && errors.surname)}>
                            <InputLabel htmlFor="input-surname">Cognome</InputLabel>
                            <OutlinedInput
                              id="input-surname"
                              type="text"
                              value={values.surname}
                              name="surname"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('surname', event.target.value)}
                              label="Cognome"
                            />
                            {touched.surname && errors.surname && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.surname}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhone stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.dial_code_country && errors.dial_code_country)}>
                            <InputLabel htmlFor="input-phone">Prefisso cellulare</InputLabel>
                            <Select
                              name={'dial_code_country'}
                              fullWidth={true}
                              style={{
                                minWidth: '300px',
                                maxWidth: '30%'
                              }}
                              value={values?.dial_code_country}
                              label="Prefisso cellulare"
                              labelId="dial_code_country"
                              onChange={(e) => {
                                setFieldValue('dial_code_country', e.target.value);
                              }}
                              onBlur={handleBlur}
                              autoWidth
                            >
                              {countries?.map((country, index) => (
                                <MenuItem key={index} value={country?.id}>
                                  {country?.name} ({country?.dial_code})
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.phone && errors.phone && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.phone}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhone stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.phone && errors.phone)}>
                            <InputLabel htmlFor="input-phone">Cellulare</InputLabel>
                            <OutlinedInput
                              id="input-phone"
                              type="text"
                              value={values.phone}
                              name="phone"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('phone', event.target.value)}
                              label="Cellulare"
                            />
                            {touched.phone && errors.phone && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.phone}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconAt stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                            <InputLabel htmlFor="input-surname">Email</InputLabel>
                            <OutlinedInput
                              id="input-email"
                              type="text"
                              value={values.email}
                              name="email"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('email', event.target.value)}
                              label="Email"
                            />
                            {touched.email && errors.email && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.email}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhoto stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.profile_pic && errors.profile_pic)} w={'100%'}>
                            {loadingProfilePic && <>Loading</>}
                            <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'} mb={1} ml={1}>
                              Immagine profilo
                            </Typography>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                              <Image src={values.profile_pic} w={'100px'} h={'100px'} objectFit={'cover'} />
                              <DropzoneFormikInput
                                variant={'profile_pic'}
                                setFieldValue={setFieldValue}
                                field={'profile_pic'}
                                setLoading={setLoadingProfilePic}
                                folder={'profile_pic'}
                                label={'Carica'}
                                extra_data={{
                                  account_uuid: user?.uuid ?? uuid
                                }}
                              />
                            </Box>
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconCake stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.age && errors.age)}>
                            <InputLabel htmlFor="input-age">Età</InputLabel>
                            <OutlinedInput
                              id="input-age"
                              type="number"
                              value={values.age}
                              name="age"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('age', event.target.value)}
                              label="Età"
                            />
                            {touched.age && errors.age && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.age}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'} w={'100%'}>
                        <IconColorFilter stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.sex && errors.sex)}>
                            <InputLabel id="sex-label">Sesso</InputLabel>
                            <Select
                              name={'sex'}
                              fullWidth={true}
                              value={values?.sex}
                              label="Sesso"
                              labelId="sex-label"
                              onChange={(e) => {
                                setFieldValue('sex', e.target.value);
                              }}
                              onBlur={handleBlur}
                              autoWidth
                            >
                              <MenuItem value={'M'}>Uomo</MenuItem>
                              <MenuItem value={'F'}>Donna</MenuItem>
                              <MenuItem value={'Other'}>Altro</MenuItem>
                            </Select>
                            {touched.color_id && errors.color_id && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.color_id}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBabyBottle stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.pregnant && errors.pregnant)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.pregnant}
                                  onChange={(event) => setFieldValue('pregnant', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Incinta</Typography>}
                            />
                            {touched.pregnant && errors.pregnant && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.pregnant}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconInfinity stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.permanent_makeup && errors.permanent_makeup)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.permanent_makeup}
                                  onChange={(event) => setFieldValue('permanent_makeup', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Makup permanenti</Typography>}
                            />
                            {touched.permanent_makeup && errors.permanent_makeup && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.permanent_makeup}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.anamnesi && errors.anamnesi)} w={'100%'}>
                            <Typography id="input-anamnesi" variant="body1" fontWeight={'bold'}>
                              Anamnesi
                            </Typography>
                            <Textarea
                              id="input-anamnesi"
                              value={values.anamnesi}
                              name="anamnesi"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('anamnesi', event.target.value)}
                              label="Anamnesi"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Anamnesi'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.anamnesi ? '1px solid red' : null}
                            />
                            {touched.anamnesi && errors.anamnesi && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.anamnesi}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.allergies && errors.allergies)} w={'100%'}>
                            <Typography id="input-allergies" variant="body1" fontWeight={'bold'}>
                              Allergie a principi attivi, farmaci o molecole
                            </Typography>
                            <Textarea
                              id="input-allergies"
                              value={values.allergies}
                              name="allergies"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('allergies', event.target.value)}
                              label="Allergie specifiche"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Allergie specifiche'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.allergies ? '1px solid red' : null}
                            />
                            {touched.allergies && errors.allergies && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.allergies}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.other_allergies && errors.other_allergies)} w={'100%'}>
                            <Typography id="input-other_allergies" variant="body1" fontWeight={'bold'}>
                              Altre allergie generali
                            </Typography>
                            <Textarea
                              id="input-other_allergies"
                              value={values.other_allergies}
                              name="other_allergies"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('other_allergies', event.target.value)}
                              label="Allergie Generali"
                              color={'#121926'}
                              bg={'#f8fafc'}
                              borderRadius={'12px'}
                              placeholder={'Allergie Generali'}
                              p={'10px'}
                              w={'100%'}
                              minH={'100px'}
                              resize={'none'}
                              border={errors?.other_allergies ? '1px solid red' : null}
                            />
                            {touched.other_allergies && errors.other_allergies && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.other_allergies}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.active}
                                  onChange={(event) => setFieldValue('active', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Attivo</Typography>}
                            />
                            {touched.active && errors.active && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.active}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      {errors.general && (
                        <FormHelperText
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '10px',
                            fontSize: '16px',
                            color: '#f44336'
                          }}
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.general}
                        </FormHelperText>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};
export default ModalUserAdmin;
