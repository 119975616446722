import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { IconEdit, IconEye } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import ModalDetails from './modalDetails';
import { useShop } from '../../../hooks/Shop/useShop';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import ModalTracking from './modalTracking';
import moment from 'moment/moment';
import UIInputAutocomplete from '../../utilities/InputSelect';

const ShopOrdersView = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [trackingMode, setTrackingMode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    query: ''
  });

  const { onListShopOrdersAdmin } = useShop();
  const { onListUsersAdmin } = useAccounts();

  const listOrders = () => {
    onListShopOrdersAdmin({
      account_uuid: selectedAccount,
      status: selectedStatus,
      page,
      limit: 20
    }).then((res) => {
      setOrders(res?.rows);
      setTotalPages(res?.count);
    });
  };

  const listPatients = () => {
    onListUsersAdmin({ filters }).then((res) => {
      setAccounts(res);
    });
  };

  useEffect(() => {
    listOrders();
    listPatients();
  }, []);

  useEffect(() => {
    listPatients();
  }, [filters]);

  useEffect(() => {
    listOrders();
  }, [selectedStatus, selectedAccount, page]);

  const handleModalClose = () => {
    setViewMode(false);
    setTrackingMode(false);
    setSelectedOrder(null);
    listOrders();
  };

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalDetails modalOpen={viewMode} handleModalClose={handleModalClose} order={selectedOrder} />
      <ModalTracking modalOpen={trackingMode} handleModalClose={handleModalClose} order={selectedOrder} />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Lista ordini
      </Typography>

      <Box
        my={3}
        display={'flex'}
        justifyContent={matchDownSM ? 'center' : 'flex-start'}
        alignItems={'center'}
        gap={2}
        w={'100%'}
        flexWrap={'wrap'}
      >
        <Box width={matchDownSM ? '45%' : '200px'}>
          <Autocomplete
            id="status-select"
            name={'status'}
            defaultValue={''}
            fullWidth={true}
            options={[
              { id: 'payed', label: 'In attesa' },
              { id: 'shipping', label: 'Spedito' },
              { id: 'delivered', label: 'Ricevuto' },
              { id: 'completed', label: 'Completato' },
              { id: 'refunded', label: 'Rimborsato' }
            ]}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            onChange={(e, data) => {
              setSelectedStatus(data?.id);
            }}
            renderInput={(params) => <TextField {...params} label="Stato" />}
          />
        </Box>
        <Box width={matchDownSM ? '45%' : '200px'}>
          <UIInputAutocomplete
            label={'Account'}
            value={selectedAccount ?? null}
            setValue={(value) => setSelectedAccount(value?.id)}
            options={accounts?.map((patient) => {
              return {
                id: patient?.uuid,
                label: `${patient?.name} ${patient?.surname} - ${patient?.email} - ${patient?.phone}`
              };
            })}
            needTimeout={false}
            onTextValueChange={(value) => {
              setFilters({
                ...filters,
                query: value
              });
            }}
          />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Ordine</TableCell>
              <TableCell align="center">Stato</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align={'center'}>
                  <Typography id="modal-modal-description" variant="body1">
                    Il: <span style={{ fontWeight: 'bold' }}>{moment(Number(row?.createdAt)).format('DD/MM/YYYY')}</span>
                  </Typography>
                  <Typography id="modal-modal-description" variant="body1">
                    Da:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {row?.buyer?.name} {row?.buyer?.surname}
                    </span>
                  </Typography>

                  {row?.buyer?.phone ? (
                    <a href={`tel:${row?.buyer?.phone_country?.dial_code}${row?.buyer?.phone}`}>
                      <Typography id="modal-modal-description" variant="body1">
                        {`${row?.buyer?.phone_country?.dial_code} ${row?.buyer?.phone}`}
                      </Typography>
                    </a>
                  ) : (
                    <Typography id="modal-modal-description" variant="body1">
                      Nessun numero di telefono
                    </Typography>
                  )}

                  <Typography id="modal-modal-description" variant="body1">
                    {row?.buyer?.email ?? 'Nessuna email'}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row" align={'center'}>
                  <StatusLabel status={row?.status} />
                </TableCell>
                <TableCell align="center">
                  <IconEdit
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedOrder(row);
                      setTrackingMode(true);
                    }}
                  />
                  <IconEye
                    sx={{
                      ml: 2
                    }}
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedOrder(row);
                      setViewMode(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} color={'primary'} />
      </Box>
    </Box>
  );
};

const StatusLabel = ({ status }) => {
  let label;
  let bg;
  let color;

  switch (status) {
    case 'payed':
      label = 'In attesa';
      bg = 'warning.main';
      color = 'black';
      break;
    case 'shipped':
      label = 'Spedito';
      bg = 'info.main';
      color = 'white';
      break;
    case 'delivered':
      label = 'Ricevuto';
      bg = 'success.main';
      color = 'white';
      break;
    case 'completed':
      label = 'Completato';
      bg = 'success.main';
      color = 'white';
      break;
    case 'refunded':
      label = 'Rimborsato';
      bg = 'grey';
      color = 'white';
      break;
    default:
      label = 'In attesa';
      bg = 'warning.main';
      color = 'white';
      break;
  }

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={2} bgcolor={bg} color={color} p={1}>
      <Typography variant={'body1'}>{label}</Typography>
    </Box>
  );
};

export default ShopOrdersView;
