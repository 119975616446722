import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { IconEye } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { useTheme } from '@mui/material/styles';
import ModalDetails from './modalDetails';
import UIInputAutocomplete from '../../utilities/InputSelect';

const DoctorsReviewsView = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    query: ''
  });

  const { onListDoctorReviews, onListDoctors, onListUsersAdmin } = useAccounts();

  const listReviews = () => {
    onListDoctorReviews({
      doctor_id: selectedDoctor,
      patient_id: selectedPatient,
      page,
      limit: 20
    }).then((res) => {
      setReviews(res?.rows);
      setTotalPages(res?.count);
    });
  };

  const listDoctors = () => {
    onListDoctors().then((res) => {
      setDoctors(res);
    });
  };

  const listPatients = () => {
    onListUsersAdmin({ filters }).then((res) => {
      setPatients(res);
    });
  };

  useEffect(() => {
    listReviews();
    listDoctors();
    listPatients();
  }, []);

  useEffect(() => {
    listPatients();
  }, [filters]);

  useEffect(() => {
    listReviews();
  }, [selectedDoctor, selectedPatient, page]);

  const handleModalClose = useCallback(() => {
    setViewMode(false);
    setSelectedReview(null);
    listReviews();
  }, []);

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalDetails modalOpen={viewMode} handleModalClose={handleModalClose} review={selectedReview} />

      <Typography variant={'h4'} align={'center'} textTransform={'uppercase'} fontSize={20} my={2}>
        Recensioni Dottori
      </Typography>

      <Box
        my={3}
        display={'flex'}
        justifyContent={matchDownSM ? 'center' : 'flex-start'}
        alignItems={'center'}
        gap={2}
        w={'100%'}
        flexWrap={'wrap'}
      >
        <Box width={matchDownSM ? '45%' : '200px'}>
          <Autocomplete
            id="doctor-select"
            name={'doctor'}
            defaultValue={''}
            fullWidth={true}
            options={doctors?.map((doctor) => {
              return {
                id: Number(doctor?.id),
                label: `${doctor?.name} ${doctor?.surname}`
              };
            })}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            onChange={(e, data) => {
              setSelectedDoctor(data?.id);
            }}
            renderInput={(params) => <TextField {...params} label="Dottore" />}
          />
        </Box>
        <Box width={matchDownSM ? '45%' : '200px'}>
          <UIInputAutocomplete
            label={'Paziente'}
            value={selectedPatient ?? null}
            setValue={(value) => setSelectedPatient(value?.id)}
            options={patients?.map((patient) => {
              return {
                id: patient?.id,
                label: `${patient?.name} ${patient?.surname} - ${patient?.email} - ${patient?.phone}`
              };
            })}
            needTimeout={false}
            onTextValueChange={(value) => {
              setFilters({
                ...filters,
                query: value
              });
            }}
          />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Dottore</TableCell>
              <TableCell align="center">Paziente</TableCell>
              <TableCell align="center">Preview</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviews?.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  component="th"
                  scope="row"
                  align={'center'}
                  sx={{
                    color: row?.report?.length ? 'red' : '#364152',
                    fontWeight: row?.report.length ? 'bold' : 'normal'
                  }}
                >
                  {row?.appointment?.calendar?.doctor?.name} {row?.appointment?.calendar?.doctor?.surname}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align={'center'}
                  sx={{
                    color: row?.report?.length ? 'red' : '#364152',
                    fontWeight: row?.report.length ? 'bold' : 'normal'
                  }}
                >
                  {row?.appointment?.patient?.name} {row?.appointment?.patient?.surname}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align={'center'}
                  sx={{
                    color: row?.report?.length ? 'red' : '#364152',
                    fontWeight: row?.report.length ? 'bold' : 'normal'
                  }}
                >
                  {row?.review?.slice(0, 20)}...
                </TableCell>
                <TableCell align="center">
                  <IconEye
                    cursor={'pointer'}
                    onClick={() => {
                      setSelectedReview(row);
                      setViewMode(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} color={'primary'} />
      </Box>
    </Box>
  );
};
export default DoctorsReviewsView;
