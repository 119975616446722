// assets
import { IconBrandOffice } from '@tabler/icons-react';

// constant
const icons = { IconBrandOffice };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const offices = {
  id: 'uffici',
  permission: ['ADMIN'],
  type: 'group',
  children: [
    {
      id: 'offices_list',
      title: 'Uffici',
      type: 'collapse',
      icon: icons.IconBrandOffice,
      breadcrumbs: false,
      url: '/offices',
      permission: ['ADMIN'],
      children: [
        {
          id: 'offices',
          title: 'Uffici',
          type: 'item',
          url: '/offices',
          icon: icons.IconBrandOffice,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'offices_cities',
          title: 'Citta',
          type: 'item',
          url: '/offices/cities',
          icon: icons.IconBrandOffice,
          breadcrumbs: false,
          permission: ['ADMIN']
        },
        {
          id: 'offices_assign',
          title: 'Assegnazione',
          type: 'item',
          url: '/offices/assign',
          icon: icons.IconBrandOffice,
          breadcrumbs: false,
          permission: ['ADMIN']
        }
      ]
    }
  ]
};

export default offices;
