import { Box, Card, CardContent, Typography } from '@mui/material';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {
  IconCalendarEvent,
  IconEdit,
  IconPhone,
  IconPigMoney,
  IconStarFilled,
  IconStethoscope,
  IconTrash,
  IconUsers
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import UITypography from '../../utilities/Typography';
import { useAuthentication } from '../../../utils/useAuthentication';
import { useAuth } from '../../../hooks/Auth/useAuth';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import UITable, { UITableHeadCell } from '../../utilities/Table';
import moment from 'moment';
import { UIButton } from '../../utilities/Button';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { toast } from 'react-toastify';
import { UiDialog } from '../../utilities/Dialog';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { useOffices } from '../../../hooks/Offices/useOffices';
import ModalAppointmentDetails from '../modals/modalAppointmentDetails';
import { SectionHeader } from '../../utilities/Headers';
import { useShop } from '../../../hooks/Shop/useShop';
import ArrowRight from '../../../assets/images/icons/arrowRight.svg';
import { Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const navigate = useNavigate();
  const [me, setMe] = React.useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [offices, setOffices] = useState([]);
  const [commissionsData, setCommissionsData] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);

  const { getMe } = useAuth();
  const { onCountMineReviews } = useAccounts();
  const { onListDailyAppointments, onUpdateAppointmentStatus } = useCalendar();
  const { onListTreatmentsDoctor, onListTreatmentsAdmin } = useTreatments();
  const { onListOffices } = useOffices();
  const { onGetCommissionsData } = useShop();

  useEffect(() => {
    onCountMineReviews().then((result) => {
      setTotalReviews(result ?? 0);
    });
    if (user.admin) {
      onListTreatmentsAdmin().then((res) => {
        setTreatments(res);
      });
    } else {
      onListTreatmentsDoctor().then((res) => {
        setTreatments(res);
      });
      getMe().then((result) => {
        setMe(result);
      });
    }
    onListOffices().then((res) => {
      setOffices(res);
    });
    onGetCommissionsData().then((res) => {
      setCommissionsData(res);
    });
  }, []);

  /*** DIALOGS ***/
  const [editMode, setEditMode] = useState(false);
  const [treatmentsMode, setTreatmentsMode] = useState(false);
  const [officeMode, setOfficeMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [phoneMode, setPhoneMode] = useState(false);

  /*** TABLE ***/
  const [forceUpdate, setForceUpdate] = useState(false);
  const [filtersData, setFiltersData] = useState({
    treatment_ids: [],
    office_id: null
  });

  const columns = [
    {
      name: <UITableHeadCell title={'Appuntamento'} />,
      value: 'appointmentEl'
    },
    {
      name: '',
      value: 'actionsEl'
    }
  ];

  const prepareValues = (appointments) => {
    let appointmentsArray = [];

    let last_day = moment().add(4, 'days').format('YYYY-MM-DD');
    let analyzed_day = moment();

    while (analyzed_day.isBefore(last_day)) {
      let appointmentsData = appointments.filter((appointment) => appointment?.date === analyzed_day.format('YYYY-MM-DD'));

      if (appointmentsData.length > 0) {
        appointmentsArray?.push({
          appointment: null,
          appointmentEl: (
            <Box width={'100%'} height={'100%'} fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {analyzed_day.format('DD MMMM YYYY')}
            </Box>
          ),
          actionsEl: null,
          date: analyzed_day.format('YYYY-MM-DD')
        });

        appointmentsArray?.push(
          ...appointmentsData.map((appointment) => {
            return {
              appointment: appointment,
              appointmentEl: (
                <Box>
                  <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                    {`${appointment?.start_hour}`}
                    <span
                      style={{
                        fontWeight: 300,
                        marginLeft: '5px'
                      }}
                    >
                      {appointment?.treatment?.name}
                    </span>
                  </Typography>
                  <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
                    {appointment?.patient_id
                      ? `${appointment?.patient?.name} ${appointment?.patient?.surname}`
                      : appointment?.patient_alias}
                  </Typography>
                </Box>
              ),
              actionsEl: (
                <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setPhoneMode(true);
                    }}
                    icon={
                      <IconPhone
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setDeleteMode(true);
                    }}
                    icon={
                      <IconTrash
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                  <UIButton
                    type={'icon'}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setEditMode(true);
                    }}
                    icon={
                      <IconEdit
                        stroke={'1.67px'}
                        style={{
                          width: '24px'
                        }}
                      />
                    }
                  />
                </Box>
              )
            };
          })
        );
      }

      analyzed_day.add(1, 'days');
    }

    return appointmentsArray;
  };

  const filtersButtons = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px'
      }}
    >
      <Box width={'120px'}>
        <UIButton
          checked={filtersData?.treatment_ids?.length > 0}
          label={'Trattamento'}
          type={'filter'}
          onClick={() => {
            setTreatmentsMode(true);
          }}
        />
      </Box>
      <Box width={'120px'}>
        <UIButton
          checked={filtersData?.office_id !== null}
          label={'Studio'}
          type={'filter'}
          onClick={() => {
            setOfficeMode(true);
          }}
        />
      </Box>
    </Box>
  );

  const handleRejectAppointment = () => {
    onUpdateAppointmentStatus({
      appointment_id: selectedAppointment?.id,
      status: 'rejected'
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
      setDeleteMode(false);
      setSelectedAppointment(null);
      setForceUpdate(true);
    });
  };

  const handleModalClose = () => {
    setSelectedAppointment(null);
    setEditMode(false);
  };

  return (
    <Box py={'20px'}>
      <ModalAppointmentDetails modalOpen={editMode} handleModalClose={handleModalClose} appointment={selectedAppointment} />

      {/*** DIALOGS*/}
      <>
        <UiDialog
          type={'default'}
          variant={'alert'}
          title={'Vuoi annullare questo appuntamento?'}
          open={deleteMode}
          onClose={() => setDeleteMode(false)}
          onConfirm={handleRejectAppointment}
        />

        <UiDialog
          type={'phone'}
          title={`${selectedAppointment?.patient?.name} ${selectedAppointment?.patient?.surname}`}
          subtitle={`${selectedAppointment?.patient?.phone_country?.dial_code} ${selectedAppointment?.patient?.phone}`}
          open={phoneMode}
          labelConfirm={
            <>
              <IconPhone stroke={'1.64'} size={'20px'} />
              <UITypography
                variant={'Oxygen700'}
                title={'Chiama'}
                size={'14px'}
                sxCustom={{
                  ml: '5px',
                  color: '#344054'
                }}
              />
            </>
          }
          onClose={() => setPhoneMode(false)}
          onConfirm={() => {
            window.open(`tel:${selectedAppointment?.patient?.phone_country?.dial_code}${selectedAppointment?.patient?.phone}`, '_blank');
          }}
          onSecondary={() => {
            navigator.clipboard.writeText(
              `${selectedAppointment?.patient?.phone_country?.dial_code} ${selectedAppointment?.patient?.phone}`
            );
            toast('Numero copiato negli appunti', {
              style: {
                fontSize: '14px',
                backgroundColor: '#00e676',
                color: '#ffffff'
              }
            });
            setSelectedAppointment(null);
            setPhoneMode(false);
          }}
        />

        <UiDialog
          type={'checkbox'}
          open={treatmentsMode}
          onClose={() => setTreatmentsMode(false)}
          title={'Seleziona trattamenti'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              treatment_ids: value
            });
            setTreatmentsMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={treatments?.map((treatment) => {
            return {
              label: treatment.name,
              value: treatment.id,
              checked: filtersData.treatment_ids.includes(treatment.id)
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              treatment_ids: []
            });
            setTreatmentsMode(false);
          }}
        />

        <UiDialog
          type={'radio'}
          open={officeMode}
          onClose={() => setOfficeMode(false)}
          title={'Seleziona ufficio'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              office_id: value
            });
            setOfficeMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={offices?.map((office) => {
            return {
              label: office.name,
              value: office.id,
              checked: filtersData.office_id === office.id
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              office_id: null
            });
            setOfficeMode(false);
          }}
        />
      </>

      {user?.admin ? (
        <>
          <DashboardCard
            icon={<IconPigMoney stroke={1.64} size={'21px'} />}
            title={'Ricavo vendite'}
            minValue={commissionsData?.total_commissions?.actual_start}
            maxValue={commissionsData?.total_commissions?.next_goal}
            value={commissionsData?.total_commissions?.total}
            onClick={() => {
              if (!user?.admin) {
                navigate('/earnings');
              }
            }}
            subtitle={
              <UITypography
                variant={'Oxygen700'}
                size={'19px'}
                title={`${commissionsData?.total_commissions?.total_formatted}`}
                sxCustom={{
                  color: '#232D42'
                }}
              />
            }
          />
          <DashboardCard
            icon={<IconStethoscope stroke={1.64} size={'21px'} />}
            title={'Medici attivi'}
            minValue={commissionsData?.total_doctors?.actual_start ?? 0}
            maxValue={commissionsData?.total_doctors?.next_goal ?? 100}
            value={commissionsData?.total_doctors?.total ?? 0}
            subtitle={
              <UITypography
                variant={'Oxygen700'}
                size={'19px'}
                title={`${commissionsData?.total_doctors?.total_formatted ?? 0}`}
                sxCustom={{
                  color: '#232D42'
                }}
              />
            }
          />
          <DashboardCard
            icon={<IconUsers stroke={1.64} size={'21px'} />}
            title={'Totale utenti'}
            minValue={commissionsData?.total_users?.actual_start ?? 0}
            maxValue={commissionsData?.total_users?.next_goal ?? 100}
            value={commissionsData?.total_users?.total ?? 0}
            subtitle={
              <UITypography
                variant={'Oxygen700'}
                size={'19px'}
                title={`${commissionsData?.total_users?.total_formatted ?? 0}`}
                sxCustom={{
                  color: '#232D42'
                }}
              />
            }
          />
          <DashboardCard
            icon={<IconCalendarEvent stroke={1.64} size={'21px'} />}
            title={'Appuntamenti presi nel mese'}
            minValue={commissionsData?.total_appointments?.actual_start ?? 0}
            maxValue={commissionsData?.total_appointments?.next_goal ?? 100}
            value={commissionsData?.total_appointments?.total ?? 0}
            subtitle={
              <UITypography
                variant={'Oxygen700'}
                size={'19px'}
                title={`${commissionsData?.total_appointments?.total_formatted ?? 0}`}
                sxCustom={{
                  color: '#232D42'
                }}
              />
            }
          />
        </>
      ) : (
        <>
          <DashboardCard
            minValue={0}
            maxValue={5}
            value={me?.reviews_average ?? 0}
            icon={<IconStarFilled stroke={1.64} size={'21px'} />}
            title={'La tua valutazione'}
            subtitle={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '5px'
                }}
              >
                {totalReviews > 0 && (
                  <UITypography
                    variant={'Oxygen700'}
                    size={'19px'}
                    title={me?.reviews_average}
                    sxCustom={{
                      color: '#232D42'
                    }}
                  />
                )}

                <UITypography
                  variant={'Oxygen400'}
                  size={'14px'}
                  title={totalReviews ? `da ${totalReviews} recensioni` : 'Non hai ancora recensioni'}
                  sxCustom={{
                    color: '#667085',
                    mt: !totalReviews ? '10px' : 0
                  }}
                />
              </Box>
            }
          />
          <DashboardCard
            icon={<IconPigMoney stroke={1.64} size={'21px'} />}
            title={'Ricavo vendite'}
            minValue={commissionsData?.total_commissions?.actual_start}
            maxValue={commissionsData?.total_commissions?.next_goal}
            value={commissionsData?.total_commissions?.total}
            onClick={() => {
              if (!user?.admin) {
                navigate('/earnings');
              }
            }}
            subtitle={
              <UITypography
                variant={'Oxygen700'}
                size={'19px'}
                title={`${commissionsData?.total_commissions?.total_formatted}`}
                sxCustom={{
                  color: '#232D42'
                }}
              />
            }
          />
        </>
      )}

      {!user?.admin && (
        <>
          <SectionHeader
            title={'Appuntamenti di oggi'}
            sx={{
              m: '50px 10px 20px 10px'
            }}
          />

          <UITable
            columns={columns}
            prepareValues={prepareValues}
            enableSearch={true}
            enableCustomFilters={true}
            filtersData={filtersData}
            filtersButtons={filtersButtons}
            infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
            infiniteScrollFunction={onListDailyAppointments}
            tableHeight={'calc(100vh - 400px)'}
            forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate}
            muiTableBodyRowProps={(data) => {
              return {
                sx: {
                  bgcolor: data?.row?.original?.date ? '#FDF2B5' : '',
                  p: 0,
                  '& td': data?.row?.original?.date
                    ? {
                        p: '5px 20px'
                      }
                    : {}
                }
              };
            }}
          />
        </>
      )}
    </Box>
  );
};

export const DashboardCard = ({
  value,
  icon,
  title,
  subtitle = null,
  arrow = false,
  minified = false,
  minValue = 0,
  maxValue = 100,
  onClick = () => {},
  titleSize = '16px'
}) => {
  return (
    <Card
      sx={{
        m: '0px 0 15px 0',
        width: minified ? '50%' : '100%'
      }}
      onClick={onClick}
    >
      <CardContent
        sx={{
          display: 'flex',
          p: minified ? '10px' : '24px',
          pb: minified ? '10px !important' : '24px !important',
          gap: minified ? '15px' : '30px'
        }}
      >
        <Box
          sx={{
            width: '52px',
            height: '52px'
          }}
        >
          <CircularProgressbarWithChildren
            value={value}
            minValue={minValue}
            maxValue={maxValue}
            styles={{
              path: {
                stroke: '#FECE2E',
                strokeLinecap: 'round',
                strokeWidth: '8px'
              },
              trail: {
                stroke: '#E9ECEF',
                strokeWidth: '4px'
              },
              text: {
                top: 0
              }
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '-25%',
                color: '#D9D9D9'
              }}
            >
              {icon}
            </Box>
          </CircularProgressbarWithChildren>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '5px',
            width: 'calc(100% - 65px)'
          }}
        >
          <UITypography
            variant={'Oxygen400'}
            size={titleSize}
            title={title}
            sxCustom={{
              color: '#8A92A6'
            }}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '5px'
            }}
          >
            {subtitle}
            {arrow && (
              <Image
                src={ArrowRight}
                sx={{
                  width: '18px'
                }}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Dashboard;
