// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import ShopCategoriesView from '../views/pages/shopCategories/view';
import ShopProductsView from '../views/pages/shopProducts/view';
import ShopDiscounts from '../views/pages/shopDiscounts/view';
import ShopReviewsView from '../views/pages/ShopReviews/view';
import ShopBannersView from '../views/pages/shopBanners/view';
import ShopOrdersView from '../views/pages/ShopOrders/view';
import ShopCouponsView from '../views/pages/ShopCoupons/view';
import ShopProductsWarehouseView from '../views/pages/shopProductsWarehouse/view';
import ShopEarnsView from '../views/pages/shopEarns/view';

const Shop = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'shop/categories',
      element: (
        <ProtectedRoute permission={'DOCTOR'}>
          <ShopCategoriesView />
        </ProtectedRoute>
      )
    },
    {
      path: 'shop/products',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ShopProductsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'shop/warehouse',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ShopProductsWarehouseView />
        </ProtectedRoute>
      )
    },
    {
      path: 'shop/reviews',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ShopReviewsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'shop/discounts',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ShopDiscounts />
        </ProtectedRoute>
      )
    },
    {
      path: 'shop/banners',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ShopBannersView />
        </ProtectedRoute>
      )
    },
    {
      path: 'shop/orders',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ShopOrdersView />
        </ProtectedRoute>
      )
    },
    {
      path: 'shop/coupons',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ShopCouponsView />
        </ProtectedRoute>
      )
    },
    {
      path: 'shop/earns',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <ShopEarnsView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Shop;
