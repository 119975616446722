import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Badge, Chip, ListItemButton, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';

// assets
import { useShop } from '../../../../../hooks/Shop/useShop';
import { useAuthentication } from '../../../../../utils/useAuthentication';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const [count, setCount] = React.useState(0);
  let { onCountShopOrdersAdmin } = useShop();

  const data = useAuthentication();
  const user = data?.user?.account_data;

  let user_admin = user?.admin;
  let user_doctor = user?.doctor;
  let user_clinic = user?.clinic;

  useEffect(() => {
    if (item?.id === 'shop_orders') {
      onCountShopOrdersAdmin().then((res) => {
        setCount(res);
      });
    }
  }, []);

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, [pathname]);

  if (user_admin) {
    if (!item?.permission?.includes('ADMIN')) {
      return null;
    }
  } else if (user_doctor) {
    if (!item?.permission?.includes('DOCTOR')) {
      return null;
    }
  } else if (user_clinic) {
    if (!item?.permission?.includes('CLINIC')) {
      return null;
    }
  } else {
    return null;
  }

  const Icon = item.icon;
  const itemIcon = item?.icon ? <Icon stroke={1.5} size="1.3rem" /> : <></>;

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 10}px`,
        '&:hover, &:focus': {
          backgroundColor: 'rgba(159,162,180,0.08)',
          color: '#60626E'
        }
      }}
      selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
    >
      {}
      <Badge badgeContent={count} color="error" sx={{ mr: 2, color: '#60626E' }}>
        {itemIcon}
      </Badge>
      <ListItemText
        primary={
          <Typography
            variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
            color={customization.isOpen.findIndex((id) => id === item.id) > -1 ? '#FFF' : level > 1 ? '#FECE2E' : '#A4A6B3'}
            fontFamily={'Oxygen'}
            fontWeight={'700'}
            letterSpacing={0.25}
            fontSize={'14px'}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
