import { Card, CardActions } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import 'moment/locale/it';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UIPageTitle } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import { AspectRatio, Image } from '@chakra-ui/react';

const ModalUserPicsDoctor = ({ modalOpen, handleModalClose, user }) => {
  const { onDeleteUserMedia, onGetUserAdmin } = useAccounts();
  let [media, setMedia] = React.useState([]);

  useEffect(() => {
    if (!user?.uuid) return;
    onGetUserAdmin({ user_uuid: user?.uuid }).then((res) => {
      setMedia(res?.media);
    });
  }, [user?.uuid]);

  const handleDeleteMedia = async (media_id) => {
    await onDeleteUserMedia({ media_id });
    onGetUserAdmin({ user_uuid: user?.uuid }).then((res) => {
      setMedia(res?.media);
    });
  };

  const handleReloadPics = async () => {
    onGetUserAdmin({ user_uuid: user?.uuid }).then((res) => {
      setMedia(res?.media);
    });
  };

  const handleModalCloseInternal = () => {
    setMedia(null);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiModalInternal onClose={handleModalCloseInternal} isLoading={media === null}>
        <UIModalBody
          sx={{
            bgcolor: 'transparent'
          }}
        >
          <UIPageTitle title={'Foto Utente'} />
          {media?.map((pic, index) => (
            <Card
              key={index}
              sx={{
                mb: '10px'
              }}
            >
              <AspectRatio maxW="400px" ratio={4 / 3}>
                <Image src={pic?.media_link} objectFit="cover" />
              </AspectRatio>
              <CardActions
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <UIButton variant={'cancel'} icon={<IconTrash stroke={1.5} size="1rem" />} onClick={() => handleDeleteMedia(pic?.id)} />
              </CardActions>
            </Card>
          ))}
        </UIModalBody>
        <UIModalButtons>
          <DropzoneFormikInput
            variant={'account_media'}
            setFieldValue={() => {}}
            field={'icon'}
            setLoading={() => {}}
            folder={'account'}
            label={'Carica immagine'}
            callback={handleReloadPics}
            extra_data={{ account_uuid: user?.uuid }}
          />
        </UIModalButtons>
      </UiModalInternal>
    </UIModal>
  );
};
export default ModalUserPicsDoctor;
