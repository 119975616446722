import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

export const UILoader = ({ loading = false }) => {
  return (
    <Box
      className={'general_loading'}
      w={'100%'}
      h={'100%'}
      position={'absolute'}
      top={0}
      left={0}
      bg={'rgba(150,150,150,0.4)'}
      display={loading ? 'flex' : 'none'}
      justifyContent={'center'}
      alignItems={'center'}
      zIndex={1500}
      cursor={'wait'}
    >
      <Spinner width={'50px'} height={'50px'} thickness="4px" speed="2s" emptyColor="white" color="#FAC000" size="xl" />
    </Box>
  );
};
